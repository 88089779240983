<template>
  <div class="detail">
    <div class="detail-header">
        <div class="title">
          <h3>{{ clickItem.title }}</h3>
        </div>
    </div>
    <!-- 内容 -->
    <div class="detail-content" :class="{ 'detail-content-type': clickData.type == '4' }">
      <mavon-editor v-if="clickItem.questionContent" :value="clickItem.questionContent" defaultOpen="preview"
        :boxShadow="false" style="z-index: 1; max-height: 50vh !important; overflow-x: hidden;padding:30px 20px;border-radius:30px 0 0 30px" 
        :editable="false" :scrollStyle="true" :subfield="false" :toolbarsFlag="false" class="makeEditor"
        :class="{ 'left-content': clickData.type == '4' }" :language="editorLanguage">
      </mavon-editor>
      <div class="answer-box" v-if="clickData.type == '1'">
        <el-checkbox-group v-model="choiceAnswer" style="width: 80%" v-if="choiceAnswer" @change="changeChoice">
          <el-row class="list-option" v-if="clickItem.optionList[0].questionOption">
            <el-col :span="20">
              <el-checkbox label="A">{{
              clickItem.optionList[0].questionOption
              }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionList[1].questionOption">
            <el-col :span="20">
              <el-checkbox label="B">{{
              clickItem.optionList[1].questionOption
              }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionList[2].questionOption">
            <el-col :span="20">
              <el-checkbox label="C">{{
              clickItem.optionList[2].questionOption
              }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionList[3].questionOption">
            <el-col :span="20">
              <el-checkbox label="D">{{
              clickItem.optionList[3].questionOption
              }}</el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
      <!-- 代码编辑器 -->
      <div v-else-if="clickData.type == '4'" style="
          width: 48%;
          float: left;
          position: relative;
          height: 450px;
          margin: 20px 0 0 0;
          /* overflow: hidden; */
        ">
        <aceEditor v-model="clickItem.answers" @init="editorInit" lang="java" theme="monokai" width="100%" height="450"
          :options="aceOptions"></aceEditor>
        <div class="answer-select" style="
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            width: 100px;
            display: none;
          ">
          <el-select v-model="programVal" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in programOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="run-code-box">
          <div class="arrow-down-icon" @click="runFlag = true">
            <i class="el-icon-arrow-up"></i>
          </div>
          <el-row type="flex" justify="space-around">
            <el-col :span="6">
              <div class="run-code-results" @click="runFlag = true">
                {{ $t('test.autumn') }}
              </div>
            </el-col>
            <el-col :span="6"> </el-col>
            <el-col :span="6">
              <div class="run-code-results run-code-perform"></div>
              <el-button type="danger" class="run-code-perform" icon="el-icon-caret-right" @click="runCode">{{
              $t('test.executeCode') }}</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="run-code-dialog" v-show="runFlag">
          <div class="arrow-up-icon" @click="runFlag = false">
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-row type="flex" class="run-boder-bottom" justify="space-around">
            <el-col :span="6">
              <div class="run-code-results black">{{ $t('test.autumn') }}</div>
            </el-col>
            <el-col :span="6"> </el-col>
            <el-col :span="6">
              <div class="run-code-results run-code-perform"></div>
              <el-button type="danger" class="run-code-perform" icon="el-icon-caret-right" @click="runCode">{{
              $t('test.executeCode') }}</el-button>
            </el-col>
          </el-row>
          <div class="run-code-content">
            <span :class="{ red: runRedError }">{{ runCOdeContent }}</span>
          </div>
        </div>
      </div>
      <div v-else class="editor-box">
        <mavon-editor :toolbars="toolbars" v-model="clickItem.answers" class="mavonEditor" :language="editorLanguage" style="box-shadow:none" />
      </div>
    </div>

    <div class="operation-box">
      <div class="btn-box">
        <el-button type="primary" class="last" @click="$emit('last')">
          <i class="el-icon-arrow-up"></i>
        </el-button>
        <el-button type="primary" class="next" @click="$emit('next')" :disabled="buttonAnti">
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </div>
      <!-- <el-button
        type="primary"
        class="next"
        v-if="tableDataLen > currentLen"
        @click="$emit('next')"
        >{{ $t('test.nextQuestion') }}</el-button
      >
      <el-button
        type="primary"
        class="next"
        v-else
        @click="$emit('submitClick')"
        >{{ $t('test.submit') }}</el-button
      > -->
    </div>
  </div>
</template>

<script>
import 'mavon-editor/dist/css/index.css'
import Cookies from 'js-cookie'
import { post } from '@/api/http'
import aceEditor from 'vue2-ace-editor'
export default {
  name: 'test',
  props: {
    clickItem: {
      type: Object,
      default() {
        return {}
      },
    },
    clickData: {
      type: Object,
      default() {
        return {}
      },
    },
    tableDataLen: {
      type: Number,
      default() {
        return 0
      },
    },
    currentLen: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  watch: {
      choiceAnswer (newName, oldName) {
          console.log(newName,'a');
      },
      clickItem: {
          // 第一次加载显示为空，因此当结束时间不为空时渲染
          handler(newName, oldName) {
            if(newName.type==1){
              this.choiceAnswer = []
              if(newName.answers && newName.answers!==''){
                if(newName.answers.length>1){
                  this.choiceAnswer = [...newName.answers.split(',')]
                }else if(newName.answers.length==1){
                  this.choiceAnswer.push(newName.answers)
                }
              }
            }
          },
        deep: true,
        immediate: true,
      },
  },
  data() {
    return {
      content: '',
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: false, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      editorLanguage: Cookies.get('language'),

      runCOdeContent: '',
      aceOptions: {
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        printMarginColumn: 30,
        displayIndentGuides: false, // 显示参考线
        enableEmmet: true, // 启用Emmet
        tabSize: 6, // 标签大小
        fontSize: 14, // 设置字号
        useWorker: true, // 使用辅助对象
        showPrintMargin: false, //去除编辑器里的竖线
        enableMultiselect: true, //     选中多处
        readOnly: false, // 是否只读
        showFoldWidgets: true, // 显示折叠部件
        fadeFoldWidgets: true, // 淡入折叠部件
        wrap: true, //换行
      },
      runFlag: false,
      runRedError: false,

      programVal: 'JAVA',
      programOptions: [
        {
          label: 'JAVA',
          value: 'JAVA',
        },
      ],
      choiceAnswer:[],//选择题答案
      checked:false,
      buttonAnti:false,
    }
  },
  components: {
    aceEditor,
  },
  mounted() { 
    console.log(this.clickData);
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    seeAnswer() {
      this.$emit('add', this.defaultData)
    },
    labelSet(label) {
      if (label) {
        return label.toString()
      } else {
        return ''
      }
    },
    /**
     * 选择题点击后赋值给当前题目
     * 根据是否有 answers 属性判断是否显示题目已做
     */
    changeChoice(e){
      this.clickItem.answers = e.toString()
    },
    runCode() {
      post('/exam-answer/answer-programming-run', {
        source: this.clickItem.answers,
      }).then((res) => {
        this.runFlag = true
        if (res.code == 1) {
          this.runRedError = false
          this.runCOdeContent = res.data
          this.clickItem.runningResult = res.data
          //this.$message.success(res.msg)
        } else {
          this.runRedError = true
          this.runCOdeContent = res.message
          this.clickItem.runningResult = res.message
          //this.$message.error(res.data)
        }
      })
    },

    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...
      // 设置语言
      require('brace/mode/java')
      require('brace/snippets/java')
      // 设置主题 按需加载
      require('brace/theme/monokai')
    },
  },
}
</script>

<style scoped>
.detail-content .left-content {
  width: 48%;
  float: left;
  min-height: 450px;
}

.arrow-down-icon {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}

.arrow-up-icon {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}

.run-code-results {
  line-height: 60px;
  color: #fff;
  cursor: pointer;
}

.run-code-perform {
  float: right;
  margin-top: 10px;
}

.run-code-dialog {
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  height: 275px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 10;
  box-sizing: border-box;
}

.run-boder-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.black {
  color: #000;
}

.red {
  color: red;
}

.detail {
  height: calc(100vh - 167px);
  min-width: 100%;
  overflow: auto;
}

.detail-header {
  padding: 20px 40px;
  background-color: #333;
  font-size: 18px;
  border-radius: 50px 0 0 50px;
}

.title {
  display: flex;
  justify-content: space-between;
}
.title h3{
  line-height: 40px;
  font-size: 16px;
  color: #fff;
}

.star {
  width: 120px;
  text-align: right;
}

.detail-meta p {
  margin: 15px 0;
}

.detail-tag .el-tag {
  border-radius: 50px;
}

/* answer */
.detail-content {
  overflow-x: hidden;
}

.detail-content-type {
  height: 550px;
  overflow: hidden;
}

.content-box {
  min-height: 250px;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px dashed var(--el-border-color-base);
}

.editor-box{
  background-color: #fff;
  padding:10px;
  border-radius: 30px;
}

/* 选择题 */
.answer-box{
  padding:20px;
  background-color: #fff;
  border-radius: 30px;
}
::v-deep .answer-box .el-checkbox__label{
  font-size: 18px;
  vertical-align:-2px
}
::v-deep .answer-box .el-checkbox__input .el-checkbox__inner{
  width: 18px;
  height: 18px;
}
::v-deep .answer-box .el-checkbox__input .el-checkbox__inner::after{
  width: 6px;
  height: 9px;
}
::v-deep .answer-box .el-checkbox__input .el-checkbox__inner:hover{
  border-color: #F44640;
}
::v-deep .answer-box .el-checkbox__input .el-checkbox__inner:focus{
  border-color: #F44640;
}
::v-deep .answer-box .el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #F44640;
  border-color: #F44640;
}



/* 按钮 */
.operation-box{
  display: flex;
  margin:50px 0;
  justify-content: center
}
.operation-box .el-button{
    height: 66px;
    width: 66px;
    border-radius: 50%;
    color: #fff;
    border:none;
}
.operation-box .el-button:first-child{
    margin-right: 40px;
}
.operation-box .el-button i{
    font-weight: bold;
    font-size: 24px;
}
.operation-box .el-button.el-button--primary {
    background:linear-gradient(to right, #FA6455 , #F23C39) ;
}


/* collapse*/

.el-collapse {
  border: none;
}

::v-deep div[role='tab'] {
  width: 150px;
}

::v-deep div[role='tab'] .el-collapse-item__header {
  border: none;
}

::v-deep div[role='tab'] .el-collapse-item__header .el-button {
  margin-bottom: 20px;
}

::v-deep div[role='tab'] .el-collapse-item__arrow {
  display: none;
}

::v-deep .el-collapse-item__wrap {
  border: none;
}

::v-deep .el-collapse-item__wrap .el-collapse-item__content {
  padding-bottom: 0;
}

::v-deep .el-collapse-item__wrap .content-box {
  margin-bottom: 0;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}


.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}

.list-option {
  /*line-height: 70px;*/
  padding: 10px;
}

.detail-content {
  position: relative;
}

.makeEditor {
  margin: 20px 0;
  min-height: 100px;
  height: auto !important;
}


.run-code-box {
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: -60px;
  background: #272822;
  border-top: 1px solid rgb(91, 184, 207);
  z-index: 10;
}

.run-code {
  position: absolute;
  left: 1%;
}

.run-code span {
  font-size: 12px;
  text-align: center;
  color: rgb(226, 130, 130);
}

.run-code-content {
  width: 100%;
  height: 215px;
  padding: 15px;
}

.ace_editor {
  float: left;
}
</style>
<style>
.ace_gutter-cell:first-of-type {
  padding-top: 15px;
}

.ace_scroller {
  padding-top: 15px;
}
</style>
