<template>
  <div class="add-temp">
    <!-- 头部筛选 -->
    <el-row :gutter="22">
      <el-col :span="24">
        <div class="form-header">
          <el-row>
            <el-col :span="2">
              <el-dropdown trigger="click" :hide-on-click="false">
                <el-button type="primary" size="small">
                  {{ $t('question.filter')
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-checkbox-group v-model="positionList" @change="getQuestionList">
                            <el-checkbox class="label-item" v-for="item in positionData" :key="item.id" :label="item">{{
                            item.positionName }}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.position') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-checkbox-group v-model="labelList" @change="getQuestionList">
                            <el-checkbox class="label-item" v-for="item in labelData" :key="item.id" :label="item">{{
                            item.labelName }}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.skill') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-radio-group v-model="checkType" @change="getQuestionList">
                            <el-radio :label="$t('question.choiceq')"></el-radio>
                            <!-- <el-radio :label="$t('question.trueFalse')"></el-radio> -->
                            <el-radio :label="$t('question.fillInq')"></el-radio>
                            <el-radio :label="$t('question.QandA')"></el-radio>
                            <el-radio :label="$t('question.programq')"></el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.qtype') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-radio-group v-model="difficulty" @change="getQuestionList">
                            <el-radio :label="$t('question.easy')"></el-radio>
                            <el-radio :label="$t('question.medium')"></el-radio>
                            <el-radio :label="$t('question.difficult')"></el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.difficulty') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <!-- <el-popover placement="right" width="400" trigger="hover">
                      <el-date-picker v-model="year" @change="getQuestionList" type="year" value-format="yyyy"
                        format="yyyy" placeholder="选择年份">
                      </el-date-picker>
                      <li slot="reference" class="type-li">
                        {{ $t('question.year') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover> -->
                    <!-- 来源日期 -->
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <!-- <el-date-picker @change="getList" v-model="year" format="yyyy" value-format="yyyy" type="year">
                          </el-date-picker> -->
                          <el-date-picker
                            v-model="year"
                            type="daterange"
                            range-separator=" - "
                            value-format="yyyy-MM-dd"
                            :start-placeholder="$t('candidate.startDate')"
                            @change="fomatTime(year,1)"
                            :end-placeholder="$t('candidate.endDate')">
                          </el-date-picker>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.sourceTime') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-checkbox-group v-model="sourceList" @change="getQuestionList">
                            <el-checkbox class="label-item" v-for="item in sourceData" :key="item.id" :label="item">{{
                              item.name }}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.source') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <!-- 共享 -->
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-radio-group v-model="share" @change="getQuestionList">
                            <el-radio :label="$t('question.yes')"></el-radio>
                            <!-- <el-radio :label="$t('question.no')"></el-radio> -->
                            <el-radio :label="$t('question.questionBank')"></el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.share') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <!-- 创建人 -->
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <UserCheck v-model="userList" @update="getQuestionList"></UserCheck>
                      <li slot="reference" class="type-li">
                        {{ $t('question.creater') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <!-- 创建日期 -->
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <div class="check-box">
                          <el-date-picker
                            v-model="cTime"
                            type="daterange"
                            range-separator=" - "
                            value-format="yyyy-MM-dd"
                            :start-placeholder="$t('candidate.startDate')"
                            @change="fomatTime(cTime,2)"
                            :end-placeholder="$t('candidate.endDate')">
                          </el-date-picker>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        {{ $t('question.createTime') }}
                        <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <ClientCheck v-model="endList" @update="getQuestionList"></ClientCheck>
                      <li slot="reference" class="type-li">
                        EndClient<i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <ClientCheck v-model="partnerList" @update="getQuestionList"></ClientCheck>
                      <li slot="reference" class="type-li">
                        Partner<i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <ClientCheck v-model="vendor1List" @update="getQuestionList"></ClientCheck>
                      <li slot="reference" class="type-li">
                        Vendor1<i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="600" trigger="hover">
                      <ClientCheck v-model="vendor2List" @update="getQuestionList"></ClientCheck>
                      <li slot="reference" class="type-li">
                        Vendor2<i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="21">{{ $t('question.selected') }}：
              <!-- 职位 -->
              <el-tag v-for="tag in positionList" :key="tag.id" closable @close="tagsClose(tag, 'position')"
                type="info">{{ tag.positionName }}</el-tag>
              <!-- 标签 -->
              <el-tag v-for="tag in labelList" :key="tag.id" closable @close="tagsClose(tag, 'label')" type="info">{{
              tag.labelName }}</el-tag>
              <!-- 题型 -->
              <el-tag v-if="checkType" closable @close="tagClose('checkType')" type="info">{{ checkType }}</el-tag>
              <!-- 难度 -->
              <el-tag v-if="difficulty" closable @close="tagClose('difficulty')" type="info">{{ difficulty }}</el-tag>
              <!-- 年份 -->
              <!-- <el-tag v-if="year" closable @close="tagClose('year')" type="info">{{ year }}</el-tag> -->
               <!-- 来源日期 -->
              <el-tag v-if="year" closable @close="tagClose('year')" type="info">{{
              sourceTime.startTime + ' - ' + sourceTime.endTime
              }}</el-tag>
              <!-- 创建日期 -->
              <el-tag v-if="cTime" closable @close="tagClose('ctime')" type="info">{{
              sourceTime.startCreateTime + ' - ' + sourceTime.endCreateTime
              }}</el-tag>
              <!-- 共享 -->
              <el-tag v-if="share" closable @close="tagClose('share')" type="info">{{ share }}</el-tag>
              <!-- 来源 -->
              <el-tag v-for="tag in sourceList" :key="tag.id" closable @close="tagsClose(tag, 'source')" type="info">{{
              tag.name }}</el-tag>
              <el-tag v-for="tag in userList" :key="tag.id" closable @close="tagsClose(tag, 'creater')" type="info">{{
              tag.username }}</el-tag>
              <!-- 客户 -->
              <el-tag v-for="tag in endList" :key="tag.id" closable @close="tagsClose(tag, 'endClient')" type="info">{{
              tag.name }}</el-tag>
              <el-tag v-for="tag in partnerList" :key="tag.id" closable @close="tagsClose(tag, 'partner')" type="info">
                {{ tag.name }}</el-tag>
              <el-tag v-for="tag in vendor1List" :key="tag.id" closable @close="tagsClose(tag, 'vendor1')" type="info">
                {{ tag.name }}</el-tag>
              <el-tag v-for="tag in vendor2List" :key="tag.id" closable @close="tagsClose(tag, 'vendor2')" type="info">
                {{ tag.name }}</el-tag>
            </el-col>
            <el-col :span="1">
              <el-button type="text">
                <svg-icon icon-class="close" class="close" />
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="22" type="flex" justify="space-between">
      <el-col :span="5">
        <!-- 左侧列表 -->
        <div class="side-card">
          <div class="side-top">
            <h4>
              {{ $t('question.total') + '：'
              }}{{ tableData.length ? tableData.length : '0' }}
              {{ $tc('question.question', tableData.length) }}
            </h4>
          </div>
          <el-scrollbar style="height: 100%">
            <ul class="side-list">
              <li class="list-item" v-for="item in tableData" :key="item.id" @click="questionClick(item)">
                <el-row>
                  <el-col :span="21">
                    <div class="item-title">
                      {{ item.title }}
                      <el-tag effect="dark" type="success" size="small" v-if="item.difficultyGrade == '1'">{{
                      $t('question.easy') }}
                      </el-tag>
                      <el-tag effect="dark" type="warning" size="small" v-if="item.difficultyGrade == '2'">{{
                      $t('question.medium') }}
                      </el-tag>
                      <el-tag effect="dark" type="danger" size="small" v-if="item.difficultyGrade == '3'">{{
                      $t('question.difficult') }}
                      </el-tag>
                    </div>
                    <div class="item-meta">
                      <el-row>
                        <el-col :span="15">
                          <p>
                            {{
                            $t('question.category') +
                            '：' +
                            labelSet(item.labelName)
                            }}
                          </p>
                          <p>{{ $t('question.grade') + '：' + item.score }}</p>
                        </el-col>
                        <el-col :span="9">
                          <el-tooltip class="box-item" effect="light" :content="
                            item.type == '1'
                              ? $t('question.choiceq')
                              : item.type == '2'
                              ? $t('question.fillInq')
                              : item.type == '3'
                              ? $t('question.QandA')
                              : $t('question.programq')
                          " placement="top">
                            <p>
                              {{ $t('question.type') + '：'
                              }}{{
                              item.type == '1'
                              ? $t('question.choiceq')
                              : item.type == '2'
                              ? $t('question.fillInq')
                              : item.type == '3'
                              ? $t('question.QandA')
                              : $t('question.programq')
                              }}
                            </p>
                          </el-tooltip>
                          <p>
                            {{ $t('question.time') + '：' + item.answerTime }}
                            {{ $tc('question.min', item.answerTime) }}
                          </p>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
                <svg-icon v-if="subId.indexOf(item.id) == '-1'" @click="questionAdd(item)" class="list-checkbox"
                  icon-class="add" :size="30" :color="'#333399'" />
              </li>
            </ul>
          </el-scrollbar>
        </div>
        <el-pagination small style="background: #fff" layout="total,prev, pager, next" :page-size="pageConfig.pageSize"
          @current-change="handleCurrentChange" :current-page="pageConfig.pageNo" :total="pageConfig.total">
        </el-pagination>
      </el-col>
      <el-col :span="15" style="padding: 0px">
        <!-- 试题详情 -->
        <div class="detail-card">
          <el-scrollbar style="height: 100%; width: 100%" v-loading="loading">
            <QuestionContent @questionAdd="questionAdd" :clickItem="clickItem" :clickData="clickData" :subId="subId" />
          </el-scrollbar>
        </div>
      </el-col>
      <!-- 数据统计 -->
      <el-col :span="4">
        <div class="right-card">
          <div class="right-header">{{ $t('question.dataStatistics') }}</div>
          <el-divider></el-divider>
          <div class="info">
            <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlytimes')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.answerTimes') }}：{{countData.count}}
            </p>
            <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlycorrect')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.passNum') }}：{{countData.okCount}}
            </p>
            <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlyfaild')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.failNum') }}：{{countData.failCount}}
            </p>
            <!-- <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlylongest')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.longestTime') }}：{{countData.maxTime || 0}}{{ $t('question.min') }}
            </p>
            <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlyshotest')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.shortestTime') }}：{{countData.MinTime || 0}}{{ $t('question.min') }}
            </p> -->
            <!-- <p>
              <el-tooltip class="item" effect="dark" :content="$t('question.onlymediancorrect')" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              {{ $t('question.medianTime') }}：13{{ $t('question.min') }}
            </p> -->
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import QuestionContent from './content'
import ClientCheck from '@/components/client-select.vue'
import UserCheck from '@/components/user-select.vue'

export default {
  name: 'add',
  components: { QuestionContent, ClientCheck, UserCheck },
  props: {
    rowData: {
      type: Array,
      default() {
        return []
      },
    },
    dialogType: {
      type: String,
      default() {
        return ''
      },
    },
    paperId: {
      type: String,
      default() {
        return ''
      },
    },
  },
  watch: {
    endClient(val) {
      console.log(val)
    },
  },
  data() {
    return {
      value: '',
      value1: '',
      searchType: '',
      checkType: '',
      difficulty: '',
      share: '',
      isPublic: '',
      year: '',
      cTime: '',
      sourceTime:{},
      labelList: [],
      labelData: [],
      sourceList: [],
      sourceData: [],
      positionList: [],
      positionData: [],
      clientN: '',
      userList: [],
      clientData: [],
      clientList: [], //选中所有客户
      endList: [], //选中endClient客户
      partnerList: [], //选中partner
      vendor1List: [], //选中vendor1
      vendor2List: [], //选中vendor2
      clientN: '', //搜索客户名称
      pageSet: {
        pageSize: 12,
        total: 0,
        pageNo: 1,
      },
      pageConfig: {
        pageSize: 10,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
      checked: [],
      tableData: [],
      clickItem: { title: this.$t('question.unknown') },
      clickData: {},
      loading: true,
      subId: [],
      countData:{}
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getQuestionList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getQuestionList()
    },
    clearAdd() {
      this.formData = {}
      this.$emit('clear')
    },
    getQuestionList() {
      let type = ''
      let difficultyGrade = ''

      switch (this.checkType) {
        case this.$t('question.choiceq'):
          type = '1'
          break
        case this.$t('question.fillInq'):
          type = '2'
          break
        case this.$t('question.QandA'):
          type = '3'
          break
        case this.$t('question.programq'):
          type = '4'
          break
        default:
          break
      }
      switch (this.difficulty) {
        case this.$t('question.easy'):
          difficultyGrade = '1'
          break
        case this.$t('question.medium'):
          difficultyGrade = '2'
          break
        case this.$t('question.difficult'):
          difficultyGrade = '3'
          break
        default:
          break
      }
      // 共享
      switch (this.share) {
        case this.$t('question.yes'):
          this.isPublic = 1
          break
        // case this.$t('question.no'):
        //   this.isPublic = ''
        //   break
        case this.$t('question.questionBank'):
          this.isPublic = 2
          break
        default:
          break
      }
      let userId = this.objToIdArr(this.userList)
      let sourceId = this.objToIdArr(this.sourceList)
      let posiLabelId = this.posiGetLabelId(this.positionList)
      let labelId = this.objToIdArr(this.labelList)
      let labels = this.dedupe([...labelId, ...posiLabelId])
      let endId = this.objToIdArr(this.endList)
      let partnerId = this.objToIdArr(this.partnerList)
      let vendor1Id = this.objToIdArr(this.vendor1List)
      let vendor2Id = this.objToIdArr(this.vendor2List)
      post('/question-store/list', {
        limit: 1000,
        page: 1,
        type: type,
        // year: this.year,
        startTime:this.sourceTime.startTime,
        endTime:this.sourceTime.endTime,
        startCreateTime:this.sourceTime.startCreateTime,
        endCreateTime:this.sourceTime.endCreateTime,
        difficultyGrade: difficultyGrade,
        labelId: labels.toString(),
        questionSourceId: sourceId.toString(),
        endClient: endId.toString(),
        partner: partnerId.toString(),
        vendorOne: vendor1Id.toString(),
        vendorTwo: vendor2Id.toString(),
        createUserId: userId.toString(),
        isPublic: this.isPublic,
      }).then((res) => {
        if (res.code == 1) {
          let subId = []
          if (this.rowData.length) {
            this.rowData.forEach((item) => {
              subId.push(item.id)
            })
          }
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
          if (res.data.data.length) {
            this.questionClick(res.data.data[0])
          }
          this.subId = subId
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 去重
    dedupe(arr) {
      return arr.reduce((pre, cur) => {
        let result = pre.some((item) => {
          return item == cur
        })
        return !result ? pre.concat(cur) : pre
      }, [])
    },
    posiGetLabelId(list) {
      let labelArr = [],
        arr = []
      list.forEach((x) => {
        labelArr = [...x.labelDictList]
      })
      arr = labelArr.map((item) => {
        return item.labelDictId
      })
      return arr
    },
    objToIdArr(list) {
      let arr = []
      list.forEach((item) => {
        arr.push(item.id)
      })
      return arr
    },
    // 单选标签关闭
    tagClose(tag) {
      switch (tag) {
        // case 'year':
        //   this.year = ''
        //   break
        case 'year':
          this.year = ''
          this.fomatTime(this.year,1)
          break
        case 'ctime':
          this.cTime = ''
          this.fomatTime(this.cTime,2)
          break
        case 'difficulty':
          this.difficulty = ''
          break
        case 'checkType':
          this.checkType = ''
          break
        case 'share':
          this.share = ''
          this.isPublic = ''
          break
        default:
          break
      }
      this.getQuestionList()
    },
    // 多标签关闭
    tagsClose(tag, type) {
      //console.log(tag)
      console.log(this.clientList)
      switch (type) {
        case 'position':
          this.positionList.splice(this.positionList.indexOf(tag), 1)
          break
        case 'label':
          this.labelList.splice(this.labelList.indexOf(tag), 1)
          break
        case 'source':
          this.sourceList.splice(this.sourceList.indexOf(tag), 1)
          break
        case 'creater':
          this.userList.splice(this.userList.indexOf(tag), 1)
          break
        case 'endClient':
          this.endList.splice(this.endList.indexOf(tag), 1)
          break
        case 'partner':
          this.partnerList.splice(this.partnerList.indexOf(tag), 1)
          break
        case 'vendor1':
          this.vendor1List.splice(this.vendor1List.indexOf(tag), 1)
          break
        case 'vendor2':
          this.vendor2List.splice(this.vendor2List.indexOf(tag), 1)
          break
        default:
          break
      }
      this.getQuestionList()
    },
    labelSet(label) {
      return label.toString()
    },
    questionClick(item) {
      let url = ''
      if (item.type == '1') {
        url = '/question-store/find-id-choice/'
      } else {
        url = '/question-store/find-id-essay/'
      }
      get(url + item.id).then((res) => {
        //console.log(res.data, 2)
        this.clickItem = res.data
        this.clickData = item
        this.loading = false
      })
      this.getCountData()
    },

    questionAdd(item) {
      this.$prompt(
        this.$t('question.enterSort'),
        this.$t('question.confirmAdd'),
        {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
        }
      )
        .then(({ value }) => {
          post('/paper-question/insert', {
            paperId: this.paperId,
            questionId: item.id,
            questionNumber: value,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: this.$t('question.addSuccess'),
            })
            this.subId.push(item.id)
            this.$emit('getList', {})
          })
        })
        .catch(() => { })
      console.log(item)
    },
    fomatTime(year,type){
      if(year && year!==''){
        if(type==1){
          this.sourceTime.startTime=year[0]
          this.sourceTime.endTime=year[1]
        }else{
          this.sourceTime.startCreateTime=year[0]
          this.sourceTime.endCreateTime=year[1]
        }
        this.getQuestionList()
      }else{
        if(type==1){
          this.sourceTime.startTime=''
          this.sourceTime.endTime=''
        }else{
          this.sourceTime.startCreateTime=''
          this.sourceTime.endCreateTime=''
        }
      }
    },
    getLabelData() {
      post('/dict-label/list', {
        page: 1,
        limit: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.labelData = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getSourceData() {
      post('/questionSource/getList', {}).then((res) => {
        if (res.code == 1) {
          this.sourceData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getPositionData() {
      post('/dict-position/getList', {}).then((res) => {
        if (res.code == 1) {
          this.positionData = res.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    searchClient() {
      console.log(123)
      this.pageSet.pageNo = 1
      this.getClientData()
    },
    currentChange(val) {
      this.pageSet.pageNo = val
      this.getClientData()
    },
    getClientData() {
      post('/enterpriseCustomer/getPage', {
        page: this.pageSet.pageNo,
        limit: this.pageSet.pageSize,
        name: this.clientN,
      }).then((res) => {
        if (res.code == 1) {
          this.clientData = res.data.data
          this.pageSet.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getCountData() {
      post('/question-store/lookQuestionCount',).then((res) => {
        if (res.code == 1) {
          this.countData = res.data
        }
      })
    },
  },
  mounted() {
    this.getPositionData()
    this.getQuestionList()
    this.getLabelData()
    this.getSourceData()
    this.getClientData()
    this.getCountData()
  },
}
</script>

<style scoped>
.add-temp {
  padding: 15px;
  background-color: #ececf4;
}

.form-header {
  padding: 12px 20px;
  height: 35px;
  background-color: #fff;
  margin-bottom: 10px;
}

.el-dropdown button {
  background-color: #efefff;
  border-color: #a9a9e3;
  color: #333399;
}

.form-header .el-col {
  line-height: 35px;
}

.side-card {
  background-color: #fff;
  height: calc(100vh - 167px);
  overflow: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.side-card .side-top {
  padding: 10px 20px;
  border-bottom: 1px solid #ebebf5;
}

.side-card .side-top h4 {
  font-size: 14px;
}

.side-card .side-list {
  height: calc(100vh - 207px);
}

.list-item {
  padding: 15px;
  /*height: 105px;*/
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

.list-checkbox {
  position: absolute;
  right: 15px;
  top: 15px;
}

.list-item p {
  line-height: 35px;
}

.item-title {
  font-size: 16px;
  color: #333;
  line-height: 31px;
  margin-bottom: 5px;
}

.item-meta p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-tag {
  margin-top: 15px;
}

.operate {
  line-height: 100px;
}

.el-tag {
  margin-left: 20px;
}

.list-item:hover {
  background-color: #f5f5fa;
}

/* center */
.detail-card {
  background-color: #fff;
  border-radius: 2px;
  min-width: 100%;
}

/* right */
.right-card {
  background-color: #fff;
  border-radius: 2px;
  height: calc(100vh - 167px);
}

.right-header {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.right-card .info {
  padding: 10px 20px;
}

.right-header .info .item {
  color: #515151;
}

.right-card .info p {
  line-height: 40px;
  font-size: 14px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}

.label-item {
  width: 41%;
  line-height: 28px;
}

/* pop带搜索客户弹窗样式 */
.pop-search {
  margin: 0px auto;
  width: 50%;
  display: block;
}

.client .check-box .label-item {
  margin: 5px 10px;
}

.typeList {
  max-height: 800px;
  overflow-y: scroll;
}
</style>
