<template>
  <div class="question-add">
    <h3>{{ seeObj.title }}</h3>
    <el-form
      :inline="true"
      ref="elForm"
      :model="seeObj"
      size="medium"
      label-width="100px"
      label-suffix="："
    >
      <el-form-item
        :label="$t('question.qtype')"
        class="stan-width"
        prop="type"
      >
        <span v-if="seeObj.type == 1">{{ $t('question.choiceq') }}</span>
        <span v-if="seeObj.type == 2">{{ $t('question.fillInq') }}</span>
        <span v-if="seeObj.type == 3">{{ $t('question.QandA') }}</span>
        <span v-if="seeObj.type == 4">{{ $t('question.programq') }}</span>
      </el-form-item>
      <!-- <el-form-item
        :label="$t('question.year')"
        class="stan-width readonly-cover"
        prop="year"
      >
        <span>{{ seeObj.year }}</span>
      </el-form-item> -->

      <el-form-item
        :label="$t('question.grade')"
        class="stan-width"
        prop="score"
      >
        <span>{{ seeObj.score }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('question.time')"
        class="stan-width"
        prop="answerTime"
      >
        <span
          >{{ seeObj.answerTime }}
          {{ $tc('question.min', seeObj.answerTime) }}</span
        >
      </el-form-item>
      <el-form-item
        :label="$t('question.difficulty')"
        class="stan-width readonly-cover"
        prop="difficultyGrade"
      >
        <span v-if="seeObj.difficultyGrade == 1">{{
          $t('question.easy')
        }}</span>
        <span v-if="seeObj.difficultyGrade == 2">{{
          $t('question.medium')
        }}</span>
        <span v-if="seeObj.difficultyGrade == 3">{{
          $t('question.difficult')
        }}</span>
      </el-form-item>

      <el-form-item
      :label="$t('question.skill')"
      class="stan-width readonly-cover"
      prop="labelId"
      >
        <el-tag type="info">
          {{ returnName(seeObj.labelId, 'skill') }}
        </el-tag>
      </el-form-item>
      <template v-if="seeObj.active != 1">
        <el-form-item label="EndClient" class="stan-width" prop="labelId">
          <span>{{ seeObj.endClientName }}</span>
        </el-form-item>
        <el-form-item label="Partner" class="stan-width" prop="labelId">
          <span>{{ seeObj.partnerName }}</span>
        </el-form-item>

        <el-form-item label="Vendor1" class="stan-width" prop="labelId">
          <span>{{ seeObj.vendorOneName }}</span>
        </el-form-item>
        <el-form-item label="Vendor2" class="stan-width" prop="labelId">
          <span>{{ seeObj.vendorTwoName }}</span>
        </el-form-item>

        <el-form-item
          :label="$t('question.source')"
          class="stan-width readonly-cover"
          prop="labelId"
        >
          <span>{{ returnName(seeObj.sourceId, 'source') }}</span>
        </el-form-item>
      </template>

      <el-form-item
        :label="$t('question.creater')"
        class="stan-width"
        prop="labelId"
      >
        <span>{{ createUser(seeObj.createUserName) }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('question.sourceTime')"
        class="stan-width"
        prop="labelId"
      >
        <span>{{ seeObj.year }}</span>
      </el-form-item>
      <br />

      <el-form-item
        :label="$t('question.createTime')"
        class="stan-width"
        prop="labelId"
      >
        <span>{{ seeObj.createTime }}</span>
      </el-form-item>
      <template v-if="seeObj.active != 1">
        <el-form-item
          :label="$t('interview.remarks')"
          class="stan-width"
          style="width: 60%"
          prop="labelId"
        >
          <span>{{ seeObj.remarks }}</span>
        </el-form-item>
      </template>
    </el-form>
    <div class="see">
      <!-- <h2>{{seeObj.title}}</h2> -->
      <mavon-editor
        v-if="seeObj.content"
        :value="seeObj.content"
        defaultOpen="preview"
        :boxShadow="false"
        style="z-index: 1; height: 50vh"
        :editable="false"
        :subfield="false"
        :toolbarsFlag="false"
        class="makeEditor"
      >
      </mavon-editor>
      <el-button
        type="primary"
        size="mini"
        v-if="seeObj.answers"
        @click="isShow = !isShow"
        style="margin-top: 20px"
        >{{ $t('question.checkAnswer') }}</el-button
      >
      <mavon-editor
        v-if="isShow && seeObj.answers"
        :value="seeObj.answers"
        defaultOpen="preview"
        :boxShadow="false"
        style="z-index: 1; height: 50vh"
        :editable="false"
        :subfield="false"
        :toolbarsFlag="false"
        class="makeEditor"
      >
      </mavon-editor>
    </div>
    <div class="boontbtn">
      <el-button type="info" size="small" @click="clearAdd">{{
        $t('common.cancel')
      }}</el-button>
    </div>
    <div></div>
  </div>
</template>

<script>
import { post, del, get, putUrl, uploadImg } from '@/api/http'
import axios from 'axios'
import baseURL from '@/api/baseURL.js'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
// import Tinymce from '@/components/Tinymce'
import 'mavon-editor/dist/css/index.css'
import ClientCheck from '../choice/client-select.vue'

export default {
  name: 'add',
  props: {
    // seeObj: {
    //     type: Object,
    //     default() {
    //         return {};
    //     }
    // },
    shortType: {
      type: Number,
      default() {
        return 3
      },
    },
    dialogType: {
      type: String,
      default() {
        return ''
      },
    },
  },
  components: {
    ClientCheck,
  },
  data() {
    return {
      content: ``,
      seeObj: {},
      clientType: '',
      positionList: [],
      checked: [],
      input: '',
      labelData: [],
      sourceData: [],
      optionData: {},
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      baseURL: baseURL,
      img_file: {},
      img_file1: {},
      editorLanguage: Cookies.get('language'),
      isShow: false,
    }
  },
  methods: {
    createUser(value) {
      if (this.seeObj.active == 1) {
        if (value) {
          let d = value.split('_')[0]
          return d
        } else {
          return value
        }
      } else {
        return value
      }
    },
    getLabelData() {
      post('/dict-label/list', {
        page: 1,
        limit: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.labelData = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getSourceData() {
      post('/questionSource/getList', {}).then((res) => {
        if (res.code == 1) {
          this.sourceData = res.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    setData(data) {
      this.seeObj = data
    },
    clearAdd() {
      this.$emit('clear')
    },
    returnName(id, type) {
      let name = ''
      if (type == 'source') {
        this.sourceData.map((x) => {
          if ((x.id == id)) {
            name = x.name
          }
        })
        // return name
      } else if (type == 'skill') {
        this.labelData.map((x) => {
          if ((x.id == id)) {
            name = x.labelName
          }
        })
      }
      return name
    },
  },
  mounted() {
    this.getLabelData()
    this.getSourceData()
  },
}
</script>

<style scoped>
.question-add {
  width: 98%;
}
.question-add h3 {
  color: #333;
  padding: 0 20px 20px;
}
.question-add >>> .el-form,
.question-add >>> .el-form-item__label {
  color: #80809d;
}
.all-width {
  width: 100%;
}
.stan-width {
  width: 25%;
  margin-right: 0;
  margin-bottom: 10px;
}
.stan-width .el-tag {
  border-radius: 20px;
  background-color: #ddddee;
  padding: 5px 15px;
  height: 28px;
  line-height: 18px;
}
.all-width >>> .el-form-item__content {
  width: calc(100% - 150px);
}
.stan-width >>> .el-form-item__content {
  width: calc(100% - 150px);
}

.select-width {
  width: 75%;
  margin-right: 0;
}
.select-width >>> .el-form-item__content {
  width: calc(100% - 150px);
}
.select-width >>> .el-form-item__content .el-select {
  width: 100%;
}

.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}
.list-option {
  padding: 15px;
}
.makeEditor >>> .hljs {
  background-color: #f6f8fa;
}

.see {
  padding: 20px 60px;
}
.makeEditor {
  margin: 20px 0;
  min-height: 100px;
  height: auto !important;
}
.makeEditor >>> .hljs {
  background-color: #f6f8fa;
}
.answer-box {
  padding: 15px;
}
.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}
.list-option {
  /*line-height: 70px;*/
  padding: 10px;
}

.readonly-cover >>> .el-form-item__content:after {
  display: block;
  height: 100%;
  width: 100%;
  /* background: #3289a3; */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}
</style>
