<template>
    <div class="user">
        <div class="three">
            <el-button type="primary" size="small" @click="addForm()">{{$t('setup.addUser')}}</el-button>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        prop="phone"
                        :label="$t('setup.phone')"
                        >
                </el-table-column>
                <el-table-column
                        prop="email"
                        :label="$t('setup.email')">
                </el-table-column>
                <el-table-column
                        prop="username"
                        :label="$t('setup.username')">
                </el-table-column>

                <el-table-column
                        prop="status"
                        :label="$t('common.status')">
                    <template slot-scope="scope">
                        <el-switch
                                @change="changeStatus(scope.row)"
                                :active-value="'true'"
                                :inactive-value="'false'"
                                v-model="scope.row.status">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="360"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-button type="info" size="mini" @click="operation('see',scope.row)">{{$t('common.view')}}</el-button>
                        <el-button type="primary" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        <el-button type="success" size="mini" @click="operation('role',scope.row)">{{$t('setup.role')}}</el-button>
                        <el-button type="danger" size="mini" @click="operation('reset',scope.row)">{{$t('setup.resetPsw')}}</el-button>
                        <!--<el-tooltip class="item" effect="light" content="移交企业" placement="top-start">-->
                            <!--<el-button type="warning" size="mini" @click="operation('edit',scope.row)">移交企业</el-button>-->
                        <!--</el-tooltip>-->
                        <!--<el-tooltip class="item" effect="light" content="删除" placement="top-start">-->
                            <!--<el-button type="danger" size="mini" @click="operation('del',scope.row)">删除</el-button>-->
                        <!--</el-tooltip>-->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageConfig.pageSize"
                layout="total, prev, pager, next"
                :total="pageConfig.total">
            </el-pagination>
        </div>
        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="dialogType=='add'?$t('common.add'):$t('common.edit')" :visible.sync="addDialog" :before-close="clearAdd" width="30%" >
                <AddTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @update="saveUpdate" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
        <!--查看-->
        <div v-if="seeDialog">
            <el-dialog center :title="$t('common.view')" :visible.sync="seeDialog" :before-close="clearAdd" width="30%" >
                <SeeTemp ref="seeRef" :rowData="rowData" :dialogType="dialogType" @clear="clearAdd"></SeeTemp>
            </el-dialog>
        </div>
        <!--角色-->
        <div v-if="roleDialog">
            <el-dialog center :title="$t('common.view')" :visible.sync="roleDialog" :before-close="clearAdd" width="30%" >
                <RoleTemp ref="roleRef" :rowData="rowData" :dialogType="dialogType" @add="saveRole" @clear="clearAdd"></RoleTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    import SeeTemp from './see'
    import RoleTemp from './role'
    export default {
        name: "position",
        components: {AddTemp,SeeTemp,RoleTemp},
        data() {
            return {
                pageConfig: {
                    pageSize: 10,
                    total: 0,
                    pageNo: 1,
                    pageSizes: this.$pageSizes,
                },
                tableData: [],
                addDialog:false,
                seeDialog:false,
                roleDialog:false,
                rowData:{},
                dialogType:'add'
            }
        },
        methods:{
            handleCurrentChange(val) {
            this.pageConfig.pageNo = val
            this.getList()
            },
            handleSizeChange(val) {
            this.pageConfig.pageSize = val
            this.getList()
            },
            getList(){
                post("/setting-user/list",{
                    page: this.pageConfig.pageNo,
                    limit: this.pageConfig.pageSize,
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                        this.pageConfig.total = res.data.count
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            addForm(){
                this.rowData={};
                this.dialogType='add';
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
                this.seeDialog=false;
                this.roleDialog=false;
            },
            saveAdd(data){
                console.log(data);
                post("/setting-user/insert",{
                    // enterpriseId:1,
                    ...data
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            saveUpdate(data){
                data.username = data.username
                console.log(data);
                post("/setting-user/updateUser",{
                    ...data
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            changeStatus(data){
                post("/setting-user/updateUserEnabled",{
                    id:data.id,
                    status:data.status
                }).then(res => {
                    if (res.code == 1) {
                        data.status=='true'?
                        this.$message.success(this.$t('setup.opened')):
                        this.$message.success(this.$t('setup.disabled'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            saveRole(data){
                post("/enterpriseRoleController/addUserRole",{
                    ...data
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            operation(type,row){
                if (type=='see'){
                    get('/setting-user/'+row.id).then(res=>{
                        this.rowData={
                            phone:res.data.phone,
                            email:res.data.email,
                            username:res.data.username.split('_')[1],
                        };
                        this.seeDialog=true;
                    })
                }
                if (type=='edit'){
                    get('/setting-user/'+row.id).then(res=>{
                        this.rowData={
                            id:row.id,
                            phone:res.data.phone,
                            email:res.data.email,
                            enterpriseId:res.data.enterpriseId,
                            username:res.data.username.split('_')[1],
                        };
                        this.dialogType='edit'
                        this.addDialog=true;
                    })
                }
                if (type=='role'){
                    this.rowData = row
                    this.dialogType='role'
                    this.roleDialog=true;
                }
                if (type=='reset'){
                    this.$confirm(this.$t('setup.sureToRest')+'123456?', this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        post('/setting-user/reset-password/',{id:row.id}).then(res=>{
                            if (res.code == 1) {
                                // this.$message.success(this.$t('setup.resetSuccess'));
                                this.$message.success(this.$t('setup.restpsd')+'123456');
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }).catch(() => {

                    });
                }
                if (type=='del'){
                    this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        del("/setting-user/delete/" + row.id).then(res => {
                            if (res.code == 1) {
                                this.$message.success(this.$t('common.success'));
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }).catch(() => {

                    });
                }
            }
        },
        mounted(){
            this.getList();
        }
    }
</script>

<style scoped>

</style>
