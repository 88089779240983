var option = [{
  "name": "美国",
  "code": "USA",
  "city": [{
    "name": "阿肯色",
    "code": "AR",
    "city": [{
      "name": "费耶特维尔",
      "code": "FYV"
    },
    {
      "name": "史密斯堡",
      "code": "FSM"
    },
    {
      "name": "小石城",
      "code": "LIT"
    }
    ]
  },
  {
    "name": "阿拉巴马",
    "code": "AL",
    "city": [{
      "name": "伯明罕",
      "code": "BHM"
    },
    {
      "name": "蒙哥马利",
      "code": "MGM"
    },
    {
      "name": "莫比尔",
      "code": "MOB"
    }
    ]
  },
  {
    "name": "阿拉斯加",
    "code": "AK",
    "city": [{
      "name": "安克雷奇",
      "code": "ANC"
    },
    {
      "name": "费尔班克斯",
      "code": "FAI"
    },
    {
      "name": "朱诺",
      "code": "JNU"
    }
    ]
  },
  {
    "name": "爱达荷",
    "code": "ID",
    "city": [{
      "name": "爱达荷福尔斯",
      "code": "IDA"
    },
    {
      "name": "波卡特洛",
      "code": "PIH"
    },
    {
      "name": "博伊西",
      "code": "BOI"
    },
    {
      "name": "布莱克富特",
      "code": "BLK"
    },
    {
      "name": "科达伦",
      "code": "COE"
    },
    {
      "name": "刘易斯顿",
      "code": "LWS"
    },
    {
      "name": "莫斯科",
      "code": "MJL"
    },
    {
      "name": "墨菲",
      "code": "ZMU"
    },
    {
      "name": "楠帕",
      "code": "NPA"
    },
    {
      "name": "岂彻姆",
      "code": "QKM"
    },
    {
      "name": "森瓦利",
      "code": "SVY"
    },
    {
      "name": "亚美利加瀑布城",
      "code": "YAF"
    }
    ]
  },
  {
    "name": "爱荷华",
    "code": "IA",
    "city": [{
      "name": "达文波特",
      "code": "DVN"
    },
    {
      "name": "得梅因",
      "code": "DSM"
    },
    {
      "name": "锡达拉皮兹",
      "code": "CID"
    }
    ]
  },
  {
    "name": "北达科他",
    "code": "ND",
    "city": [{
      "name": "俾斯麦",
      "code": "BIS"
    },
    {
      "name": "大福克斯",
      "code": "GFK"
    },
    {
      "name": "法戈",
      "code": "FAR"
    },
    {
      "name": "迈诺特",
      "code": "MOT"
    }
    ]
  },
  {
    "name": "北卡罗来纳",
    "code": "NC",
    "city": [{
      "name": "艾许维尔",
      "code": "AEV"
    },
    {
      "name": "杜罕",
      "code": "DHH"
    },
    {
      "name": "格林斯伯勒",
      "code": "GBO"
    },
    {
      "name": "教堂山",
      "code": "CHE"
    },
    {
      "name": "罗利",
      "code": "RAG"
    },
    {
      "name": "洛利杜罕都会区",
      "code": "RDU"
    },
    {
      "name": "夏洛特",
      "code": "CRQ"
    }
    ]
  },
  {
    "name": "宾夕法尼亚",
    "code": "PA",
    "city": [{
      "name": "阿伦敦",
      "code": "AEW"
    },
    {
      "name": "费城",
      "code": "PHL"
    },
    {
      "name": "匹兹堡",
      "code": "PIT"
    }
    ]
  },
  {
    "name": "德克萨斯",
    "code": "TX",
    "city": [{
      "name": "埃尔帕索",
      "code": "ELP"
    },
    {
      "name": "奥斯汀",
      "code": "AUS"
    },
    {
      "name": "达拉斯",
      "code": "DAL"
    },
    {
      "name": "哥帕斯基斯蒂",
      "code": "CRP"
    },
    {
      "name": "交维斯顿",
      "code": "GLS"
    },
    {
      "name": "拉雷多",
      "code": "LRD"
    },
    {
      "name": "麦亚伦",
      "code": "TXC"
    },
    {
      "name": "圣安东尼奥",
      "code": "SAT"
    },
    {
      "name": "休斯敦",
      "code": "HOU"
    }
    ]
  },
  {
    "name": "俄亥俄",
    "code": "OH",
    "city": [{
      "name": "代顿",
      "code": "DYT"
    },
    {
      "name": "哥伦布",
      "code": "CZX"
    },
    {
      "name": "克利夫兰",
      "code": "CLE"
    },
    {
      "name": "托莱多",
      "code": "TOL"
    },
    {
      "name": "辛辛那提",
      "code": "CVG"
    }
    ]
  },
  {
    "name": "俄克拉荷马",
    "code": "OK",
    "city": [{
      "name": "俄克拉荷马城",
      "code": "OKC"
    },
    {
      "name": "诺曼",
      "code": "OUN"
    },
    {
      "name": "塔尔萨",
      "code": "TUL"
    }
    ]
  },
  {
    "name": "俄勒冈",
    "code": "OR",
    "city": [{
      "name": "本德",
      "code": "BZO"
    },
    {
      "name": "波特兰",
      "code": "PDX"
    },
    {
      "name": "达尔斯",
      "code": "DLS"
    },
    {
      "name": "达拉斯",
      "code": "DAC"
    },
    {
      "name": "蒂拉穆克",
      "code": "TLM"
    },
    {
      "name": "格兰茨帕斯",
      "code": "XFX"
    },
    {
      "name": "胡德里弗",
      "code": "HDX"
    },
    {
      "name": "火山口湖",
      "code": "CTR"
    },
    {
      "name": "科瓦利斯",
      "code": "YCV"
    },
    {
      "name": "库斯贝",
      "code": "COB"
    },
    {
      "name": "梅德福",
      "code": "MFR"
    },
    {
      "name": "塞勒姆",
      "code": "SLE"
    },
    {
      "name": "圣海伦斯",
      "code": "STH"
    },
    {
      "name": "斯普林菲尔德",
      "code": "SPY"
    },
    {
      "name": "尤金",
      "code": "EUG"
    }
    ]
  },
  {
    "name": "佛罗里达",
    "code": "FL",
    "city": [{
      "name": "奥兰多",
      "code": "ORL"
    },
    {
      "name": "基韦斯特",
      "code": "EYW"
    },
    {
      "name": "杰克逊维尔",
      "code": "JAX"
    },
    {
      "name": "卡纳维尔角",
      "code": "CPV"
    },
    {
      "name": "罗德岱堡",
      "code": "FLL"
    },
    {
      "name": "迈阿密",
      "code": "MIA"
    },
    {
      "name": "圣彼德斯堡市",
      "code": "PIE"
    },
    {
      "name": "塔拉哈西",
      "code": "TLH"
    },
    {
      "name": "坦帕",
      "code": "TPA"
    }
    ]
  },
  {
    "name": "佛蒙特",
    "code": "VT",
    "city": [{
      "name": "伯灵顿",
      "code": "BTV"
    },
    {
      "name": "拉特兰",
      "code": "RUT"
    },
    {
      "name": "南伯灵顿",
      "code": "ZBR"
    }
    ]
  },
  {
    "name": "哥伦比亚特区",
    "code": "DC",
    "city": [{
      "name": "华盛顿哥伦比亚特区",
      "code": "WAS"
    }]
  },
  {
    "name": "华盛顿",
    "code": "WA",
    "city": [{
      "name": "斯波坎",
      "code": "GEG"
    },
    {
      "name": "塔科马",
      "code": "TTW"
    },
    {
      "name": "西雅图",
      "code": "SEA"
    }
    ]
  },
  {
    "name": "怀俄明",
    "code": "WY",
    "city": [{
      "name": "埃文斯顿",
      "code": "EVD"
    },
    {
      "name": "卡斯珀",
      "code": "CPR"
    },
    {
      "name": "拉勒米",
      "code": "LAR"
    },
    {
      "name": "罗克斯普林斯",
      "code": "RKS"
    },
    {
      "name": "夏延",
      "code": "CYS"
    },
    {
      "name": "谢里登",
      "code": "SHR"
    }
    ]
  },
  {
    "name": "加利福尼亚",
    "code": "CA",
    "city": [{
      "name": "旧金山",
      "code": "SFO"
    },
    {
      "name": "洛杉矶",
      "code": "LAX"
    },
    {
      "name": "圣迭戈",
      "code": "SAN"
    },
    {
      "name": "圣何塞",
      "code": "SJC"
    }
    ]
  },
  {
    "name": "堪萨斯",
    "code": "KS",
    "city": [{
      "name": "阿比林",
      "code": "ABZ"
    },
    {
      "name": "奥弗兰公园",
      "code": "OVL"
    },
    {
      "name": "哈钦森",
      "code": "HCH"
    },
    {
      "name": "堪萨斯城",
      "code": "KCK"
    },
    {
      "name": "莱文沃思",
      "code": "XIA"
    },
    {
      "name": "劳伦斯",
      "code": "LWC"
    },
    {
      "name": "曼哈顿",
      "code": "MHK"
    },
    {
      "name": "托皮卡",
      "code": "TOP"
    },
    {
      "name": "威奇托",
      "code": "ICT"
    }
    ]
  },
  {
    "name": "康涅狄格",
    "code": "CT",
    "city": [{
      "name": "布里奇波特",
      "code": "BDR"
    },
    {
      "name": "达里恩",
      "code": "DAQ"
    },
    {
      "name": "格林尼治",
      "code": "GRH"
    },
    {
      "name": "哈特福德",
      "code": "HFD"
    },
    {
      "name": "米德尔顿",
      "code": "XIN"
    },
    {
      "name": "纽黑文",
      "code": "HVN"
    },
    {
      "name": "韦斯特波特",
      "code": "WPT"
    },
    {
      "name": "沃特伯里",
      "code": "WAT"
    },
    {
      "name": "新不列颠",
      "code": "NWT"
    }
    ]
  },
  {
    "name": "科罗拉多",
    "code": "CO",
    "city": [{
      "name": "阿斯彭",
      "code": "ASE"
    },
    {
      "name": "奥罗拉",
      "code": "AUX"
    },
    {
      "name": "博尔德",
      "code": "WBU"
    },
    {
      "name": "大章克申",
      "code": "GJT"
    },
    {
      "name": "丹佛",
      "code": "DEN"
    },
    {
      "name": "柯林斯堡",
      "code": "FNL"
    },
    {
      "name": "科罗拉多斯普林斯",
      "code": "COS"
    },
    {
      "name": "韦尔",
      "code": "VAC"
    }
    ]
  },
  {
    "name": "肯塔基",
    "code": "KY",
    "city": [{
      "name": "列克星敦",
      "code": "LEX"
    },
    {
      "name": "路易斯维尔",
      "code": "LUI"
    },
    {
      "name": "欧文斯伯勒",
      "code": "OWB"
    }
    ]
  },
  {
    "name": "路易斯安那",
    "code": "LA",
    "city": [{
      "name": "巴吞鲁日",
      "code": "BTR"
    },
    {
      "name": "什里夫波特",
      "code": "SHV"
    },
    {
      "name": "新奥尔良",
      "code": "MSY"
    }
    ]
  },
  {
    "name": "罗德岛",
    "code": "RI",
    "city": [{
      "name": "波塔基特",
      "code": "PAW"
    },
    {
      "name": "克兰斯顿",
      "code": "CQH"
    },
    {
      "name": "纽波特",
      "code": "NPO"
    },
    {
      "name": "普罗维登斯",
      "code": "PVD"
    },
    {
      "name": "韦斯特利",
      "code": "WST"
    },
    {
      "name": "文索基特",
      "code": "SFN"
    },
    {
      "name": "沃威克",
      "code": "UZO"
    }
    ]
  },
  {
    "name": "马里兰",
    "code": "MD",
    "city": [{
      "name": "巴尔的摩",
      "code": "BAL"
    },
    {
      "name": "盖瑟斯堡",
      "code": "GAI"
    },
    {
      "name": "罗克维尔",
      "code": "RKV"
    }
    ]
  },
  {
    "name": "马萨诸塞",
    "code": "MA",
    "city": [{
      "name": "波士顿",
      "code": "BZD"
    },
    {
      "name": "斯普林菲尔德",
      "code": "SFY"
    },
    {
      "name": "伍斯特",
      "code": "ORH"
    }
    ]
  },
  {
    "name": "蒙大拿",
    "code": "MT",
    "city": [{
      "name": "比灵斯",
      "code": "BGS"
    },
    {
      "name": "大瀑布村",
      "code": "GTF"
    },
    {
      "name": "米苏拉",
      "code": "MSO"
    }
    ]
  },
  {
    "name": "密苏里",
    "code": "MO",
    "city": [{
      "name": "哥伦比亚",
      "code": "COV"
    },
    {
      "name": "杰佛逊市",
      "code": "JEF"
    },
    {
      "name": "堪萨斯城",
      "code": "MKC"
    },
    {
      "name": "圣路易斯",
      "code": "STL"
    },
    {
      "name": "斯普林菲尔德",
      "code": "SGF"
    }
    ]
  },
  {
    "name": "密西西比",
    "code": "MS",
    "city": [{
      "name": "比洛克西",
      "code": "BIX"
    },
    {
      "name": "格尔夫波特",
      "code": "GPT"
    },
    {
      "name": "格林维尔",
      "code": "GLH"
    },
    {
      "name": "哈蒂斯堡",
      "code": "HBG"
    },
    {
      "name": "杰克逊",
      "code": "JAN"
    },
    {
      "name": "默里迪恩",
      "code": "MEI"
    },
    {
      "name": "维克斯堡",
      "code": "VKS"
    }
    ]
  },
  {
    "name": "密歇根",
    "code": "MI",
    "city": [{
      "name": "安娜堡",
      "code": "ARB"
    },
    {
      "name": "巴特尔克里克",
      "code": "BTL"
    },
    {
      "name": "贝城",
      "code": "BCY"
    },
    {
      "name": "大急流城",
      "code": "GRR"
    },
    {
      "name": "迪尔伯恩",
      "code": "DEO"
    },
    {
      "name": "底特律",
      "code": "DET"
    },
    {
      "name": "弗林特",
      "code": "FNT"
    },
    {
      "name": "怀恩多特",
      "code": "WYD"
    },
    {
      "name": "卡拉马袓",
      "code": "AZO"
    },
    {
      "name": "兰辛",
      "code": "LAN"
    },
    {
      "name": "马斯基根",
      "code": "MKG"
    },
    {
      "name": "庞菷亚克",
      "code": "PTK"
    },
    {
      "name": "萨吉诺",
      "code": "SGM"
    },
    {
      "name": "苏圣玛丽",
      "code": "SSM"
    },
    {
      "name": "沃伦",
      "code": "WAM"
    },
    {
      "name": "休伦港",
      "code": "PHN"
    }
    ]
  },
  {
    "name": "缅因",
    "code": "ME",
    "city": [{
      "name": "班戈",
      "code": "BNQ"
    },
    {
      "name": "波特兰",
      "code": "POL"
    },
    {
      "name": "刘易斯顿",
      "code": "QLW"
    }
    ]
  },
  {
    "name": "明尼苏达",
    "code": "MN",
    "city": [{
      "name": "罗切斯特",
      "code": "RST"
    },
    {
      "name": "明尼阿波利斯",
      "code": "MES"
    },
    {
      "name": "圣保罗",
      "code": "STP"
    }
    ]
  },
  {
    "name": "南达科他",
    "code": "SD",
    "city": [{
      "name": "阿伯丁",
      "code": "ABK"
    },
    {
      "name": "拉皮德城",
      "code": "RAP"
    },
    {
      "name": "苏福尔斯",
      "code": "FSD"
    }
    ]
  },
  {
    "name": "南卡罗来纳",
    "code": "SC",
    "city": [{
      "name": "北查尔斯顿",
      "code": "NTS"
    },
    {
      "name": "查尔斯顿",
      "code": "CHS"
    },
    {
      "name": "哥伦比亚",
      "code": "COV"
    }
    ]
  },
  {
    "name": "内布拉斯加",
    "code": "NE",
    "city": [{
      "name": "奥马哈",
      "code": "OMA"
    },
    {
      "name": "贝尔维尤",
      "code": "XDE"
    },
    {
      "name": "林肯",
      "code": "LNK"
    }
    ]
  },
  {
    "name": "内华达",
    "code": "NV",
    "city": [{
      "name": "埃尔科",
      "code": "EKO"
    },
    {
      "name": "北拉斯维加斯",
      "code": "NVS"
    },
    {
      "name": "弗吉尼亚城",
      "code": "VGI"
    },
    {
      "name": "亨德森",
      "code": "HNZ"
    },
    {
      "name": "卡森城",
      "code": "CSN"
    },
    {
      "name": "拉斯维加斯",
      "code": "LAS"
    },
    {
      "name": "里诺",
      "code": "RNO"
    },
    {
      "name": "斯帕克斯",
      "code": "SPK"
    }
    ]
  },
  {
    "name": "纽约",
    "code": "NY",
    "city": [{
      "name": "布法罗",
      "code": "FFO"
    },
    {
      "name": "罗切斯特",
      "code": "ROC"
    },
    {
      "name": "纽约市",
      "code": "QEE"
    }
    ]
  },
  {
    "name": "特拉华",
    "code": "DE",
    "city": [{
      "name": "多佛",
      "code": "DOR"
    },
    {
      "name": "纽瓦克",
      "code": "NWK"
    },
    {
      "name": "威明顿",
      "code": "ILG"
    }
    ]
  },
  {
    "name": "田纳西",
    "code": "TN",
    "city": [{
      "name": "布利斯托",
      "code": "BSJ"
    },
    {
      "name": "查塔努加",
      "code": "CHA"
    },
    {
      "name": "金斯波特",
      "code": "TRI"
    },
    {
      "name": "孟菲斯",
      "code": "MEM"
    },
    {
      "name": "纳什维尔",
      "code": "BNA"
    },
    {
      "name": "诺克斯维尔",
      "code": "TYS"
    },
    {
      "name": "三城区",
      "code": "YTC"
    },
    {
      "name": "士麦那",
      "code": "MQY"
    },
    {
      "name": "斯普林希尔",
      "code": "RGI"
    },
    {
      "name": "约翰逊城",
      "code": "JCY"
    }
    ]
  },
  {
    "name": "威斯康星",
    "code": "WI",
    "city": [{
      "name": "阿普尓顿",
      "code": "ATW"
    },
    {
      "name": "奥什科什",
      "code": "OSH"
    },
    {
      "name": "格林贝",
      "code": "GBK"
    },
    {
      "name": "基诺沙",
      "code": "ENW"
    },
    {
      "name": "拉克罗斯",
      "code": "LSE"
    },
    {
      "name": "拉辛",
      "code": "RAC"
    },
    {
      "name": "马尼托沃克",
      "code": "MTW"
    },
    {
      "name": "迈迪逊",
      "code": "QMD"
    },
    {
      "name": "密尔沃基",
      "code": "MKE"
    },
    {
      "name": "欧克莱尓",
      "code": "EAU"
    },
    {
      "name": "沃索",
      "code": "AUW"
    },
    {
      "name": "希博伊根",
      "code": "SBM"
    }
    ]
  },
  {
    "name": "维吉尼亚",
    "code": "VA",
    "city": [{
      "name": "弗吉尼亚比奇",
      "code": "VAB"
    },
    {
      "name": "诺福克",
      "code": "ORF"
    },
    {
      "name": "切萨皮克",
      "code": "HTW"
    }
    ]
  },
  {
    "name": "西佛吉尼亚",
    "code": "WV",
    "city": [{
      "name": "查尔斯顿",
      "code": "CRW"
    },
    {
      "name": "亨廷顿",
      "code": "HNU"
    },
    {
      "name": "帕克斯堡",
      "code": "PKB"
    }
    ]
  },
  {
    "name": "夏威夷",
    "code": "HI",
    "city": [{
      "name": "凯卢阿",
      "code": "KHH"
    },
    {
      "name": "檀香山",
      "code": "HNL"
    },
    {
      "name": "希洛",
      "code": "ITO"
    }
    ]
  },
  {
    "name": "新罕布什尔",
    "code": "NH",
    "city": [{
      "name": "康科德",
      "code": "CON"
    },
    {
      "name": "曼彻斯特",
      "code": "MHT"
    },
    {
      "name": "纳舒厄",
      "code": "ASH"
    }
    ]
  },
  {
    "name": "新墨西哥",
    "code": "NM",
    "city": [{
      "name": "阿尔伯克基",
      "code": "ABQ"
    },
    {
      "name": "拉斯克鲁塞斯",
      "code": "LRU"
    },
    {
      "name": "罗斯韦尔",
      "code": "ROW"
    },
    {
      "name": "圣菲",
      "code": "SAF"
    }
    ]
  },
  {
    "name": "新泽西",
    "code": "NJ",
    "city": [{
      "name": "纽瓦克",
      "code": "NRK"
    },
    {
      "name": "帕特森",
      "code": "PAT"
    },
    {
      "name": "泽西城",
      "code": "JEC"
    }
    ]
  },
  {
    "name": "亚利桑那",
    "code": "AZ",
    "city": [{
      "name": "凤凰城",
      "code": "PHX"
    },
    {
      "name": "格兰代尔",
      "code": "GDA"
    },
    {
      "name": "梅萨",
      "code": "MQA"
    },
    {
      "name": "史卡兹代尔",
      "code": "STZ"
    },
    {
      "name": "坦普",
      "code": "TPE"
    },
    {
      "name": "图森",
      "code": "TUC"
    },
    {
      "name": "优玛",
      "code": "YUM"
    }
    ]
  },
  {
    "name": "伊利诺斯",
    "code": "IL",
    "city": [{
      "name": "奥尔顿",
      "code": "ALN"
    },
    {
      "name": "奥罗拉",
      "code": "AUZ"
    },
    {
      "name": "布卢明顿",
      "code": "BLO"
    },
    {
      "name": "丹维尓",
      "code": "DVI"
    },
    {
      "name": "迪卡尔布",
      "code": "DEK"
    },
    {
      "name": "迪凯持",
      "code": "DEC"
    },
    {
      "name": "东圣路易斯",
      "code": "ESL"
    },
    {
      "name": "厄巴纳-香槟",
      "code": "CMI"
    },
    {
      "name": "盖尔斯堡",
      "code": "GSU"
    },
    {
      "name": "卡本代尔",
      "code": "MDH"
    },
    {
      "name": "罗克艾兰",
      "code": "RKI"
    },
    {
      "name": "罗克福德",
      "code": "RFD"
    },
    {
      "name": "诺黙尔",
      "code": "NOM"
    },
    {
      "name": "皮奥里亚",
      "code": "PLA"
    },
    {
      "name": "森特勒利亚",
      "code": "CRA"
    },
    {
      "name": "斯普林菲尔德",
      "code": "SPI"
    },
    {
      "name": "沃其根",
      "code": "UGN"
    },
    {
      "name": "芝加哥",
      "code": "CHI"
    }
    ]
  },
  {
    "name": "印第安那",
    "code": "IN",
    "city": [{
      "name": "埃文斯维尔",
      "code": "EVV"
    },
    {
      "name": "韦恩堡",
      "code": "FWA"
    },
    {
      "name": "印第安纳波利斯",
      "code": "IND"
    }
    ]
  },
  {
    "name": "犹他",
    "code": "UT",
    "city": [{
      "name": "奥格登",
      "code": "OGD"
    },
    {
      "name": "雷登",
      "code": "LTJ"
    },
    {
      "name": "欧仁",
      "code": "OEU"
    },
    {
      "name": "帕克城",
      "code": "PAC"
    },
    {
      "name": "普罗沃",
      "code": "PVU"
    },
    {
      "name": "圣乔治",
      "code": "SGU"
    },
    {
      "name": "西瓦利城",
      "code": "WVC"
    },
    {
      "name": "盐湖城",
      "code": "SLC"
    }
    ]
  },
  {
    "name": "佐治亚",
    "code": "GA",
    "city": [{
      "name": "奥古斯塔",
      "code": "AUT"
    },
    {
      "name": "哥伦布",
      "code": "CZX"
    },
    {
      "name": "梅肯",
      "code": "MCN"
    },
    {
      "name": "沙瓦纳",
      "code": "SAV"
    },
    {
      "name": "亚特兰大",
      "code": "TAT"
    }
    ]
  },
  {
    "name": "美属萨摩亚",
    "code": "ASM",
    "city": [{
      "name": "阿纳",
      "code": "AAN"
    },
    {
      "name": "阿图阿",
      "code": "ATU"
    },
    {
      "name": "艾加伊勒泰",
      "code": "AIT"
    },
    {
      "name": "法塞莱莱阿加",
      "code": "FAA"
    },
    {
      "name": "加盖福毛加",
      "code": "GFG"
    },
    {
      "name": "加加埃毛加",
      "code": "GMG"
    },
    {
      "name": "帕劳利",
      "code": "PAL"
    },
    {
      "name": "萨图帕伊泰阿",
      "code": "SAT"
    },
    {
      "name": "萨瓦伊岛",
      "code": "SAV"
    },
    {
      "name": "图阿马萨加",
      "code": "TUA"
    },
    {
      "name": "瓦奥福诺蒂",
      "code": "VAF"
    },
    {
      "name": "韦西加诺",
      "code": "VAI"
    },
    {
      "name": "乌波卢岛",
      "code": "UPO"
    }
    ]

  }, {
    "name": "美属外岛",
    "code": "UMI"
  }
  ]
}]
export default option