import router from './router'
// import { asyncRoutes, constantRoutes } from './router'
import store from './store'
import { Message } from 'element-ui'
import Layout from '@/layout'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/answer', '/answer/home','/answer/home-new', '/video', '/video/home', '/auth-redirect', '/schedule/getListByEnterpriseId','/answer/test-over'] // no redirect whitelist

export function generaMenu (routes, data) {
  data.forEach(item => {
    // alert(JSON.stringify(item))
    const menu = {
      path: item.url === '#' ? item.menu_id + '_key' : item.url,
      component: item.url === '#' ? Layout : () => require(`@/views${item.url}/index`),
      // hidden: true,
      children: [],
      name: 'menu_' + item.menu_id,
      meta: { title: item.menu_name, id: item.menu_id, roles: ['admin'] }
    }
    if (item.children) {
      generaMenu(menu.children, item.children)
    }
    routes.push(menu)
  })
}
router.beforeEach(async (to, from, next) => {

  // start progress bar
  NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next()
      // next({ path: '/index' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        // next()
        try {
          const currentRoute = await store.dispatch('user/getInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes', currentRoute)
          router.addRoutes(accessRoutes)
          if(accessRoutes.length==0){
            next(`/blank`)
          }else if(accessRoutes.find(x => x.resourceUrl == to.path.slice(0,to.path.lastIndexOf("/")))){
            next(to.path)
          }else{
            next(accessRoutes[0].resourceUrl)
          }
          // if(accessRoutes.length==0){
          //   next(`/blank`)
          // }else{
          //   console.log(accessRoutes.find(x => x.resourceUrl == to.path));
          //   next(accessRoutes[0].resourceUrl)
          //   // next()
          // }
        }catch(error){
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }

  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
