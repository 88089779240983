<template>
  <div class="question-add">
    <el-form :inline="true" ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="150px"
      label-suffix="：">
      <el-form-item :label="$t('question.skill')" class="stan-width" prop="labelId">
        <el-select v-model="rowData.labelId" filterable :placeholder="$t('question.skill')">
          <el-option v-for="item in labelData" :key="item.id" :label="item.labelName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.difficulty')" class="stan-width" prop="difficultyGrade">
        <el-select v-model="rowData.difficultyGrade" :placeholder="$t('question.difficulty')">
          <el-option :label="$t('question.easy')" :value="1"></el-option>
          <el-option :label="$t('question.medium')" :value="2"></el-option>
          <el-option :label="$t('question.difficult')" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.grade')" class="stan-width" prop="score">
        <el-input type="number" v-model="rowData.score" :placeholder="$t('question.grade')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('question.time')" class="stan-width" prop="answerTime">
        <el-input type="number" v-model="rowData.answerTime" :placeholder="$t('question.time')">
          <template slot="append">
            {{
            $tc('question.min', rowData.answerTime)
            }}
          </template>
        </el-input>
      </el-form-item>
      <br />
      <el-form-item :label="$t('question.source')" class="stan-width" prop="sourceId">
        <el-select v-model="rowData.sourceId" :placeholder="$t('question.source')">
          <el-option v-for="item in sourceData" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('question.sourceTime')" class="stan-width" prop="year">
        <el-date-picker v-model="rowData.year" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" style="width:95%" :placeholder="$t('question.sourceTime')">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="EndClient" class="stan-width" prop="endClientName">
        <el-popover placement="bottom" :width="600" trigger="click" @show="client('endClient')">
          <template #reference>
            <el-input v-model="rowData.endClientName" placeholder="EndClient"></el-input>
          </template>
          <ClientCheck v-model="rowData.endClient" :name="rowData.endClientName" v-if="clientType == 'endClient'"
            :prop="'endClientName'" @update="getName"></ClientCheck>
        </el-popover>
      </el-form-item>
      <el-form-item label="Partner" class="stan-width" prop="partnerName">
        <el-popover placement="bottom" :width="600" trigger="click" @show="client('partner')">
          <template #reference>
            <el-input v-model="rowData.partnerName" placeholder="Partner"></el-input>
          </template>
          <ClientCheck v-model="rowData.partner" :name="rowData.partnerName" v-if="clientType == 'partner'"
            :prop="'partnerName'" @update="getName"></ClientCheck>
        </el-popover>
      </el-form-item>
      <br />
      <el-form-item label="Vendor1" class="stan-width" prop="vendorOne">
        <el-popover placement="bottom" :width="600" trigger="click" @show="client('vendorOne')">
          <template #reference>
            <el-input v-model="rowData.vendorOneName" placeholder="Vendor1"></el-input>
          </template>
          <ClientCheck v-model="rowData.vendorOne" :name="rowData.vendorOneName" v-if="clientType == 'vendorOne'"
            :prop="'vendorOneName'" @update="getName"></ClientCheck>
        </el-popover>
      </el-form-item>
      <el-form-item label="Vendor2" class="stan-width" prop="vendorTwo">
        <el-popover placement="bottom" :width="600" trigger="click" @show="client('vendorTwo')">
          <template #reference>
            <el-input v-model="rowData.vendorTwoName" placeholder="Vendor2"></el-input>
          </template>
          <ClientCheck v-model="rowData.vendorTwo" :name="rowData.vendorTwoName" v-if="clientType == 'vendorTwo'"
            :prop="'vendorTwoName'" @update="getName"></ClientCheck>
        </el-popover>
      </el-form-item>
      <el-form-item label="备注" class="stan-width" style="width: 50%" prop="remarks">
        <el-input v-model="rowData.remarks" :placeholder="$t('setup.remarks')"></el-input>
      </el-form-item>
      <br />
      <el-form-item :label="$t('question.questionName')" class="all-width" prop="title">
        <el-input v-model="rowData.title" :placeholder="$t('question.questionName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('question.questionDetail')" class="all-width" required 
      :error="showError2" >
        <mavon-editor :toolbars="toolbars" v-model="rowData.content" ref="md" @imgAdd="imgAdd" @imgDel="imgDel"
          class="makeEditor" :language="editorLanguage" @change="changeRequire2" @blur="changeRequire2"/>
      </el-form-item>
      <el-form-item :label="$t('question.questionContent')" class="all-width" required
      :error="showError">
        <el-row class="title-option">
          <el-col :span="4">{{ $t('question.answer') }}</el-col>
          <el-col :span="18">{{ $t('question.questionContent') }}</el-col>
        </el-row>
        <el-checkbox-group v-model="checked" @change="changeRequire()">
          <el-row class="list-option">
            <el-col :span="4">
              <el-checkbox label="A"></el-checkbox>
            </el-col>
            <el-col :span="18">
              <el-input v-model="rowData.optionA" :placeholder="$t('question.pleasecontent')" style="width: 80%" @change="changeRequire()">
              </el-input>
            </el-col>
          </el-row>
          <el-row class="list-option">
            <el-col :span="4">
              <el-checkbox label="B"></el-checkbox>
            </el-col>
            <el-col :span="18">
              <el-input v-model="rowData.optionB" :placeholder="$t('question.pleasecontent')" style="width: 80%" @change="changeRequire()">
              </el-input>
            </el-col>
          </el-row>
          <el-row class="list-option">
            <el-col :span="4">
              <el-checkbox label="C"></el-checkbox>
            </el-col>
            <el-col :span="18">
              <el-input v-model="rowData.optionC" :placeholder="$t('question.pleasecontent')" style="width: 80%" @change="changeRequire()">
              </el-input>
            </el-col>
          </el-row>
          <el-row class="list-option">
            <el-col :span="4">
              <el-checkbox label="D"></el-checkbox>
            </el-col>
            <el-col :span="18">
              <el-input v-model="rowData.optionD" :placeholder="$t('question.pleasecontent')" style="width: 80%" @change="changeRequire()">
              </el-input>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div class="boontbtn">
      <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm('1')" v-if="dialogType == 'add'">
        {{ $t('question.saveAdd') }}</el-button>
      <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm('2')">{{ $t('common.save') }}
      </el-button>
      <el-button type="info" size="small" @click="clearAdd">
        {{
        $t('common.cancel')
        }}
      </el-button>
    </div>
    <div></div>
  </div>
</template>

<script>
import { post, del, get, putUrl, uploadImg } from "@/api/http";
// import Tinymce from '@/components/Tinymce'
import Cookies from "js-cookie";
import "mavon-editor/dist/css/index.css";
import ClientCheck from "./client-select.vue";

export default {
  name: "add",
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: {
    ClientCheck,
  },
  computed:{
    ifOption: function () {
      return this.checked.length!==0
      &&(this.rowData.optionA&&this.rowData.optionA!=='')
      &&(this.rowData.optionB&&this.rowData.optionB!=='')
      &&(this.rowData.optionC&&this.rowData.optionC!=='')
    },
    ifContent: function () {
      return this.rowData.content&&this.rowData.content!==''
    }
  },
  data() {
    return {
      content: ``,
      // clientN:'',//客户名称
      clientType: "",
      // pageSet:{
      //     pageSize: 12,
      //     total: 0,
      //     pageNo: 1,
      // },
      // endList:[],
      // clientData:[],//客户数据
      showError:'',
      showError2:'',
      noError:false,//提交后不触发手动验证
      rules: {
        difficultyGrade: [{
            required: true,
            message: this.$t('common.pleaseSelect')+this.$t('question.difficulty')+'!',
            trigger: 'blur'
        }],
        score: [{
            required: true,
            message:this.$t("common.pleaseEnter") + this.$t("question.grade") + "!",
            trigger: "blur",
          },],
        answerTime: [{
            required: true,
            message:this.$t("common.pleaseEnter") + this.$t("question.time") + "!",
            trigger: "blur",
          },],
        labelId: [{
            required: true,
            message:this.$t("common.pleaseEnter") + this.$t("question.tag") + "!",
            trigger: "blur",
          },],
        year: [{
            required: true,
            message:this.$t("common.pleaseEnter") + this.$t("question.sourceTime") + "!",
            trigger: "blur",
          },],
          sourceId: [{
            required: true,
            message:this.$t("common.pleaseEnter") + this.$t("question.source") + "!",
            trigger: "blur",
          },],
        title: [{
            required: true,
            message:this.$t("common.pleaseEnter") +this.$t("question.questionName") + "!",
            trigger: "blur",
          },],
        checked: [{
            required: true,
            message:this.$t("common.pleaseEnter") +this.$t("question.questionName") + "!",
            trigger: "blur",
          },],
      },
      positionList: [],
      checked: [],
      input: "",
      labelData: [],
      sourceData: [],
      optionData: {},
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      img_file: {},
      editorLanguage: Cookies.get("language"),
    };
  },
  methods: {
    getLabelData() {
      post("/dict-label/list", {
        page: 1,
        limit: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.labelData = res.data.data;
        } else {
          this.$message.error(res.data);
        }
      });
    },
    getSourceData() {
      post("/questionSource/getList", {}).then((res) => {
        if (res.code == 1) {
          this.sourceData = res.data;
        } else {
          this.$message.error(res.data);
        }
      });
    },
    client(type) {
      this.clientType = type;
    },
    // 搜索客户
    // searchClient(item){
    //     console.log(123);

    // },
    // getClientData() {
    //     post("/enterpriseCustomer/getPage", {
    //         page:this.pageSet.pageNo,
    //         limit:this.pageSet.pageSize,
    //         name:this.clientN,
    //     }).then((res) => {
    //         if (res.code == 1) {
    //         this.clientData = res.data.data;
    //         this.pageSet.total = res.data.count
    //         } else {
    //         this.$message.error(res.data);
    //         }
    //     });
    // },
    // 选中客户
    getName(obj) {
      this.rowData[`${obj.name}`] = obj.value;
      console.log(this.rowData);
    },
    // // 客户翻页
    // currentChange(val) {
    //     this.pageSet.pageNo = val;
    //     this.getClientData();
    // },
    submitForm(type) {
      this.noError = false
      this.$refs["elForm"].validate((valid) => {
        this.changeRequire()
        this.changeRequire2()
        if (!valid||!this.ifOption||!this.ifContent) {
          this.$message.error(this.$t("common.pleaseComplete"));
          return false;
        } else {
          let answers = "";
          if (this.checked) {
            this.checked.forEach((item) => {
              answers += item + ",";
            });
          }
          if (answers) {
            answers = answers.substring(0, answers.length - 1);
          }
          let subData = {
            ...this.rowData,
            answers: answers,
            type: 1,
          };
          subData.labelId && subData.labelId.length !== 0 ?
            subData.labelId = subData.labelId.toString() : ''

          this.$emit("add", subData, type);
          this.noError = true
          this.checked = [];
        }
      });
    },
    clearAdd() {
      this.$emit("clear");
    },
    // 绑定@imgAdd event
    imgAdd(pos, $file) {
      // 第一步.将图片上传到服务器.
      var formdata = new FormData();
      formdata.append("file", $file);
      this.img_file[pos] = $file;
      uploadImg("file-local/upload", formdata).then((res) => {
        console.log(res);
        let _res = res.data;

        if (_res.code == 1) {
          console.log(_res);
          this.$refs.md.$img2Url(
            pos,
            "https://www.jobs-interviews.com/update/" + _res.data
          );
        }
      });
    },
    imgDel(pos) {
      delete this.img_file[pos];
    },
    changeRequire(){
      if(this.ifOption || this.noError){
          this.showError = ''
      }else{
          this.showError = this.$t('question.completeContent')
      }
    },
    changeRequire2(){
      this.$nextTick(()=>{
        if(this.ifContent || this.noError){
            this.showError2 = ''
        }else{
            this.showError2 = this.$t("common.pleaseEnter") + this.$t("question.questionDetail") + "!"
        }
      })
    }
  },
  mounted() {
    this.getLabelData();
    this.getSourceData();

    if (this.rowData) {
      if (this.rowData.answers) {
        this.checked = this.rowData.answers.split(",");
      } else {
        this.checked = [];
      }
      if (this.rowData.year) {
        this.rowData.year = this.rowData.year+ ' 00:00:00'
      }
    } else {
      this.checked = [];
    }
  },
};
</script>

<style scoped>
.question-add {
  width: 98%;
}

.all-width {
  width: 100%;
}

.stan-width {
  width: 25%;
  margin-right: 0;
}

.all-width>>>.el-form-item__content {
  width: calc(100% - 150px);
}

.stan-width>>>.el-form-item__content {
  width: calc(100% - 150px);
}
::v-deep el-form .stan-width .el-select{
  width: 80%;
}

.select-width {
  width: 75%;
  margin-right: 0;
}

.select-width>>>.el-form-item__content {
  width: calc(100% - 150px);
}

.select-width>>>.el-form-item__content .el-select {
  width: 100%;
}

.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}

.list-option {
  padding: 15px;
}

.makeEditor>>>.hljs {
  background-color: #f6f8fa;
}

/* 带搜索客户选择样式 */
.search-select {
  width: 600px;
  height: 300px;
}

.el-select-dropdown__item.hover {
  background-color: #fff;
}

.checkList .el-checkbox-group {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.pop-search {
  margin: 0px auto;
  width: 50%;
  display: block;
}

.checkList .check-box .label-item {
  margin: 5px 10px;
}

.el-checkbox {
  width: 29%;
}
</style>
