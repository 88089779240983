<template>
    <div class="add-temp">
        <el-row>
            <el-col :span="6" :offset="7">
                <el-input v-model="name" size="small" clearable></el-input>
            </el-col>
            <el-col :span="5">
                <el-button type="primary" size="small" icon="el-icon-search" @click="search"></el-button>
                <el-button type="success" size="small" @click="selectAll">{{$t('question.all')}}</el-button>
            </el-col>
        </el-row>
        <el-row class="select-box">
            <el-col>
                <el-checkbox-group 
                v-model="checkList"  
                @change="handleCheckedChange">
                    <el-checkbox :label="item.id" v-for="item in companyList" :key="item.id">{{item.enterpriseName}}</el-checkbox>
                </el-checkbox-group>
            </el-col>
        </el-row>
        <el-pagination
        :page-size="5"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :total="pageConfig.total"
      ></el-pagination>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
  </template>
  
  <script>
  import {post, del, get, putUrl} from "@/api/http";
  
  export default {
      name: "add",
      props:{
        rowData:{
              type: Object,
              default() {
                  return {};
              }
          },
      },
      data() {
          const vm = window.vm;
          return {
              vm: vm,
              checkList:[],
              companyList:[],
              pageConfig: {
                pageSize: 50,
                total: 0,
                pageNo: 1,
                pageSizes: this.$pageSizes,
              },
              name:'',
              checkAll:false
          }
      },
      methods: {
        // 分页
        handleCurrentChange(val) {
        this.pageConfig.pageNo = val
        this.getList()
        },
        handleSizeChange(val) {
        this.pageConfig.pageSize = val
        this.getList()
        },
        search(){
            this.pageConfig.pageNo = 1
            this.getList()
        },
        selectAll(){
            this.checkList = !this.checkAll?this.companyList.map(ele=>ele.id):[]
            this.checkAll = !this.checkAll
        },
        getList(){
            post("/setting-enterprise/getPage",{
            page: this.pageConfig.pageNo,
            limit: this.pageConfig.pageSize,
            enterpriseName:this.name
            }).then(res => {
                if (res.code == 1) {
                    this.companyList=res.data.data
                    res.data.data.map(ele=>{
                        if(ele.choice)
                        this.companyList.push(ele.id) 
                    })
                } else {
                    this.$message.error(res.data);
                }
            });
        },
        handleCheckedChange(value){
            console.log(value);
            this.checkList = value
        },
        submitForm() {
            this.$emit("add",{
            enterpriseInfoId:this.checkAll?[]:this.checkList.toString(),
            questionId:this.rowData.id,
            selectAll:this.checkAll
            })
        },
        clearAdd() {
            this.formData = {};
            this.$emit("clear");
        },
      },
      mounted() {
          this.getList()
      }
    }
  </script>
  
  <style scoped>
  .add-temp{
    padding:0 20px
  }
  .select-box{
    margin: 30px 0;
  }
  ::v-deep .el-checkbox{
    width: 18.3%;
    margin-right: 2%;
    line-height: 35px;
  }
  ::v-deep .el-checkbox:nth-of-type(5n){
    margin-right: 0;
  }
  ::v-deep .select-box .el-checkbox__label{
    white-space: normal;
  }
  </style>
  