export default {
  menu: {
    home: "Home",
    test: "Test",
    interview: "Interview",
    questionBank: "Question Bank",
    statistics: "Statistics",
    setup: "Settings",
    candidate: "Candidate"
  },
  common: {
    operation: "Operation",
    query: "Query",
    reset: "Reset",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    detail: "Detail",
    view: "View",
    save: "Save",
    status: "Status",
    cancel: "Cancel",
    ok: "OK",
    close: "Close",
    pleaseSelect: "Please select ",
    pleaseEnter: "Please enter ",
    position: "Position",
    time: "Time",
    minutes: "Minutes",
    nodata: "No data",
    uploadSuccess: "Uploaded successfully",
    saveSuccess: "Saved successfully",
    pleaseComplete: "Please enter complete information!",
    sureToDel: "Are you sure to delete?",
    tips: "Confirmation",
    delSuccess: "Deleted successfully",
    success: "Successed",
    nodata: "No data",
    all: "All",

    personalInfo: "Personal Info",
    changePsw: "Change Password",
    helpCenter: "Help center",
    feedback: "Problem feedback",
    myFeedback: "My feedback",
    logout: "Log out",

    phoneNumber: "Phone number",
    email: "Email",
    username: "User name",
    modifySuccess: "Modified successfully",
    originPsw: "Original password",
    newPsw: "New password",
    confirmPsw: "Confirm password",
    passwordNotMatch: "The two passwords are inconsistent",
    schedule: "Schedule",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    leisure: "Leisure",
    selected: "Selected",
    optional: "Optional",
    more: "More",
  },
  login: {
    enterLogin: "Enterprise login",
    enterRegist: "Enterprise sign up",
    passwordLogin: "Password login",
    enterName: "Enterprise name",
    loginAccount: "Login account",
    password: "Password",
    remenberAccount: "Remember account",
    forgetPsw: "Forget the password?",
    codeLogin: "Verification code login",
    mobilePhone: "Phone number",
    verifyCode: "Verification Code",
    login: "Login",
    signIn: "Sign in",
    signUp: "Sign up",
    email: "Email",
    confirmPsw: "Confirm password",
    backToLogin: "Back to login",
    pswless6: "The password must be at least 6 digits",
    pswNotMatch: "The two passwords are inconsistent. Please re-enter.",
    notInput_: 'Please do not enter "_"',
    getCode: "Get verification code",
    signUpSuccess: "Sign up succeeded, please login.",
    errorNamePsw: "Wrong user name and password",
    userEnabled: "This user has been disabled, please contact the administrator!",
    codeExpired: "Verification code expired",
    

  },
  home: {
    hello: "Hello",
    trialVersion: 'Your current version is "trial version", ',
    day: " day left | days left",
    upgradeNow: "Upgrade now",
    shortcutMenu: "Shortcut Menu",
    addTest: "Add test",
    addInterview: "Add interview",
    addQuestion: "Add question",
    userSettings: "User settings",
    todayData: "Today's data",
    onlineNum: "Online population",
    joinTestNum: "Number of participant",
    joinInterviewNum: "Number of interviewees",
    hadjoinTestNum: "Number of tested",
    hadjoinInterviewNum: "Number of Interviewed",
    person: " person| persons",
    summaryData: "Summary data",
    unmarkPaperNum: "Paper to be evaluated",
    unTestNum: "Number to be tested",
    unInterviewNum: "To be interviewed",
    announce: "System announcement",
    consult: "Consult",
    help: "Help",
  },
  test: {
    testName: "Test name",
    position: "Position",

    personManage: "Personnel management",
    invitedNum: "Number of invited",
    completedNum: "Number of completed",
    participantNum: "Number of participants",
    noParticipantNum: "Number of non-participants",
    qualifiedNum: "Number of qualified",
    nonQualifiedNum: "Number of Unqualified",
    highestScore: "Highest score",
    lowestScore: "Lowest score",
    qualified: "Qualified",
    unqualified: "Unqualified",

    candidateName: "Candidate name",
    candidatePhone1: "Candidate phone",
    testStatus: "Status",
    queryPerson: "Query person",
    addPerson: "Add person",
    invitedEmail: "Invitee Email",
    name: "Name",
    invitedTime: "Invited time",
    examStartTime: "Start time",
    testStartTime: "Test start time",
    testEndTime: "Test end time",
    costTime: "Cost time",
    score: "Score",
    isqualified: "Qualified or not",
    seeScene: "View the scene",
    manualMark: "Manual marking",
    runCode: "Run Code",
    supportJava: "Only support Java",
    haveRead: "Have read",
    personnelDetail: "Personnel detail",
    candidate: "Candidate name",
    candidateArea: "Country region",
    candidatePhone: "Candidate phone",
    candidateEmail: "Candidate EMAIL",
    candidateGender: "Candidate gender",
    saveAndPublish: "Save and publish",
    male: "Male",
    female: "Female",
    china: "China",
    selectTime: "Select exam start time",
    exceedScore: "Cannot exceed the total score of the question",

    recordLoad: "Recording loading",
    submitTest: "Submit test",

    hour: "hour|hours",
    second: "second|seconds",
    timeIsUp:
      "The examination time is up, please click the OK button to submit the examination, otherwise there is no examination result.",
    overAndSubmit:
      "Confirm that the test questions have been answered. Will you submit this test?",
    autoSubmit: "The test time has expired, and the test paper has been submitted automatically. Do you want to close the page?",
    submitSuccess: "Submitted successfully",
    join: "Join",
    joinSuccess: "Join the room successfully",
    streamFaild: "Streaming failed",
    joinFaild: "Failed to join the room",
    submitOver:'Submitted successfully, you have completed the exam!',

    all: "All",
    waitStart: "Wait to start",
    inProgress: "In progress",
    testEnd: "Test over",
    scoringEnd: "Score over",
    reviewed: "Reviewed",
    uncorrect: "Uncorrected",


    qustionList: "Qustion list",
    submitMark: "Submit marking",
    correctAnswer: "Correct answer",
    notAnswer: "This question has not been answered!",
    standardAns: "The standard answer",
    noStandardAns: "There is no standard answer to this question!",
    lastQuestion: "Last question",
    nextQuestion: "Next question",
    point: " points",

    addTest: "Add test",
    testExplain: "Test description",
    selectPaper: "Select paper",
    testTime: "Test time",
    qualifiedGrade: "Qualified score",
    paperDetail: "Paper details",
    ctime: "Creation time",
    total: "Total",
    question: "Question",
    category: "Category",
    qtype: "Question type",
    grade: "Grades",
    mark: " points",
    addQuestion: "Add question",
    questionName: "Question name",
    time: "Time",
    min: " min| mins",
    question2: " question",
    operation: "Operation",
    personManage: "Personnel management",
    testDetail: "Test details",
    recordplay: "Play video recording",
    answerRecordScreen: "Answer recording screen",

    getInfo: "Getting exam information",
    welcome: "Welcome to",
    examination: "Examination",
    enterTest: "Fill out your resume and take the test",
    invalidAndContect:
      "The current exam is invalid, please contact the administrator",
    phone: "Phone",
    name: "Name",
    verifyCode: "Verification Code",
    vCode: "Code",
    emailCode: "Email code",
    vCodeInvalid: "Verification code has expired.",
    vCodeSent:
      "The verification code has been sent to your mobile phone with tail number ",
    emailCodeSent:
      "The verification code has been sent to you ",
    sentPhone: "",
    openScreenShare:
      "You need to turn on the screen sharing function in this exam. If you check it, you agree to the screen sharing",
    agreeToRecord: "Please tick agree to record",
    join: "Join",
    joinSuccess: "Join successfully",
    getVerifyCode: "Get code",
    noCamaraOrMic:
      "The current device is missing a camera or microphone, please check and try again!",
    examInvalid: "The exam has expired",
    authenticate: "Authentication",
    channel: "channel",
    ifrecordSreen: 'Open record sreen',
    styleType: 'Style',
    defaultStyle: 'Default style',
    newStyle: 'New style',
    notifyMethod: 'Notify Method',
    isVerification: "Verify or not",
    yes: 'Yes',
    no: 'No',
    messages: 'Messages',
    Email: 'Email',
    autumn: "AUTUMN",
    executeCode: "Execute the code",
    submit: "Submit",
    runResultTip: "Run result display area (red font if errors are reported)",

    lastOne:"It's the last question",

  },
  interview: {
    candidateName: "Name",
    phone: "Phone",
    position: "Position",
    startDate: "Start date",
    endDate: "End date",
    addInterview: "Add interview",
    interviewee: "Interviewee",
    creater: "Creater",
    startTime: "Start time",
    endTime: "End time",
    notStart: "Not start",
    inProgress: "In progress",
    completed: "Completed",
    interviewRestult: "Interview restult",
    interviewDetial: "Interview detial",
    viewInterview: "View interview",
    recordPreview: "Screen recording Preview",
    candidateArea: "Country region",
    china: "China",
    candidate: "Candidate",
    resume: "Resume",
    uploadPDF: "Please upload PDF file",
    selectFile: "Select file",
    interviewExplain: "Interview explain",
    interviewer: "Interviewer",
    add: "Add",
    isPrimary: "Primary interviewer or not",
    searchName: "Name search",
    mostThreeInterviewer: "You can only select up to three interviewers!",
    interviewTime: "Interview time",
    interviewLink: "Interview link",
    notifyMethod: "Notification method",
    remarks: "Remarks",
    message: "Message",
    phoneNumber: "Phone number",
    email: "Email",
    publishAndSend: "Publish and send notifications",
    gender: "Gender",
    male: "Male",
    female: "Female",
    interviewTips: `1.The computer must be equipped with a camera and aim at the body. Otherwise, you can't take the test.\n2.During the test, we'll record the screen and video in the whole process. If there is a pop-up dialog, please click agree.`,
    interviewRecordScreen: "Interview recording screen",
    interview: " interview ",
    primaryCantDelete: "The primary interviewer cannot be deleted.",
    addInterviewer: "Add interviewer",

    interviewInfo: "Interview information",
    interviewResume: "Resume",
    interviewVideo: "Video",
    interviewOverview: "Interview overview",
    interviewEva: "Interviewer evaluation",
    interviewScore: "Score",
    attitude: "Attitude",
    character: "Character",
    technology: "Technology",
    experience: "Experience",
    comment: "Comment",
    noResume: "Resume not uploaded yet",
    videoComing: "The video is being generated, please wait",
    verygood: "Very good",
    good: "Good",
    average: "Average",
    bad: "Bad",
    verybad: "Very bad",

    welcome: "Welcome to ",
    expiredOrNotExist: "The current interview has expired or does not exist!",
    ImInterviewer: "I'm the interviewer",
    interviewerIdintity: "Please select the identity of the interviewer",
    errorOrNoIntervewer:
      "Network error or no interviewer in the current interview!",
    ImCandidate: "I'm a candidate",
    enterAfterVerify: "Candidates enter the interview after verification",
    joinNow: "Join now",
    authenticate: "Authentication",
    verifyCode: "Verification Code",
    vCode: "Code",
    channel: "channel",
    getVerifyCode: "Get code",
    noInterview: "No current interview!",
    join: "Join",
    joinSuccessed: "Join successfully",
    noCamaraOrMic:
      "The current device is missing a camera or microphone, please check and try again!",

    viewResume: "View resume",
    viewSelf: "View yourself",
    allMute: "Mute All",
    endInterview: "End the interview",
    startInterview: "Start interview",
    waitInterview: "Waiting for interview",
    clickStart: "Please click the button below to start the interview",
    aboutToBegin: "The interview is about to begin......",
    interviewEnded: "The interview is over",
    notSupportSocket: "Your browser does not support sockets",
    microphone: "Microphone",
    camara: "Camera",
    joinRoom: "Join the room",
    leaveRoom: "Leave the room",
    roomMember: "Room member ",

    pushSuccess: "Streaming succeeded",
    stopPushSuccess: "Stop streaming succeeded",
    invitation: "Save and send the invitation",
    candidateChoice: "Candidate selection in progress",
    candidateReview: "Interviewer review",
    candidateAgree: "Interviewer agree",
    candidateSuccess: "Invitation succeeded",
    selectedDate: "The selected date",
    interviewerConfirm: "Interviewer confirmation",
    generateInterview: "Generate the interview",
    agree: "Agree",
    agreeChange: "Agree and change the time",
    reject: "Reject",
    dateTime: "Year month day",
    sendInvitation: "Send the invitation again",
    choose: "Choose",
    forInterview: "For an interview?",
    chooseInterviewTime: "Candidates choose the interview time",
    inviteInterviewTime: "Invite candidates to select time"

  },
  question: {
    addQuestion: "Add question",
    questionName: "Question name",
    category: "Category",
    qtype: "Question type",
    type: "Type",
    choiceq: "Choice question",
    trueFalse: "true or false",
    QandA: "Question and answer",
    programq: "Programming question",
    fillInq: "Fill-in-the-blanks",
    grade: "Grades",
    time: "Time",
    operation: "Operation",
    filter: "Filter",
    selected: "Selected",
    skill: "Skill",
    difficulty: "Difficulty",
    easy: "Easy",
    medium: "Medium",
    difficult: "Difficult",
    question: " question | questions",
    // apple: 'no apples | one apple | {count} apples',
    total: "Total",
    dataStatistics: "Data statistics",
    answerTimes: "Total of answers",
    passNum: "Number of answers passed",
    failNum: "Number of failed answers",
    longestTime: "Maximum duration",
    shortestTime: "Minimum duration",
    medianTime: "Median duration",
    minute: "minute",
    min: " min| mins",
    year: "Year",
    recommendTime: "Recommended answer time",
    onlytimes: "The data only counts the total number of answers",
    onlycorrect: "The data only counts the number of correct answers",
    onlyfaild: "The data only counts the number of wrong answers",
    onlylongest: "The data only counts the longest time for correct answers",
    onlyshotest:
      "The data only counts the correct answer and the shortest time",
    onlymediancorrect:
      "The data only counts the median time of correct answers",
    checkAnswer: "View answers",
    enterSort: "Please enter sorting",
    confirmAdd: "Confirm to add test questions",
    addSuccess: "Added successfully",
    unknown: "Unknown",

    enterbank: "Enterprise question bank",
    commonbank: "Open question bank",
    entercontent: "Please enter the content",
    skills: "Skills",
    nonSkills: "There is no skill category, please add",
    questionView: "Preview of test questions",
    questionDetail: "Question details",
    questionContent: "Option content",
    answers: "Answers to questions",
    tag: "Label",
    year2: " ",
    answer: "Answer",
    nodata: "No data",
    saveAdd: "Save and add",
    pleasecontent: "Please enter the content",
    completeContent:'Please enter the options and select the correct answer',

    source: "Source",
    sourceTime: "Source Time",
    nonPosition: "No position classification, please add",
    nonSource: "No source classification, please add",
    nonClient: "No customer classification, please add",
    nonUser: "No user classification, please add",
    position: "Position",
    creater: "Creater",
    createTime: "Create Time",
    yes: "Yes",
    no: "No",
    share: "Share",
    sharingRecords: "Sharing Records",
    selectEnterprise: "Select enterprise",
    questionBank: "The question bank",
    openly: "Shared",
    public: "Public",
    check: "Checked",
    search: "Search",
    question: "Question",
    submission: "Submission time",
    submitter: "Submitter",
    review: "Inreview",
    rejected: "Has been rejected",
    auditInfo: "Audit information",
    withdraw: "Withdraw",
    withdrawed: "Has withdraw",
    shareTip: "If you disclose this question, it means that you give up the ownership of this question, and you will share this question in the public question bank and cannot withdraw it. Any enterprise can use this question. Are you sure to disclose this question",
    shareTip1: "After the test is published successfully, the system will automatically issue the corresponding coupon, which can be viewed in the Settings - Enterprise Authorization",
    submitShare: "The share application is submitted successfully. Procedure",
    viewShare: "Click here to view shared records ",
    all:"Select All",
    shareCompany:"Share enterprise",
  },
  statistics: {},
  setup: {
    enterInfo: "Enterprise Information",
    invoiceTit: "Invoice Tittle",
    userSet: "User Settings",
    roleSet: "Role Settings",
    positionSet: "Position Setting",
    paperSet: "Paper Configuration",
    oprationLog: "Operation Log",
    baseInfo: "Essential Information",
    businessInfo: "Business Information",
    CoName: "Company name",
    CoAdress: "Company Address",
    CoScale: "Enterprise scale",
    lessThan50: "Less than 50 people",
    persons: " persons",
    moreThan500: "More than 500 people",
    CoProfile: "Company profile",
    CoPicture: "Enterprise pictures",
    legalrep: "Legal representative",
    registeredCap: "Registered capital",
    incorporationDate: "Date of incorporation",
    businessTerm: "Business term",
    enterType: "Enterprise type",
    registAddr: "Company registered addr.",
    creditCode: "Uniform social credit code",
    businessNature: "Nature of business",

    region: "Region",
    detailAddress: "Detail Address",
    addAddress: "New Address",

    role:"Role",
    rolename:"Role name",
    addRole:"Add role",
    authSet:"Authority Settings",
    disabled:'Disabled',
    opened:'Opened',

    addInvoiceTit: "Add invoice title",
    tittleType: "Tittle type",
    invoiceName: "Invoice name",
    defaultTit: "Default Title",
    invoiceType: "Invoice type",
    enterUnit: "Enterprise unit",
    INDuint: "Individual / non enterprise unit",
    invoicetit: "Invoice tittle",
    taxNum: "Unit tax number",
    registtele: "Registration telephone",
    depositBank: "Bank of deposit",
    bankAccount: "Bank account",
    addUser: "Add user",
    phone: "Phone number",
    email: "Email",
    username: "Username",
    psw: "Password",
    resetPsw: "Reset password",
    sureToRest: "Are you sure to reset password?",
    restpsd: "Password reset to",
    resetSuccess: "Reset successful",
    positionName: "Position name",
    positionSet: "Position Settings",
    addPosition: "Add position",
    addPaper: "Add paper",
    paperName: "Paper name",
    paperExplain: "Paper explain",
    paperSet: "Paper Settings",
    paperEdit: "Edit paper",
    paperView: "View paper",
    questionNum: "Quantity",
    grade: "Grades",
    lastModifyTime: "Last modification time",
    inputValidateEmail:'Please enter a valid email address',
    inputValidatePhoneNumber:'Please enter a valid mobile number',
    paperInuse:'The test paper is in use',

    clientManage: "Client management",
    client: "Client",
    clientName: "Client Name",
    male: "Male",
    female: "Female",
    unknown: "Unknown",
    name: "Name",
    gender: "Gender",
    position: "Position",
    mobilePhone: "Mobile Phone",
    email: "Email",
    address: "Address",
    remarks: "Remarks",
    contacts: "Contacts",
    more: "More",
    addContacts: "Add Contact",
    pleaseFoundClient: "Please create a new customer",
    clientRepeat: "The customer already exists",


    source: "Source",
    paperSource: "Paper Source",
    sourceName: "Source Name",
    addSource: "Add Source",
    setSkill: "Set Skill",

  },
  candidate: {
    fillInformation: "Fill in information",
    candidate: 'Candidate',
    candidateName: "Name",
    phone: "Phone number",
    email: "Email",
    startDate: "Start date",
    endDate: "End date",
    setfield: "Setting field display",
    basicInfo: "Basic information",
    educationIfo: "Education information",
    detailsInfo: "The details information",
    workStatus: "Work status",
    workLocation: "Future workplace requirements",

    whatsAccount: "Whatsup account",
    linkedinAccount: "Linkedin account",
    facebookAccount: "Facebook account",
    jobHuntingStatus: "Job status",
    weChatAccount: "WeChat account",
    cptSponsorship: "CPT sponsorship is needed",
    marketingServices: "Whether to accept Marketing Services",
    hOneBSponsorship: "H1B sponsorship is needed",
    gcSponsorship: "GC sponsorship is needed",
    optExtensionSponsorship: "opt extension sponsorship is needed",
    technicalTraining: "Willingness to receive technical training",
    degree: "Highest Degree",
    currentPlaceWork: "Current Working Place",
    currentPlaceResidence: "Current Residence",
    schoolName: "School name",
    studyTime: "Studying time",
    profession: "Degree",
    degree: "Education",
    achievement: "Grade",
    academicDegree: "Degree",
    workExperience: "Work experience",
    companyName: "Company",
    workingTime: "working time",
    position: "Job title",
    request: "No request",
    specificPlace: "Have to go to a specific place",
    goSomewhere: "Tend to go somewhere",
    other: "Other",
    accepted: "Accepted",
    paid: "compensation",
    freeCharge: "Free of charge",
    depends: "It Depends",
    na: "NA",
    postgraduate: "Postgraduate",
    undergraduate: "Undergraduate",
    aLevel: "A-level",
    juniorMiddleSchool: "Junior middle school",
    primarySchool: "Primary school",
    city: "City",
    cityName: "City Name",
    detailedAddress: "Detailed address",
    saveTest: "Save and enter the test",
    skipTest: "Skip and enter the test",
    Leaveavailabl: "Leave. - Be available at any time",
    jobWithin: "On the job - within a month",
    jobConsider: "On the job - Consider opportunities",
    jobYet: "On the job. - Not yet"
  }
};
