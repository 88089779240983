import { render, staticRenderFns } from "./contacts-view.vue?vue&type=template&id=563ef9aa&scoped=true&"
import script from "./contacts-view.vue?vue&type=script&lang=js&"
export * from "./contacts-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563ef9aa",
  null
  
)

export default component.exports