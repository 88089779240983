<template>
  <div class="home">
    <div class="navbar">
      <el-row>
        <el-col :span="4">
          <div class="nav-img">
            <img src="../assets/u6376.png" alt="" style="height: 50px" />
          </div>
        </el-col>
        <el-col :span="16">
          <div class="nav-menu">
            <el-menu
              :default-active="navIndex"
              class="el-menu-demo"
              mode="horizontal"
              background-color="transparent"
              text-color="#ffffff"
              active-text-color="#ffffff"
              router
              @select="handleSelect"
            >
              <el-menu-item :index="route.resourceUrl" v-for="route in permission_routes" :key="route.id">
                <span v-if="language == '中文'">{{ route.resourceName}}</span>
                <span v-else>{{ route.resourceNameEnglish}}</span>
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="nav-menu language">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ language }}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh-Cn">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="info-drop">
            <el-dropdown @command="loginCommand">
              <span class="el-dropdown-link">
                <img src="@/assets/login/user.png" alt="" /><i
                  class="el-icon-caret-bottom"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="info">{{
                  $t('common.personalInfo')
                }}</el-dropdown-item>
                <el-dropdown-item command="update">{{
                  $t('common.changePsw')
                }}</el-dropdown-item>
                <!-- <el-dropdown-item>{{
                  $t('common.helpCenter')
                }}</el-dropdown-item>
                <el-dropdown-item>{{ $t('common.feedback') }}</el-dropdown-item> -->
                <!-- <el-dropdown-item>{{
                  $t('common.myFeedback')
                }}</el-dropdown-item> -->
                <el-dropdown-item command="schedule">{{
                  $t('common.schedule')
                }}</el-dropdown-item>
                <el-dropdown-item command="loginout">{{
                  $t('common.logout')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </div>
    <router-view />

    <!--个人信息-->
    <div v-if="infoDialog">
      <el-dialog
        center
        :title="$t('common.personalInfo')"
        :visible.sync="infoDialog"
        :before-close="clearDialog"
        width="30%"
      >
        <InfoDialog @clear="clearDialog"></InfoDialog>
      </el-dialog>
    </div>
    <!--修改密码-->
    <div v-if="updateDialog">
      <el-dialog
        center
        :title="$t('common.changePsw')"
        :visible.sync="updateDialog"
        :before-close="clearDialog"
        width="30%"
      >
        <UpdatePassword @clear="clearDialog"></UpdatePassword>
      </el-dialog>
    </div>
    <!-- 日程安排 -->
    <div v-if="selTimeDialog">
      <el-dialog
        class="schedule-dialog"
        append-to-body
        :visible.sync="selTimeDialog"
        width="80%"
      >
        <InterviewTime @clear="clearDialog"></InterviewTime>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'
import InfoDialog from './HomeDialog/InfoDialog'
import UpdatePassword from './HomeDialog/UpdatePassword'
import InterviewTime from '@/components/InterviewTime'
export default {
  name: 'Home',
  components: { InfoDialog, UpdatePassword, InterviewTime },
  computed:{
    ...mapGetters([
      'permission_routes',
      'name',
      'role',
    ]),
  },
  data() {
    return {
      navIndex: '1',
      language: '',
      infoDialog: false,
      updateDialog: false,
      selTimeDialog: false,
    }
  },
  watch: {
    $route: {
    handler: function (route) {
        this.navIndex = route.redirectedFrom
    },
    immediate: true,
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleCommand(lang) {
      console.log(lang)
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success',
      })
      location.reload()
    },
    loginCommand(command) {
      console.log(command)
      if (command == 'loginout') {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }
      if (command == 'info') {
        this.infoDialog = true
      }
      if (command == 'update') {
        this.updateDialog = true
      }
      if (command == 'schedule') {
        this.selTimeDialog = true
      }
    },
    clearDialog() {
      this.infoDialog = false
      this.updateDialog = false
      this.selTimeDialog = false
    },
  },
  mounted() {
    // active
    if(this.$route.path!=='/index'){
      this.navIndex = this.$route.path.slice(0,this.$route.path.lastIndexOf("/"))
    }else{
      this.navIndex = '/index'
    }
    // 语言
    if (Cookies.get('language') == 'en') {
      this.language = 'English'
    } else {
      this.language = '中文'
    }
  },
}
</script>
<style>
.navbar {
  width: 100%;
  height: 68px;
  background-color: #333399;
}

.nav-img {
  text-align: left;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 68px;
}

.nav-menu {
  height: 68px;
  float: left;
  line-height: 68px;
}
.language {
  /*width: 50px;*/
}

.nav-my {
  height: 68px;
}

.nav-menu ul {
  height: 68px;
}

.nav-menu ul .el-menu-item{
  height: 68px;
  line-height: 68px;
  margin: 0 30px;
  /* font-weight: bold; */
}
.nav-menu ul .el-menu-item span{
  font-weight: bold;
}

.nav-menu ul li:hover {
  background-color: transparent !important;
  color: #0085ff !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid transparent !important;
  background-color: #5857a8 !important;
}
.el-dropdown-link {
  color: #ffffff;
}

.el-dropdown-link img {
  width: 30px;
  margin-right: 5px;
}
.info-drop {
  height: 68px;
  float: right;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.info-drop .el-dropdown-link {
  display: flex;
  align-items: center;
}
.schedule-dialog .el-icon-close {
  color: #fff !important;
}
</style>
