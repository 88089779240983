<template>
    <div class="userSelete">
        <div class="userSearch">
            <el-input :placeholder="$t('interview.searchName')" v-model="userName" class="input-with-select" @keyup.enter.native="goSearch()">
                <el-button slot="append" icon="el-icon-search" @click="goSearch" ></el-button>
            </el-input>
        </div>
        <div class="userList">
            <el-checkbox-group v-model="checkList" v-if="userList.length>0">
                <el-checkbox v-for="item in userList" :key="item.id" :label="item.id" @change="checkboxChange">{{item.username}}</el-checkbox>
            </el-checkbox-group>
            <div v-else class="tip">{{$t('question.nodata')}}</div>
        </div>
        <div v-if="noSub">  <p style="color: red">{{$t('interview.mostThreeInterviewer')}}</p></div>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitUser()" :disabled="noSub">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearUser">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    import {post} from "@/api/http";
    export default {
        name: "userSelete",
        props:{
            userData:{
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data(){
            return{
                userName:'',
                userList:[],
                checkList:[],
                noSub:false
            }
        },
        watch:{
            userData:{
                handler(newName, oldName) {
                    let that=this;
                    if (newName.length){
                        newName.forEach(item=>{
                            that.checkList.push(item.userId)
                        })
                    }
                },
                deep: true,
                immediate: true
            }
        },
        methods:{
            goSearch(){
                this.getUserList()
            },
            getUserList(){
                post("/setting-user/list", {
                    page: 1,
                    limit: 1000,
                    username:this.userName
                }).then(res => {
                    if (res.code == 1) {
                        this.userList = res.data.data
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            submitUser(){
                console.log(this.checkList);
                let userData=[];
                this.checkList.map(item=>{
                    this.userList.forEach(user=>{
                        if (item==user.id){
                            userData.push({
                                userId:user.id,
                                username:user.username
                            })
                        }
                    })
                })
                this.$emit("add",userData);
            },
            clearUser(){
                this.checkList = [];
                this.$emit("clear");
            },
            checkboxChange(){
                if (this.checkList.length>3){
                    this.noSub=true;
                }else {
                    this.noSub=false;
                }
            }
        },
        mounted(){
            this.getUserList()
        }
    }
</script>

<style scoped>
    .userSelete{
        width: 300px;
        margin: 0 auto;
    }
    .userList{

    }
.userList>>>.el-checkbox{
    display: block;
    margin: 15px 0;
}
.tip{
    color:#999;
    text-align: center;
    padding:20px;
}
</style>
