<template>
  <div class="result">
    <!--<h2 style="text-align: center;margin-bottom: 40px;">-->
    <!--{{resultData.positionName}}面试-{{resultData.candidateName}}-->
    <!--</h2>-->
    <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane :label="$t('interview.interviewInfo')" name="first">
        <div class="tabs-box">
          <h3 class="tabs-title">{{ $t('interview.interviewOverview') }}</h3>
          <div class="tabs-card">
            <p>
              {{ $t('interview.candidate') }}：{{ resultData.candidateName }}-{{
              resultData.candidateEmail
              }}-{{ resultData.candidatePhone }}
            </p>
            <p>
              {{ $t('interview.interviewer') }}：<span v-for="item in resultData.list" :key="item.id">{{ item.username
              }},</span>
            </p>
            <p>{{ $t('interview.startTime') }}：{{ resultData.startTime }}</p>
            <p>{{ $t('interview.endTime') }}：{{ resultData.endTime }}</p>
          </div>
        </div>
        <div class="tabs-box">
          <h3 class="tabs-title">{{ $t('interview.interviewEva') }}</h3>
          <div class="tabs-card">
            {{ $t('interview.interviewScore') }}：
            <div class="score">
              <ul>
                <li>
                  {{ $t('interview.attitude') }}：
                  <el-rate class="score-rate" v-model="resultData.scoreAttitude" show-text disabled :texts="texts"
                    text-color="#F7BA2A"></el-rate>
                </li>
                <li>
                  {{ $t('interview.character') }}：
                  <el-rate class="score-rate" v-model="resultData.scoreCharacter" show-text disabled :texts="texts"
                    text-color="#F7BA2A"></el-rate>
                </li>
                <li>
                  {{ $t('interview.technology') }}：
                  <el-rate class="score-rate" v-model="resultData.scoreProfessional" show-text disabled :texts="texts"
                    text-color="#F7BA2A"></el-rate>
                </li>
                <li>
                  {{ $t('interview.experience') }}：
                  <el-rate class="score-rate" v-model="resultData.scoreExperience" show-text disabled :texts="texts"
                    text-color="#F7BA2A"></el-rate>
                </li>
              </ul>
            </div>
          </div>
          <div class="tabs-card">
            {{ $t('interview.comment') }}：{{ resultData.comment }}
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('interview.interviewResume')" name="second" style="min-height: 500px">
        <div class="iFrame" v-if="iframeUrl">
          <iframe style="width: 100%; height: 500px" :src="iframeUrl"></iframe>
        </div>
        <div class="resume-none" v-else>
          <h2>{{ $t('interview.noResume') }}</h2>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('interview.interviewVideo')" name="video" style="min-height: 500px">
        <div class="video-box" v-if="videoSrc">
          <video :src="videoSrc" autoplay controls style="width: 650px"></video>
        </div>
        <div v-else class="resume-none">
          <h2>{{ $t('interview.videoComing') }}</h2>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="boontbtn">
      <el-button type="info" size="small" @click="clearAdd">{{
      $t('common.cancel')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import request from '@/utils/request.js'
export default {
  name: 'result',
  props: {
    resultData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      activeName: 'first',
      videoSrc: '',
      loading: false,
      texts: [
        this.$t('interview.verybad'),
        this.$t('interview.bad'),
        this.$t('interview.average'),
        this.$t('interview.good'),
        this.$t('interview.verygood'),
      ],
      haveFile: false,
      iframeUrl: '',
      showVideo: false,
    }
  },
  methods: {
    handleClick() {
      if (this.activeName == 'video') {
        this.loading = true
        this.showVideo = false
        post('/video-interview/video-file-path', {
          id: this.resultData.id,
        }).then((res) => {
          if (res.code == 1) {
            this.videoSrc = res.data
            this.loading = false
            setTimeout(function () {
              this.showVideo = true
              console.log(this.showVideo)
            }, 1000)
          } else {
            setTimeout(() => {
              this.loading = false
            }, 2000)
            // this.$message.error(res.data);
          }
        })
        // this.download(this.resultData,'see')
      }
    },
    // 下载
    async download(row, type) {
      var res = await request({
        url: '/video-interview/download',
        method: 'POST',
        params: {
          id: row.id,
        },
        responseType: 'blob',
      })
      const blob = new Blob([res], {
        type: 'video/mp4',
      })
      const fileName =
        this.$t('interview.interviewRecordScreen') + '-' + row.candidateName
      const linkNode = document.createElement('a')

      linkNode.download = fileName //a标签的download属性规定下载文件的名称
      linkNode.style.display = 'none'
      linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
      if (type == 'see') {
        this.videoSrc = URL.createObjectURL(blob)
        this.loading = false
      } else {
        document.body.appendChild(linkNode)
        linkNode.click() //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
        document.body.removeChild(linkNode)
      }
      console.log(linkNode.href)
    },
    // 下载
    async getFileInfo(id) {
      var res = await request({
        url: '/file/download',
        method: 'POST',
        params: {
          id: id,
        },
        responseType: 'blob',
      })
      const blob = new Blob([res], {
        type: 'application/pdf',
      })
      this.iframeUrl = URL.createObjectURL(blob)
      console.log(this.iframeUrl)
    },
    clearAdd() {
      this.formData = {}
      this.$emit('clear')
    },
  },
  mounted() {
    if (this.resultData.fileId) {
      this.haveFile = true
      this.getFileInfo(this.resultData.fileId)
    } else {
      this.haveFile = false
    }
  },
}
</script>

<style scoped>
.result {
  padding: 20px;
  min-height: 500px;
}

.tabs-box {
  padding: 10px;
}

.tabs-title {
  padding: 10px 0;
  border-bottom: 1px solid #f0f2f4;
  margin-bottom: 20px;
}

.tabs-box p {
  margin: 10px 0;
}

.tabs-card {
  padding: 0 10px;
}

.score-rate {
  display: inline;
  vertical-align: top;
}

.score {
  display: inline;
}

.score li {
  margin: 20px;
}

.video-box {
  text-align: center;
}
</style>
