<template>
  <div class="interview" style="position: relative">
    <div class="header">
      <div class="navbar">
        <el-row>
          <el-col :span="4">
            <div class="nav-img">
              <img src="@/assets/u6376.png" alt="" style="height: 50px" />
            </div>
          </el-col>
          <el-col :span="16"> </el-col>
          <el-col :span="4"> </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="content"
      v-loading="isLoading"
      :element-loading-text="$t('test.recordLoad')"
    >
      <div class="title">
        <h3>{{ infoData.title }}</h3>
        <div class="sub">
          <el-button type="primary" size="mini" @click="submitClick">{{
            $t('test.submitTest')
          }}</el-button>
        </div>
        <div class="time">
          <p>
            <img src="@/assets/icon/lang-time.svg" alt="" />{{ hour }}
            {{ $tc('test.hour', hour) }} {{ min }} {{ $tc('test.min', min) }}
            {{ second }} {{ $tc('test.second', second) }}
          </p>
        </div>
        <div style="clear: both"></div>
      </div>
      <el-row :gutter="22" type="flex" justify="space-between">
        <el-col :span="5">
          <!-- 左侧列表 -->
          <div class="side-card">
            <div class="side-top">
              <h4>{{ $t('test.qustionList') }}</h4>
            </div>
            <el-scrollbar style="height: 100%">
              <ul class="side-list">
                <li
                  class="list-item"
                  v-for="(item, index) in tableData"
                  :key="item.id"
                  @click="questionClick(index)"
                  :class="selectLi == index ? 'select-item' : ''"
                >
                  <el-row>
                    <el-col :span="24">
                      <div class="item-title">
                        <i
                          class="el-icon-success is-answers"
                          style="color: #005eff"
                          v-show="item.answers"
                        ></i>
                        {{ index + 1 }}.{{ item.title }}
                      </div>
                    </el-col>
                  </el-row>
                </li>
              </ul>
            </el-scrollbar>
          </div>
        </el-col>
        <el-col :span="19" style="padding: 0 15px 0 0">
          <!-- 试题详情 -->
          <div class="detail-card">
            <el-scrollbar style="height: 100%; width: 100%" v-loading="loading">
              <QuestionContent
                v-if="endTime!==''"
                ref="content"
                :clickItem="clickItem"
                :clickData="clickData"
                :tableDataLen="tableDataLen"
                :currentLen="currentLen"
                @last="lastQuestion"
                @next="nextQuestion"
                @submitClick="submitClick"
              />
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import baseURL from '@/api/baseURL.js'

import '@/components/Meeting/aliyun-webrtc-sdk-1.12.1.js'
import sha256 from './sha256'
import { post, del, get, putUrl } from '@/api/http'
import qs from 'qs'
import QuestionContent from './content'

export default {
  name: 'index',
  components: { QuestionContent },
  data() {
    return {
      infoData: {},
      tableData: [],
      count: 0, 
      tableDataLen: 0, //题目总数
      currentLen: 0, //当前第几个题目
      clickItem: {},
      clickData: {},
      loading: false,
      selectLi: 0,
      startTime: '',//进入考试时间，固定不变
      timeTiming: '',//每次更新的开始时间，刷新会变
      endTime: '',
      curStartTime: '2019-07-31 08:00:00',
      day: '0',
      hour: '00',
      min: '00',
      second: '00',
      examId: '',
      aliWebrtc: new AliRtcEngine(),
      okTime: null,
      isLoading: true,
    }
  },
  methods: {
    //WebSocket链接，用途：主面试官开始面试通知其他用户
    init: function () {
        if (typeof(WebSocket) === "undefined") {
            alert(this.$t('interview.notSupportSocket'))
        } else {
            // let token = getToken();
            let url ="wss://" + baseURL.split('//')[1] + "video/"+this.infoData.phone;
            // 实例化socket
            this.socket = new WebSocket("wss://" + baseURL.split('//')[1] + "video/"+this.infoData.candidatePhone);
            // this.socket = new WebSocket("wss://47.99.204.31:8443/video/"+this.loginInfo.phone);
            // 监听socket连接
            this.socket.onopen = this.open;
            // 监听socket错误信息
            this.socket.onerror = this.error;
            // 监听socket消息
            this.socket.onmessage = this.getMessage;
            // 关闭
            this.socket.onclose = this.close;
        }
    },
    open: function () {
        console.log("socket连接成功")
    },
    error: function (err) {
        console.log(err);
        console.log("连接错误")
    },
    getMessage: function (msg) {
      // console.log(msg.data);
        if (msg.data == '1') {
            // this.clearMask();
            // this.init();
        }
        if (msg.data == '-1') {
            // location.reload()
        }
    },
    send: function () {
        this.socket.send(params)
    },
    close: function () {
        console.log("socket已经关闭")
    },
    //获取题目列表
    getList() {
      get('/paper/show/' + this.infoData.paperId).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.questionList.sort(function (a, b) {
            if (a.questionNumber < b.questionNumber) {
              return -1
            } else {
              return 1
            }
          })
          this.tableDataLen = this.tableData.length - 1
          this.selectQuestion(0)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //题目点击
    questionClick(index) {
      let question = this.tableData[this.selectLi]
      if (question.answers) {
        post('/exam-answer/answer-input', {
          examineeId: this.examId,
          paperId: this.infoData.paperId,
          questionId: question.id,
          answer: question.answers,
          runningResult: question.runningResult || '',
        }).then((res) => {
          if (res.code == 1) {
            this.selectQuestion(index)
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        this.selectQuestion(index)
      }
    },
    //题目切换
    selectQuestion(index) {
      this.selectLi = index
      this.clickData = this.tableData[index]
      this.clickItem = this.tableData[index]
      this.$refs.content.buttonAnti = false
    },
    //上一题
    lastQuestion() {
      if (this.selectLi > 0) {
        this.currentLen--
        let question = this.tableData[this.selectLi]
        //有答案提交答题
        console.log(question)
        if (question.answers) {
          post('/exam-answer/answer-input', {
            examineeId: this.examId,
            paperId: this.infoData.paperId,
            questionId: question.id,
            answer: question.answers,
            runningResult: question.runningResult || '',
          }).then((res) => {
            if (res.code == 1) {
              this.selectQuestion(this.selectLi - 1)
            } else {
              this.$message.error(res.data)
            }
          })
        } else {
          //无答案切换试题
          this.selectQuestion(this.selectLi - 1)
        }
      }
    },
    //下一题
    nextQuestion() {
      if (this.selectLi < this.tableData.length - 1) {
        this.currentLen++
        let question = this.tableData[this.selectLi]
        console.log(question)
        if (question.answers) {
          this.$refs.content.buttonAnti = true
          post('/exam-answer/answer-input', {
            examineeId: this.examId,
            paperId: this.infoData.paperId,
            questionId: question.id,
            answer: question.answers,
            runningResult: question.runningResult || '',
          }).then((res) => {
            if (res.code == 1) {
              this.$refs.content.buttonAnti = false
              this.selectQuestion(this.selectLi + 1)
            } else {
              this.$message.error(res.data)
            }
          })
        } else {
          this.selectQuestion(this.selectLi + 1)
        }
      }else{
        this.$message.warning(this.$t('test.lastOne'))
      }
    },
    // 倒计时
    countTime() {
      // 获取当前时间
      // let date = new Date()
      // console.log(date,'date');
      // let now = date.getTime()
      if(this.count==0){
        this.count = this.timeTiming*1000 
      }else{
        this.count += 1000
      }
      let now = this.count
      
      console.log(now,'now');
      // 设置截止时间
      let endDate = new Date(this.curStartTime*1000) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // 时间差
      let leftTime = end - now
      console.log(leftTime,'leftTime');
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24)
        this.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60)
        this.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor((leftTime / 1000) % 60)
        this.second = s < 10 ? '0' + s : s
      } else {
        this.day = 0
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        this.autoSubmitClick()
        return
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
    //时间到必须提交通知
    mustSubmitClick() {
      this.$alert(this.$t('test.timeIsUp'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        let question = this.tableData[this.selectLi]
        if (question.answers) {
          post('/exam-answer/answer-input', {
            examineeId: this.examId,
            paperId: this.infoData.paperId,
            questionId: question.id,
            answer: question.answers,
          }).then((res) => {
            if (res.code == 1) {
              this.submitAnswer()
            } else {
              this.$message.error(res.data)
            }
          })
        } else {
          this.submitAnswer()
        }
      })
    },
    //提交点击
    submitClick() {
      this.$confirm(this.$t('test.overAndSubmit'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      })
        .then(() => {
          let question = this.tableData[this.selectLi]
          if (question.answers) {
            post('/exam-answer/answer-input', {
              examineeId: this.examId,
              paperId: this.infoData.paperId,
              questionId: question.id,
              answer: question.answers,
              runningResult: question.runningResult || '',
            }).then((res) => {
              if (res.code == 1) {
                this.submitAnswer()
              } else {
                this.$message.error(res.data)
              }
            })
          } else {
            this.submitAnswer()
          }
        })
        .catch(() => {})
    },
    //时间到自动提交
    async autoSubmitClick() {
      let question = this.tableData[this.selectLi]
      if (question.answers) {
        post('/exam-answer/answer-input', {
          examineeId: this.examId,
          paperId: this.infoData.paperId,
          questionId: question.id,
          answer: question.answers,
        }).then(async(res) => {
          await this.autoSubmitTime()
        })
      }else {
        await this.autoSubmitTime()
      }

    },
    // 自动提交第二步
    autoSubmitTime(){
      return new Promise((resolve, reject)=>{
          this.startTime = this.setTime(new Date(this.startTime*1000))
          this.endTime =  this.setTime(new Date(this.endTime*1000))
          if(this.infoData.candidatePhonePrefix=='1'){
            this.endTime = this.$moment(this.endTime).utcOffset(-5).format('YYYY-MM-DD HH:mm:ss')
            this.startTime = this.$moment(this.startTime).utcOffset(-5).format('YYYY-MM-DD HH:mm:ss')
          }
          console.log(this.startTime,this.endTime,'endTime');
          post('/exam-answer/answer-submit', {
            endTime: this.setTime(this.endTime),
            examineeId: this.examId,
            startTime: this.setTime(this.startTime),
          }).then((res) => {
            if(res.code==1){
              this.successToOver()
            }
              resolve(res)
          }).catch(error => {
              reject(error)
          })
      })
    },
    // 提交成功后提示
    successToOver(){
      this.$confirm(this.$t('test.autoSubmit'), this.$t('common.tips'), {
        confirmButtonText:  '✓',
        cancelButtonText:  '✕',
        type: 'warning',
        customClass:'alert-box',
        center:'true',
        showCancelButton:false,
        showClose:false,
        closeOnClickModal:false
      })
        .then(() => {
          this.$router.replace({ name: 'Test-Over' })
        })
    },
    /// 记录考试开始时间戳
    // 返回已做题目的答案
    updateTimeStamp(){
      return new Promise((resolve, reject)=>{
        post('/exam-examinee/updateExamineeTimeStartExam', {
            id:this.examId,
            fullTime:this.infoData.fullTime,
            paperId: this.infoData.paperId,
        }).then((res) => {
          if(res.code==1){
            this.examResultList = res.data.examResultSheet
            // 预计开始时间
            this.startTime = res.data.timeStartExam
            this.timeTiming = res.data.timeTiming
            // 预计结束时间
            this.endTime = res.data.timeEndExam
            this.backShowAnswer()
            resolve(res.data)
          }else{
            this.testOver()
          }
        }).catch(error => {
            reject(error)
        })
      })
    },
    /**
     * 刷新后重新获取已做题目的答案列表
     * 将答案列表和题目列表对应赋值
     */
    backShowAnswer(){
      let arr = this.examResultList
      if(this.examResultList.length!==0){
        this.tableData.map(ele=>{
          arr.forEach(x=>{
            if(ele.id==x.questionId){
              ele.answers = x.answer
            }
          })
        })
      }
    },
    async ifTimeIsUp (){
      // 考试开始时间
      let gettime = await this.updateTimeStamp()
      console.log( gettime.timeStartExam);
      console.log(  gettime.timeEndExam,'返回结束时间');
      if(gettime && gettime.status!==2){
        this.startTime = gettime.timeStartExam||gettime.timeTiming
        this.timeTiming = gettime.timeTiming
        // 预计结束时间
        this.endTime = gettime.timeEndExam
        // 剩余时间
        let leftTime = this.endTime - this.timeTiming
        // 倒计时时间
        let time = Number(this.endTime)
        this.curStartTime = time //开始倒计时的时间戳日期
        // console.log(this.startTime,'startTime');
        // console.log(this.endTime,'endTime');
        // console.log(leftTime/60,'剩余时间');
        if(leftTime > 0){
          this.countTime()
        }else{
          this.hour = '00'
          this.min = '00'
          this.second = '00'
          // this.startTime = this.endTime - this.infoData.fullTime * 60
          this.autoSubmitClick()
          // console.log('submit');
        }
      }else{
        this.testOver()
      }
    },
    // 考试结束或失效退出
    testOver(){
      this.$router.replace({
        name: 'Answer',
        query: {
          id: this.$route.query.id,
        },
      })
    },
    //试卷提交接口
    submitAnswer() {
      this.startTime = this.setTime(new Date(this.startTime*1000))
      this.endTime =  this.setTime(new Date(this.count))
      if(this.infoData.candidatePhonePrefix=='1'){
        this.endTime = this.$moment(this.endTime).utcOffset(-5).format('YYYY-MM-DD HH:mm:ss')
        this.startTime = this.$moment(this.startTime).utcOffset(-5).format('YYYY-MM-DD HH:mm:ss')
      }
      post('/exam-answer/answer-submit', {
        endTime: this.setTime(this.endTime),
        examineeId: this.examId,
        startTime: this.setTime(this.startTime),
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('test.submitSuccess'))
          this.aliWebrtc.unPublish().then(
            () => {
              console.log('录制结束 ')
            },
            (error) => {
              console.log(error.message)
            }
          )
          // 考试结束跳转提示页
          this.$router.replace({ name: 'Test-Over' })
          // window.location.replace('about:blank')
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //时间格式转换
    setTime(data) {
      let time = new Date(data)
      let Year = time.getFullYear()
      let Month = time.getMonth() + 1
      let Day = time.getDate()
      let Hours = time.getHours()
      let Minutes = time.getMinutes()
      let Seconds = time.getSeconds()
      return (
        Year +
        '-' +
        this.add0(Month) +
        '-' +
        this.add0(Day) +
        ' ' +
        this.add0(Hours) +
        ':' +
        this.add0(Minutes) +
        ':' +
        this.add0(Seconds)
      )
    },
    //补0
    add0(time) {
      if (time < 10) {
        return '0' + time
      } else {
        return time
      }
    },
    //开始答题并开始录制
    startAnswer() {
      post('/exam-answer/start', {
        examineeId: this.infoData.id,
      }).then((res) => {
        if (res.code == 1) {
          console.log('录制开始')
          this.isLoading = false
        } else {
          this.$message.error(res.data)
        }
      })
    },
    
    //    视频录制/本地流分享
    /**
     * 加入房间
     * 触发：输入房间号、单击加入房间按钮
     * 更新页面信息
     * 默认开启预览
     * 获取鉴权信息
     * 加入房间
     * 本地默认自动推视频流（视频流 + 音频流）
     * 发布本地流
     */
    joinroom() {
      //1.预览
      var localVideo = this.$refs.meeting
      // this.aliWebrtc.startPreview(localVideo).then((obj) => {
      // }).catch((error) => {
      //     this.showAlert("[开启预览失败]" + error.message, "danger");
      // });
      // 2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
      var authInfo = this.GenerateAliRtcAuthInfo(this.examId)
      //3. 加入房间 默认推音频视频流
      this.aliWebrtc
        .joinChannel(authInfo, this.userName)
        .then(() => {
          this.$message.success(this.$t('test.joinSuccess'))
          // 4. 发布本地流
          // this.aliWebrtc.configLocalAudioPublish = true;
          this.aliWebrtc.configLocalCameraPublish = false
          this.aliWebrtc.configLocalScreenPublish = true
          this.aliWebrtc.publish().then(
            (res) => {
              console.log(res)
              this.okTime = setTimeout(() => {
                console.log('发布流成功')
                this.startAnswer()
              }, 2000)
            },
            (error) => {
              // $(".streamType").show();
              this.$message.error(
                '[' + this.$t('test.streamFaild') + ']' + error.message
              )
            }
          )
        })
        .catch((error) => {
          this.$message.error(
            '[' + this.$t('test.joinFaild') + ']' + error.message
          )
        })
      /**
       *  错误信息
       */
      this.aliWebrtc.on('onError', (error) => {
        if (error.errorCode == 10011) {
          if (this.okTime) {
            clearTimeout(this.okTime)
          }
          this.$router.push({ name: 'Answer', query: { id: this.examId } })
        }
      })
    },

    /*
     * 获取测试token
     * @param {*} channelId 频道号
     * @return {object} authinfo
     */
    GenerateAliRtcAuthInfo(channelId) {
      var appId = 'gwu2pxyn' // 修改为自己的appid 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
      var appKey = '059973a2a6d1f363cd6ee320c50d0e35' // 修改为自己的appkey 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
      this.userId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      ) // 可以自定义
      var timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60)
      var nonce = 'AK-' + timestamp
      var token = sha256(
        appId + appKey + channelId + this.userId + nonce + timestamp
      )
      return {
        appid: appId,
        userid: this.userId,
        timestamp: timestamp,
        nonce: nonce,
        token: token,
        gslb: ['https://rgslb.rtc.aliyuncs.com'],
        channel: channelId,
      }
    },
    // 浏览器关闭监听
    beforeunloadHandler(e) {
      this.socket.close()
      console.log('guanbi');
    },
  },
  mounted() {
    this.infoData = JSON.parse(localStorage.getItem('info'))
    this.examId = JSON.parse(localStorage.getItem('info')).id
    this.getList()
    // let startTime = new Date()
    // let time = new Date().getTime() + this.infoData.fullTime * 1000 * 60
    // this.startTime = startTime
    // this.curStartTime = time
    // this.countTime()
    this.ifTimeIsUp()
    if (this.infoData.screenRecording == 1) {
      this.aliWebrtc
        .isSupport()
        .then((re) => {
          this.joinroom()
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      this.isLoading = false
    }
    if(this.infoData.candidatePhone){
      this.init()
    }
    // 绑定监听
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // 刷新提交时间戳
    // window.addEventListener("load", () => {
    //   this.updateTimeStamp()
    // });
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')

      document.onselect = new Function('event.returnValue=false')
    })
  },
  destroyed() {
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
}
</script>

<style scoped>
.navbar {
  width: 100%;
  height: 68px;
}

.nav-img {
  text-align: left;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 68px;
}

.nav-menu {
  height: 68px;
}

.nav-my {
  height: 68px;
}

.content {
  padding: 15px;
  background-color: #ececf4;
}

.content .title {
  background-color: #fff;
  padding: 20px 15px;
  margin: 15px 0;
  position: relative;
}

.content .title h3 {
  float: left;
}

.content .title .time {
  float: right;
  vertical-align: middle;
  margin-right: 20px;
}

.content .title .time p {
  font-size: 18px;
  color: #ff0000;
  font-weight: bold;
}

.content .title .time img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 15px;
}

.content .title .sub {
  float: right;
}

.form-header {
  padding: 12px 20px;
  height: 35px;
  background-color: #fff;
  margin-bottom: 10px;
}

.el-dropdown button {
  background-color: #efefff;
  border-color: #a9a9e3;
  color: #333399;
}

.form-header .el-col {
  line-height: 35px;
}

.side-card {
  background-color: #fff;
  height: calc(100vh - 197px);
  overflow: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.side-card .side-top {
  padding: 10px 20px;
  border-bottom: 1px solid #ebebf5;
}

.side-card .side-top h4 {
  font-size: 14px;
}

.side-card .side-list {
  height: calc(100vh - 207px);
}

.list-item {
  padding: 15px;
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

.list-checkbox {
  position: absolute;
  right: 15px;
  top: 15px;
}

.list-item p {
  line-height: 35px;
}

.item-title {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  padding: 0 30px;
  position: relative;
}

.is-answers {
  position: absolute;
  left: 12px;
  top: 3px;
}

.item-tag {
  margin-top: 15px;
}

.operate {
  line-height: 100px;
}

.el-tag {
  margin-left: 20px;
}

.list-item:hover {
  background-color: #f5f5fa;
}

/* center */
.detail-card {
  background-color: #fff;
  border-radius: 2px;
  min-width: 100%;
  height: calc(100vh - 197px);
}

/* right */
.right-card {
  background-color: #fff;
  border-radius: 2px;
  height: calc(100vh - 197px);
}

.right-header {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.right-card .info {
  padding: 10px 20px;
}

.right-header .info .item {
  color: #515151;
}

.right-card .info p {
  line-height: 40px;
  font-size: 14px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}

.select-item {
  background-color: #f7f8f9;
  border-right: 1px solid #005eff;
}

.select-item .item-title {
  color: #005eff;
}
</style>
