<template>
    <div class="question-see">
        <!--{{seeData}}-->
        <div class="test-info">
            <h2>{{seeData.paperEntity.title}}</h2>
            <p>{{seeData.paperEntity.remark}}</p>
        </div>
        <div class="test-question">
            <div class="question-box">
                <div class="question-list" v-for="item,index in seeData.questionList " :key="item.id">
                    <h2>{{index+1}}.{{item.title}}</h2>
                    <div style="padding: 0 20px;">
                        <mavon-editor
                                v-if="item.questionContent"
                                :value="item.questionContent"
                                defaultOpen="preview"
                                :boxShadow="false"
                                style="z-index:1;height:50vh"
                                :editable="false"
                                :subfield="false"
                                :toolbarsFlag="false"
                                class="makeEditor"
                        >
                        </mavon-editor>
                        <div class="answer-box" v-if="item.type=='1'">
                            <el-radio-group v-model="checked">
                                <el-row class="list-option" v-for="label in item.optionList" :key="label.id">
                                    <el-col :span="20" v-if="label.questionOption"> <el-radio label="item.id">{{label.questionOption}}</el-radio ></el-col>
                                </el-row>
                            </el-radio-group>
                        </div>
                        <div class="answer-box" v-else>
                            <mavon-editor  defaultOpen="edit" :toolbars="toolbars" v-model="content" class="mavonEditor"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="boontbtn">
            <el-button type="info" size="small" @click="$emit('clear')">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "see",
        props:{
            seeData:{
                type:Object,
                default() {
                    return {};
                }
            }
        },
        data(){
            return{
                content:''
            }
        }
    }
</script>

<style scoped>
    .test-info{
        text-align: center;
    }
    .test-info h2{
        margin-bottom: 15px;
    }

    .test-question{
        margin-top: 20px;
    }

    .question-list h2{
        margin-bottom: 15px;
    }

    .answer-box{
        padding: 15px;
    }
    .title-option{
        background-color: #f2f3f7;
        color: #778ca2;
        font-weight: bold;
        padding: 0 15px;
    }
    .list-option{
        /*line-height: 70px;*/
        padding: 10px;
    }

    .makeEditor{
        min-height: 100px;
        height: auto !important;
    }

</style>
