
// const baseURL = 'https://www.jobs-interviews.com';
//const baseURL = 'http://192.168.2.11:8082/';

// const baseURL = 'https://192.168.2.11:8443/';
// const baseURL = 'https://www.jobs-interviews.cn/';
// const baseURL = 'https://192.168.1.34:8472/';
// const baseURL = 'http://192.168.1.34:8072/';
// const baseURL = 'https://192.168.5.10:8472/';
const baseURL = 'https://www.jobs-interviews.cn/';
// const baseURL = "https://192.168.1.35:8443/";
export default baseURL;
 