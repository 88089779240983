<template>
  <div class="business">
    <el-form ref="business" :model="business" label-width="200px">
      <el-form-item :label="$t('setup.CoName')" style="width: 40%">
        <el-input v-model="business.name" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.legalrep')" style="width: 40%">
        <el-input v-model="business.legalPerson" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.registeredCap')" style="width: 40%">
        <el-input v-model="business.capital" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.incorporationDate')" style="width: 40%">
        <el-input v-model="business.establishDate" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.businessTerm')" style="width: 40%">
        <el-input v-model="business.validPeriod" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.enterType')" style="width: 40%">
        <el-input v-model="business.type" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.registAddr')" style="width: 40%">
        <el-input v-model="business.address" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.creditCode')" style="width: 40%">
        <el-input v-model="business.registerNumber" :readonly="isReadonly"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.businessNature')" style="width: 40%">
        <el-input type="textarea" :rows="4" v-model="business.business" :readonly="isReadonly"></el-input>
      </el-form-item>
      <div class="boontbtn">
        <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
          {{ $t('common.save') }}
        </el-button>
      </div>
    </el-form>
    <div class="upload-box">
      <el-upload v-loading="loading" class="avatar-uploader" ref="upload" :action="baseURL + 'file/upload'"
        :on-success="uploadSuccess" :on-change="uploadChange" :headers="uploadHeader" :file-list="fileList"
        accept=".jpg">
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import baseURL from '@/api/baseURL.js'
import { getToken } from '@/utils/auth'
export default {
  name: 'Business',
  data() {
    return {
      business: {},
      isReadonly: true,
      loading: false,
      imageUrl: '',
      uploadHeader: {},
      fileList: [],
      baseURL: baseURL,
      fileId: '',
      infoId: '',
    }
  },
  methods: {
    //上传成功回调
    uploadSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.$message.success(this.$t('common.uploadSuccess'))
        let imgId = response.data.id
        this.getImgUrl(imgId)
        // this.getBusinessInfo(imgId)
        this.fileId = imgId
        this.loading = false
      }
    },
    //超出处理
    uploadChange(file, fileList) {
      let fileData = []
      fileData.push(fileList[fileList.length - 1])
      this.fileList = fileData
    },
    submitForm() {
      let subData = {
        id: this.infoId,
        businessAddress: this.business.address,
        businessScope: this.business.business,
        companyName: this.business.name,
        companyType: this.business.type,
        creditCode: this.business.registerNumber,
        // enterpriseId:'1',
        fileId: this.fileId,
        legalPerson: this.business.legalPerson,
        registeredCapital: this.business.capital,
        registrationDate: this.business.establishDate,
        validPeriod: this.business.validPeriod,
      }
      post('/setting-enterprise-business-license/insert', subData).then(
        (res) => {
          if (res.code == 1) {
            this.$message.success(this.$t('common.saveSuccess'))
            this.getDefaultinfo()
          } else {
            this.$message.error(res.data)
          }
        }
      )
    },

    getImgUrl(id) {
      if (id) {
        get('/file/oss-url/' + id).then((res) => {
          if (res.code == 1) {
            this.imageUrl = res.data
          } else {
            this.$message.error(res.data)
          }
        })
      }
    },
    getBusinessInfo(id) {
      get('/setting-enterprise-business-license/image-analyse/' + id).then(
        (res) => {
          if (res.code == 1) {
            res.data.establishDate =
              res.data.establishDate.slice(0, 4) +
              '-' +
              res.data.establishDate.slice(4, 6) +
              '-' +
              res.data.establishDate.slice(6)
            res.data.validPeriod =
              res.data.validPeriod.slice(0, 4) +
              '-' +
              res.data.validPeriod.slice(4, 6) +
              '-' +
              res.data.validPeriod.slice(6)
            this.business = { ...res.data }
            this.isReadonly = false
          } else {
            this.$message.error(res.data)
          }
        }
      )
    },
    getDefaultinfo() {
      get('/setting-enterprise-business-license/').then((res) => {
        if (res.code == 1) {
          if (res.data) {
            let setData = {
              id: res.data.id,
              address: res.data.businessAddress,
              business: res.data.businessScope,
              name: res.data.companyName,
              type: res.data.companyType,
              registerNumber: res.data.creditCode,
              // enterpriseId:'1',
              fileId: res.data.fileId,
              legalPerson: res.data.legalPerson,
              capital: res.data.registeredCapital,
              establishDate: res.data.registrationDate,
              validPeriod: res.data.validPeriod,
            }
            this.business = setData
            this.fileId = res.data.fileId
            this.infoId = res.data.id
            this.getImgUrl(res.data.fileId)
            this.isReadonly = false
          } else {
            this.isReadonly = true
          }
        } else {
          this.$message.error(res.data)
        }
      })
    },
  },
  mounted() {
    this.getDefaultinfo()
    this.uploadHeader = {
      Authorization: getToken(),
    }
  },
}
</script>

<style scoped>
.upload-box {
  position: absolute;
  left: 45%;
  top: 0;
  width: 600px;
  height: 400px;
}

.info-type .el-radio-button {
  margin-right: 20px;
}

.upload-box>>>.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  /*width: 178px;*/
  /*height: 178px;*/
  line-height: 400px;
  text-align: center;
}

.avatar {
  max-width: 100%;
  max-height: 400px;
  display: block;
}

.upload-box>>>.el-upload-list {
  display: none;
}
</style>
