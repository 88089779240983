<template>
    <div class="InfoDialog">
        <el-form ref="elForm" :model="infoData" size="medium" label-width="160px" label-suffix="：">
            <el-form-item :label="$t('common.phoneNumber')" prop="phone">
                <el-input v-model="infoData.phone" :placeholder="$t('common.pleaseEnter')+$t('common.phoneNumber')" clearable :style="{width: '70%'}" @change="setPhone()">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('common.email')" prop="email">
                <el-input v-model="infoData.email" :placeholder="$t('common.pleaseEnter')+$t('common.email')" clearable :style="{width: '70%'}" @change="setEmail()">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('common.username')" prop="username">
                <el-input readonly v-model="infoData.username" :placeholder="$t('common.pleaseEnter')+$t('common.username')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="info" size="small" @click=" $emit('clear')">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    export default {
        name: "InfoDialog",
        data(){
            return{
                infoData:{},
            }
        },
        methods:{
            getInfo(){
                get("/setting-user/my-info").then(res => {
                    if (res.code == 1) {
                        this.infoData=res.data;
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            setPhone(){
                post('setting-user/update-phone',{phone:this.infoData.phone}).then(res=>{
                    if (res.code==1){
                        this.$message.success(this.$t('common.modifySuccess'));
                    }
                })
            },
            setEmail(){
                post('setting-user/update-email',{email:this.infoData.email}).then(res=>{
                    if (res.code==1){
                        this.$message.success(this.$t('common.modifySuccess'));
                    }
                })
            }
        },
        mounted(){
            this.getInfo();
        }
    }
</script>

<style scoped>

</style>
