<template>
    <div class="position">
        <div class="three">
            <el-button type="primary" size="small" @click="addForm()">{{$t('setup.addPosition')}}</el-button>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        width="300"
                        prop="positionName"
                        :label="$t('setup.positionName')"
                        >
                </el-table-column>
                <el-table-column 
                        prop="positionName"
                        :label="$t('question.skill')"
                        >
                    <template slot-scope="scope">
                        <el-tag
                        v-for="tag in scope.row.labelDictList"
                        :key="tag.name"
                        class="tags"
                        type="primary">
                        {{tag.labelName}}
                        </el-tag>
                    </template>
                    
                </el-table-column>
                <!-- <el-table-column
                        prop="status"
                        :label="$t('common.status')"
                        width="180">
                </el-table-column> -->
                <el-table-column
                        fixed="right"
                        width="300"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        <el-button type="success" size="mini" @click="operation('setTag',scope.row)">{{$t('setup.setSkill')}}</el-button>
                        <el-button type="danger" size="mini" @click="operation('del',scope.row)">{{$t('common.delete')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageConfig.pageSize"
                layout="total, prev, pager, next"
                :total="pageConfig.total">
            </el-pagination>
        </div>

        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="$t('setup.positionSet')" :visible.sync="addDialog" :before-close="clearAdd" width="30%" >
                <AddTemp ref="addRef" :rowData="rowData" @add="saveAdd" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
        <!--设置标签-->
        <div v-if="labelDialog" class="label">
            <el-dialog center :title="$t('setup.setSkill')" :visible.sync="labelDialog" :before-close="clearAdd" width="70%" >
                <LabelTemp ref="labelRef" :rowData="rowData" @add="saveSet" @clear="clearAdd" @del="delSet"></LabelTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    import LabelTemp from './set-label.vue'
    export default {
        name: "position",
        components: {AddTemp,LabelTemp},
        data() {
            return {
                pageConfig: {
                    pageSize: 10,
                    total: 0,
                    pageNo: 1,
                    pageSizes: this.$pageSizes,
                },
                tableData: [],
                addDialog:false,
                labelDialog:false,
                rowData:{},
            }
        },
        methods:{
            handleCurrentChange(val) {
            this.pageConfig.pageNo = val
            this.getList()
            },
            handleSizeChange(val) {
            this.pageConfig.pageSize = val
            this.getList()
            },
            getList(){
                post("/dict-position/list",{
                    page: this.pageConfig.pageNo,
                    limit: this.pageConfig.pageSize,
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                        this.pageConfig.total = res.data.count
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            addForm(){
                this.rowData={};
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
                this.labelDialog=false;
            },
            saveAdd(data){
                console.log(data);
                post("/dict-position/insert",{
                    // enterpriseId:1,
                    ...data
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            saveSet(data) {
                post("/positionLabelMiddle/insert",data).then(res => {
                    if (res.code == 1) {
                        this.$message.success(res.msg)
                        this.getList()
                        this.$refs['labelRef'].getPositionList()
                        this.$refs['labelRef'].chechedList=[]
                        this.clearAdd();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            delSet(item){
                this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        del("/positionLabelMiddle/delete/" + item.id).then(res => {
                            if (res.code == 1) {
                                this.$message.success(this.$t('common.success'));
                                this.getList()
                                this.$refs['labelRef'].getPositionList()
                                this.$refs['labelRef'].chechedList=[]
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }).catch(() => {
                    });
            },
            operation(type,row){
                if (type=='edit'){
                    delete row.labelDictList
                    this.rowData={
                        id: row.id,
                        enterpriseId: row.enterpriseId,
                        positionName: row.positionName
                    };
                    this.addDialog=true;
                }
                if (type=='setTag'){
                    this.rowData=row;
                    this.labelDialog=true;
                }
                if (type=='del'){
                    this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        del("/dict-position/delete/" + row.id).then(res => {
                            if (res.code == 1) {
                                this.$message.success(this.$t('common.success'));
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }).catch(() => {
                    });
                }
            }
        },
        mounted(){
            this.getList();
        }
    }
</script>

<style scoped>
.tags{
    margin-right: 10px;
    border-radius: 20px;
}
.tags:last-child{
    margin-right: 0;
}
.label >>> .el-dialog__body{
    background-color: #F2F2F2;
}
</style>
