import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/login/account-login',
        method: 'post',
        data
    })
}
export function smsLogin(data) {
    return request({
        url: '/login/sms-login',
        method: 'post',
        data
    })
}

export function getInfo(token) {
  return request({
    url: '/enterpriseRoleController/getUserRoleResourceList',
    // url: '/navigation',
    method: 'post',
    // params: { token }
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}
