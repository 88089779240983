<template>
    <div class="interview" style="position: relative;">
        <div class="header">
            <el-row>
                <el-col :span="2">
                    <div class="logo-img">
                        <img src="@/assets/u6395.png" alt="">
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="title">
                        <!-- <h4>{{infoData.positionName}}{{$t('interview.interview')}}- {{userName}}</h4> -->
                        <h4>{{infoData.positionName}}{{$t('interview.interview')}}- {{infoData.candidateName}}</h4>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="time-box">
                        <!--<div class="time">-->
                            <!--<i class="el-icon-time"></i>-->
                            <!--00:00:00-->
                        <!--</div>-->
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="time-box">
                        <div class="btn" @click="showFile">
                        <!-- <div class="btn" @click="showFile" v-if="loginInfo.isPrimary=='1'"> -->
                            <i class="el-icon-tickets"></i>
                            {{$t('interview.viewResume')}}
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="time-box">
                        <div class="btn" @click="previewSelete">
                            <i class="el-icon-user"></i>
                            {{$t('interview.viewSelf')}}
                        </div>
                    </div>
                </el-col>
                <!-- <el-col :span="3">
                    <div class="time-box">
                        <div class="btn" @click="allMute" v-if="loginInfo.isPrimary=='1'">
                            <i class="el-icon-turn-off-microphone"></i>
                            {{$t('interview.allMute')}}
                        </div>
                    </div>
                </el-col> -->
                <el-col :span="2">
                    <div class="time-box">
                        <el-button v-if="loginInfo.isPrimary=='1'" type="danger" @click="toEnd">{{$t('interview.endInterview')}}</el-button>
                    </div>

                </el-col>
            </el-row>
        </div>
        <div class="content">
            <div class="video" v-if="!maskDialog">
                <!--视频组件-->
                <AlyVideo ref="videoDemo" v-if="isInit" :channelId="this.channelId" :userName="userName" @setUser="setUser"></AlyVideo>
            </div>
        </div>
        <el-dialog append-to-body
                   class="mask-dialog"
                   :title="loginInfo.isPrimary=='1'?$t('interview.startInterview'):$t('interview.waitInterview')"
                   :visible.sync="maskDialog"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :show-close="false"
                   width="30%" top="200px">
            <div class="mask" v-if="loginInfo.isPrimary=='1'&&!endData">
                <p>{{$t('interview.clickStart')}}</p>
                <div class="mask-tip">
                    <el-button type="primary" @click="startBtn">{{$t('interview.startInterview')}}</el-button>
                </div>
            </div>
            <div class="mask" v-if="loginInfo.isPrimary!='1'&&!endData">
                <p>{{$t('interview.aboutToBegin')}}</p>
            </div>
            <div class="mask" v-if="endData">
                <p>{{$t('interview.interviewEnded')}}</p>
            </div>
        </el-dialog>
        <el-dialog append-to-body
                   :title="$t('interview.interviewScore')"
                   :visible.sync="endDialog"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :show-close="false"
                   width="30%" top="200px">
            <div class="endMask">
                <el-form ref="elForm" label-width="110px" :model="rateData" label-suffix="：">
                    <el-form-item :label="$t('interview.attitude')">
                        <el-rate v-model="rateData.scoreAttitude" show-text :texts="texts" text-color="#F7BA2A"></el-rate>
                    </el-form-item>
                    <el-form-item :label="$t('interview.character')">
                        <el-rate v-model="rateData.scoreCharacter" show-text :texts="texts" text-color="#F7BA2A"></el-rate>
                    </el-form-item>
                    <el-form-item :label="$t('interview.technology')">
                        <el-rate v-model="rateData.scoreProfessional" show-text :texts="texts" text-color="#F7BA2A"></el-rate>
                    </el-form-item>
                    <el-form-item :label="$t('interview.experience')">
                        <el-rate v-model="rateData.scoreExperience" show-text :texts="texts" text-color="#F7BA2A"></el-rate>
                    </el-form-item>
                    <el-form-item :label="$t('interview.comment')">
                        <el-input type="textarea" v-model="rateData.comment"></el-input>
                    </el-form-item>
                </el-form>
                <div class="boontbtn">
                    <el-button type="primary" size="small" style="margin: 0 30px" @click="theEnd()">
                        {{$t('interview.endInterview')}}
                    </el-button>
                    <el-button type="info" size="small" @click="endDialog=false">{{$t('common.cancel')}}</el-button>
                </div>
            </div>
        </el-dialog>
        <!--查看简历-->
        <el-dialog append-to-body
                   :title="$t('interview.viewResume')"
                   :visible.sync="fileDialog"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   width="80%" top="60px">
            <div class="endMask">
                <div class="iFrame" v-if="iframeUrl" >
                    <iframe style="width: 100%;height: calc( 100vh - 220px )" :src="iframeUrl"></iframe>
                </div>
                <div class="resume-none" v-if="!iframeUrl">
                    <h2>{{$t('interview.noResume')}}</h2>
                </div>
                <div class="boontbtn">
                    <el-button type="info" size="small" @click="fileDialog=false">{{$t('common.cancel')}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import AlyVideo from '../AudioVideo/csvideo'
    import {post, del, get, putUrl} from "@/api/http";
    import baseURL from '@/api/baseURL.js'
    import qs from 'qs'
    import request from "@/utils/request.js";

    export default {
        name: "index",
        components:{AlyVideo},
        data(){
            return{
                table: false,
                tabPosition:'',
                videoList:[],
                videoIndex: -1,
                codeDialog:false,
                channelId:'',
                formLabelWidth: '120px',
                isInit:true,
                infoData:{},
                loginInfo:{},
                userName:'',
                maskDialog:true,
                endData:false,
                endDialog:false,
                fileDialog:false,
                rateData:{},
                texts:[this.$t('interview.verybad'), this.$t('interview.bad'), this.$t('interview.average'), this.$t('interview.good'), this.$t('interview.verygood')],
                interviewId:'',
                iframeUrl:'',
            }
        },
        methods:{
            // tabClick(type){
            //     this.table = true
            // },
            // clearCode(){
            //     this.codeDialog=false;
            // },
            // submitCode(){
            //     this.$refs['codeForm'].validate(valid => {
            //         if (!valid) {
            //             this.$message.error(this.$t('common.pleaseComplete'));
            //             return false
            //         }else {
            //             get("/videoconferencing/play/"+this.form.code).then(res => {
            //                 if (res.code == 1) {
            //                     this.codeDialog=false;
            //                     this.isInit=true;
            //                 } else {
            //                     this.$message.error(res.data);
            //                 }
            //             });
            //         }
            //     })
            // },
            setUser(list){
                console.log('设置user')
                this.videoList=list;
            },
            subscribe(item){
                this.$refs.videoDemo.subscribe(item);
            },
            allMute(){
                this.$refs.videoDemo.allMute();
            },
            nameSet(name){
                return name.split('_')[1]
            },
            //WebSocket链接，用途：主面试官开始面试通知其他用户
            init: function () {
                if (typeof(WebSocket) === "undefined") {
                    alert(this.$t('interview.notSupportSocket'))
                } else {
                    // let token = getToken();
                    let url ="wss://" + baseURL.split('//')[1] + "video/"+this.loginInfo.phone;
                    // 实例化socket
                    this.socket = new WebSocket("wss://" + baseURL.split('//')[1] + "video/"+this.loginInfo.phone);
                    // this.socket = new WebSocket("wss://47.99.204.31:8443/video/"+this.loginInfo.phone);
                    // 监听socket连接
                    this.socket.onopen = this.open;
                    // 监听socket错误信息
                    this.socket.onerror = this.error;
                    // 监听socket消息
                    this.socket.onmessage = this.getMessage;
                }
            },

            open: function () {
                console.log("socket连接成功")
            },
            error: function (err) {
                console.log(err);
                console.log("连接错误")
            },
            getMessage: function (msg) {
                console.log(msg.data);
                if (msg.data == '1') {
                    this.clearMask();
                    this.init();
                }
                if (msg.data == '-1') {
                    location.reload()
                }
            },
            send: function () {
                this.socket.send(params)
            },
            close: function () {
                console.log("socket已经关闭")
            },
            clearMask(){
                this.maskDialog=false;
            },
            startBtn(){
                let phoneLIst=[];
                phoneLIst.push(this.infoData.candidatePhone);
                if (this.infoData.list.length){
                    this.infoData.list.map(item=>{
                        phoneLIst.push(item.phone)
                    })
                }
                post("/videoconferencing/start?interviewId="+this.interviewId+"&phones="+phoneLIst).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //检查面试是否开始
            isStart(){
                post("/videoconferencing/check",{interviewId:this.interviewId}).then(res => {
                    if (res.code == 1) {
                        if (res.data=='0'){
                            this.maskDialog=true;
                            this.init();
                        } else if(res.data=='1') {
                            this.maskDialog=false
                            this.reloadWebSocket()
                            this.init()
                        }else {
                            this.endData=true;
                            this.maskDialog=true
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            toEnd(){
                this.endDialog=true;
            },
            theEnd(){
                let phoneLIst=[];
                phoneLIst.push(this.infoData.candidatePhone)
                if (this.infoData.list.length){
                    this.infoData.list.map(item=>{
                        phoneLIst.push(item.phone)
                    })
                }
                post("/videoconferencing/end?phones="+phoneLIst,{...this.rateData,interviewId:this.interviewId}).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        // this.$router.push({name:'/'})
                        location.reload();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //打开本地预览
            previewSelete(){
                this.$refs.videoDemo.previewSelete();
            },
            // 刷新重新链接websocket
            reloadWebSocket(e){
                post("/videoconferencing/addWebSocket",{phone:this.loginInfo.phone}).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //查看简历
            async showFile(){
                if(this.infoData.fileId){
                    var res = await request({
                        url: "/file/download",
                        method: "POST",
                        params: {
                            id:this.infoData.fileId
                        },
                        responseType: "blob"
                    });
    
                    const blob = new Blob([res], {
                        type:"application/pdf"
                    });
                    this.iframeUrl=URL.createObjectURL(blob);
                    this.fileDialog=true;
                    // console.log(this.iframeUrl);
                }else{
                    this.$message.error(this.$t('interview.noResume'));
                }
            },
            
        },
        mounted(){
            this.interviewId=localStorage.getItem('channelId');
            this.userName=this.nameSet(localStorage.getItem('userName'));
            this.channelId=localStorage.getItem('channelId');
            this.infoData=JSON.parse(localStorage.getItem('info'));
            this.loginInfo=JSON.parse(localStorage.getItem('loginInfo'));
            this.isStart();

            console.log(this.loginInfo);
        },
    }
</script>

<style scoped>
    .header {
        height: 68px;
        background-color: #262626;
        color: #fff;
        border-bottom: 1px solid #fff;

    }

    .logo-img {
        height: 68px;
        padding: 0 15px;
        display: flex;
        align-items: center;
    }

    .logo-img img {
        height: 50px;
    }

    .title {
        height: 68px;
        display: flex;
        align-items: center;
    }

    .time-box {
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .time {
        background-color: #383838;
        padding: 8px 15px;
        font-size: 14px;
    }

    .btn {
        background-color: #383838;
        padding: 8px 15px;
        font-size: 14px;
        color: #d4d4d4;
        cursor: pointer;
    }

    .btn:hover {
        color: #fff;
    }

    .content {
        width: 100%;
        background-color: #1e1e1e;
        position: relative;
    }




    .user-ul{

    }
    .user-ul-li{
        height: 40px;
        line-height: 40px;
    }
    .user-ul-li span{

    }
    .user-ul-li .li-btn{
        float: right;
    }

    .mask-dialog>>>.el-dialog__header{
        border-bottom: 1px solid #c4cdd5;
    }

    .mask{
        padding: 20px 40px;
    }
    .mask .mask-tip{
        margin-top: 20px;
        text-align: center;
    }
    .mask h1{

    }
    .mask p{
        font-size: 30px;
    }
    .endMask>>>.el-form-item__content{
        height: 40px;
        display: flex;
        align-items: center;
    }

    .boontbtn {
        margin-top: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
</style>
