import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/theme/index.css'
import router from "./router";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import i18n from './lang/index'
import locale from 'element-ui/lib/locale/lang/en'

import './icons' // icon

import './permission'

//引入
import moment from 'moment';
//挂载到原型
Vue.prototype.$moment = moment ;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");

import { pageSize, pageSizes } from "./views/pageParameter"

Vue.prototype.$pageSize = pageSize;
Vue.prototype.$pageSizes = pageSizes;

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(mavonEditor);

window.vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

