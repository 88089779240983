<template>
  <div class="candidate">
    <div class="candidate-card">
      <el-form :inline="true" :model="searchlist" class="demo-form-inline" size="mini" label-suffix="：">
        <el-form-item>
          <el-input :placeholder="
            $t('candidate.candidateName') +
            '/' +
            $t('candidate.phone') +
            '/' +
            $t('candidate.email')
          " v-model="searchlist.candidatePhone">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker v-model="timeValue" type="daterange" :start-placeholder="$t('candidate.startDate')"
            :end-placeholder="$t('candidate.endDate')" format="yyyy - MM - dd" value-format="yyyy-MM-dd"
            @change="timgChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList" size="mini">{{
          $t('common.query')
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="resetForm" size="mini">{{
          $t('common.reset')
          }}</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-popover placement="bottom-start" width="200" trigger="click" popper-class="pop">
            <div class="set-field">
              <el-checkbox v-for="i in checkedCandidate" :label="i.id" :key="i.id" :value="i.status == 1 ? true : false"
                @change="(checked) => changeSel(checked, i)">{{ languageSet(i) }}</el-checkbox>
            </div>
            <el-button slot="reference" type="primary" size="medium">{{
            $t('candidate.setfield')
            }}</el-button>
          </el-popover>
        </el-form-item>
      </el-form>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" @row-click="openDetails">
          <template v-for="(item, index) in tableOption">
            <el-table-column :key="item.id" :prop="item.code" :show-overflow-tooltip="true" 
            v-if="item.code=='technicalTraining'||item.code=='optExtensionSponsorship'"
              :label="language == 'zh-Cn' ? item.name : item.code">
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property]=='1'">{{ $t('candidate.accepted') }}</span>
                <span v-if="scope.row[scope.column.property]=='2'">{{ $t('candidate.paid')}}</span>
                <span v-if="scope.row[scope.column.property]=='3'">{{ $t('candidate.freeCharge') }}</span>
                <span v-if="scope.row[scope.column.property]=='4'">{{ $t('candidate.depends') }}</span>
                <span v-if="scope.row[scope.column.property]=='5'">{{ $t('candidate.na') }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="item.id" :prop="item.code" :show-overflow-tooltip="true" v-else-if="item.code=='jobHuntingStatus'"
              :label="language == 'zh-Cn' ? item.name : item.code">
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property]=='1'">{{ $t('candidate.Leaveavailabl') }}</span>
                <span v-if="scope.row[scope.column.property]=='2'">{{ $t('candidate.jobWithin')}}</span>
                <span v-if="scope.row[scope.column.property]=='3'">{{ $t('candidate.jobConsider') }}</span>
                <span v-if="scope.row[scope.column.property]=='4'">{{ $t('candidate.jobYet') }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="item.id" :prop="item.code" :show-overflow-tooltip="true" v-else-if="item.code=='workIdentity'"
              :label="language == 'zh-Cn' ? item.name : item.code">
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property]=='1'">OPT (year one)</span>
                <span v-if="scope.row[scope.column.property]=='2'">CPT</span>
                <span v-if="scope.row[scope.column.property]=='3'">H1B</span>
                <span v-if="scope.row[scope.column.property]=='4'">GC</span>
                <span v-if="scope.row[scope.column.property]=='5'">Citzen</span>
                <span v-if="scope.row[scope.column.property]=='6'">OPT</span>
                <span v-if="scope.row[scope.column.property]=='7'">other</span>
              </template>
            </el-table-column>
            <el-table-column :key="item.id" :prop="item.code" :show-overflow-tooltip="true" v-else-if="item.code=='candidateName'"
              :label="language == 'zh-Cn' ? item.name : item.code" width="150" fixed="left">
              <template slot-scope="scope">
                <slot v-if="item.slot" :name="scope.column.property" :row="scope.row" :$index="scope.$index" />
                <span v-else>{{ scope.row[scope.column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="item.id" :prop="item.code" :show-overflow-tooltip="true" v-else
              :label="language == 'zh-Cn' ? item.name : item.code" width="150">
              <template slot-scope="scope">
                <slot v-if="item.slot" :name="scope.column.property" :row="scope.row" :$index="scope.$index" />
                <span v-else>{{ scope.row[scope.column.property] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-pagination :page-size="5" layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="pageConfig.pageSizes"
          :total="pageConfig.total"></el-pagination>
      </div>
    </div>
    <!--详情-->
    <div v-if="detailsDialog">
      <el-dialog append-to-body title="详情信息" :visible.sync="detailsDialog" :before-close="clearDetails" width="80%">
        <DetailsDialog @clearDetails="clearDetails" ref="detailsRef" :rowDetailsData="rowDetailsData"></DetailsDialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DetailsDialog from './detailsDialog'
import { post, get } from '@/api/http'
import Cookies from 'js-cookie'
export default {
  name: 'Candidate',
  data() {
    return {
      searchlist: {},
      tableData: [],
      timeValue: '',
      detailsDialog: false,
      rowDetailsData: {},
      checkedCandidate: [],
      tableOption: [],
      language: Cookies.get('language'),
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
    }
  },

  components: {
    DetailsDialog,
  },

  created() {
    this.getShowList()
    this.getList()
    //console.log(this.language)
  },

  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    //时间段选择赋值参数
    timgChange() {
      if (this.timeValue) {
        this.searchlist.startTime = this.timeValue[0]
        this.searchlist.endTime = this.timeValue[1]
      } else {
        this.searchlist.startTime = ''
        this.searchlist.endTime = ''
      }
    },
    //重置
    resetForm() {
      this.timeValue = []
      this.searchlist = {}
      this.getList()
    },
    //字段显示列表接口
    getShowList() {
      let list = ''
      //
      post('/dictData/getList', {
        typeId: '1507164026737610754',
      }).then((res) => {
        if (res.code == 1) {
          this.checkedCandidate = res.data
          list = res.data.filter((item) => {
            return item.status == 1
          })

          this.tableOption = list
          //console.log(this.tableOption)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //列表接口
    getList() {
      post('/exam-examinee/getPage', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
        ...this.searchlist,
      }).then((res) => {
        if (res.code == 1) {
          //console.log(res.data.data)
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //点击当前行弹窗
    openDetails(row) {
      //console.log(row)
      post('/exam-examinee/getExamExamineeEntityDetails', {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          console.log(res.data)
          this.rowDetailsData = res.data
          this.detailsDialog = true
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //关闭弹窗
    clearDetails() {
      this.detailsDialog = false
    },
    //选择字段

    changeSel(checked, item) {
      //console.log(checked, item)
      if (checked) {
        this.update(item.id, 1)
      } else {
        this.update(item.id, 2)
      }
    },
    update(id, num) {
      post('/dictData/update', {
        typeId: '1507164026737610754',
        id: id,
        status: num,
      }).then((res) => {
        if (res.code == '1') {
          this.getShowList()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    languageSet(value) {
      if (this.language == 'zh-Cn') {
        return value.name
      } else {
        return value.code
      }
    },
  },
}
</script>
<style scoped>
.candidate {
  padding: 15px;
  overflow: scroll;
}

.candidate-card {
  background-color: #fff;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  padding: 15px;
}

.set-field {
  width: 100%;
  padding: 15px;
  background: #fff;
  border: 1px solid #333399;
}

</style>
<style>
  .pop.el-popover {
    width: 750px !important;
    left: 30% !important;
  }
  .el-popover .el-checkbox {
    padding: 10px;
  }
</style>
