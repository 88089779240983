<template>
    <div class="UpdatePassword">
        <el-form ref="elForm" :model="passData" :rules="rules" size="medium" label-width="160px">
            <el-form-item :label="$t('common.originPsw')" prop="oldPassword">
                <el-input type="password"  v-model="passData.oldPassword" :placeholder="this.$t('common.pleaseEnter')+this.$t('common.originPsw')+'!'" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('common.newPsw')" prop="newPassword">
                <el-input type="password" v-model="passData.newPassword" :placeholder="this.$t('common.pleaseEnter')+this.$t('common.newPsw')+'!'" clearable :style="{width: '70%'}" >
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('common.confirmPsw')" prop="subPassword">
                <el-input type="password"  v-model="passData.subPassword" :placeholder="this.$t('common.pleaseEnter')+this.$t('common.confirmPsw')+'!'" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click=" $emit('clear')">{{$t('common.close')}}</el-button>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import {post, del, get, putUrl} from "@/api/http";
    export default {
        name: "UpdatePassword",
        data(){
            const validate = (rule, value, callback) => {
                if (value != this.passData.newPassword) {
                    callback(new Error(this.$t('common.passwordNotMatch')))
                } else {
                    callback()
                }
            }
            return{
                passData:{},
                rules: {
                    oldPassword: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('common.originPsw')+'!',
                        trigger: 'blur'
                    }],  newPassword: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('common.newPsw')+'!',
                        trigger: 'blur'
                    }],
                    subPassword: [{
                        required: true,
                        trigger: 'blur',
                        validator: validate
                    }],
                }
            }
        },
        methods:{
            submitForm(){
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        post('setting-user/update-password',{newPassword:this.passData.newPassword,oldPassword:this.passData.oldPassword}).then(res=>{
                            if (res.code==1){
                                this.$message.success(this.$t('common.modifySuccess'));
                                setTimeout(()=>{
                                    store.dispatch('user/resetToken').then(() => {
                                        location.reload()
                                    })
                                },1000)

                            }else {
                                this.$message.error(res.data);
                            }
                        })
                    }
                })
            }
        },
    }
</script>

<style scoped>

</style>
