<template>
    <div class="essential">
        <el-form ref="essential" :model="essential" label-width="200px" label-suffix="：">
            <el-form-item label="Logo：" style="width: 40%;">
                <div class="upload-box">
                    <el-upload
                            v-loading="loading"
                            class="avatar-uploader"
                            ref="upload"
                            :action="baseURL+'file/upload'"
                            :on-success="uploadSuccess"
                            :on-change="uploadChange"
                            :headers="uploadHeader"
                            :file-list="fileList"
                            accept=".jpg,.png">
                        <img v-if="logoUrl" :src="logoUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
            <el-form-item :label="$t('setup.CoScale')" style="width: 40%;">
                <el-select v-model="essential.crewSize" :placeholder="$t('common.pleaseSelect')">
                    <el-option
                            v-for="item in crewSize"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('setup.CoProfile')">
                <mavon-editor  :toolbars="toolbars"  :subfield="false" :defaultOpen="'edit'" v-model="essential.remark" class="makeEditor" :language="editorLanguage"/>
            </el-form-item>
            <el-form-item :label="$t('setup.CoPicture')">
                <div class="upload-box">
                    <div class="img-list" v-if="imgList.length" style="display: inline-block">
                        <ul>
                            <li v-for="(item,index) in imgList" :key="index" class="list-li">
                                <img :src="item.ossUrl" class="avatar list-avatar">
                                <i class="el-icon-delete img-del" @click="delListImg(item)"></i>
                            </li>
                        </ul>

                    </div>
                    <el-upload
                            style="display: inline-block"
                            v-loading="loading"
                            class="avatar-uploader"
                            ref="upload"
                            :action="baseURL+'file/upload'"
                            :on-success="imgSuccess"
                            :on-change="imgChange"
                            :headers="uploadHeader"
                            :file-list="imgFileList"
                            accept=".jpg,.jpeg,.png,.gif,.bmp">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
            <div class="boontbtn">
                <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                    {{$t('common.save')}}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import Cookies from 'js-cookie'
    import baseURL from '@/api/baseURL.js'
    import {getToken} from '@/utils/auth'
    export default {
        name: "Essential",
        data(){
            return{
                essential:{},
                logoUrl:'',
                uploadHeader:{},
                fileList: [],
                fileId:'',
                baseURL: baseURL,
                loading:false,
                crewSize:[
                    {
                        value:'0',
                        label:this.$t('setup.lessThan50'),
                    },
                    {
                        value:'1',
                        label:'50-150'+this.$t('setup.persons'),
                    },
                    {
                        value:'2',
                        label:'150-500'+this.$t('setup.persons'),
                    },
                    {
                        value:'3',
                        label:this.$t('setup.moreThan500'),
                    },
                ],
                toolbars: {
                    bold: true, // 粗体
                    italic: true, // 斜体
                    header: true, // 标题
                    underline: true, // 下划线
                    strikethrough: true, // 中划线
                    mark: true, // 标记
                    superscript: true, // 上角标
                    subscript: true, // 下角标
                    quote: true, // 引用
                    ol: true, // 有序列表
                    ul: true, // 无序列表
                    link: true, // 链接
                    imagelink: false, // 图片链接
                    code: true, // code
                    table: true, // 表格
                    fullscreen: true, // 全屏编辑
                    readmodel: true, // 沉浸式阅读
                    htmlcode: true, // 展示html源码
                    help: false, // 帮助
                    /* 1.3.5 */
                    undo: true, // 上一步
                    redo: true, // 下一步
                    trash: true, // 清空
                    save: false, // 保存（触发events中的save事件）
                    /* 1.4.2 */
                    navigation: true, // 导航目录
                    /* 2.1.8 */
                    alignleft: true, // 左对齐
                    aligncenter: true, // 居中
                    alignright: true, // 右对齐
                    /* 2.2.1 */
                    subfield: true, // 单双栏模式
                    preview: false // 预览
                },
                imgFileList:[],
                imageUrl:'',
                imgId:'',
                imgList:[],
                editorLanguage:Cookies.get('language')
            }
        },
        methods:{
            //上传成功回调
            uploadSuccess(response, file, fileList) {
                if (response.code == 1) {
                    this.$message.success('上传成功');
                    let imgId=response.data.id;
                    this.getImgUrl(imgId,'logo');
                    // this.getBusinessInfo(imgId);
                    this.fileId=imgId;
                    this.loading = false
                }
            },
            //超出处理
            uploadChange(file, fileList) {
                let fileData = [];
                fileData.push(fileList[fileList.length - 1]);
                this.fileList = fileData;
            },
            getImgUrl(id,type){
                if (id){
                    get("/file/oss-url/"+id).then(res => {
                        if (res.code == 1) {
                            if (type=='logo'){
                                this.logoUrl=res.data;
                            }
                            if (type=='img'){
                                this.imgList.push({
                                    imgFileId:id,
                                    ossUrl:res.data
                                })
                            }
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }

            },

            //上传成功回调
            imgSuccess(response, file, fileList) {
                if (response.code == 1) {
                    this.$message.success('上传成功');
                    let imgId=response.data.id;
                    this.getImgUrl(imgId,'img');
                    // this.getBusinessInfo(imgId);
                    this.imgId=imgId;
                    this.loading = false
                }
            },
            //超出处理
            imgChange(file, fileList) {
                let fileData = [];
                fileData.push(fileList[fileList.length - 1]);
                this.imgFileList = fileData;
            },

            submitForm(){
                console.log(this.essential);
                let imgData=[];
                this.imgList.forEach(item=>{
                    imgData.push(item.imgFileId)
                })
                let subData={
                    ...this.essential,
                    imgFileId:imgData.toString(),
                    // logoFile:imgData,
                    logoFile:this.fileId,
                }
                post("/setting-enterprise/insert",subData).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.saveSuccess'));
                        this.getEssentialinfo();
                    } else {
                        this.$message.error(res.data);
                    }
                });
                console.log(subData);
            },
            getEssentialinfo(){
                get("/setting-enterprise/").then(res => {
                    if (res.code == 1) {
                        if (res.data){
                            let setData={
                                id:res.data.enterpriseInfo.id,
                                enterpriseName:res.data.enterpriseInfo.enterpriseName,
                                remark:res.data.enterpriseInfo.remark,
                                crewSize:res.data.enterpriseInfo.crewSize,
                            }
                            this.essential=setData;
                            this.fileId=res.data.enterpriseInfo.logoFile;
                            this.getImgUrl(this.fileId,'logo');
                            this.imgList=res.data.imgList;
                        }
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            delListImg(item){
                this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                    confirmButtonText: this.$t('common.ok'),
                    cancelButtonText: this.$t('common.cancel'),
                    type: 'warning'
                }).then(() => {
                    del("/setting-enterprise/delete-img/" + item.id).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.delSuccess'));
                            this.getEssentialinfo();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }).catch(() => {

                });
            }
        },
        mounted(){
            this.getEssentialinfo();
            this.uploadHeader = {
                Authorization: getToken()
            };
        }
    }
</script>

<style scoped>
    .essential{
        width: 80%;
    }
    .upload-box>>>.avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .upload-box>>>.el-upload-list{
        display: none;
    }

    .list-avatar{
        display: inline-block;
        margin-right: 20px;
    }

    .list-li{
        display: inline-block;
        position: relative;
    }

    .img-del{
        position: absolute;
        right: 25px;
        top: 5px;
        color: #f00;
        display: none;
    }

    .list-li:hover .img-del{
        display: inline-block;
    }
</style>
