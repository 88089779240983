<template>
    <div class="see">
        <h2>{{seeObj.title}}</h2>
        <mavon-editor
                :value="seeObj.content"
                defaultOpen="preview"
                :boxShadow="false"
                style="z-index:1;height:50vh"
                :editable="false"
                :subfield="false"
                :toolbarsFlag="false"
                class="makeEditor"
        >
        </mavon-editor>
        <div class="answer-box">
            <!--<el-radio-group v-model="radio">-->
                <!--<el-radio label="A">{{seeObj.optionA}}</el-radio>-->
                <!--<el-radio label="B">{{seeObj.optionB}}</el-radio>-->
                <!--<el-radio label="C">{{seeObj.optionC}}</el-radio>-->
                <!--<el-radio label="D">{{seeObj.optionD}}</el-radio>-->
            <!--</el-radio-group>-->
            <el-radio-group v-model="checked">
                <el-row class="list-option">
                    <el-col :span="20"> <el-radio label="A">{{seeObj.optionA}}</el-radio ></el-col>
                </el-row>
                <el-row class="list-option">
                    <el-col :span="20"> <el-radio label="B">{{seeObj.optionB}}</el-radio ></el-col>
                </el-row>
                <el-row class="list-option">
                    <el-col :span="20"> <el-radio label="C">{{seeObj.optionC}}</el-radio ></el-col>
                </el-row>
                <el-row class="list-option">
                    <el-col :span="20"> <el-radio label="D">{{seeObj.optionD}}</el-radio ></el-col>
                </el-row>
            </el-radio-group>
        </div>
        <el-button type="primary" size="mini"  v-if="seeObj.answers" @click="showAnwser" style="margin-top: 20px;">{{$t('question.checkAnswer')}}</el-button>
        <div class="boontbtn">
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.close')}}</el-button>
        </div>
    </div>
</template>

<script>
    import 'mavon-editor/dist/css/index.css';
    export default {
        name: "see",
        data() {
            return {
                seeObj:{},
                toolbars: {
                    bold: false, // 粗体
                    italic: false, // 斜体
                    header: false, // 标题
                    underline: false, // 下划线
                    strikethrough: false, // 中划线
                    mark: false, // 标记
                    superscript: false, // 上角标
                    subscript: false, // 下角标
                    quote: false, // 引用
                    ol: false, // 有序列表
                    ul: false, // 无序列表
                    link: false, // 链接
                    imagelink: false, // 图片链接
                    code: false, // code
                    table: false, // 表格
                    fullscreen: false, // 全屏编辑
                    readmodel: false, // 沉浸式阅读
                    htmlcode: false, // 展示html源码
                    help: false, // 帮助
                    /* 1.3.5 */
                    undo: false, // 上一步
                    redo: false, // 下一步
                    trash: false, // 清空
                    save: false, // 保存（触发events中的save事件）
                    /* 1.4.2 */
                    navigation: false, // 导航目录
                    /* 2.1.8 */
                    alignleft: false, // 左对齐
                    aligncenter: false, // 居中
                    alignright: false, // 右对齐
                    /* 2.2.1 */
                    subfield: false, // 单双栏模式
                    preview: false // 预览
                },
                checked:'',
                radio:'',
                isShow:false,
            }
        },
        methods:{
            setData(data){
                this.seeObj=data;
            },
            showAnwser(data) {
                if(!this.isShow){
                    this.checked = this.seeObj.answers;
                    this.isShow=true
                }else{
                    this.checked = '';
                    this.isShow=false;
                }
            },
            clearAdd(){
                this.$emit('clear')
            }
        },
        mounted(){

        }

    }
</script>

<style scoped>
.see{
    padding: 20px;
}
.makeEditor{
    margin: 20px 0;
    min-height: 100px;
    height: auto !important;
}
.makeEditor>>>.hljs{
    background-color: #f6f8fa ;
}
.answer-box{
    padding: 15px;
}
.title-option{
    background-color: #f2f3f7;
    color: #778ca2;
    font-weight: bold;
    padding: 0 15px;
}
.list-option{
    /*line-height: 70px;*/
    padding: 10px;
}

</style>
