<template>
  <div class="question">
    <div class="info">
      <!-- 头部 -->
      <el-row>
        <el-col :span="18">
          <div class="hello">
            <el-tabs v-model="activeName" @tab-click="activeSel">
              <el-tab-pane :label="$t('question.enterbank')" name=" "></el-tab-pane>
              <el-tab-pane :label="$t('question.commonbank')" name="1"></el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <el-col :span="6" v-show="activeName == ' '">
          <div class="help">
            <el-button @click="shareRecord">
              {{ $t('question.sharingRecords') }}
            </el-button>
            <el-button type="primary" @click="selectType">
              {{ $t('question.addQuestion') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 左侧列表 -->
    <div class="left-card">
      <div class="search-box">
        <div class="type-search">
          <el-input :placeholder="$t('question.pleasecontent')" v-model="searchTitle" clearable=""
            @keyup.native.enter="handleSearch" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        </div>
      </div>
      <!-- 列表开始 -->
      <ul class="type-ul">
        <!-- 职位 -->
        <el-popover placement="right" width="600" trigger="hover">
          <div class="typeList">
            <div class="check-box" v-if="positionData.length">
              <el-checkbox-group v-model="positionList" @change="getList">
                <el-checkbox class="label-item" v-for="item in positionData" :key="item.id" :label="item">{{
                item.positionName }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="check-box" v-else>{{ $t('question.nonPosition') }}</div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.position') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 技能 -->
        <el-popover placement="right" width="600" trigger="hover">
          <div class="typeList">
            <div class="check-box" v-if="labelData.length">
              <el-checkbox-group v-model="labelList" @change="getList">
                <el-checkbox class="label-item" v-for="item in labelData" :key="item.id" :label="item">{{ item.labelName
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="check-box" v-else>{{ $t('question.nonSkills') }}</div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.skills') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 题型 -->
        <el-popover placement="right" width="400" trigger="hover">
          <div class="typeList">
            <div class="check-box">
              <el-radio-group v-model="checkType" @change="getList">
                <el-radio :label="$t('question.choiceq')"></el-radio>
                <!-- <el-radio :label="$t('question.fillInq')"></el-radio> -->
                <el-radio :label="$t('question.QandA')"></el-radio>
                <el-radio :label="$t('question.programq')"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.qtype') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 难度 -->
        <el-popover placement="right" width="400" trigger="hover">
          <div class="typeList">
            <div class="check-box">
              <el-radio-group v-model="difficulty" @change="getList">
                <el-radio :label="$t('question.easy')"></el-radio>
                <el-radio :label="$t('question.medium')"></el-radio>
                <el-radio :label="$t('question.difficult')"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.difficulty') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 来源 -->
        <el-popover placement="right" width="400" trigger="hover" v-if="activeName == ' '">
          <div class="typeList">
            <div class="check-box" v-if="sourceData.length">
              <el-checkbox-group v-model="sourceList" @change="getList">
                <el-checkbox class="label-item" v-for="item in sourceData" :key="item.id" :label="item">{{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="check-box" v-else>{{ $t('question.nonSource') }}</div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.source') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 来源日期 -->
        <el-popover placement="right" width="400" trigger="hover">
          <div class="typeList">
            <div class="check-box">
              <!-- <el-date-picker @change="getList" v-model="year" format="yyyy" value-format="yyyy" type="year">
              </el-date-picker> -->
              <el-date-picker
                v-model="year"
                type="daterange"
                range-separator=" - "
                value-format="yyyy-MM-dd"
                :start-placeholder="$t('candidate.startDate')"
                @change="fomatTime(year,1)"
                :end-placeholder="$t('candidate.endDate')">
              </el-date-picker>
            </div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.sourceTime') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 共享 -->
        <el-popover placement="right" width="400" trigger="hover" v-if="activeName == ' '">
          <div class="typeList">
            <div class="check-box">
              <el-radio-group v-model="shareStatus" @change="getList">
                <el-radio :label="$t('question.yes')"></el-radio>
                <!-- <el-radio :label="$t('question.no')"></el-radio> -->
                <el-radio :label="$t('question.questionBank')"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.share') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 创建人 -->
        <el-popover placement="right" width="600" trigger="hover" v-if="activeName == ' '">
          <UserCheck v-model="userList" @update="getList"></UserCheck>
          <li slot="reference" class="type-li">
            {{ $t('question.creater') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- 创建日期 -->
        <el-popover placement="right" width="400" trigger="hover">
          <div class="typeList">
            <div class="check-box">
              <el-date-picker
                v-model="cTime"
                type="daterange"
                range-separator=" - "
                value-format="yyyy-MM-dd"
                :start-placeholder="$t('candidate.startDate')"
                @change="fomatTime(cTime,2)"
                :end-placeholder="$t('candidate.endDate')">
              </el-date-picker>
            </div>
          </div>
          <li slot="reference" class="type-li">
            {{ $t('question.createTime') }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- EndClient -->
        <el-popover placement="right" width="600" trigger="hover" v-if="activeName == ' '">
          <ClientCheck v-model="endList" @update="getList"></ClientCheck>
          <li slot="reference" class="type-li">
            EndClient
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- Partner -->
        <el-popover placement="right" width="600" trigger="hover" v-if="activeName == ' '">
          <ClientCheck v-model="partnerList" @update="getList"></ClientCheck>
          <li slot="reference" class="type-li">
            Partner
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- Vendor1 -->
        <el-popover placement="right" width="600" trigger="hover" v-if="activeName == ' '">
          <ClientCheck v-model="vendor1List" @update="getList"></ClientCheck>
          <li slot="reference" class="type-li">
            Vendor1
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
        <!-- Vendor2 -->
        <el-popover placement="right" width="600" trigger="hover" v-if="activeName == ' '">
          <ClientCheck v-model="vendor2List" @update="getList"></ClientCheck>
          <li slot="reference" class="type-li">
            Vendor2
            <i class="el-icon-arrow-right"></i>
          </li>
        </el-popover>
      </ul>
    </div>
    <!-- 右侧表格 -->
    <div class="right-card">
      <div class="table">
        <div class="table-title">
          {{ $t('question.selected') }}：
          <!-- 职位 -->
          <el-tag v-for="tag in positionList" :key="tag.id" closable @close="tagsClose(tag, 'position')" type="info">{{
          tag.positionName }}</el-tag>
          <!-- 标签 -->
          <el-tag v-for="tag in labelList" :key="tag.id" closable @close="tagsClose(tag, 'label')" type="info">{{
          tag.labelName }}</el-tag>
          <!-- 题型 -->
          <el-tag v-if="checkType" closable @close="tagClose('checkType')" type="info">{{ checkType }}</el-tag>
          <!-- 难度 -->
          <el-tag v-if="difficulty" closable @close="tagClose('difficulty')" type="info">{{ difficulty }}</el-tag>
          <!-- 来源日期 -->
          <el-tag v-if="year" closable @close="tagClose('year')" type="info">{{
          sourceTime.startTime + ' - ' + sourceTime.endTime
          }}</el-tag>
          <!-- 创建日期 -->
          <el-tag v-if="cTime" closable @close="tagClose('ctime')" type="info">{{
          sourceTime.startCreateTime + ' - ' + sourceTime.endCreateTime
          }}</el-tag>
          <!-- 来源 -->
          <el-tag v-for="tag in sourceList" :key="tag.id" closable @close="tagsClose(tag, 'source')" type="info">{{
          tag.name }}</el-tag>
          <!-- 共享 -->
          <el-tag v-if="shareStatus" closable @close="tagClose('shareStatus')" type="info">{{ shareStatus }}</el-tag>
          <!-- 创建人 -->
          <el-tag v-for="tag in userList" :key="tag.id" closable @close="tagsClose(tag, 'creater')" type="info">{{
          tag.username }}</el-tag>
          <!-- 客户 -->
          <el-tag v-for="tag in endList" :key="tag.id" closable @close="tagsClose(tag, 'endClient')" type="info">{{
          tag.name }}</el-tag>
          <el-tag v-for="tag in partnerList" :key="tag.id" closable @close="tagsClose(tag, 'partner')" type="info">{{
          tag.name }}</el-tag>
          <el-tag v-for="tag in vendor1List" :key="tag.id" closable @close="tagsClose(tag, 'vendor1')" type="info">{{
          tag.name }}</el-tag>
          <el-tag v-for="tag in vendor2List" :key="tag.id" closable @close="tagsClose(tag, 'vendor2')" type="info">{{
          tag.name }}</el-tag>
        </div>
        <div class="table-content">
          <ul style="margin-top: 5px" v-if="tableData.length">
            <li v-for="(item, index) in tableData" :key="index" style="overflow: hidden">
              <el-row>
                <el-col :span="activeName == ' ' ? 20 : 23">
                  <div style="width: 100%" @click="editClick(item, 'see', activeName)">
                    <div class="li-title">
                      {{ item.title }}
                      <span class="success" v-if="item.difficultyGrade == '1'">{{ $t('question.easy') }}</span>
                      <span class="warning" v-if="item.difficultyGrade == '2'">{{ $t('question.medium') }}</span>
                      <span class="danger" v-if="item.difficultyGrade == '3'">
                        {{ $t('question.difficult') }}
                      </span>
                    </div>
                    <div class="li-info">
                      <span>
                        {{ $t('question.type') + '：'
                        }}{{
                        item.type == '1'
                        ? $t('question.choiceq')
                        : item.type == '2'
                        ? $t('question.fillInq')
                        : item.type == '3'
                        ? $t('question.QandA')
                        : $t('question.programq')
                        }}
                      </span>
                      <span>{{ $t('question.sourceTime') + '：' + item.year }}</span>
                      <span>{{ $t('question.grade') + '：' }}{{ item.score }}</span>
                      <span>
                        {{
                        $t('question.time') +
                        '：' +
                        item.answerTime +
                        ' ' +
                        $tc('question.min', item.answerTime)
                        }}
                      </span>
                    </div>
                    <div class="li-time">
                      <span>{{ $t('question.skills') }}：</span>
                      <span class="label-span" v-for="(label, index) in item.labelName" :key="index">{{ label }}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4" style="line-height: 120px" v-if="activeName == ' '">
                  <!--<el-button type="primary" size="mini" @click="handleDetail">详情</el-button>-->
                  <el-button icon="el-icon-share" circle :title="$t('question.share')" :disabled="
                    item.auditStatus == 1 ||
                    item.auditStatus == 2 ||
                    item.auditStatus == '2,4' ||
                    item.auditStatus == '2,5' ||
                    item.isPublic == 1 ||
                    item.isPublic == 2
                  " @click="shareClick(item)"></el-button>

                  <el-button icon="el-icon-edit" circle :title="$t('common.edit')" @click="editClick(item, 'edit')">
                  </el-button>
                  <el-button icon="el-icon-view" circle :title="$t('common.view')" @click="editClick(item, 'see')">
                  </el-button>
                  <el-button :title="$t('common.delete')" icon="el-icon-delete" circle @click="deleteClick(item)">
                  </el-button>
                </el-col>
                <el-col :span="1" style="line-height: 120px" v-else>
                  <el-button icon="el-icon-circle-plus-outline" circle :disabled="item.isSave == 1 || item.tempDisable"
                    :title="$t('interview.join') + $t('question.enterbank')" @click="enterpriseClick(item)"></el-button>
                </el-col> 
                <template v-if="item.isQuestion == 1">
                  <div class="li-share li-share-red" style="z-index: 1" v-show="
                    ((item.isPublic == 1 || item.isPublic == 2) &&
                      item.auditStatus == 2) ||
                    item.auditStatus == '2,4' ||
                    item.auditStatus == '2,5'
                  ">
                    {{ $t('question.shared') }}
                  </div>
                  <template v-if="item.isQuestion == 1">
                    <div class="li-share li-share-blue" v-show="
                      (item.isPublic == 1 || item.isPublic == 2) &&
                      item.auditStatus != 1 &&
                      item.auditStatus != 2 &&
                      item.auditStatus != '2,4' &&
                      item.auditStatus != '2,5'
                    ">
                      {{ $t('question.public') }}
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="li-share li-share-red" v-show="item.auditStatus == '2,5'">
                    {{ $t('question.check') }}
                  </div>
                </template>
              </el-row>
            </li>
          </ul>
          <div style="
              text-align: center;
              font-size: 20px;
              margin: 20px;
              color: #778cb5;
            " v-else>
            {{ $t('question.nodata') }}
          </div>
          <el-pagination :page-size="5" layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="pageConfig.pageSizes"
            :total="pageConfig.total"></el-pagination>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <!-- 选择题型 -->
    <div v-if="typeDialog">
      <el-dialog append-to-body :title="$t('question.addQuestion')" :visible.sync="typeDialog" width="50%">
        <div class="select-box">
          <div class="select-item" v-for="item in question" :key="item.id">
            <el-button type="primary" plain size="large" @click="handleAdd(item)">{{ item.name }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <!--新增选择题-->
    <div v-if="addDialog">
      <el-dialog append-to-body :title="$t('question.questionDetail')" :visible.sync="addDialog" width="80%">
        <ChoiceAdd :dialogType="dialogType" ref="addRef" :rowData="rowData" @add="saveAdd" @clear="clearHand">
        </ChoiceAdd>
      </el-dialog>
    </div>
    <div v-if="seeDialog">
      <el-dialog append-to-body :title="$t('question.questionDetail')" :visible.sync="seeDialog" width="80%">
        <ChoiceSee ref="seeRef" @clear="clearHand"></ChoiceSee>
      </el-dialog>
    </div>
    <!--新增问答题-->
    <div v-if="shortDialog">
      <el-dialog append-to-body :title="$t('question.questionDetail')" :visible.sync="shortDialog" width="80%">
        <ShortAdd :dialogType="dialogType" ref="addRef" :rowData="rowData" :shortType="shortType" @add="saveShort"
          @clear="clearHand"></ShortAdd>
      </el-dialog>
    </div>
    <div v-if="shortSeeDialog">
      <el-dialog append-to-body :title="$t('question.questionView')" :visible.sync="shortSeeDialog" width="80%">
        <ShortSee ref="seeRef" @clear="clearHand" :rowData="rowData"></ShortSee>
      </el-dialog>
    </div>

    <!-- 共享记录 -->
    <div v-if="shareDialog">
      <el-dialog append-to-body :title="$t('question.sharingRecords')" :visible.sync="shareDialog" width="80%">
        <shareRecord ref="shareRef" @clear="clearHand" :shareData="shareData"></shareRecord>
      </el-dialog>
    </div>
    <!-- 共享 -->
    <div v-if="shareTipDialog">
      <el-dialog append-to-body :title="$t('common.tips')" :visible.sync="shareTipDialog" width="50%">
        <shareTip ref="shareTipRef" @clear="clearHand" @shareRecord="shareRecord" @getList="getList"
          :shareTipData="rowData"></shareTip>
      </el-dialog>
    </div>
    <!-- 选择共享企业 -->
    <div v-if="selectEnterDialog">
      <el-dialog append-to-body :title="$t('question.selectEnterprise')" :visible.sync="selectEnterDialog" width="70%">
        <shareCompany ref="shareTipRef" @clear="clearHand" @getList="getList" @add="shareCommonCompany"
          :rowData="rowData"></shareCompany>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import ChoiceAdd from './choice/add'
import ChoiceSee from './choice/see'
import ShortAdd from './short/add'
import ShortSee from './short/see'
import shareRecord from './shareRecord/index'
import shareTip from './shareRecord/tip'
import shareCompany from './shareRecord/select'
import ClientCheck from '@/components/client-select'
import UserCheck from '@/components/user-select'

export default {
  name: 'question',
  components: {
    ChoiceAdd,
    ChoiceSee,
    ShortAdd,
    ShortSee,
    ClientCheck,
    UserCheck,
    shareRecord,
    shareTip,
    shareCompany
  },
  data() {
    return {
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },

      tableData: [],
      input1: '',
      searchlist: {},
      question: [
        { id: 1, name: this.$t('question.choiceq') },
        // { id: 2, name: this.$t('question.fillInq') },
        { id: 3, name: this.$t('question.QandA') },
        { id: 4, name: this.$t('question.programq') },
      ],
      value: '',
      value1: '',
      activeName: ' ',
      isPublic: '',
      searchTitle: '',
      checkType: '', //题型
      difficulty: '',
      auditStatus: '', //共享
      shareStatus: '', //共享
      shareData: {},
      shareDialog: false,
      shareTipData: {},
      shareTipDialog: false,
      selectEnterDialog: false,
      addDialog: false,
      seeDialog: false,
      typeDialog: false,
      rowData: {},
      shortDialog: false,
      shortSeeDialog: false,
      shortType: '',
      dialogType: 'add',
      labelList: [], //选中的标签
      labelData: [], //标签列表
      positionData: [], //职位列表
      positionList: [], //选中职位
      year: '', //选中年份
      cTime:'',//选中创建日期
      createTime:{},
      sourceTime:{},
      sourceData: [], //来源列表
      sourceList: [], //选中来源
      clientData: [], //客户列表
      clientList: [], //选中所有客户
      userData: [], //用户列表
      userList: [], //选中所有用户
      endList: [], //选中endClient客户
      partnerList: [], //选中partner
      vendor1List: [], //选中vendor1
      vendor2List: [], //选中vendor2
      clientN: '', //搜索客户名称
      pageSet: {
        pageSize: 12,
        total: 0,
        pageNo: 1,
      },
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleDetail() {
      this.$router.push('questionDetail')
    },
    selectType() {
      this.typeDialog = true
      this.dialogType = 'add'
    },
    handleAdd(item) {
      console.log(item)
      this.rowData = {}

      if (item.id == '1') {
        this.addDialog = true
      }
      if (item.id == '3') {
        this.shortDialog = true
        this.shortType = 3
      }
      if (item.id == '4') {
        this.shortDialog = true
        this.shortType = 4
      }
      this.typeDialog = false
    },
    clearHand() {
      this.addDialog = false
      this.seeDialog = false
      this.shortDialog = false
      this.shortSeeDialog = false
      this.shareDialog = false
      this.shareTipDialog = false
      this.selectEnterDialog = false
    },
    // 搜索标题
    handleSearch() {
      this.pageConfig.pageNo = 1
      this.getList()
    },
    //企业题库和公开题库切换
    activeSel() {
      this.isPublic = this.activeName.trim()
      this.getList()
    },
    // 条件查询试题列表
    getList() {
      let type = ''
      let difficultyGrade = ''
      // 题型
      switch (this.checkType) {
        case this.$t('question.choiceq'):
          type = '1'
          break
        case this.$t('question.fillInq'):
          type = '2'
          break
        case this.$t('question.QandA'):
          type = '3'
          break
        case this.$t('question.programq'):
          type = '4'
          break
        default:
          break
      }
      // 难度
      switch (this.difficulty) {
        case this.$t('question.easy'):
          difficultyGrade = '1'
          break
        case this.$t('question.medium'):
          difficultyGrade = '2'
          break
        case this.$t('question.difficult'):
          difficultyGrade = '3'
          break
        default:
          break
      }
      // 共享$t('question.yes') $t('question.no') $t('question.commonbank')
      switch (this.shareStatus) {
        case this.$t('question.yes'):
          //this.auditStatus = '2'
          this.isPublic = 1
          break
        // case this.$t('question.no'):
        //   //this.auditStatus = ''
        //   this.isPublic = ''
        //   break
        case this.$t('question.questionBank'):
          //this.auditStatus = ''
          this.isPublic = 2
          break
        default:
          break
      }
      // 职位匹配技能查询
      let sourceId = this.objToIdArr(this.sourceList)
      let userId = this.objToIdArr(this.userList)
      let posiLabelId = this.posiGetLabelId(this.positionList)
      let labelId = this.objToIdArr(this.labelList)
      let labels = this.dedupe([...labelId, ...posiLabelId])
      let endId = this.objToIdArr(this.endList)
      let partnerId = this.objToIdArr(this.partnerList)
      let vendor1Id = this.objToIdArr(this.vendor1List)
      let vendor2Id = this.objToIdArr(this.vendor2List)
      if (this.activeName == ' ') {
        post('/question-store/list', {
          page: this.pageConfig.pageNo,
          limit: this.pageConfig.pageSize,
          title: this.searchTitle,
          // year: this.year,
          startTime:this.sourceTime.startTime,
          endTime:this.sourceTime.endTime,
          startCreateTime:this.sourceTime.startCreateTime,
          endCreateTime:this.sourceTime.endCreateTime,
          type: type,
          difficultyGrade: difficultyGrade,
          labelId: labels.toString(),
          questionSourceId: sourceId.toString(),
          endClient: endId.toString(),
          partner: partnerId.toString(),
          vendorOne: vendor1Id.toString(),
          vendorTwo: vendor2Id.toString(),
          // createUserId: userList.toString(),
          createUserId: userId.toString(),
          isPublic: this.isPublic,
          auditStatus: this.auditStatus,
        }).then((res) => {
          if (res.code == 1) {
            this.tempDisable = false //按钮恢复
            res.data.data=res.data.data.map(ele=>{
              ele.tempDisable = false
              return ele
            })
            this.tableData = res.data.data
            this.pageConfig.total = res.data.count
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        post('/question-store/listPublic', {
          page: this.pageConfig.pageNo,
          limit: this.pageConfig.pageSize,
          title:this.searchTitle,
          year: this.year,
          type: type,
          difficultyGrade: difficultyGrade,
          labelId: labels.toString(),
        }).then((res) => {
          if (res.code == 1) {
            this.tableData = res.data.data
            this.pageConfig.total = res.data.count
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 去重
    dedupe(arr) {
      return arr.reduce((pre, cur) => {
        let result = pre.some((item) => {
          return item == cur
        })
        return !result ? pre.concat(cur) : pre
      }, [])
    },
    posiGetLabelId(list) {
      let labelArr = [],
        arr = []
      list.forEach((x) => {
        labelArr = [...x.labelDictList]
      })
      arr = labelArr.map((item) => {
        return item.labelDictId
      })
      return arr
    },
    objToIdArr(list) {
      let arr = []
      list.forEach((item) => {
        arr.push(item.id)
      })
      return arr
    },
    // 保存新增题库 选择题
    saveAdd(data, type) {
      console.log(data, type)
      post('/question-store/insert-choice', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.getList()
          if (type == '2') {
            this.clearHand()
          } else {
            this.rowData = {}
          }
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 保存编程题
    saveShort(data, type) {
      post('/question-store/insert-essay', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.getList()
          if (type == '2') {
            this.clearHand()
          } else {
            this.rowData = {}
          }
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 查询题目详情
    editClick(item, type, active) {
      this.dialogType = 'edit'
      if (item.type == '1') {
        get('/question-store/find-id-choice/' + item.id).then((res) => {
          if (res.code == 1) {
            // if (res.data.labelId) {
            //     res.data.labelId = res.data.labelId.split(',');
            // }
            this.rowData = res.data
            this.rowData.active = active
            if (this.rowData.year) {
              this.rowData.year = this.rowData.year.toString()
            }
            if (this.rowData.score) {
              this.rowData.score = this.rowData.score.toString()
            }
            if (type == 'edit') {
              this.addDialog = true
            }
            if (type == 'see') {
              this.seeDialog = true
              this.$nextTick(() => {
                this.$refs['seeRef'].setData(res.data)
              })
            }
          } else {
            this.$message.error(res.data)
          }
        })
      }
      if (item.type == '3' || item.type == '4') {
        this.shortType = item.type
        get('/question-store/find-id-essay/' + item.id).then((res) => {
          if (res.code == 1) {
            // if (res.data.labelId) {
            //     res.data.labelId = res.data.labelId.split(',');
            // }
            this.rowData = res.data
            this.rowData.active = active
            if (this.rowData.year) {
              this.rowData.year = this.rowData.year.toString()
            }
            if (this.rowData.score) {
              this.rowData.score = this.rowData.score.toString()
            }
            if (type == 'edit') {
              this.shortDialog = true
            }
            if (type == 'see') {
              this.shortSeeDialog = true
              this.rowData = res.data
              this.$nextTick(() => {
                this.$refs['seeRef'].setData(res.data)
              })
            }
          } else {
            this.$message.error(res.data)
          }
        })
      }
    },
    // 删除题库
    deleteClick(row) {
      this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      })
        .then(() => {
          del('/question-store/delete/' + row.id).then((res) => {
            if (res.code == 1) {
              this.$message.success(this.$t('common.success'))
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    
    //添加企业用户
    enterpriseClick(row) {
      // 按钮置灰
      this.$set(row,'tempDisable',true)
      if (row.type == 1) {
        //写入-选择题（从公共题库添加）
        post('/question-store/insertChoicePublic', {
          id: row.id,
          questionSharedRecordId: row.questionSharedRecordId,
          pid: row.id,
        }).then((res) => {
          if (res.code == 1) {
            this.getList()
            this.$message.success(this.$t('common.success'))
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        post('/question-store/insertEssayPublic', {
          id: row.id,
          pid: row.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(this.$t('common.success'))
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 单选标签关闭
    tagClose(tag) {
      switch (tag) {
        case 'year':
          this.year = ''
          this.fomatTime(this.year,1)
          break
        case 'ctime':
          this.cTime = ''
          this.fomatTime(this.cTime,2)
          break
        case 'difficulty':
          this.difficulty = ''
          break
        case 'checkType':
          this.checkType = ''
          break
        case 'shareStatus':
          this.shareStatus = ''
          this.isPublic = ''
          break
        default:
          break
      }
      this.getList()
    },

    // 多标签关闭
    tagsClose(tag, type) {
      switch (type) {
        case 'position':
          this.positionList.splice(this.positionList.indexOf(tag), 1)
          break
        case 'label':
          this.labelList.splice(this.labelList.indexOf(tag), 1)
          break
        case 'source':
          this.sourceList.splice(this.sourceList.indexOf(tag), 1)
          break
        case 'creater':
          this.userList.splice(this.userList.indexOf(tag), 1)
          break
        case 'endClient':
          this.endList.splice(this.endList.indexOf(tag), 1)
          break
        case 'partner':
          this.partnerList.splice(this.partnerList.indexOf(tag), 1)
          break
        case 'vendor1':
          this.vendor1List.splice(this.vendor1List.indexOf(tag), 1)
          break
        case 'vendor2':
          this.vendor2List.splice(this.vendor2List.indexOf(tag), 1)
          break
        default:
          break
      }
      this.getList()
    },
    fomatTime(year,type){
      if(year && year!==''){
        if(type==1){
          this.sourceTime.startTime=year[0]
          this.sourceTime.endTime=year[1]
        }else{
          this.sourceTime.startCreateTime=year[0]
          this.sourceTime.endCreateTime=year[1]
        }
        this.getList()
      }else{
        if(type==1){
          this.sourceTime.startTime=''
          this.sourceTime.endTime=''
        }else{
          this.sourceTime.startCreateTime=''
          this.sourceTime.endCreateTime=''
        }
      }
    },
    getLabelData() {
      post('/dict-label/list', {
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.code == 1) {
          this.labelData = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 共享记录
    shareRecord() {
      this.shareDialog = true
      this.shareTipDialog = false
    },
    //共享
    shareClick(row) {
      this.rowData = row
      this.selectEnterDialog = true
    },
    // 选择题库共享企业
    shareCommonCompany(data){
      console.log(data);
      post('/enterpriseInfoQuestionController/setEnterprise', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.shareTipDialog = true
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getPositionData() {
      post('/dict-position/getList', {}).then((res) => {
        if (res.code == 1) {
          this.positionData = res.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getSourceData() {
      post('/questionSource/getList', {}).then((res) => {
        if (res.code == 1) {
          this.sourceData = res.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
  },
  mounted() {
    this.getList()
    this.getLabelData()
    this.getPositionData()
    this.getSourceData()
  },
}
</script>

<style scoped>
.question {
  padding: 15px;
}

.info {
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.help {
  font-size: 14px;
  text-align: right;
  height: 28px;
  line-height: 28px;
}

.help span {
  margin: 10px;
}

.help button {
  /* background-color: #333399;
  border-color: #333399; */
  vertical-align: -13px;
}

.test {
  padding: 15px;
}

.left-card {
  width: 15.5%;
  float: left;
  min-height: 100vh;
  background-color: #fff;
}

.right-card {
  float: right;
  background-color: #fff;
  padding: 15px;
  width: calc(85% - 50px);
}

.table .table-title {
  /*background-color: #f3f5f7;*/
  padding: 10px 20px;
  font-size: 14px;
  color: #778ca2;
  font-weight: bold;
  border-bottom: 1px solid #f3f5f7;
}

.table .table-content {}

.table-content li {
  min-height: 120px;
  padding: 15px 20px;
  border-bottom: 1px solid #f3f5f7;
}

.table-content li:hover {
  background-color: #f8f8fd;
}

.table-content li div {
  line-height: 40px;
}

.table-content li .li-title {
  /* display: flex; */
  align-items: center;
  font-weight: bold;
  line-height: 2;
}

.table-content li .li-title img {
  margin-right: 10px;
}

.table-content li .li-title span {
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  padding: 3px 15px;
  line-height: 22px;
  min-width: 30px;
}

.table-content li .li-info {
  /*padding-left: 30px;*/
}

.table-content li .li-info span {
  font-size: 14px;
  display: inline-block;
  width: 250px;
  color: #7b8b99;
}

.table-content li .li-time {
  /*padding-left: 30px;*/
}

.table-content li .li-time span {
  font-size: 14px;
  display: inline-block;
  color: #7b8b99;
}

.warning {
  background-color: #ffb61e;
}

.success {
  background-color: #00af9b;
}

.danger {
  background-color: #ff2121;
}

.hello>>>.el-tabs__nav-wrap::after {
  background-color: #fff;
}

.search-box {
  border-bottom: 1px solid #dedeec;
}

.type-search {
  width: calc(100% - 40px);
  margin: 20px auto;
}

.type-ul .type-li {
  padding: 10px 20px;
  margin: 10px 0;
}

.type-ul .type-li:hover {
  background-color: #f5f5fa;
}

.type-ul .type-li i {
  float: right;
}

.check-box {
  padding: 15px;
}

.select-box {
  display: flex;
  justify-content: space-around;
  height: 180px;
  align-items: center;
}

.select-box .select-item button {
  background-color: #ddddee;
  border-color: #ddddee;
  color: #576871;
  /* width: 133px; */
  min-width: 133px;
  line-height: 43px;
  font-weight: bold;
  font-size: 16px;
}

.select-box .select-item button:hover {
  background-color: #5c5cad;
  border-color: #5c5cad;
  color: #ffffff;
}

.label-span {
  display: inline-block;
  background-color: #ddddee;
  padding: 0 20px;
  margin: 0 5px;
  border-radius: 50px;
  line-height: 26px;
}

.el-tag {
  margin-left: 20px;
}

.label-item {
  width: 41%;
  line-height: 28px;
}

/* pop带搜索客户弹窗样式 */
.pop-search {
  margin: 0px auto;
  width: 50%;
  display: block;
}

.client .check-box .label-item {
  margin: 5px 10px;
}

.li-share {
  position: absolute;
  top: -30px;
  right: -60px;
  width: 100px;
  height: 60px;
  text-align: center;
  transform: rotate(45deg);
  color: #fff;
  font-size: 12px;
  line-height: 90px !important;
}

.li-share-red {
  background-color: #ff2121;
}

.li-share-blue {
  background-color: #409eff;
}

.typeList {
  max-height: 800px;
  overflow-y: scroll;
}
</style>
