<template>
    <div class="test-add">
        <br>
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="160px" label-suffix="：">
            <div class="step1">
                <el-form-item :label="$t('test.position')" prop="positionId">
                    <el-select v-model="rowData.positionId" :placeholder="$t('common.pleaseSelect')+$t('test.position')" style="width: 20%">
                        <el-option
                                v-for="(item,index) in positionList"
                                :key="index"
                                :label="item.positionName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('test.testName')" prop="title">
                    <el-input
                            :placeholder="$t('common.pleaseEnter')+$t('test.testName')"
                            v-model="rowData.title"
                            style="width: 50%"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('test.testExplain')">
                    <!--<el-input-->
                            <!--type="textarea"-->
                            <!--placeholder="请输入测试说明"-->
                            <!--v-model="rowData.remark"-->
                            <!--style="width: 50%"-->
                            <!--rows="5"-->
                    <!--&gt;-->
                    <!--</el-input>-->
                    <mavon-editor  v-model="rowData.remark" class="makeEditor" :language="editorLanguage"/>
                </el-form-item>
                <el-form-item :label="$t('test.selectPaper')">
                    <el-select v-model="rowData.paperId" :placeholder="$t('test.selectPaper')" @change="paperChange()"  style="width: 20%" :disabled="dialogType=='see'">
                        <el-option
                                v-for="(item,index) in paperList"
                                :key="index"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('test.testTime')">
                    <el-input type="number" :placeholder="$t('test.testTime')" v-model="rowData.fullTime" style="width: 20%" 
                              :disabled="dialogType=='see'">
                        <template slot="append">{{$tc('test.min',rowData.fullTime)}}</template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('test.qualifiedGrade')">
                    <el-input type="number" :placeholder="$t('test.qualifiedGrade')" v-model="rowData.passScore"  style="width: 20%" :disabled="dialogType=='see'">
                        <template slot="append">{{$t('test.mark')}}</template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('test.paperDetail')">
                    <p>{{$t('test.total')}}：{{questionLength}}{{$t('test.question2')}}，{{questionScore}}{{$t('test.mark')}}，{{$t('test.time')}}：{{questionTime}} {{$tc('test.min',questionTime)}}</p>
                    <div class="table-title">
                        <el-row>
                            <el-col :span="12">{{$t('test.question')}}</el-col>
                            <el-col :span="5">{{$t('test.category')}}</el-col>
                            <el-col :span="3">{{$t('test.qtype')}}</el-col>
                            <el-col :span="2">{{$t('test.time')}}</el-col>
                            <el-col :span="2">{{$t('test.grade')}}</el-col>
                        </el-row>
                    </div>
                    <div class="table-content">
                        <ul style="margin-top: 5px;" v-if="questionList.length">
                            <li v-for="(item,index) in questionList" :key="index" @click="openSee(item)">
                                <el-row>
                                    <el-col :span="12">
                                        {{item.title}}
                                        <span class="success" v-if="item.difficultyGrade == '1'">{{$t('question.easy')}}</span>
                                        <span class="warning" v-if="item.difficultyGrade == '2'">{{$t('question.medium')}}</span>
                                        <span class="danger" v-if="item.difficultyGrade == '3'">{{$t('question.difficult')}}</span>
                                    </el-col>
                                    <el-col :span="5">{{item.labelName.toString()}}</el-col>
                                    <el-col :span="3">{{item.type=='1'?$t('question.choiceq'):item.type=='2'?$t('question.fillInq'):item.type=='3'?$t('question.QandA'):$t('question.programq')}}</el-col>
                                    <el-col :span="2">{{item.answerTime}}</el-col>
                                    <el-col :span="2">{{item.score}}</el-col>
                                </el-row>
                            </li>
                        </ul>
                    </div>
                    <!--<el-checkbox v-model="enableIde" :disabled="isProgram">是否开启WebIde</el-checkbox>-->
                </el-form-item>
                <!--<el-form-item label="测试人员信息：">-->
                    <!--<el-form-item label="姓名：" style="margin-bottom: 15px;" label-width="80px">-->
                        <!--<el-input-->
                                <!--placeholder="姓名"-->
                                <!--v-model="rowData.candidateName"-->
                                <!--style="width: 20%"-->
                        <!--&gt;-->
                        <!--</el-input>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="手机号：" style="margin-bottom: 15px;" label-width="80px">-->
                        <!--<el-input-->
                                <!--placeholder="手机号"-->
                                <!--v-model="rowData.candidatePhone"-->
                                <!--style="width: 20%"-->
                        <!--&gt;-->
                        <!--</el-input>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="邮箱：" label-width="80px">-->
                        <!--<el-input-->
                                <!--placeholder="邮箱"-->
                                <!--v-model="rowData.candidateEmail"-->
                                <!--style="width: 20%"-->
                        <!--&gt;-->
                        <!--</el-input>-->
                    <!--</el-form-item>-->
                <!--</el-form-item>-->

            </div>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitAdd()" v-if="dialogType!=='see'">
               {{$t('common.save')}}
            </el-button>
            <el-button type="info" size="small" @click="$emit('clear')">{{$t('common.cancel')}}</el-button>
        </div>
        <div v-if="choiceSeeDialog">
            <el-dialog append-to-body :title="$t('question.questionView')" :visible.sync="choiceSeeDialog" width="50%">
                <ChoiceSee ref="seeRef" @clear="clearHand"></ChoiceSee>
            </el-dialog>
        </div>
        <div v-if="shortSeeDialog">
            <el-dialog append-to-body :title="$t('question.questionView')" :visible.sync="shortSeeDialog" width="50%">
                <ShortSee ref="seeRef" @clear="clearHand"></ShortSee>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import ChoiceSee from './choiceSee'
    import ShortSee from './shortSee'
    import Cookies from 'js-cookie'
    import {post, del, get, putUrl} from "@/api/http";
    import 'mavon-editor/dist/css/index.css';
    export default {
        name: "test-add",
        components: { ChoiceSee, ShortSee},
        props: {
            rowData: {
                type: Object,
                default() {
                    return {};
                }
            }, positionList: {
                type: Array,
                default() {
                    return [];
                }
            }, dialogType: {
                type: String,
                default() {
                    return '';
                }
            },
        },
        data(){
            return{
                rules: {
                    positionId: [{
                        required: true,
                        message:  this.$t('common.pleaseEnter')+this.$t('test.position')+'!',
                        trigger: 'blur'
                    }], title: [{
                        required: true,
                        message:  this.$t('common.pleaseEnter')+this.$t('test.testName')+'!',
                        trigger: 'blur'
                    }],},
                paperList:[],
                questionList:[],
                questionLength:0,
                questionScore:0,
                questionTime:0,
                step:0,
                // explain:'1.测试有效时间2021-10-01 08:00:00~2021-10-03 08:00:00\n' +
                // '2.答题的电脑需要配置摄像头，并把摄像头对准身体，否则不能参加测试\n' +
                // '3.测试过程中，全程录制屏幕以及摄像头录像，如有弹出对话框，请点击同意',
                choiceSeeDialog:false,
                shortSeeDialog:false,
                isProgram:false,
                enableIde:false,
                editorLanguage:Cookies.get('language')

            }
        },
        methods:{
            getPaper(){
                post("/paper/list", {
                    page: 1,
                    limit: 1000
                },true).then(res => {
                    if (res.code == 1) {
                        this.paperList = res.data.data
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            paperChange(){
                post("/paper-question/list", {
                    paperId: this.rowData.paperId
                },true).then(res => {
                    if (res.code == 1) {
                        this.questionList = res.data;
                        let questionScore=0, questionTime=0,isProgram=false;
                        res.data.map(item=>{
                            questionScore+=Number(item.score);
                            questionTime+=Number(item.answerTime);
                            if (item.type=='4'){
                                isProgram=true
                            }
                        })
                        this.isProgram=isProgram;
                        this.questionLength=res.data.length;
                        this.questionScore=questionScore;
                        this.questionTime=questionTime;
                        if (this.rowData.id) {
                            this.rowData.fullTime=this.rowData.fullTime
                        }else {
                            this.rowData.fullTime = questionTime;
                        }
                        // if (isProgram) {
                        //     this.$alert('题目中包含编程题，需开启WebIde', '提示', {
                        //         confirmButtonText: '确定',
                        //         callback: action => {
                        //             this.enableIde=isProgram
                        //         }
                        //     });
                        // }
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            //下一步
            nextStep(){
                this.step+=1;
            } ,
            previousStep(){
                this.step-=1;
            },
            submitAdd(){
                // this.rowData.enableIde='1';
                // this.rowData.enterpriseId='1';
                console.log(this.rowData);
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.$emit('add',this.rowData)
                    }
                })
            },
            stepClidk(step){
                this.step=step;
            },
            clearHand() {
                this.choiceSeeDialog = false;
                this.shortSeeDialog = false;
            },
            openSee(item){
                if (item.type=='1'){
                    console.log('choiceSeeDialog')
                    get("/question-store/find-id-choice/" + item.id).then(res => {
                        if (res.code == 1) {
                            if (res.data.labelId) {
                                res.data.labelId = res.data.labelId.split(',');
                            }
                            this.choiceSeeDialog = true;
                            this.$nextTick(() => {
                                this.$refs["seeRef"].setData(res.data);
                            })

                        } else {
                            this.$message.error(res.data);
                        }
                    });
                } else {
                    console.log('shortSeeDialog')
                    get("/question-store/find-id-essay/" + item.id).then(res => {
                        if (res.code == 1) {
                            if (res.data.labelId) {
                                res.data.labelId = res.data.labelId.split(',');
                            }
                            this.shortSeeDialog = true;
                            this.$nextTick(() => {
                                this.$refs["seeRef"].setData(res.data);
                            })

                        } else {
                            this.$message.error(res.data);
                        }
                    });

                }

            },
            fullTimeChange(value){
                console.log(value);
                this.$set(this.rowData,'fullTime',value);
            }
        },
        mounted(){
            this.getPaper();
        }
    }
</script>

<style scoped>
    .step-box{
        position: relative;
        width: 100%;
        height: 60px;
    }
    .step-box .step1{
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        cursor: pointer;
    }

    .step-box .step1 img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
    }
    .step-box .step-select .step-img{
        /*display: none;*/
    }
    .step-box .step1 p{
        position: absolute;
        left: 0;
        top: 0;
        line-height: 50px;
        text-align: center;
        width: 110%;
        font-size: 16px;
    }
    .step-box .step-select p{
        color: #ffffff;
    }
    .step-box .step2{
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        cursor: pointer;
    }
    .step-box .step2 img{
        position: absolute;
        left: -6%;
        top: 0;
        width: 110%;
        height: 50px;
    }
    .step-box .step2 p{
        position: absolute;
        left: -6%;
        top: 0;
        line-height: 50px;
        text-align: center;
        width: 110%;
        font-size: 16px;
    }
    .step-box .step-select p{
        color: #ffffff;
    }
    .table-title{
        background-color: #f3f5f7;
        padding: 0px 20px;
        font-size: 14px;
        color: #778ca2;
        font-weight: bold;
    }

    .table-content li{
        padding: 0px 20px;
        border-bottom: 1px solid #f3f5f7;
    }
    .table-content li:hover{
        background-color: #f8f8fd;
    }
    .table-content li span {
        margin-left: 10px;
        font-size: 14px;
        color: #fff;
        padding: 0 15px;
        line-height: 22px;
    }

    .warning {
        background-color: #ffb61e;
    }

    .success {
        background-color: #00af9b;
    }

    .danger {
        background-color: #ff2121;
    }

    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    /deep/ input[type='number'] {
        -moz-appearance: textfield !important;
    }
</style>
