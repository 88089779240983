<template>
  <div class="share-record">
    <el-form
      :inline="true"
      :model="searchlist"
      class="demo-form-inline"
      label-suffix="："
    >
      <el-form-item>
        <el-input
          :placeholder="$t('question.search')"
          v-model="searchlist.title"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchlist.status"
          :placeholder="$t('common.pleaseSelect') + $t('common.status')"
        >
          <el-option
            v-for="item in shareStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          :start-placeholder="$t('candidate.startDate')"
          :end-placeholder="$t('candidate.endDate')"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd"
          @change="timgChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList" size="small">{{
          $t('common.query')
        }}</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="resetForm" size="small">{{
          $t('common.reset')
        }}</el-button>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table :data="tableData" style="width: 100%" @row-click="openDetails">
        <el-table-column :label="$t('question.question')" width="400">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('question.shareCompany')" width="250">
          <template slot-scope="scope">
            <span>{{ returnArr(scope.row.enterpriseNameFirstTwoList) }}</span>
            <el-popover
              placement="bottom"
              width="400"
              popper-class="all-company"
              trigger="click"
              :content="returnArr(scope.row.enterpriseNameList)">
              <el-button slot="reference" type="text" v-if="scope.row.enterpriseNameList.length>2">{{$t('common.more')}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :label="$t('question.submission')">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('question.submitter')">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.status')">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">{{
              $t('question.review')
            }}</span>
            <span
              v-else-if="
                scope.row.status == 2 ||
                scope.row.status == '2,4' ||
                scope.row.status == '2,5'
              "
              >{{ $t('question.shared') }}</span
            >
            <span v-else-if="scope.row.status == 3">{{
              $t('question.rejected')
            }}</span>
            <span v-else>{{ $t('question.withdrawed') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="scope.row.status == 1"
              @click="auditClick(scope.row)"
              >{{ $t('question.auditInfo') }}</el-button
            >
            <el-button
              type="text"
              :disabled="scope.row.status != 1"
              @click="updateClick(scope.row)"
              >{{ $t('question.withdraw') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="5"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageConfig.pageSizes"
        :total="pageConfig.total"
      ></el-pagination>
    </div>
    <div class="boontbtn">
      <el-button size="small" @click="clearHand">{{
        $t('common.close')
      }}</el-button>
    </div>
    <div v-if="auditDialog">
      <el-dialog
        append-to-body
        :title="$t('question.auditInfo')"
        :visible.sync="auditDialog"
        width="50%"
      >
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6"></el-col>
          <el-col :span="12"> {{ auditBox.auditDescription }} </el-col>
          <el-col :span="6"></el-col>
        </el-row>
        <div class="boontbtn">
          <el-button size="small" @click="auditDialog = false">{{
            $t('common.close')
          }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post } from '@/api/http'
export default {
  props: {
    shareData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      timeValue: '',
      searchlist: {},
      tableData: [],
      auditBox: {},
      auditDialog: false,
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
      shareStatus: [
        {
          value: '',
          label: this.$t('common.all'),
        },
        {
          value: '1',
          label: this.$t('question.review'),
        },
        {
          value: '2',
          label: this.$t('question.shared'),
        },
        {
          value: '3',
          label: this.$t('question.rejected'),
        },
      ],
      showAll:false,
      AllData: [],
    }
  },

  computed: {},

  created() {
    this.getList()
  },

  methods: {
    openDetails(row) {},
    updateClick(row) {
      post('/questionSharedRecord/withdraw', {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.getList()
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    auditClick(row) {
      this.auditBox = row
      this.auditDialog = true
      //consoale.log(row)
    },
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    //重置
    resetForm() {
      this.timeValue = []
      this.searchlist = {}
      this.getList()
    },
    // 显示全部
    showAllData(){
      this.showAll = !this.showAll
    },
    getList() {
      post('/questionSharedRecord/getPage', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
        ...this.searchlist,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //时间段选择赋值参数
    timgChange() {
      if (this.timeValue) {
        this.searchlist.startTime = this.timeValue[0]
        this.searchlist.endTime = this.timeValue[1]
      } else {
        this.searchlist.startTime = ''
        this.searchlist.endTime = ''
      }
    },
    // 返回数组内容
    returnArr(arr){
      if(arr.length>0){
        return arr.toString()
      }
    },
    clearHand() {
      this.$emit('clear')
    },
  },
}
</script>
<style scoped>
.share-record {
  width: 98%;
}
</style>
