<template>
    <div class="user">
        <div class="three">
            <el-form :inline="true" :model="searchlist" class="demo-form-inline" size="mini" label-suffix="：">
                <el-form-item label="">
                    <el-input
                        :placeholder="$t('common.pleaseEnter')+$t('setup.clientName')"
                        v-model="searchlist.name">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch" size="mini">{{$t('common.query')}}</el-button>
                </el-form-item>
                <el-form-item style="float: right;">
                    <el-button type="primary" size="small" @click="addForm()" >新增客户</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        prop="name"
                        label="客户名称">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="360"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="light" :content="$t('common.view')" placement="top-start">
                            <el-button type="primary" size="mini" @click="operation('view',scope.row)">{{$t('common.view')}}</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start">
                            <el-button type="success" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start">
                            <el-button type="danger" size="mini" @click="operation('del',scope.row)">{{$t('common.delete')}}</el-button>
                        </el-tooltip> 
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageConfig.pageSize"
                layout="total, prev, pager, next"
                :total="pageConfig.total">
            </el-pagination>
        </div>
        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="$t('setup.client')" :visible.sync="addDialog" :before-close="clearAdd" width="70%" >
                <AddTemp ref="addRef" :rowData="rowData" :addCustomerId="addCustomerId" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
        <!-- 预览 -->
        <div v-if="viewDialog">
            <el-dialog center :title="$t('setup.client')" :visible.sync="viewDialog" :before-close="clearAdd" width="70%" >
                <ViewTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></ViewTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    import ViewTemp from './view'
    export default {
        name: "Client",
        components: {AddTemp,ViewTemp},
        data() {
            return {
                tableData: [],
                addDialog:false,
                viewDialog:false,
                addCustomerId:'',
                rowData:{},
                dialogType:'add',
                searchlist:{},
                pageConfig: {
                    pageSize: 10,
                    total: 0,
                    currentPage: 1,
                    pageSizes: [
                        10, 50, 100, 150, 200
                    ]
                },
            }
        },
        methods:{
            handleSizeChange() {
                console.log('handleClick!')
            },
            handleCurrentChange(val) {
                this.pageConfig.currentPage = val;
                this.getList()
            },
            handleSearch(val) {
                this.pageConfig.currentPage = 1;
                this.getList()
            },
            getList(){
                post("/enterpriseCustomer/getPage",{
                    page:this.pageConfig.currentPage,
                    limit:this.pageConfig.pageSize,
                    name:this.searchlist.name
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                        this.pageConfig.total=res.data.count
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            // 新增
            addForm(){
                this.rowData={};
                this.dialogType='add';
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
                this.viewDialog=false;
            },
            // 保存
            saveAdd(data){
                if(this.dialogType=='add'){
                    post("/enterpriseCustomer/insert",{
                        ...data
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            // this.clearAdd();
                            this.getList();
                            this.addCustomerId = res.data
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }else{
                    post("/enterpriseCustomer/update",{
                        ...data
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            // this.clearAdd();
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            },
            operation(type,row){
                if (type=='del'){
                    this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        post("/enterpriseCustomer/delete",{id:row.id}).then(res => {
                            if (res.code == 1) {
                                this.$message.success(this.$t('common.delSuccess'));
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }).catch(() => {

                    });
                }else{
                    post("/enterpriseCustomer/getDetails",{id:row.id}).then(res => {
                        if (res.code == 1) {
                            this.rowData=res.data;
                            this.dialogType=type;
                            if(type=='edit'){
                                this.addDialog=true;
                            }else{
                                this.viewDialog=true;
                            }
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }
            },
            switchChange(row){
                console.log(row);
                if (row.isDefault == 0) {
                    post("/setting-invoice/set-default",{
                        id:row.id,
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.getList();
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }

            },
        },
        mounted(){
            this.getList();
        }
    }
</script>

<style scoped>

</style>
