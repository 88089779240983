import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

/* Layout */
// import Layout from '@/layout'

export const routes = [

  //首页
  {
    path: "/index",
    name: "Home",
    component: Home,
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () =>
          import("../views/Dashboard/index.vue"),
      },

    ]
  },
  //缺省页
  {
    path: "/errpage",
    name: "Errpage",
    component: Home,
    redirect: '/errpage/blank',
    children: [
      {
        path: '/blank',
        name: 'Blank',
        component: () =>
          import("../views/publish/blank.vue"),
      },

    ]
  },
  //候选人
  {
    path: "/candidate",
    name: "Candidate",
    redirect: '/candidate/index',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'index',
        component: () =>
          import("../views/Candidate/index.vue"),
      },
    ]
  },
  //测试
  {
    path: "/test",
    name: "Test",
    redirect: '/test/testIndex',
    component: Home,
    children: [
      {
        path: 'testIndex',
        name: 'TestIndex',
        component: () =>
          import("../views/Test/index.vue"),
      },
      {
        path: 'testPersonnel',
        name: 'TestPersonnel',
        component: () =>
          import("../views/Test/test-personnel/personnel-index.vue"),
      },
      {
        path: 'testCorrect',
        name: 'TestCorrect',
        component: () =>
          import("../views/Test/test-correct/correct-index.vue"),
      },
    ]
  },
  //面试
  {
    path: "/interview",
    name: "Interview",
    redirect: '/interview/interviewIndex',
    component: Home,
    children: [
      {
        path: 'interviewIndex',
        name: 'InterviewIndex',
        component: () =>
          import("../views/Interview/index.vue"),
      },

    ]
  },
  //题库
  {
    path: "/question",
    name: "Question",
    redirect: '/question/questionIndex',
    component: Home,
    children: [
      {
        path: 'questionIndex',
        name: 'QuestionIndex',
        component: () =>
          import("../views/Question/index.vue"),
      },
      {
        path: 'questionDetail',
        name: 'QuestionDetail',
        component: () =>
          import("../views/Question/detail.vue"),
      },

    ]
  },
  //设置
  {
    path: "/setting",
    name: "Setting",
    redirect: '/setting/settingIndex',
    component: Home,
    children: [
      {
        path: 'settingIndex',
        name: 'SettingewIndex',
        component: () =>
          import("../views/Setting/index.vue"),
      },

    ]
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/video",
    name: "Video",
    component: () => import("../views/AudioVideo/index.vue"),
  },
  {
    path: "/video/home",
    name: "VideoHome",
    component: () => import("../views/AudioVideo/Home.vue"),
  },
  {
    path: "/answer",
    name: "Answer",
    component: () => import("../views/AudioAnswer/index.vue"),
  }, 
  {
    path: "/answer/home",
    name: "AnswerHome",
    component: () => import("../views/AudioAnswer/Home.vue"),
  },
  {
    path: "/answer/home-new",
    name: "AnswerHomeNew",
    component: () => import("../views/AudioAnswer-v2/Home.vue"),
  },
  //选择时间段
  {
    path: "/schedule/getListByEnterpriseId",
    name: "InterviewTime",
    component: () => import("../views/InterviewTime/index.vue"),
  },
  // 成功页
  {
    path: "/answer/test-over",
    name: "Test-Over",
    component: () => import("../views/publish/submit-success.vue"),
  },

];

const router = new VueRouter({
  routes,
});
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

// 去掉路由守卫报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router;


