<template>
    <div class="user">
        <div class="three">
            <el-button type="primary" size="small" @click="addForm()">{{$t('setup.addSource')}}</el-button>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        prop="name"
                        :label="$t('setup.source')"
                        >
                </el-table-column>
                <el-table-column
                        prop="status"
                        width="120"
                        :label="$t('common.status')">
                    <template slot-scope="scope">
                        <el-switch
                            @change="changeStatus(scope.row)"
                            active-value="1"
                            inactive-value="2"
                            v-model="scope.row.status">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start">
                            <el-button type="primary" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageConfig.pageSize"
                layout="total, prev, pager, next"
                :total="pageConfig.total">
            </el-pagination>
        </div>

        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="dialogType=='add'?$t('common.add'):$t('common.edit')" :visible.sync="addDialog" :before-close="clearAdd" width="30%" >
                <AddTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
        <!--查看-->
        <div v-if="seeDialog">
            <el-dialog center :title="$t('common.view')" :visible.sync="seeDialog" :before-close="clearAdd" width="30%" >
                <SeeTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @clear="clearAdd"></SeeTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    import SeeTemp from './see'
    export default {
        name: "position",
        components: {AddTemp,SeeTemp},
        data() {
            return {
                tableData: [],
                addDialog:false,
                seeDialog:false,
                rowData:{},
                dialogType:'add',
                pageConfig: {
                    pageSize: 10,
                    total: 0,
                    currentPage: 1,
                    pageSizes: [
                        10, 50, 100, 150, 200
                    ]
                },
            }
        },
        methods:{
            handleSizeChange() {
                console.log('handleClick!')
            },
            handleCurrentChange(val) {
                this.pageConfig.currentPage = val;
                this.getList()
            },
            getList(){
                post("/questionSource/getPage",{
                    page:this.pageConfig.currentPage,
                    limit:this.pageConfig.pageSize,
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                        this.pageConfig.total = res.data.count
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            addForm(){
                this.rowData={status:'1'};
                this.dialogType='add';
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
                this.seeDialog=false;
            },
            changeStatus(e){
                console.log(e);
                this.dialogType='edit'
                this.saveAdd({id:e.id,status:e.status})
            },
            saveAdd(data){
                console.log(data);
                if(this.dialogType=='add'){
                    post("/questionSource/insert",{
                    ...data
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.clearAdd();
                            this.getList();
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }
                if(this.dialogType=='edit'){
                    post("/questionSource/update",{
                    ...data
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.clearAdd();
                            this.getList();
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }
                
            },
            operation(type,row){
                if (type=='edit'){
                    this.dialogType = type
                    post('/questionSource/getDetails',{id:row.id}).then(res=>{
                        this.rowData =res.data
                    })
                    this.addDialog=true;
                }
            }
        },
        mounted(){
            this.getList();
        }
    }
</script>

<style scoped>

</style>
