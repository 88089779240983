<template>
  <div class="content">
    <div class="detail-header">
      <div class="title-box">
        <div class="title">
          {{ clickItem.title }}
          <el-tag effect="dark" type="success" size="small" v-if="clickData.difficultyGrade == '1'">{{
          $t('question.easy') }}</el-tag>
          <el-tag effect="dark" type="warning" size="small" v-if="clickData.difficultyGrade == '2'">{{
          $t('question.medium') }}</el-tag>
          <el-tag effect="dark" type="danger" size="small" v-if="clickData.difficultyGrade == '3'">{{
          $t('question.difficult') }}</el-tag>
        </div>
        <svg-icon v-if="subId.indexOf(clickItem.id) == '-1'" @click="questionAdd()" class="list-checkbox"
          icon-class="add" :size="30" :color="'#333399'" />
        <!--<div class="star">-->
        <!--<i class="el-icon-star-on" style="font-size:20px;vertical-align: -2px;"></i>收藏-->
        <!--</div>-->
      </div>
      <el-row class="detail-meta">
        <el-col :span="11">
          <p>
            {{ $t('question.category') + '：' + labelSet(clickData.labelName) }}
          </p>
          <p>{{ $t('question.grade') + '：' + clickData.score }}</p>
        </el-col>
        <el-col :span="8">
          <p>
            {{ $t('question.qtype') + '：'
            }}{{
            clickData.type == '1'
            ? $t('question.choiceq')
            : clickData.type == '2'
            ? $t('question.fillInq')
            : clickData.type == '3'
            ? $t('question.QandA')
            : $t('question.programq')
            }}
          </p>
          <p>
            {{ $t('question.recommendTime') + '：' + clickData.answerTime }}
            {{ $tc('question.min', clickData.answerTime) }}
          </p>
        </el-col>
        <el-col :span="5" style="text-align: right">
          <p>{{ $t('question.sourceTime') + '：' + clickData.year }}</p>
        </el-col>
      </el-row>
      <!--<div class="detail-tag">-->
      <!--标签：-->
      <!--<el-tag type="info" size="small">Nginx</el-tag>-->
      <!--</div>-->
    </div>
    <!-- 内容 -->
    <!-- v-if="clickItem.answers" -->
    <div class="detail-content">
      <mavon-editor v-if="clickItem.content" :value="clickItem.content" defaultOpen="preview" :boxShadow="false"
        style="z-index: 1; height: 50vh" :editable="false" :subfield="false" :toolbarsFlag="false" class="makeEditor"
        :language="editorLanguage">
      </mavon-editor>
      <div class="answer-box" v-if="clickData.type == '1'">
        <!--<el-radio-group v-model="radio">-->
        <!--<el-radio label="A">{{seeObj.optionA}}</el-radio>-->
        <!--<el-radio label="B">{{seeObj.optionB}}</el-radio>-->
        <!--<el-radio label="C">{{seeObj.optionC}}</el-radio>-->
        <!--<el-radio label="D">{{seeObj.optionD}}</el-radio>-->
        <!--</el-radio-group>-->
        <!-- <el-radio-group v-model="checked" style="width: 80%">
          <el-row class="list-option" v-if="clickItem.optionA">
            <el-col :span="20">
              <el-radio label="A">{{ clickItem.optionA }}</el-radio>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionB">
            <el-col :span="20">
              <el-radio label="B">{{ clickItem.optionB }}</el-radio>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionC">
            <el-col :span="20">
              <el-radio label="C">{{ clickItem.optionC }}</el-radio>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionD">
            <el-col :span="20">
              <el-radio label="D">{{ clickItem.optionD }}</el-radio>
            </el-col>
          </el-row>
        </el-radio-group> -->
        <el-checkbox-group v-model="checked" style="width: 80%">
          <el-row class="list-option" v-if="clickItem.optionA">
            <el-col :span="20">
              <el-checkbox label="A">{{ clickItem.optionA }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionB">
            <el-col :span="20">
              <el-checkbox label="B">{{ clickItem.optionB }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionC">
            <el-col :span="20">
              <el-checkbox label="C">{{ clickItem.optionC }}</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="list-option" v-if="clickItem.optionD">
            <el-col :span="20">
              <el-checkbox label="D">{{ clickItem.optionD }}</el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
      <div v-else>
        <el-button type="primary" @click="isShow = !isShow">{{
        $t('question.checkAnswer')
        }}</el-button>
        <mavon-editor v-if="isShow && clickItem.answers" :value="clickItem.answers" defaultOpen="preview"
          :boxShadow="false" style="z-index: 1; height: 50vh" :editable="false" :subfield="false" :toolbarsFlag="false"
          class="makeEditor" :language="editorLanguage">
        </mavon-editor>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
import 'mavon-editor/dist/css/index.css'
import Cookies from 'js-cookie'
export default {
  name: 'test',
  props: {
    clickItem: {
      type: Object,
      default() {
        return {}
      },
    },
    clickData: {
      type: Object,
      default() {
        return {}
      },
    },
    subId: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      input1: '',
      searchlist: {},
      options: [],
      value: '',
      value1: '',
      activeContent: '',
      isShow: false,
      checked: [],
      editorLanguage: Cookies.get('language'),
    }
  },
  watch: {
    clickItem: {
      handler() {
        this.isShow = false
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    seeAnswer() {
      this.$emit('add', this.defaultData)
    },
    labelSet(label) {
      if (label) {
        return label.toString()
      } else {
        return ''
      }
    },
    questionAdd() {
      this.$emit('questionAdd', this.clickItem)
    },
  },
}
</script>

<style scoped>
.content {
  height: calc(100vh - 167px);
  min-width: 100%;
}

.detail-header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  font-size: 14px;
}

.title-box {
  line-height: 26px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  color: #333;
}

.star {
  width: 120px;
  text-align: right;
}

.detail-meta p {
  margin: 15px 0;
}

.detail-tag .el-tag {
  border-radius: 50px;
}

/* answer */
.detail-content {
  padding: 20px;
}

.content-box {
  min-height: 250px;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px dashed var(--el-border-color-base);
}

.el-button {
  background-color: #333399;
  border-color: #333399;
  width: 150px;
}

/* collapse*/

.el-collapse {
  border: none;
}

::v-deep div[role='tab'] {
  width: 150px;
}

::v-deep div[role='tab'] .el-collapse-item__header {
  border: none;
}

::v-deep div[role='tab'] .el-collapse-item__header .el-button {
  margin-bottom: 20px;
}

::v-deep div[role='tab'] .el-collapse-item__arrow {
  display: none;
}

::v-deep .el-collapse-item__wrap {
  border: none;
}

::v-deep .el-collapse-item__wrap .el-collapse-item__content {
  padding-bottom: 0;
}

::v-deep .el-collapse-item__wrap .content-box {
  margin-bottom: 0;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}

.answer-box {
  padding: 15px;
}

.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}

.list-option {
  /*line-height: 70px;*/
  padding: 10px;
}

.makeEditor {
  margin: 20px 0;
  min-height: 100px;
  height: auto !important;
}
</style>
