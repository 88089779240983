<template>
    <div class="add-temp">
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="160px">
            <el-form-item :label="$t('setup.positionName')" prop="positionName">
                <el-input v-model="rowData.positionName" :placeholder="$t('common.pleaseEnter')+$t('setup.positionName')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
        },
        data() {
            return {
                rules: {
                    positionName: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.positionName')+'!',
                        trigger: 'blur'
                    }],
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.$emit("add",this.rowData)
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
