export default {
  menu: {
    home: "首页",
    test: "测试",
    interview: "面试",
    questionBank: "题库",
    statistics: "统计",
    setup: "设置",
    candidate: "候选人"
  },
  common: {
    operation: "操作",
    query: "查询",
    reset: "重置",
    add: "新增",
    edit: "编辑",
    preview: "预览",
    delete: "删除",
    detail: "详情",
    view: "查看",
    save: "保存",
    status: "状态",
    cancel: "取消",
    ok: "确定",
    close: "关闭",
    pleaseSelect: "请选择",
    pleaseEnter: "请输入",
    position: "职位",
    time: "时间",
    minutes: "分钟",
    nodata: "暂无数据",
    uploadSuccess: "上传成功",
    saveSuccess: "保存成功",
    pleaseComplete: "请输入完整信息",
    sureToDel: "是否确认删除？",
    tips: "操作确认",
    delSuccess: "删除成功",
    success: "成功",
    nodata: "暂无数据",
    all: "全部",

    personalInfo: "个人信息",
    changePsw: "修改密码",
    helpCenter: "帮助中心",
    feedback: "问题反馈",
    myFeedback: "我的反馈",
    logout: "退出系统",
    phoneNumber: "手机号码",
    email: "邮箱",
    username: "用户名",
    modifySuccess: "修改成功",
    originPsw: "原密码",
    newPsw: "新密码",
    confirmPsw: "确认密码",
    passwordNotMatch: "两次密码不一致",
    schedule: "日程安排",
    mon: "周一",
    tue: "周二",
    wed: "周三",
    thu: "周四",
    fri: "周五",
    sat: "周六",
    sun: "周日",
    leisure: "空闲",
    selected: "已选择",
    optional: "可选择",
    submit: "提交",
    more: "更多",
  },
  login: {
    enterLogin: "企业登录",
    enterRegist: "企业注册",
    passwordLogin: "密码登录",
    enterName: "企业名称",
    loginAccount: "登录账号",
    password: "密码",
    remenberAccount: "记住账号",
    forgetPsw: "忘记密码？",
    codeLogin: "验证码登录",
    mobilePhone: "手机号码",
    verifyCode: "验证码",
    login: "登录",
    signIn: "登陆",
    signUp: "注册",
    email: "邮箱",
    confirmPsw: "确认密码",
    backToLogin: "返回登录",
    pswless6: "密码最少为6位",
    pswNotMatch: "两次输入密码不一致，请重新输入",
    notInput_: '请不要输入含"_"的名称',
    getCode: "获取验证码",
    signUpSuccess: "注册成功，请登录。",
    errorNamePsw: "用户名密码错误",
    userEnabled: "该用户已禁用，请联系管理员！",
    codeExpired: "验证码过期",

    // hello: "企业注册",
  },
  home: {
    hello: "您好",
    trialVersion: "您当前版本为“试用版”，剩余",
    day: "天",
    upgradeNow: "立即升级",
    shortcutMenu: "快捷菜单",
    addTest: "新增测试",
    addInterview: "新增面试",
    addQuestion: "新增试题",
    userSettings: "用户设置",
    todayData: "今日数据",
    onlineNum: "实时在线人数",
    joinTestNum: "参加测试人数",
    joinInterviewNum: "参加面试人数",
    hadjoinTestNum: "已参加测试人数",
    hadjoinInterviewNum: "已参加面试人数",
    person: "人",
    summaryData: "汇总数据",
    unmarkPaperNum: "待评卷数",
    unTestNum: "待测试数",
    unInterviewNum: "待面试数",
    announce: "系统公告",
    consult: "咨询",
    help: "帮助",
  },
  test: {
    testName: "测试名称",
    position: "职位",

    personManage: "人员管理",
    invitedNum: "邀请人数",
    completedNum: "完成人数",
    participantNum: "已参加人数",
    noParticipantNum: "未参加人数",
    qualifiedNum: "合格人数",
    nonQualifiedNum: "不合格人数",
    highestScore: "最高分",
    lowestScore: "最低分",
    qualified: "合格",
    unqualified: "不合格",

    candidateName: "应试人员名称",
    candidatePhone1: "应试人员手机号",
    testStatus: "测试状态",
    queryPerson: "查询人员",
    addPerson: "新增人员",
    invitedEmail: "被邀请人邮箱",
    name: "姓名",
    invitedTime: "邀请时间",
    examStartTime: "考试开始时间",
    testStartTime: "开始测试时间",
    testEndTime: "结束测试时间",
    costTime: "耗时",
    score: "得分",
    isqualified: "是否合格",
    seeScene: "查看现场",
    manualMark: "人工阅卷",
    runCode: "在线运行",
    supportJava: "仅支持Java",
    haveRead: "已阅",
    personnelDetail: "人员详情",
    candidate: "候选人名称",
    candidateArea: "候选人国家区域",
    candidatePhone: "候选人手机号",
    candidateEmail: "候选人EMAIL",
    candidateGender: "候选人性别",
    saveAndPublish: "保存并发布",
    male: "男",
    female: "女",
    china: "中国",
    selectTime: "选择考试开始时间",
    exceedScore: "不能超过题目总分",

    all: "全部",
    waitStart: "等待开始",
    inProgress: "正在进行",
    testEnd: "考试结束",
    scoringEnd: "评分结束",
    reviewed: "已阅",
    uncorrect: "未批改",

    qustionList: "试题列表",
    submitMark: "提交阅卷",
    correctAnswer: "正确答案",
    notAnswer: "该题未作答！",
    standardAns: "标准答案",
    noStandardAns: "该题无标准答案！",
    lastQuestion: "上一题",
    nextQuestion: "下一题",
    point: "分",

    recordLoad: "录制加载中",
    submitTest: "提交测试",
    hour: "小时",
    second: "秒",
    timeIsUp: "考试时间已到，请点击确定按钮提交考试，否则无考试成绩",
    overAndSubmit: "确定试题已作答完毕，提交本次测试吗？",
    autoSubmit: "考试时间已到，已自动提交试卷，是否关闭页面？",
    submitSuccess: "提交成功",
    joinSuccess: "加入房间成功",
    streamFaild: "推流失败",
    joinFaild: "加入房间失败",
    submitOver:'提交成功，您已完成考试!',

    addTest: "新增测试",
    testExplain: "测试说明",
    selectPaper: "选择试卷",
    testTime: "测试时间",
    qualifiedGrade: "合格分数",
    paperDetail: "试卷详情",
    ctime: "创建时间",
    total: "共计",
    question: "试题",
    category: "分类",
    qtype: "题型",
    grade: "分数",
    mark: "分",
    addQuestion: "新增试题",
    questionName: "试题名称",
    time: "时间",
    min: "分钟",
    question2: "题",
    operation: "操作",
    personManage: "人员管理",
    testDetail: "测试详情",
    recordplay: "录屏播放",
    answerRecordScreen: "答题录屏",

    getInfo: "获取考试信息中",
    welcome: "欢迎，参加",
    examination: "考试",
    enterTest: "填写简历并进入测试",
    invalidAndContect: "当前考试失效，请联系管理员",
    phone: "手机号",
    name: "姓名",
    verifyCode: "验证码",
    vCode: "验证码",
    emailCode: "邮箱验证码",
    vCodeInvalid: "验证码已过期",
    vCodeSent: "验证码已发送到您尾号",
    emailCodeSent: "验证码已发送到您",
    sentPhone: "的手机",
    openScreenShare: "本次考试需开启屏幕分享功能，勾选代表您同意屏幕分享",
    agreeToRecord: "请勾选同意录制",
    join: "加入",
    joinSuccess: "加入成功",

    getVerifyCode: "获取验证码",
    noCamaraOrMic: "当前设备缺少摄像头或者麦克风，请检查后重试！",
    examInvalid: "考试已失效",
    authenticate: "身份验证",
    channel: "频道",

    styleType: '样式选择',
    defaultStyle: '现有样式',
    newStyle: '新款样式',
    ifrecordSreen: '开启录屏',
    notifyMethod: '通知方式',
    isVerification: "是否验证",
    yes: '是',
    no: '否',
    messages: '短信',
    Email: '邮箱',
    autumn: "运行结果",
    executeCode: "执行代码",
    submit: "提交",
    runResultTip: "运行结果显示区域（如果报错，字体为红色）",

    lastOne:'已经是最后一题'
  },
  interview: {
    candidateName: "姓名",
    phone: "手机号",
    position: "职位",
    startDate: "开始日期",
    endDate: "结束日期",
    addInterview: "新增面试",
    interviewee: "面试人",
    creater: "创建人",
    startTime: "开始时间",
    endTime: "结束时间",
    notStart: "未开始",
    inProgress: "进行中",
    completed: "已完成",
    interviewRestult: "面试结果",
    interviewDetial: "面试详情",
    viewInterview: "查看面试",
    recordPreview: "录屏预览",
    candidateArea: "候选人国家区域",
    china: "中国",
    candidate: "候选人",
    resume: "简历",
    uploadPDF: "请上传PDF文件",
    selectFile: "选取文件",
    interviewExplain: "面试说明",
    interviewer: "面试官",
    add: "添加",
    isPrimary: "是否主面试官",
    searchName: "姓名搜索",
    mostThreeInterviewer: "最多只能选择三个面试官！",
    interviewTime: "面试时间",
    interviewLink: "面试链接",
    notifyMethod: "通知方式",
    remarks: "备注",
    message: "短信",
    phoneNumber: "号码",
    email: "邮箱",
    publishAndSend: "发布并发送通知",
    gender: "性别",
    male: "男",
    female: "女",
    interviewTips: `1.答题的电脑需要配置摄像头，并把摄像头对准身体，否则不能参加测试。\n2.测试过程中，全程录制屏幕以及摄像头录像，如有弹出对话框，请点击同意。`,
    interview: "面试",
    interviewRecordScreen: "面试录屏",
    primaryCantDelete: "主面试官不可删除",
    addInterviewer: "添加面试官",

    interviewInfo: "面试信息",
    interviewResume: "面试简历",
    interviewVideo: "面试视频",
    interviewOverview: "面试概况",
    interviewEva: "面试官评价",
    interviewScore: "评分",
    attitude: "态度",
    character: "性格",
    technology: "技术",
    experience: "经验",
    comment: "点评",
    noResume: "暂未上传简历",
    videoComing: "视频正在生成中，请稍后……",
    verygood: "非常好",
    good: "好",
    average: "一般",
    bad: "差",
    verybad: "非常差",

    welcome: "欢迎参加",
    expiredOrNotExist: "当前面试已过期或不存在！",
    ImInterviewer: "我是面试官",
    interviewerIdintity: "请选择面试官身份",
    errorOrNoIntervewer: "网络错误或当前面试无面试官！",
    ImCandidate: "我是候选人",
    enterAfterVerify: "候选人验证后进入面试",
    joinNow: "立即加入",
    authenticate: "身份验证",
    verifyCode: "验证码",
    vCode: "验证码",
    channel: "频道",
    getVerifyCode: "获取验证码",
    noInterview: "无当前面试！",
    join: "加入",
    joinSuccessed: "加入成功",
    noCamaraOrMic: "当前设备缺少摄像头或者麦克风，请检查后重试！",

    viewResume: "查看简历",
    viewSelf: "查看自己",
    allMute: "全部静音",
    endInterview: "结束面试",
    startInterview: "开始面试",
    waitInterview: "等待面试",
    clickStart: "请点击下方按钮开始面试",
    aboutToBegin: "面试即将开始......",
    interviewEnded: "面试已结束",
    notSupportSocket: "您的浏览器不支持socket",
    microphone: "麦克风",
    camara: "摄像头 ",
    joinRoom: "加入房间 ",
    leaveRoom: "离开房间 ",
    roomMember: "房间成员 ",
    pushSuccess: "推流成功 ",
    stopPushSuccess: "停止推流成功 ",
    invitation: "保存并发送邀请",
    candidateChoice: "候选人选择中",
    candidateReview: "面试官审核中",
    candidateAgree: "面试官已同意",
    candidateSuccess: "邀约成功",
    selectedDate: "选定日期",
    interviewerConfirm: "面试官确认",
    generateInterview: "生成面试",
    agree: "同意",
    agreeChange: "同意并更改时间",
    reject: "拒绝",
    dateTime: "年月日",
    sendInvitation: "再次发送邀请",
    choose: "确认选择",
    forInterview: "参加面试吗？",
    chooseInterviewTime: "候选人选择面试时间",
    inviteInterviewTime: "邀请候选人选择时间"

  },
  question: {
    addQuestion: "新增试题",
    questionName: "试题名称",
    category: "分类",
    qtype: "题型",
    type: "题型",
    choiceq: "选择题",
    trueFalse: "判断题",
    QandA: "问答题",
    programq: "编程题",
    fillInq: "填空题",
    grade: "分数",
    time: "时间",
    operation: "操作",
    filter: "筛选",
    selected: "已选择",
    skill: "技能",
    difficulty: "难度",
    easy: "简单",
    medium: "一般",
    difficult: "困难",
    question: "题",
    total: "合计",
    dataStatistics: "数据统计",
    answerTimes: "答题总次数",
    passNum: "答题通过数",
    failNum: "答题失败数",
    longestTime: "答题最长时间",
    shortestTime: "答题最短时间",
    medianTime: "答题中位数最短时间",
    minute: "分钟",
    min: "分钟",
    year: "年份",
    recommendTime: "推荐答题时间",
    onlytimes: "数据仅统计答题总次数",
    onlycorrect: "数据仅统计答题正确次数",
    onlyfaild: "数据仅统计答题错误次数",
    onlylongest: "数据仅统计答题正确最长用时",
    onlyshotest: "数据仅统计答题正确最短用时",
    onlymediancorrect: "数据仅统计答题正确中位数用时",
    checkAnswer: "查看答案",
    enterSort: "请输入排序",
    confirmAdd: "确认添加试题",
    addSuccess: "添加成功",
    unknown: "未知",
    enterbank: "企业题库",
    commonbank: "公开题库",
    entercontent: "请输入内容",
    skills: "技能",
    nonSkills: "暂无技能分类，请添加",
    questionView: "试题预览",
    questionDetail: "试题详情",
    questionContent: "选项/内容",
    answers: "试题答案",
    tag: "标签",
    year2: "年",
    answer: "答案",
    nodata: "暂无数据",
    saveAdd: "保存并添加",
    pleasecontent: "请输入内容",
    completeContent:'请输入选项内容并选择正确答案',

    source: "来源",
    sourceTime: "来源日期",
    nonPosition: "暂无职位分类，请添加",
    nonSource: "暂无来源分类，请添加",
    nonClient: "暂无客户分类，请添加",
    nonUser: "暂无用户分类，请添加",
    position: "职位",
    creater: "创建人",
    createTime: "创建日期",
    yes: "是",
    no: "否",
    share: "共享",
    sharingRecords: "共享记录",
    selectEnterprise: "选择企业",
    questionBank: "公开题库",
    shared: "已共享",
    public: "公",
    check: "已核验",
    search: "搜索",
    question: "试题",
    submission: "提交时间",
    submitter: "提交人",
    review: "审核中",
    rejected: "已驳回",
    auditInfo: "审核信息",
    withdraw: "撤回",
    withdrawed: "已撤回",
    shareTip: "如果公开此题，代表放弃此题的所有权，将共享此题到公开题库并且不能撤回，任何企业都可以使用，确定要公开此题吗？",
    shareTip1: "试题公开成功之后，系统将自动发放对应的优惠券，可在设置-企业授权里查看",
    submitShare: "提交共享申请成功！",
    viewShare: "点此可查看共享记录》",
    all:"全选",
    shareCompany:"分享企业",
  },
  statistics: {},
  setup: {
    enterInfo: "企业信息",
    invoiceTit: "发票抬头",
    userSet: "用户设置",
    roleSet: "角色设置",
    positionSet: "职位设置",
    paperSet: "试卷配置",
    oprationLog: "操作日志",
    baseInfo: "基本信息",
    businessInfo: "工商信息",
    CoAdress: "公司地址",
    CoName: "公司名称",
    CoScale: "企业规模",
    lessThan50: "少于50人",
    persons: "人",
    moreThan500: "500人以上",
    CoProfile: "企业简介",
    CoPicture: "企业图片",
    legalrep: "法定代表人",
    registeredCap: "注册资本",
    incorporationDate: "成立日期",
    businessTerm: "营业期限",
    enterType: "企业类型",
    registAddr: "注册地址",
    creditCode: "统一社会信用代码",
    businessNature: "经营范围",

    region: "所在地区",
    detailAddress: "详细地址",
    addAddress: "添加公司地址",
    
    role:"角色",
    rolename:"角色名称",
    addRole:"新增角色",
    auth:"权限",
    authSet:"权限设置",
    disabled:'已禁用',
    opened:'已开启',

    

    addInvoiceTit: "新增发票抬头",
    tittleType: "抬头类型",
    invoiceName: "抬头名称",
    defaultTit: "默认抬头",
    invoiceType: "发票类型",
    enterUnit: "企业单位",
    INDuint: "个人/非企业单位",
    invoicetit: "发票抬头",
    taxNum: "单位税号",
    registtele: "注册电话",
    depositBank: "开户银行",
    bankAccount: "银行账号",
    addUser: "新增用户",
    phone: "手机号码",
    email: "邮箱",
    username: "用户名",
    psw: "密码",
    resetPsw: "重置密码",
    sureToRest: "是否确认重置密码为",
    restpsd: "已重置密码为",
    resetSuccess: "重置成功",
    positionName: "职位名称",
    positionSet: "职位设置",
    addPosition: "新增职位",
    addPaper: "新增试卷",
    paperName: "试卷名称",
    paperExplain: "试卷说明",
    paperSet: "试卷设置",
    paperEdit: "试题编辑",
    paperView: "试卷预览",
    questionNum: "题数",
    grade: "分数",
    lastModifyTime: "最后修改时间",
    inputValidateEmail:'请输入有效的邮箱',
    inputValidatePhoneNumber:'请输入有效的手机号',
    paperInuse:'试卷使用中',

    clientManage: "客户管理",
    client: "客户",
    clientName: "客户名称",
    male: "男",
    female: "女",
    unknown: "未知",
    name: "姓名",
    gender: "性别",
    position: "职位",
    mobilePhone: "手机",
    email: "邮箱",
    address: "地址",
    remarks: "备注",
    contacts: "联系人",
    more: "更多",
    addContacts: "添加联系人",
    pleaseFoundClient: "请新建客户",
    clientRepeat: "该客户已存在",
    
    source: "来源",
    paperSource: "试题来源",
    sourceName: "来源名称",
    addSource: "新增来源",
    setSkill: "设置技能",
  },
  candidate: {
    fillInformation: "填写信息",
    candidate: '候选人',
    candidateName: "姓名",
    phone: "手机号码",
    email: "邮箱",
    startDate: "开始日期",
    endDate: "结束日期",
    setfield: "设置字段显示",
    basicInfo: "基本信息",
    educationIfo: "教育信息",
    detailsInfo: "详情信息",
    workStatus: "工作身份",
    workLocation: "未来工作地点要求",
    whatsAccount: "Whatsup账号",
    linkedinAccount: "Linkedin账号",
    facebookAccount: "Facebook账号",
    jobHuntingStatus: "求职状况",
    weChatAccount: "WeChat账号",
    cptSponsorship: "是否需要CPT sponsorship",
    marketingServices: "是否接受Marketing services",
    hOneBSponsorship: "是否需要H1B sponsorship",
    gcSponsorship: "是否需要GC sponsorship",
    optExtensionSponsorship: "是否需要opt extension sponsorship",
    technicalTraining: "是否愿意接受技术培训",
    degree: "最高学历",
    currentPlaceWork: "目前工作地点",
    currentPlaceResidence: "目前居住地点",
    schoolName: "学校名称",
    studyTime: "就读时间",
    profession: "所学专业",
    achievement: "成绩",
    academicDegree: "学历学位",
    workExperience: "工作经历",
    companyName: "公司名称",
    workingTime: "工作时间",
    position: "职位",
    request: "无要求",
    specificPlace: "必须去特定的地方",
    goSomewhere: "倾向于去某个地方",
    other: "其他",
    accepted: "不接受",
    paid: "有偿",
    freeCharge: "无偿",
    depends: "看情况",
    na: "NA",
    postgraduate: "研究生",
    undergraduate: "本科",
    aLevel: "高中",
    juniorMiddleSchool: "初中",
    primarySchool: "小学",
    city: "城市",
    cityName: "城市名称",
    detailedAddress: "详细地址",
    saveTest: "保存并进入测试",
    skipTest: "跳过并进入测试",
    Leaveavailabl: "离职-随时到岗",
    jobWithin: "在职-月内到岗",
    jobConsider: "在职-考虑机会",
    jobYet: "在职-暂不考虑"
  }
};
