<template>
    <div class="user">
        <div class="three">
            <el-button type="primary" size="small" @click="addForm()">{{$t('setup.addRole')}}</el-button>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        prop="name"
                        :label="$t('setup.rolename')">
                </el-table-column>
                <!-- <el-table-column
                        prop="enabled"
                        :label="$t('common.status')">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.enabled">
                        </el-switch>
                    </template>
                </el-table-column> -->
                <el-table-column
                        fixed="right"
                        width="360"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-button type="success" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        <el-button type="primary" size="mini" @click="operation('auth',scope.row)">{{$t('setup.auth')}}</el-button>
                        <el-button type="danger" size="mini" @click="operation('del',scope.row)">{{$t('common.delete')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageConfig.pageSize"
                layout="total, prev, pager, next"
                :total="pageConfig.total">
            </el-pagination>
        </div>

        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="$t('setup.roleSet')" :visible.sync="addDialog" :before-close="clearAdd" width="30%" >
                <AddTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
        <!-- 权限 -->
        <div v-if="authDialog">
            <el-dialog center :title="$t('setup.authSet')" :visible.sync="authDialog" :before-close="clearAdd" width="30%" >
                <AuthTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAuth" @clear="clearAdd"></AuthTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    import AuthTemp from './auth'
    export default {
        name: "position",
        components: {AddTemp,AuthTemp},
        data() {
            return {
                pageConfig: {
                    pageSize: 10,
                    total: 0,
                    pageNo: 1,
                    pageSizes: this.$pageSizes,
                },
                tableData: [],
                addDialog:false,
                authDialog:false,
                rowData:{},
                dialogType:'add',
                infoData:{},
                enterpriseId:'',
            }
        },
        methods:{
            handleCurrentChange(val) {
            this.pageConfig.pageNo = val
            this.getList()
            },
            handleSizeChange(val) {
            this.pageConfig.pageSize = val
            this.getList()
            },
            getList(){
                post("/enterpriseRoleController/list",{
                    page: this.pageConfig.pageNo,
                    limit: this.pageConfig.pageSize,
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                        this.pageConfig.total = res.data.count
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            getUser(){
                get("/setting-user/my-info").then(res => {
                    if (res.code == 1) {
                        this.enterpriseId=res.data.enterpriseId;
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            addForm(){
                this.rowData={enterpriseId:this.enterpriseId};
                this.dialogType='add';
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
                this.authDialog=false;
            },
            saveAdd(data){
                console.log(data);
                let url = ''
                if(this.dialogType=='add'){
                    url = '/enterpriseRoleController/add'
                }else{
                    url = '/enterpriseRoleController/update'
                }
                post(url,{...data}).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            saveAuth(data){
                post(`enterpriseRoleController/addRoleResource`,{...data}).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            operation(type,row){
                if (type=='edit'){
                    this.rowData=row;
                    this.dialogType='edit';
                    this.addDialog=true;
                }
                if (type=='auth'){
                    this.rowData=row
                    this.dialogType='auth';
                    this.authDialog=true;
                }
                if (type=='del'){
                    this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning',
                    })
                    .then(() => {
                        post('/enterpriseRoleController/delete/',{id:row.id}).then((res) => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'))
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                        })
                    })
                    .catch(() => { })
                }
            }
        },
        mounted(){
            this.getList();
            this.getUser();
        }
    }
</script>

<style scoped>

</style>
