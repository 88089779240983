<template>
    <div class="add-temp">
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="160px">
            <el-form-item :label="$t('setup.phone')" prop="phone">
                <el-input v-model="rowData.phone" :placeholder="$t('common.pleaseEnter')+$t('setup.phone')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.email')" prop="email">
                <el-input v-model="rowData.email" :placeholder="$t('common.pleaseEnter')+$t('setup.email')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.username')" prop="username">
                <el-input v-model="rowData.username" :placeholder="$t('common.pleaseEnter')+$t('setup.username')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.psw')" prop="password" v-if="dialogType=='add'">
                <el-input type="password" v-model="rowData.password" :placeholder="$t('common.pleaseEnter')+$t('setup.psw')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    var validateEmail = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(vm.$t('common.pleaseEnter')+vm.$t('setup.email')+'!'));
        } else {
          if (value !== '') { 
            var reg=/^[A-Za-z0-9\u4e00-\u9fa5.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(!reg.test(value)){
              callback(new Error(vm.$t('setup.inputValidateEmail')));
            }
          }
          callback();
        }
      };
      var validateMobilePhone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(vm.$t('common.pleaseEnter')+vm.$t('setup.phone')+'!'));
        } else {
          if (value !== '') { 
            var reg=/^[0-9+-]{10,20}$/;
            if(!reg.test(value)){
              callback(new Error(vm.$t('setup.inputValidatePhoneNumber')));
            }
          }
          callback();
        }
      };

    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            const vm = window.vm;
            return {
                vm: vm,
                rules: {
                    phone: [{
                        required: true,
                        // message: this.$t('common.pleaseEnter')+this.$t('setup.phone')+'!',
                        validator:validateMobilePhone,
                        trigger: 'blur'
                    }], email: [{
                        required: true,
                        // message: this.$t('common.pleaseEnter')+this.$t('setup.email')+'!',
                        validator:validateEmail,
                        trigger: 'blur'
                    }], username: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.username')+'!',
                        trigger: 'blur'
                    }], password: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.psw')+'!',
                        trigger: 'blur'
                    }],
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.rowData.id?
                        this.$emit("update",this.rowData):
                        this.$emit("add",this.rowData)
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {
            console.log(this.rowData);
        }
    }
</script>

<style scoped>

</style>
