<template>
  <div class="interview">
    <div class="sear">
      <el-form :inline="true" :model="searchlist" class="demo-form-inline" size="mini" label-suffix="：">
        <el-form-item :label="$t('interview.candidateName')">
          <el-input :placeholder="
            $t('common.pleaseEnter') + $t('interview.candidateName')
          " v-model="searchlist.candidateName">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('interview.phone')">
          <el-input :placeholder="$t('common.pleaseEnter') + $t('interview.phone')" v-model="searchlist.candidatePhone">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('interview.position')">
          <el-select v-model="searchlist.positionId"
            :placeholder="$t('common.pleaseSelect') + $t('interview.position')">
            <el-option v-for="(item, index) in positionList" :key="index" :label="item.positionName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('interview.startDate')">
          <el-date-picker v-model="timeValue" type="daterange" :start-placeholder="$t('interview.startDate')"
            :end-placeholder="$t('interview.endDate')" format="yyyy - MM - dd" value-format="yyyy-MM-dd"
            @change="timgChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList" size="mini">{{
          $t('common.query')
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="resetForm" size="mini">{{
          $t('common.reset')
          }}</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button @click="selectForm()" size="medium">{{$t('interview.inviteInterviewTime')}}</el-button>
          <el-button type="primary" @click="addForm()" size="medium">{{
          $t('interview.addInterview')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="three"></div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" :label="$t('interview.interviewee')" width="500">
          <template slot-scope="scope">
            <span>{{ scope.row.candidateName }}-{{ scope.row.candidateEmail }}-{{
            scope.row.candidatePhone
            }}
            </span>
            <span v-if="!scope.row.startTime && !scope.row.endTime" class="status-btn warning">{{
            $t('interview.notStart') }}</span>
            <span v-if="scope.row.startTime && scope.row.endTime" class="status-btn success">{{
            $t('interview.completed') }}</span>
            <span v-if="scope.row.startTime && !scope.row.endTime" class="status-btn danger">{{
            $t('interview.inProgress') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="positionName" :label="$t('interview.position')" width="180">
        </el-table-column>
        <el-table-column prop="createUsername" :label="$t('interview.creater')">
        </el-table-column>
        <el-table-column prop="startTime" :label="$t('interview.startTime')">
        </el-table-column>

        <el-table-column prop="endTime" :label="$t('interview.endTime')">
        </el-table-column>
        <el-table-column align="center" :label="$t('common.operation')" fixed="right" width="220px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start"
              v-if="!scope.row.startTime">
              <el-button icon="el-icon-edit" circle @click="editClick(scope.row, 'edit')"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('common.view')" placement="top-start"
              v-if="scope.row.startTime">
              <el-button icon="el-icon-view" circle @click="editClick(scope.row, 'see')"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start"
              v-if="!scope.row.startTime">
              <el-button icon="el-icon-delete" circle @click="deleteClick(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('interview.interviewRestult')" placement="top-start"
              v-if="scope.row.startTime && scope.row.endTime">
              <el-button icon="el-icon-s-order" circle @click="result(scope.row)"></el-button>
            </el-tooltip>
            <!--<el-tooltip class="item" effect="light" content="预览录屏" placement="top-start">-->
            <!--<el-button icon="el-icon-video-play" circle @click="download(scope.row,'see')"></el-button>-->
            <!--</el-tooltip>-->
            <!--<el-tooltip class="item" effect="light" content="下载录屏" placement="top-start">-->
            <!--<el-button icon="el-icon-download" circle @click="download(scope.row,'download')"></el-button>-->
            <!--</el-tooltip>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="5" layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="pageConfig.pageSizes"
        :total="pageConfig.total"></el-pagination>
    </div>

    <!--新增-->
    <div v-if="addDialog">
      <el-dialog append-to-body :title="$t('interview.interviewDetial')" :visible.sync="addDialog"
        :before-close="clearAdd" width="50%">
        <InterviewAdd ref="addRef" :rowData="rowData" :positionList="positionList" @add="saveAdd" @clear="clearAdd">
        </InterviewAdd>
      </el-dialog>
    </div>
    <!--查看-->
    <div v-if="seeDialog">
      <el-dialog append-to-body :title="$t('interview.viewInterview')" :visible.sync="seeDialog"
        :before-close="clearAdd" width="50%">
        <InterviewSee ref="addRef" :rowData="rowData" :positionList="positionList" @clear="clearAdd"></InterviewSee>
      </el-dialog>
    </div>
    <!--面试结果-->
    <div v-if="resultDialog">
      <el-dialog append-to-body :title="
        resultData.positionName +
        $t('interview.interview') +
        resultData.candidateName
      " :visible.sync="resultDialog" width="50%">
        <InterviewResult :resultData="resultData" @clear="resultDialog = false"></InterviewResult>
      </el-dialog>
    </div>
    <!--预览-->
    <div v-if="videoDialog">
      <el-dialog append-to-body :title="$t('interview.recordPreview')" :visible.sync="videoDialog" width="50%"
        style="text-align: center">
        <video :src="videoSrc" autoplay controls></video>
      </el-dialog>
    </div>
    <!-- 候选人选择时间 -->
    <div v-if="selectDialog">
      <el-dialog append-to-body :title="$t('interview.inviteInterviewTime')" :visible.sync="selectDialog" width="80%">
        <SelectTime @clear="selectDialog = false" @update="getList"></SelectTime>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import InterviewAdd from './interview-add'
import InterviewSee from './interview-see'
import InterviewResult from './interview-result'
import SelectTime from '../select-time'

import request from '@/utils/request.js'
export default {
  name: 'index',
  components: {
    InterviewAdd,
    InterviewResult,
    InterviewSee,
    SelectTime,
  },
  data() {
    return {
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
      tableData: [],
      searchlist: {},
      timeValue: '',
      addDialog: false,
      seeDialog: false,
      rowData: {},
      input1: '',
      positionList: [],
      value: '',
      value1: '',
      videoSrc: '',
      videoDialog: false,
      fullscreenLoading: false,
      resultDialog: false,
      resultData: {},
      selectDialog: false,
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    //时间段选择赋值参数
    timgChange() {
      if (this.timeValue) {
        this.searchlist.beginTime = this.timeValue[0]
        this.searchlist.overTime = this.timeValue[1]
      } else {
        this.searchlist.beginTime = ''
        this.searchlist.overTime = ''
      }
    },
    //获取列表信息
    getList() {
      post('/video-interview/list', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
        ...this.searchlist,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    resetForm() {
      this.timeValue = []
      this.searchlist = {}
      this.getList()
    },
    selectForm() {
      this.selectDialog = true
    },
    addForm() {
      this.rowData = {}
      this.addDialog = true
    },
    clearAdd() {
      this.addDialog = false
      this.seeDialog = false
    },
    //保存新增或编辑
    saveAdd(data) {
      post('/video-interview/insert', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.clearAdd()
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取职位列表
    getPosition() {
      post(
        '/dict-position/list',
        {
          page: 1,
          limit: 1000,
        },
        true
      ).then((res) => {
        if (res.code == 1) {
          this.positionList = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //编辑点击
    editClick(row, type) {
      get('/video-interview/' + row.id).then((res) => {
        if (res.code == 1) {
          this.rowData = res.data
          if (type == 'edit') {
            this.addDialog = true
          } else {
            this.seeDialog = true
          }
          //console.log(this.rowData)
          this.$nextTick(() => {
            this.$refs['addRef'].setData(res.data)
          })
        } else {
          this.$message.error(res.data)
        }
      })
    },
    //删除点击
    deleteClick(row) {
      this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      })
        .then(() => {
          del('/video-interview/delete/' + row.id).then((res) => {
            if (res.code == 1) {
              this.$message.success(this.$t('common.success'))
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    //日期初始化
    setTime(time) {
      let date = new Date(time)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    //查看面试结果
    result(row) {
      get('/video-interview/result/' + row.id).then((res) => {
        if (res.code == 1) {
          this.resultData = res.data
          this.resultDialog = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 下载
    async download(row, type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      var res = await request({
        url: '/video-interview/download',
        method: 'POST',
        params: {
          id: row.id,
        },
        responseType: 'blob',
      })
      const blob = new Blob([res], {
        type: 'video/mp4',
      })
      const fileName =
        this.$t('interview.interviewRecordScreen') + '-' + row.candidateName
      const linkNode = document.createElement('a')

      linkNode.download = fileName //a标签的download属性规定下载文件的名称
      linkNode.style.display = 'none'
      linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
      if (type == 'see') {
        this.videoDialog = true
        this.videoSrc = URL.createObjectURL(blob)
        loading.close()
      } else {
        document.body.appendChild(linkNode)
        linkNode.click() //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
        document.body.removeChild(linkNode)
        loading.close()
      }
      console.log(linkNode.href)
    },
  },
  mounted() {
    this.getList()
    this.getPosition()
  },
}
</script>

<style scoped>
.status-btn {
  display: inline-block;
  padding: 2px 6px;
  color: #ffffff;
  float: right;
}

.warning {
  background-color: #ffb61e;
}

.success {
  background-color: #00af9b;
}

.danger {
  background-color: #ff2121;
}
</style>
<style>
.schedule-dialog .el-dialog__header,
.schedule-dialog .el-dialog__body {
  padding: 0 !important;
}
</style>
