<template>
    <div class="set-temp">
        <div class="label-table">
            <div class="table-header"><h4>已选择技能({{labelList.length + chechedList.length}})</h4></div>
            <div class="table-list">
                <el-row class="list-item" v-for="item in labelList" :key="item.id">
                    <el-col :span="22">{{item.labelName}}</el-col>
                    <el-col :span="2" >
                        <el-button type="text" style="color:#FF0000" @click="delItem(item,'del')"><i class="el-icon-close"></i></el-button>
                    </el-col>
                </el-row>
                <el-row class="list-item new" v-for="item in chechedList" :key="item.id">
                    <el-col :span="22">{{item.labelName}}</el-col>
                    <el-col :span="2" >
                        <el-button type="text" style="color:#FF0000" @click="delItem(item,'minus')"><i class="el-icon-close"></i></el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="label-filter">
            <div class="table-header"><h4>所有技能</h4></div>
            <div class="search-box">
                <el-input placeholder="请输入内容" v-model="searName" clearable size="small" @keyup.native.enter="gosearch">
                    <el-button slot="append" icon="el-icon-search" @click="gosearch"></el-button>
                </el-input>
            </div>
            <div class="label-list">
                <el-checkbox-group v-model="checkList">
                <!-- <el-checkbox-group v-model="checkList" @change="select"> -->
                    <!-- <el-checkbox v-for="item in dataList" :key="item.id" :label="item.id">{{item.labelName}}</el-checkbox> -->
                    <el-checkbox v-for="item in dataList" :key="item.id" :disabled="ifDisable(item.id)" @change="val=>select(val,item)" :label="item.id">{{item.labelName}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <el-pagination
              style="margin-left:10px"
              small
              layout="total,prev, pager, next"
              :page-size="pageSet.pageSize"
              @current-change="currentChange"
              :current-page="pageSet.pageNo"
              :total="pageSet.total">
          </el-pagination>
        </div>
        <div style="clear:both"></div>
         <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="saveAdd()">
                {{$t('common.save')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
    
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";

    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
        },
        data() {
            return {
                searName:'',
                dataList:[],//获取全部列表
                labelList:[],//职位标签
                chechedList:[],//左侧同步标签对象
                checkList:[],//右侧已选中标签id
                totalList:[],//已加载所有标签对象
                pageSet:{
                  pageSize: 15,
                  total: 0,
                  pageNo: 1,
                },
            }
        },
        methods: {
            currentChange(val) {
                this.pageSet.pageNo = val;
                this.getList()
            },
            gosearch(){
                this.pageSet.pageNo = 1;
                this.getList()
            },
            getList(){
                post("/dict-label/list",{
                    page:this.pageSet.pageNo,
                    limit:this.pageSet.pageSize,
                    labelName:this.searName
                }).then(res => {
                    if (res.code == 1) {
                        this.dataList=res.data.data
                        this.pageSet.total = res.data.count;
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            // 获取职位标签
            getPositionList(){
                post("/positionLabelMiddle/getLabelList",{
                   positionDictId:this.rowData.id
                }).then(res => {
                    if (res.code == 1) {
                        this.labelList=res.data
                        this.setChecked()
                        // this.pageSet.total = res.data.count;
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            ifDisable(id){
                let result = this.labelList.some(item=>{
                    return item.labelDictId==id
                })
                return result
            },
            select(val,data){
                if(val){
                    this.chechedList.push(data)
                }else{
                    this.chechedList.splice(this.chechedList.indexOf(data), 1);
                }
            },
            delItem(item,type){
                if(type=='del'){
                //     this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                //         confirmButtonText: this.$t('common.ok'),
                //         cancelButtonText: this.$t('common.cancel'),
                //         type: 'warning'
                //     }).then(() => {
                //         del("/positionLabelMiddle/delete/" + item.id).then(res => {
                //             if (res.code == 1) {
                //                 this.$message.success(this.$t('common.success'));
                //                 this.getPositionList();
                //             } else {
                //                 this.$message.error(res.msg);
                //             }
                //         });
                //     }).catch(() => {
                //     });
                    this.$emit('del',item)

                }
                if(type =='minus'){
                    this.chechedList.splice(this.chechedList.indexOf(item), 1);
                    this.checkList.splice(this.checkList.indexOf(item.id), 1);
                }
            },
            saveAdd() {
                this.$emit('add',{
                    positionDictId:this.rowData.id,
                    labelDict:this.checkList.toString()
                })
            },
            setChecked(){
                if(this.labelList.length>0){
                    this.checkList=this.labelList.map(x=>{
                        return x.labelDictId
                    })
                }else{
                    this.checkList=[]
                }
                if(this.chechedList.length>0){
                    let arr = []
                    arr = this.chechedList.map(x=>{
                        return x.id
                    })
                    this.checkList=[...this.checkList,...arr]
                }
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {
            this.getList()
            this.getPositionList()
            console.log(this.labelList);
        }
    }
</script>

<style scoped>
.set-temp{
    color:#333;
}
.label-table{
    background-color: #ffffff;
    float: left;
    width:75%;
    margin-right: 10px;
    min-height: 600px;
}
.label-filter{
    background-color: #ffffff;
    float: right;
    width:24%;
    min-height: 600px;
    /* padding:20px */
}
.table-header{
    padding:20px
}
.table-list{
    padding:0 20px
}
.list-item{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #E9ECF7;
    padding-left: 10px;
}
.list-item.new{
    background-color: #fbfaff;
}
.search-box{
    /* width:70%; */
    padding:0 20px;
    margin:0 auto
}
.label-list{
    padding:20px;
    height: 100%;
}
.el-checkbox{
    width:100%;
    line-height: 26px;
}
 .el-checkbox >>> .el-checkbox__label{
    font-size: 14px;
}
 .el-checkbox >>> .el-checkbox__inner{
    width: 16px;
    height: 16px;
}
 .el-checkbox >>> .el-checkbox__inner::after{
    left:5px;
    top:2px
}
</style>
