<template>
    <div>
       <el-form ref="elForm"  :model="rowData" :rules="rules" size="medium" label-width="120px" label-suffix="：">
           <el-row style="width:90%">
               <el-col :span="12">
                    <el-form-item :label="$t('setup.name')" prop="name">
                        <el-input v-model="rowData.name" :placeholder="$t('common.pleaseEnter')+$t('setup.name')">
                        </el-input>
                    </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item :label="$t('setup.position')" prop="position">
                        <el-input v-model="rowData.position" :placeholder="$t('common.pleaseEnter')+$t('setup.position')">
                        </el-input>
                        <!-- <el-select v-model="rowData.position" filterable placeholder="请选择" style="width:100%">
                            <el-option
                            v-for="item in positionList"
                            :key="item.id"
                            :label="item.positionName"
                            :value="item.positionName">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
               </el-col>
           </el-row>
           <el-row style="width:90%">
               <el-col :span="12">
                    <el-form-item :label="$t('setup.mobilePhone')" prop="mobilePhone">
                        <el-input v-model="rowData.mobilePhone" :placeholder="$t('common.pleaseEnter')+$t('setup.mobilePhone')">
                        </el-input>
                    </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item :label="$t('setup.email')" prop="mailbox">
                        <el-input v-model="rowData.mailbox" :placeholder="$t('common.pleaseEnter')+$t('setup.email')">
                        </el-input>
                    </el-form-item>
               </el-col>
           </el-row>
            
            <el-form-item :label="$t('setup.gender')" prop="sex">
                <el-radio-group v-model="rowData.sex">
                    <el-radio :label="'1'">{{$t('setup.male')}}</el-radio>
                    <el-radio :label="'2'">{{$t('setup.female')}}</el-radio>
                    <el-radio :label="'3'">{{$t('setup.unknown')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('setup.address')" prop="address">
                <el-input v-model="rowData.address" :placeholder="$t('common.pleaseEnter')+$t('setup.address')" :style="{width: '88%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.remarks')" prop="remarks">
                <el-input v-model="rowData.remarks" :placeholder="$t('common.pleaseEnter')+$t('setup.remarks')" :style="{width: '88%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.save')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
import {post, del, get, putUrl} from "@/api/http";

    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            return {
                positionList:[],
                defaultData:{},
                rules: {
                    name: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.name')+'!',
                        trigger: 'blur'
                    }]
                }
            }
        },
        methods: {
            getList(){
                post("/dict-position/list",{
                    page:1,
                    limit:100,
                }).then(res => {
                    if (res.code == 1) {
                        this.positionList=res.data.data
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        if(this.rowData.id && this.rowData.name == this.defaultData.name){
                            delete this.rowData.name
                        }
                        this.$emit("add",this.rowData,!this.rowData.id?'add':'')
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {
            this.getList()
            this.defaultData = JSON.parse(JSON.stringify(this.rowData))
        }
    }
</script>

<style scoped>

</style>
