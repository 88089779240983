<template>
  <div>
    <el-form
      ref="interviewForm"
      :model="interviewlist"
      :rules="rules"
      label-suffix="："
    >
      <el-form-item :label="$t('common.pleaseSelect')" label-width="70px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-radio v-model="interviewlist.radio" label="1">{{
              $t('interview.agree')
            }}</el-radio>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-radio v-model="interviewlist.radio" label="2">{{
              $t('interview.agreeChange')
            }}</el-radio>
            <el-date-picker
              v-model="interviewlist.radioValue"
              type="date"
              :disabled="interviewlist.radio != 2"
              :placeholder="
                $t('common.pleaseSelect') + $t('interview.dateTime')
              "
              format="yyyy - MM - dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-time-select
              style="width: 150px; margin-left: 15px"
              :placeholder="$t('interview.startTime')"
              v-model="interviewlist.startTime"
              :disabled="interviewlist.radio != 2"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
              }"
            >
            </el-time-select>
            <el-time-select
              style="width: 150px; margin-left: 15px"
              :placeholder="$t('interview.endTime')"
              :disabled="interviewlist.radio != 2"
              v-model="interviewlist.endTime"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
                minTime: interviewlist.startTime,
              }"
            >
            </el-time-select>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-radio v-model="interviewlist.radio" label="3">{{
              $t('interview.reject')
            }}</el-radio>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <div class="boontbtn">
      <el-button
        type="primary"
        size="small"
        @click="add"
        v-if="interviewlist.radio != 3"
        >{{ $t('common.ok') }}</el-button
      >

      <el-button type="primary" size="small" @click="sendClick" v-else>
        {{ $t('interview.sendInvitation') }}
      </el-button>
      <el-button size="small" @click="clear">{{
        $t('common.cancel')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    yesRowDate: {
      typeof: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      rules: {},
      interviewlist: {
        radio: '1',
        startTime: '',
        radioValue: '',
        endTime: '',
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    let time = this.yesRowDate.selectedTime.split(' ')
    let startTime = time[1].split('~')
    this.interviewlist.radioValue = time[0]

    this.interviewlist.startTime = startTime[0]
    this.interviewlist.endTime = startTime[1]
    console.log(this.interviewlist)
  },

  methods: {
    add() {
      let dateTiee = ''
      if (!this.interviewlist.radioValue) {
        return this.$message.error(
          this.$t('common.pleaseSelect') + this.$t('interview.dateTime')
        )
      }
      if (!this.interviewlist.startTime) {
        return this.$message.error(this.$t('interview.startTime'))
      }
      if (!this.interviewlist.endTime) {
        return this.$message.error(this.$t('interview.endTime'))
      }
      dateTiee =
        this.interviewlist.radioValue +
        ' ' +
        this.interviewlist.startTime +
        '~' +
        this.interviewlist.endTime
      let obj = {
        id: this.yesRowDate.id,
        //...this.yesRowDate,
        selectedTime: dateTiee,
        interviewerStatus: this.interviewlist.radio,
        status: 3,
      }
      //console.log(obj)
      this.$emit('yesClick', obj)
    },
    sendClick() {
      let obj = {
        ...this.yesRowDate,
        interviewerStatus: this.interviewlist.radio,
        selectedTime: this.interviewlist.time,
        notificationMode: 1,
      }
      //console.log(obj)
      this.$emit('sendClick', obj)
    },
    //时间段选择赋值参数
    timgChange() {
      if (this.radioValue) {
        this.interviewlist.time = this.radioValue
      } else {
        this.interviewlist.time = ''
      }
    },
    clear() {
      this.$emit('clear')
    },
  },
}
</script>
<style lang="scss" scoped></style>
