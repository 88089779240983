<template>
    <div class="add-temp">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">
                <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="120px" label-suffix="：">
                    <el-form-item label="客户名称" prop="name">
                        <el-input v-model="rowData.name" :placeholder="$t('common.pleaseEnter')+$t('setup.clientName')" clearable :style="{width: '50%'}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="address">
                        <el-input v-model="rowData.address" :placeholder="$t('common.pleaseEnter')+$t('setup.address')" clearable :style="{width: '50%'}">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="boontbtn">
                    <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                        {{$t('common.save')}}
                    </el-button>
                    <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="联系人" name="second">
                <Contacts :dialogType="dialogType" :customerId="rowData.id" :addCustomerId="addCustomerId"></Contacts>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Contacts from './contacts.vue'
    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
            // 新增返回的客户id
            addCustomerId:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        components:{
            Contacts
        },
        data() {
            return {
                activeName:'first',
                rules: {
                    name: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.clientName')+'!',
                        trigger: 'blur'
                    }],
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        if(this.rowData.id){
                            delete this.rowData.createTime
                        }
                        this.$emit("add",this.rowData)
                    }
                })
            },
            handleClick(tab) {
                // console.log(tab._props.name);
                // if(tab.props.name == 'second'){
                //     this.customerId = props.rowData.id
                // }
            },
            clearAdd() {
                this.$emit("clear");
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>
.el-form{
    height: 100px;
    margin: 30px 0 0;
}
</style>
