<template>
  <div class="interview-time schedule-box">
    <div class="dis-flex justify-center">
      <div class="week-time justify-center dis-flex items-center">
        <i class="el-icon-arrow-left" @click="prevWeek"></i>
        {{ week }} {{ year }}
        <i class="el-icon-arrow-right" @click="nextWeek"></i>
      </div>
    </div>
    <el-table
      :data="newTimeLists"
      height="700"
      style="width: 100%"
      ref="multipleTable"
      class="schedule-table"
      border
      v-loading="loading"
    >
      <el-table-column width="80" align="center">
        <template slot="header">
          <i class="el-icon-caret-top fs-24 icon-caret" @click="scollTop"></i>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header" slot-scope="scope">
          <span
            >{{ timeArr[0].month + '.' + timeArr[0].day
            }}{{ $t('common.mon') }}</span
          >
        </template>
        <template slot-scope="scope">
          <!-- {{ scope.row.mon.day }} -->
          <div
            @click="weekClick(scope.row, scope.$index, 'mon')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.mon.flag == 2,
              'mon-ceil-bgSel': scope.row.mon.flag == 3,
            }"
          >
            <span v-if="scope.row.mon.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.mon.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[1].month + '.' + timeArr[1].day
            }}{{ $t('common.tue') }}</span
          >
        </template>
        <template slot-scope="scope">
          <div
            @click="weekClick(scope.row, scope.$index, 'tue')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.tue.flag == 2,
              'mon-ceil-bgSel': scope.row.tue.flag == 3,
            }"
          >
            <span v-if="scope.row.tue.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.tue.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[2].month + '.' + timeArr[2].day
            }}{{ $t('common.wed') }}</span
          >
        </template>
        <template slot-scope="scope">
          <div
            @click="weekClick(scope.row, scope.$index, 'wed')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.wed.flag == 2,
              'mon-ceil-bgSel': scope.row.wed.flag == 3,
            }"
          >
            <span v-if="scope.row.wed.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.wed.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[3].month + '.' + timeArr[3].day
            }}{{ $t('common.thu') }}</span
          >
        </template>
        <template slot-scope="scope">
          <div
            @click="weekClick(scope.row, scope.$index, 'thur')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.thur.flag == 2,
              'mon-ceil-bgSel': scope.row.thur.flag == 3,
            }"
          >
            <span v-if="scope.row.thur.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.thur.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[4].month + '.' + timeArr[4].day
            }}{{ $t('common.fri') }}</span
          >
        </template>
        <template slot-scope="scope">
          <div
            @click="weekClick(scope.row, scope.$index, 'fri')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.fri.flag == 2,
              'mon-ceil-bgSel': scope.row.fri.flag == 3,
            }"
          >
            <span v-if="scope.row.fri.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.fri.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[5].month + '.' + timeArr[5].day
            }}{{ $t('common.sat') }}</span
          >
        </template>
        <template slot-scope="scope">
          <div
            @click="weekClick(scope.row, scope.$index, 'sat')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.sat.flag == 2,
              'mon-ceil-bgSel': scope.row.sat.flag == 3,
            }"
          >
            <span v-if="scope.row.sat.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.sat.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span
            >{{ timeArr[6].month + '.' + timeArr[6].day
            }}{{ $t('common.sun') }}</span
          >
        </template>
        <template slot-scope="scope">
          <!-- {{ scope.row.sun.day }} -->
          <div
            @click="weekClick(scope.row, scope.$index, 'sun')"
            class="mon-ceil"
            :class="{
              'mon-ceil-bg': scope.row.sun.flag == 2,
              'mon-ceil-bgSel': scope.row.sun.flag == 3,
            }"
          >
            <span v-if="scope.row.sun.flag == 2">{{
              $t('common.leisure')
            }}</span>
            <span v-else-if="scope.row.sun.flag == 3">{{
              $t('common.selected')
            }}</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import '../../assets/css/style.css'
import { post } from '@/api/http'
export default {
  name: 'InterviewTime',
  data() {
    return {
      currentFirstDate: '',
      year: '', //年
      week: '', //周 如03.14-03.20
      timeArr: [], // 一周的数据
      timeList: [
        {
          date: '00:00',
          dateEnd: '00:30',
        },
        {
          date: '00:30',
          dateEnd: '01:00',
        },
        {
          date: '01:00',
          dateEnd: '01:30',
        },
        {
          date: '01:30',
          dateEnd: '02:00',
        },
        {
          date: '02:00',
          dateEnd: '02:30',
        },
        {
          date: '02:30',
          dateEnd: '03:00',
        },
        {
          date: '03:00',
          dateEnd: '03:30',
        },
        {
          date: '03:30',
          dateEnd: '04:00',
        },
        {
          date: '04:00',
          dateEnd: '04:30',
        },
        {
          date: '04:30',
          dateEnd: '05:00',
        },
        {
          date: '05:00',
          dateEnd: '05:30',
        },
        {
          date: '05:30',
          dateEnd: '06:00',
        },
        {
          date: '06:00',
          dateEnd: '06:30',
        },
        {
          date: '06:30',
          dateEnd: '07:00',
        },
        {
          date: '07:00',
          dateEnd: '07:30',
        },
        {
          date: '07:30',
          dateEnd: '08:00',
        },
        {
          date: '08:00',
          dateEnd: '08:30',
        },
        {
          date: '08:30',
          dateEnd: '09:00',
        },
        {
          date: '09:00',
          dateEnd: '09:30',
        },
        {
          date: '09:30',
          dateEnd: '10:00',
        },
        {
          date: '10:00',
          dateEnd: '10:30',
        },
        {
          date: '10:30',
          dateEnd: '11:00',
        },
        {
          date: '11:00',
          dateEnd: '11:30',
        },
        {
          date: '11:30',
          dateEnd: '12:00',
        },
        {
          date: '12:00',
          dateEnd: '12:30',
        },
        {
          date: '12:30',
          dateEnd: '13:00',
        },
        {
          date: '13:00',
          dateEnd: '13:30',
        },
        {
          date: '13:30',
          dateEnd: '14:00',
        },
        {
          date: '14:00',
          dateEnd: '14:30',
        },
        {
          date: '14:30',
          dateEnd: '15:00',
        },
        {
          date: '15:00',
          dateEnd: '15:30',
        },
        {
          date: '15:30',
          dateEnd: '16:00',
        },
        {
          date: '16:00',
          dateEnd: '16:30',
        },
        {
          date: '16:30',
          dateEnd: '17:00',
        },
        {
          date: '17:00',
          dateEnd: '17:30',
        },
        {
          date: '17:30',
          dateEnd: '18:00',
        },
        {
          date: '18:00',
          dateEnd: '18:30',
        },
        {
          date: '18:30',
          dateEnd: '19:00',
        },
        {
          date: '19:00',
          dateEnd: '19:30',
        },
        {
          date: '19:30',
          dateEnd: '20:00',
        },
        {
          date: '20:00',
          dateEnd: '20:30',
        },
        {
          date: '20:30',
          dateEnd: '21:00',
        },
        {
          date: '21:00',
          dateEnd: '21:30',
        },
        {
          date: '21:30',
          dateEnd: '22:00',
        },
        {
          date: '22:00',
          dateEnd: '22:30',
        },
        {
          date: '22:30',
          dateEnd: '23:00',
        },
        {
          date: '23:00',
          dateEnd: '23:30',
        },
        {
          date: '23:30',
          dateEnd: '23:59',
        },
      ], //每半小时的时间段
      newTimeList: [], //判断timeList时间段与当前时间段是否<=,是则存储
      newTimeLists: [],
      selectedArr: [], //面试官日程安排 判断是否为已选择
      loading: false,
    }
  },

  components: {},

  created() {
    this.setDate(new Date())
    this.timeCycle()
    //this.setTime()
    // setInterval(() => {
    //   this.setTime()
    // }, 60000)
  },

  methods: {
    scollTop() {
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    prevWeek() {
      this.timeArr = []
      this.setDate(this.addDate(this.currentFirstDate, -7))
      this.timeCycle()
    },
    nextWeek() {
      this.timeArr = []
      this.setDate(this.addDate(this.currentFirstDate, 7))
      this.timeCycle()
    },

    weekClick(row, index, item) {
      let newTimeListArr = []
      newTimeListArr = JSON.parse(JSON.stringify(this.newTimeList)) //拷贝原始数据，不被收操作影响
      if (row[item].isSelect != 2) {
        if (row[item].flag == 1) {
          row[item].flag = 2
          newTimeListArr[index][item].flag = 2
          this.newTimeList[index][item].flag = 2
        } else {
          row[item].flag = 1
          newTimeListArr[index][item].flag = 1
          this.newTimeList[index][item].flag = 1
        }
        this.loading = true
        this.$nextTick(() => {
          this.selectedTime(newTimeListArr, index, item, row)
        })
      }
      //console.log(row)
    },

    selectedTime(list, index, item, row) {
      let startTime =
        row[item].year +
        '-' +
        row[item].month +
        '-' +
        row[item].day +
        ' ' +
        row.date +
        ':' +
        '00'
      let endTime =
        row[item].year +
        '-' +
        row[item].month +
        '-' +
        row[item].day +
        ' ' +
        row.dateEnd +
        ':' +
        '00'
      this.newTimeLists = [...list]

      if (row[item].flag == 2) {
        row[item].flag = 2
        list[index][item].flag = 2
        this.newTimeList[index][item].flag = 2
        post('/schedule/insert', {
          startTime,
          endTime,
        }).then((res) => {
          if (res.code == 1) {
            this.$set(this.newTimeLists, index, row)
            this.loading = false
            //this.getList(this.newTimeList).then((res) => {})
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        list[index][item].flag = 1
        row[item].flag = 1
        this.newTimeList[index][item].flag = 1
        //console.log(list, this.newTimeLists)
        post('/schedule/delete', {
          id: row[item].id,
        }).then((res) => {
          if (res.code == 1) {
            this.$set(this.newTimeLists, index, row)
            this.loading = false
            //this.getList(this.newTimeList).then((res) => {})
          } else {
            this.$message.error(res.data)
          }
        })
      }

      // this.$set(this.newTimeList, index, row)
    },
    timeCycle(date) {
      //循环添加相应数据
      let timeList = this.timeList
      let timeArr = this.timeArr
      //console.log(timeArr)
      this.monDay = this.timeArr[0].month + '.' + this.timeArr[0].day

      this.week =
        timeArr[0].month +
        '.' +
        timeArr[0].day +
        '-' +
        timeArr[6].month +
        '.' +
        timeArr[6].day

      //循环添加相应数据 数据跟后台协商
      for (let i = 0; i < timeList.length; i++) {
        //console.log(timeArr[0])
        timeList[i].mon = {
          ...timeArr[0],
          flag: 1,
        }
        timeList[i].tue = {
          ...timeArr[1],
          flag: 1,
        }
        timeList[i].wed = {
          ...timeArr[2],
          flag: 1,
        }
        timeList[i].thur = {
          ...timeArr[3],
          flag: 1,
        }
        timeList[i].fri = {
          ...timeArr[4],
        }
        timeList[i].sat = {
          ...timeArr[5],
        }
        timeList[i].sun = {
          ...timeArr[6],
        }
      }
      //console.log(timeList)
      //this.setTime()
      let newTimeList = []
      for (let i = 0; i < this.timeList.length; i++) {
        newTimeList.push(this.timeList[i])
      }
      this.newTimeList = [...newTimeList]
      //console.log(this.newTimeList)
      this.newTimeLists = []

      this.$nextTick(() => {
        this.getList(newTimeList).then((res) => {
          this.newTimeLists = [...res]
          console.log(res)
        })
      })
      //console.log(this.timeArr, this.monDay)
    },
    getList(list) {
      //查询查询面试官日程安排
      return new Promise((resolve, reject) => {
        post('/schedule/getList', {
          startTime:
            this.timeArr[0].year +
            '-' +
            this.timeArr[0].month +
            '-' +
            this.timeArr[0].day,
          endTime:
            this.timeArr[6].year +
            '-' +
            this.timeArr[6].month +
            '-' +
            this.timeArr[6].day,
        }).then((res) => {
          let weekArr = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun']
          if (res.code == 1) {
            this.selectedArr = res.data
            if (res.data != '') {
              for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < this.selectedArr.length; j++) {
                  //list[i].mon
                  //获取返回行 true
                  for (let r = 0; r < weekArr.length; r++) {
                    if (
                      this.selectedArr[j].startTime ==
                      list[i][weekArr[r]].year +
                        '-' +
                        list[i][weekArr[r]].month +
                        '-' +
                        list[i][weekArr[r]].day +
                        ' ' +
                        list[i].date +
                        ':' +
                        '00'
                    ) {
                      //list[i][weekArr[r]].flag = 2
                      if (this.selectedArr[j].isSelect == 2) {
                        list[i][weekArr[r]].flag = 3
                        list[i][weekArr[r]].isSelect = 2
                      } else {
                        list[i][weekArr[r]].flag = 2
                        list[i][weekArr[r]].isSelect = 1
                      }

                      list[i][weekArr[r]].id = this.selectedArr[j].id

                      this.$set(this.newTimeList, i, list[i])

                      resolve(this.newTimeList)
                      //console.log(list[i], i)
                    }
                  }
                }
              }
            } else {
              resolve(list)
            }
          } else {
            this.$message.error(res.data)
          }
        })
      })
      p
    },
    addDate(date, n) {
      date.setDate(date.getDate() + n)
      // console.log(date.getDate(), n)
      return date
    },
    //获取年月日时分
    setCalendar(date) {
      let year = ''
      year = this.year = date.getFullYear()
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      // let minute =
      //   date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return this.timeArr.push({
        year: year,
        month: month,
        day: day,
        isSelect: 1,
        flag: 1,
        //hour: hour,
        //minute: minute,
      })
    },

    setDate(date) {
      //设置周
      var week = date.getDay() - 1
      date = this.addDate(date, week * -1)
      // console.log(date)
      this.currentFirstDate = new Date(date)

      for (let i = 0; i < 7; i++) {
        //从星期一开始
        if (i == 0) {
          //date
          this.setCalendar(date)
        } else {
          this.setCalendar(this.addDate(date, 1))
        }
        //从星期日开始
        // if (i == 0) {
        //   //date
        //   this.setCalendar(this.addDate(date, -1))
        // } else {
        //   this.setCalendar(this.addDate(date, 1))
        // }
      }
    },
  },
}
</script>
<style scoped>
.navbar {
  background: #fff;
}
.interview-time {
  width: 100%;
  margin: 0 auto;
}
.week-time {
  width: 100%;
  height: 48px;
  font-size: 22px;
  color: #fff;
  background: rgb(144, 166, 253);
}
.week-time i {
  margin: 0 10px;
  cursor: pointer;
}
.time-left {
  width: 100px;
  height: 600px;
  overflow-y: scroll;
}
.time-left ul {
  width: 100%;
  overflow-y: hidden;
}

.time-left::-webkit-scrollbar {
  width: 0 !important;
}
.time-left li {
  width: 100%;
  height: 48px;
  line-height: 48px;
}
.caret-time {
  position: absolute;
  margin-top: 0px;
  width: 100px;
  height: 30px;
  text-align: center;
  background: #fff;
}
h2 {
  font-size: 26px;
  padding: 15px 0;
}
.fs-24 {
  font-size: 24px;
}
.icon-caret {
  cursor: pointer;
  color: rgb(144, 166, 253);
}
.relative {
  position: relative;
}
.mon-ceil-bg {
  background: rgb(144, 173, 253);
  color: #fff;
}
.mon-ceil-bgSel {
  background: rgb(252, 147, 147);
  color: #fff;
}
.mon-ceil {
  height: 48px;
  line-height: 48px;
}
</style>
<style>
.schedule-table {
  margin-bottom: 5px;
}

.schedule-table .el-table__cell {
  padding: 0 !important;
}

.schedule-table .cell {
  padding: 0 !important;
}

.schedule-table th.el-table__cell > .cell {
  height: 48px;
  line-height: 48px;
}
.schedule-table .el-table__row .is-center:first-child {
  border-bottom: 0;
}
.schedule-table .el-table__row .is-center:first-child .cell {
  margin-top: -30px !important;
}
</style>
