<template>
    <div class="index">
        <!--<svg-icon icon-class="edit" class="arrow-right"/>-->
        <div class="info">
            <el-row>
                <el-col :span="18">
                    <div class="hello">
                        {{$t('home.hello')}}，{{infoData.username}}！ 
                        <!-- <span>{{$t('home.trialVersion')}}10 {{$tc('home.day',10)}}</span>
                        <el-button type="danger" size="mini">{{$t('home.upgradeNow')}}</el-button> -->
                        <!--<svg-icon icon-class="edit" class-name="card-panel-icon"/>-->
                    </div>
                </el-col>
                <!-- <el-col :span="6">
                    <div class="help">
                        <span><i class="el-icon-edit"></i>{{$t('home.consult')}}</span>
                        <span><i class="el-icon-edit"></i>{{$t('home.help')}}</span>
                    </div>
                </el-col> -->
            </el-row>
        </div>
        <div class="tap">
            <div class="left-card">
                <div class="tap-card">
                    <div class="card-title">
                        {{$t('home.shortcutMenu')}}
                    </div>
                    <el-divider></el-divider>
                    <div class="card-content">
                        <el-row>
                            <router-link :to="{name:'Test',}" v-if="homeBtn1">
                                <el-col :span="3" @click="gotoPage('test')">
                                    <img src="@/assets/dashboard/u8992.svg" alt="">
                                    <p>{{$t('home.addTest')}}</p>
                                </el-col>
                            </router-link>
                            <router-link :to="{name:'Interview',}" v-if="homeBtn2">
                                <el-col :span="3" :offset="4">
                                    <img src="@/assets/dashboard/u9001.svg" alt="">
                                    <p>{{$t('home.addInterview')}}</p>
                                </el-col>
                            </router-link>
                            <router-link :to="{name:'Question',}" v-if="homeBtn3">
                                <el-col :span="3" :offset="4">
                                    <img src="@/assets/dashboard/u9023.svg" alt="">
                                    <p>{{$t('home.addQuestion')}}</p>
                                </el-col>
                            </router-link>
                            <router-link :to="{name:'Setting',}" v-if="homeBtn4">
                                <el-col :span="3" :offset="4">
                                    <img src="@/assets/dashboard/u9030.svg" alt="">
                                    <p>{{$t('home.userSettings')}}</p>
                                </el-col>
                            </router-link>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="right-card">
                <div class="tap-card">
                    <!-- <div class="card-title">
                        {{$t('home.announce')}}
                    </div>
                    <el-divider></el-divider>
                    <div class="notice-list">
                        <ul>
                            <li v-for="item in 6" :key="item">
                                    <span class="list-title">
                                        <img src="@/assets/dashboard/红色_u7462.svg" alt="">
                                    2021国庆节放假通知
                                    </span>
                                <span>2021-09-28</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="left-card">
                <div class="tap-card">
                    <div class="card-title">
                        {{$t('home.todayData')}}
                    </div>
                    <el-divider></el-divider>
                    <div class="card-content">
                        <el-row>
                            <el-col :span="8" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.onlineNum')}}</p>
                                <p> {{onlinePeople}}{{$tc('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="8" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.joinTestNum')}}</p>
                                <p>{{countData.numberParticipantsTest}} {{$tc('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="8">
                                <p>{{$t('home.joinInterviewNum')}}</p>
                                <p>{{countData.numberPeopleAttendingInterview}} {{$tc('home.person',5)}}</p>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div style="clear: both"></div>
            <div class="all-card">
                <div class="tap-card">
                    <div class="card-title">
                        {{$t('home.summaryData')}}
                    </div>
                    <el-divider></el-divider>
                    <div class="card-content">
                        <el-row>
                            <el-col :span="4" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.unmarkPaperNum')}}</p>
                                <p>{{countData.numberPapersAssessed}} {{$tc('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="4" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.unTestNum')}}</p>
                                <p>{{countData.numberTested}} {{$tc('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="4" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.unInterviewNum')}}</p>
                                <p>{{countData.numberPeopleWaitingInterview}} {{$tc('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="4" style="border-right: 1px solid #ECECF4;">
                                <p>{{$t('home.hadjoinTestNum')}}</p>
                                <p>{{countData.numberParticipants}} {{$t('home.person',5)}}</p>
                            </el-col>
                            <el-col :span="4">
                                <p>{{$t('home.hadjoinInterviewNum')}}</p>
                                <p>{{countData.numberPeopleAttendingInterviewCount}} {{$tc('home.person',5)}}</p>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import baseURL from '@/api/baseURL.js'

    export default {
        name: "index",
        data() {
            return {
            navIndex: '1',
            language: '',
            infoData:{},
            countData:{},
            infoDialog: false,
            updateDialog: false,
            selTimeDialog: false,
            onlinePeople:0,
            homeBtn1:false,
            homeBtn2:false,
            homeBtn3:false,
            homeBtn4:false,
            }
        },
        computed: {
            navList: ()=> {
                return JSON.parse(window.localStorage.getItem('navList')) || []
            },
        },
        methods:{
            getHomeList(){
                if(this.navList.length>0){
                    this.navList.forEach(ele=>{
                        if(ele.resourceUrl == 'Test'){
                            this.homeBtn1 = true
                        }
                        if(ele.resourceUrl == 'Interview'){
                            this.homeBtn2 = true
                        }
                        if(ele.resourceUrl == 'Question'){
                            this.homeBtn3 = true
                        }
                        if(ele.resourceUrl == 'Setting'){
                            this.homeBtn4 = true
                        }
                    })
                }
            },
            getUser(){
                get("/setting-user/my-info").then(res => {
                    if (res.code == 1) {
                        this.infoData=res.data;
                        this.init()
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            gotoPage(){

            },
            getStatistics(){
                post("/homeController/getHomeStatistics").then(res => {
                    if (res.code == 1) {
                        this.countData=res.data;
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            //WebSocket链接，用途：主面试官开始面试通知其他用户
            init: function () {
                if (typeof(WebSocket) === "undefined") {
                    alert(this.$t('interview.notSupportSocket'))
                } else {
                    // let token = getToken();
                    let url ="wss://" + baseURL.split('//')[1] + "video/"+this.infoData.phone;
                    // 实例化socket
                    this.socket = new WebSocket("wss://" + baseURL.split('//')[1] + "video/"+this.infoData.id);
                    // this.socket = new WebSocket("wss://47.99.204.31:8443/video/"+this.loginInfo.phone);
                    // 监听socket连接
                    this.socket.onopen = this.open;
                    // 监听socket错误信息
                    this.socket.onerror = this.error;
                    // 监听socket消息
                    this.socket.onmessage = this.getMessage;
                }
            },
            open: function () {
                console.log("socket连接成功")
            },
            error: function (err) {
                console.log(err);
                console.log("连接错误")
            },
            getMessage: function (msg) {
                console.log(msg.data);
                this.onlinePeople = msg.data
            },
            send: function () {
                this.socket.send(params)
            },
            close: function () {
                console.log("socket已经关闭")
            },
        },
        mounted(){
            this.getUser()
            this.getStatistics()
            setTimeout(()=>{
                this.getHomeList()
            },150)
        },
    }
</script>

<style scoped>
    .left-card {
        width: 70%;
        float: left;
    }

    .right-card {
        width: calc(30% - 20px);
        float: right;
    }

    .left-card .tap-card{
        height: 240px;
    }

    .right-card .tap-card {
        height: 530px;
    }

    .index {
        padding: 15px;
    }

    .info {
        background-color: #fff;
        padding: 10px 20px;
    }

    .hello {
        font-size: 14px;
    }

    .hello span {
        color: red;
        margin-right: 20px;
    }

    .help {
        font-size: 14px;
        text-align: right;
        height: 28px;
        line-height: 28px;
    }

    .help span {
        margin: 10px;
    }

    .tap-card {
        background-color: #fff;
        margin-top: 20px;
        padding: 15px;
    }

    .tap-card .card-title {
        font-size: 14px;
        font-weight: bold;
    }

    .tap-card .el-divider--horizontal {
        margin: 10px 0;
    }

    .tap-card .card-content {
        text-align: center;
        padding: 40px 20px;
    }

    .tap-card .card-content img{
        height: 60px;
    }

    .tap-card .card-content p {
        margin: 16px;
        font-size: 14px;
    }

    .notice-list li {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        border-bottom: 1px solid #ececf4;
        padding: 10px 0;
    }

    .notice-list li img {
        margin-right: 15px;
    }

    .notice-list li span {
        align-self: stretch;
    }

    .list-title {
        display: flex;
        align-items: center;
    }


</style>
