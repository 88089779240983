<template>
    <div class="login">
        <div class="header">
            <div class="navbar">
                <el-row>
                    <el-col :span="4">
                        <div class="nav-img">
                            <img src="@/assets/u6376.png" alt="" style="height: 50px"/>
                        </div>
                    </el-col>
                    <el-col :span="16">

                    </el-col>
                    <el-col :span="4">

                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content">
            <div class="type-box">
                <div class="type-title">
                    <h2 v-if="!isInfo">{{$t('interview.welcome')}}  {{infoData.positionName}}{{$t('interview.interview')}}-{{infoData.candidateName}}</h2>
                    <h2 v-if="isInfo">{{$t('interview.expiredOrNotExist')}}</h2>
                </div>
                <div class="type type-left">
                    <div class="tt-title">
                        <h4>{{$t('interview.ImInterviewer')}}</h4>
                    </div>
                    <div class="tt-content">
                        <p>{{$t('interview.interviewerIdintity')}}</p>
                        <div class="btn-box" v-if="!isInfo">
                            <span v-for="item in infoData.list" :key="item.id" style="margin-right:10px">
                                <el-button type="primary"   @click="clickInfo('1',item)" v-if="item.id"
                                :disabled="(hasVideo||hasAudio)||item.hasGetIn">
                                    {{nameSet(item.username)  }}
                                </el-button>
                            </span>
                        </div>
                        <div class="no-user" v-else>
                            <p>{{$t('interview.errorOrNoIntervewer')}}</p>
                        </div>
                    </div>
                </div>
                <div class="type type-right">
                    <div class="tt-title">
                        <h4>{{$t('interview.ImCandidate')}}</h4>
                    </div>
                    <div class="tt-content">
                        <p>{{$t('interview.enterAfterVerify')}}</p>
                        <el-button type="primary" @click="clickInfo('2')" :disabled="isInfo||hasVideo||hasAudio">{{$t('interview.joinNow')}}</el-button>
                    </div>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
        <!--填写信息-->
        <div v-if="infoDialog">
            <el-dialog center :title="$t('interview.authenticate')" :visible.sync="infoDialog" :before-close="clearInfo" width="500px">
                <div class="login-box">
                    <div class="login-info">
                        <el-form :inline="true" label-position="right" ref="infoForm" :model="form" :rules="rules" label-width="85px">
                            <el-form-item :label="$t('interview.candidateName')">
                                <el-input :value="nameSet(loginInfo.username)" autocomplete="off" disabled=""></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('interview.phone')">
                                <el-input v-model="loginInfo.phone" autocomplete="off" disabled></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('interview.vCode')"  prop="code">
                                <el-input :placeholder="$t('interview.verifyCode')"
                                          v-model="form.code"
                                          class="input-with-select">
                                    <el-button slot="append" @click="openCode" :disabled="codeDisabled">{{codeMsg}}</el-button>
                                </el-input>
                            </el-form-item>
                            <!--<el-form-item label="频道:" prop="channelId">-->
                                <!--<el-input v-model="form.channelId" autocomplete="off" ></el-input>-->
                            <!--</el-form-item>-->
                        </el-form>
                    </div>

                </div>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button @click="infoDialog = false">{{$t('common.cancel')}}</el-button>
                    <el-button type="primary" @click="submitCode">{{$t('common.ok')}}</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import '@/components/Meeting/aliyun-webrtc-sdk-1.12.1.js'
    import {post, del, get, putUrl} from "@/api/http";
    import qs from 'qs'
    export default {
        name: "index",
        data(){
            return{
                aliWebrtc: new AliRtcEngine(),
                isInfo:true,
                infoDialog:false,
                infoData:{},
                form:{},
                rules:{
                    interviewer: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('interview.candidateName'),
                        trigger: 'blur'
                    }], code: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('interview.verifyCode'),
                        trigger: 'blur'
                    }],
                    channelId: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('interview.channel'),
                        trigger: 'blur'
                    }],
                },
                formLabelWidth: '140px',
                interviewer:'',
                hasVideo:false,//false是有设备
                hasAudio:false,
                loginInfo:{},
                interviewId:'',
                codeMsg:this.$t('interview.getVerifyCode'),
                codeDisabled:false,
                loginType:'',
            }
        },
        methods:{
            clickInfo(type,info){
                this.loginType=type;
                if (type=='1'){
                    this.loginInfo=info;
                    this.loginInfo.candidatePhonePrefix=this.infoData.candidatePhonePrefix;
                    this.infoDialog=true;
                }
                if (type=='2'){
                    this.loginInfo={
                        phone:this.infoData.candidatePhone,
                        username:this.$t('interview.candidate')+'_'+this.infoData.candidateName,
                        candidatePhonePrefix:this.infoData.candidatePhonePrefix,
                    }
                    this.infoDialog=true;
                }
            },
            clearInfo(){
                this.infoDialog=false
            },
            async getInfo(){
                let id = this.$route.query.id;
                this.interviewId=id;
                if (id){
                    get("/videoconferencing/play/"+id).then(res => {
                        if (res.code == 1) {
                            let arr = []
                            res.data.list.map(ele=>{
                                post("/videoconferencing/getIsPhone",{phone:ele.phone}).then(res => {
                                    if (res.code == 1) {
                                        ele.hasGetIn = res.data
                                        arr.push(ele)
                                    }
                                });
                            })
                            this.infoData=res.data;
                            this.infoData.list = arr
                            this.isInfo=false;
                        } else {
                            this.isInfo=true;
                            this.$message.error(res.data);
                        }
                    });
                } else {
                    this.isInfo=true;
                    this.$message.error(this.$t('interview.noInterview'));
                }

            },
            submitCode(){
                this.$refs.infoForm.validate(valid => {
                    if (valid) {
                        if (this.loginInfo.phone) {
                            let obj={
                                phone:this.loginInfo.phone,
                                smsCode:this.form.code,
                            }
                            post("/videoconferencing/check-sms-code", obj).then(res => {
                                if (res.code == 1) {
                                    localStorage.setItem('info', JSON.stringify(this.infoData));
                                    localStorage.setItem('loginInfo', JSON.stringify(this.loginInfo));
                                    localStorage.setItem('userName', this.loginInfo.username);
                                    localStorage.setItem('channelId', this.infoData.id);
                                    if (this.loginType=='1'){
                                        localStorage.setItem('Admin-Token', res.data);
                                    } else {
                                        localStorage.setItem('Admin-Token', '');
                                    }

                                    this.$router.push({name:'VideoHome',params:{info:this.loginInfo,interviewId:this.interviewId}});
                                    // this.$router.push({name:'Home',params:{info:this.infoData}})
                                    this.$message.success(this.$t('interview.joinSuccessed'));
                                } else {
                                    this.$message.error(res.data);
                                }
                            });
                        }else {
                            this.$message.error(this.$t('common.pleaseEnter')+this.$t('interview.phone'));
                        }

                    } else {
                        return false
                    }
                })

            },
            openCode(){
                if (this.loginInfo.phone){
                    console.log(123);
                    console.log(this.loginInfo);
                    // post("/videoconferencing/send-sms-code", qs.stringify({ phone:this.loginInfo.phone,prefix:this.loginInfo.candidatePhonePrefix})).then(res => {
                    post("/videoconferencing/send-sms-code", {phone:this.loginInfo.phone,prefix:this.loginInfo.candidatePhonePrefix}).then(res => {
                        if (res.code == 1) {
                            this.$message.success(res.data);
                            this.codeMsg=61;
                            this.codeDisabled=true;
                            this.countTime();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    this.$message.error(this.$t('common.pleaseEnter')+this.$t('interview.phone'));
                }
            },
            // 倒计时
            countTime() {
                this.codeMsg = Number(this.codeMsg)-1;
                // 等于0的时候不调用
                if (Number(this.codeMsg) === 0 ) {
                    this.codeMsg=this.$t('interview.getVerifyCode')
                    this.codeDisabled=false;
                    return
                } else {
                    // 递归每秒调用countTime方法，显示动态时间效果,
                    setTimeout(this.countTime, 1000)
                }
            },
            testing(){
                //阿里云检测设备信息
                this.aliWebrtc.getDevices().then((re) => {
                    if (re.videoDevices.length) {
                        this.hasVideo = false;
                    } else {
                        this.hasVideo = true;
                    }
                    if (re.audioDevices.length) {
                        this.hasAudio = false;
                    } else {
                        this.hasAudio = true;
                    }
                    if (this.hasAudio || this.hasVideo){
                        alert(this.$t('interview.noCamaraOrMic'))
                    }
                }).catch((error) => {
                    console.log(error.message)
                });
            },
            nameSet(name){
                return name.split('_')[1];
            },
            // 判断面试官是否已经进入频道
            checkPhone(value){
                post("/videoconferencing/getIsPhone",{phone:value}).then(res => {
                    if (res.code == 1) {
                        console.log(res.data);
                        return res.data
                    }
                });
            }
        },
        mounted(){
            let url =window.location.search;
            this.getInfo();
            this.testing();
        }
    }
</script>

<style scoped>
    .navbar {
        width: 100%;
        height: 68px;
    }

    .nav-img {
        text-align: left;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 68px;
    }

    .nav-menu {
        height: 68px;
    }

    .nav-my {
        height: 68px;
    }


    .content{
        width: 100%;
        height: calc( 100vh - 68px);
        background-color: #ececf4;
        padding: 50px 0;
        box-sizing: border-box;
    }
    .type-box{
        width: 80%;
        height: 500px;
        margin: 0 auto;
        /*position: absolute;*/
        /*left: 50%;*/
        /*top: 50px;*/
        /*margin-left: -40%;*/
        background-color: #fff;
        padding: 50px;
        position: relative;
    }
    .type-box .type-title {
        text-align: center;

    }
    .type{
        width: 350px;
        height: 250px;
        border: 1px solid #d5d5e6;
        position: absolute;
        padding: 20px;
    }
    .type-left{
        left: 15%;
        top: 140px;
    }
    .type-right{
        right: 15%;
        top: 140px;
    }
    .tt-content{
        text-align: center;
        margin-top: 80px;
    }
    .tt-content p{
        margin-bottom: 40px;
    }
    .login-box{
        width: 100%;
        height: 160px;
        position: relative;
    }
    .login-info{
        width: 400px;
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -200px;
    }
    .el-input{
        width: 300px;
    }
    .input-with-select{
        width: 300px;
    }
</style>
