<template>
    <div class="add-temp">
        <el-tree
        :data="authorityList"
        show-checkbox
        node-key="id"
        ref="roleTree"
        @check-change="handleCheckChange"
        :props="defaultProps">
        </el-tree>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
import { post, del, get, postJSON } from '@/api/http'

    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            return {
                rules: {
                    name: [{
                        required: true,
                        message: this.$t('common.pleaseEnter') + this.$t('setup.rolename') + '!',
                        trigger: 'blur'
                    }],
                },
                authorityList:[],
                checked:[],
                backCheck:[],
                defaultProps:{
                    children: 'children',
                    label: 'resourceName',
                }

            }
        },
        methods: {
            handleCheckChange(data, checked, indeterminate) {
                this.checked=[...this.$refs.roleTree.getCheckedKeys(),...this.$refs.roleTree.getHalfCheckedKeys()]
            },
            // 获取资源
            getPermission(){
                console.log(this.rowData);
                post("/enterpriseRoleController/setPermissions",{
                    id: this.rowData.id,
                }).then(res => {
                    if (res.code == 1) {
                        this.authorityList=res.data
                        if(res.data.length){
                            this.backCheck=this.findLeafNodes(res.data)
                            this.setCheckedKeys(this.backCheck)
                        }
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            // 查找子节点
            findLeafNodes(items=[]){
                return items.reduce((leafs,{id,choice,children})=>{
                    if(choice)leafs.push(id);
                    if(children){
                        // 半选去掉此节点
                        let child = this.findLeafNodes(children)
                        choice && child.length < children.length 
                        ? leafs.splice(leafs.indexOf(id), 1) : null
                        leafs.push(...this.findLeafNodes(children));
                    }
                    return leafs;
                },[]);
            },
            // findLeafNodes(items=[]){
            //     return items.reduce((leafs,{id,choice,children})=>{
            //         if(choice)leafs.push(id);
            //         if(children)
            //         leafs.push(...this.findLeafNodes(children));
            //         return leafs;
            //     },[]);
            // },
            // 默认选中
            setCheckedKeys(keyData) {
                this.$nextTick(function() {
                    this.$refs.roleTree.setCheckedKeys(keyData)
                })
            },
            // 提交
            submitForm() {
                this.rowData.enterpriseResourceId = this.checked.toString()
                this.$emit("add",{
                    enterpriseRoleId:this.rowData.id,
                    enterpriseResourceId:this.checked.toString(),
                })
            },
            clearAdd() {
                this.$emit("clear");
            },
        },
        mounted() {
            this.getPermission()
            this.$refs.roleTree.setCheckedKeys(['1579017212376170498'])
        }
    }
</script>

<style scoped>

</style>
