<template>
    <div class="test-home">
        <div>
            <TestStyleTwo v-if="infoData.id && infoData.style==2"></TestStyleTwo>
            <TestStyleOne v-else></TestStyleOne>
        </div>
    </div>
</template>

<script>
import Fill from './fill'
import '@/components/Meeting/aliyun-webrtc-sdk-1.12.1.js'
import 'mavon-editor/dist/css/index.css'
import { post, del, get, postJSON } from '@/api/http'
import Cookies from 'js-cookie'
import qs from 'qs'

import TestStyleOne from './index-v1.vue'
import TestStyleTwo from '../AudioAnswer-v2/index.vue'

export default {
    name: 'index',
    components:{
        TestStyleOne,
        TestStyleTwo,
        Fill
    },
    data() {
        return {
            aliWebrtc: new AliRtcEngine(),
            isInfo: true,
            infoDialog: false,
            infoData: {},
            form: {},
            formLabelWidth: '140px',
            interviewer: '',
            hasVideo: false,
            hasAudio: false,
            loginInfo: {},
            isShare: false,
            codeMsg: this.$t('test.getVerifyCode'),
            codeDisabled: false,
            loadingInfo: true,
            editorLanguage: Cookies.get('language'),
            fillDialog: false,
            fillData: {},
            messageTip: '',

            fullscreenLoading: false
        }
    },
    
    methods: {
        openFullScreen() {
            this.fullscreenLoading = true;
        },
        getInto() {
            this.infoDialog = true
            // post('/exam-examinee/getExamExamineeEntityDetails', {
            //   id: this.infoData.id,
            // }).then((res) => {
            //   if (res.code == 1) {
            //     this.fillData = res.data
            //     this.fillDialog = true
            //     this.infoData.screenRecording = res.data.screenRecording
            //   } else {
            //     this.$message.error(res.data)
            //   }
            // })
            // localStorage.setItem('info', JSON.stringify(this.infoData));
            // this.$router.push({name:'AnswerHome',params:{info:this.loginInfo}});
        },
        clearInfo() {
            this.infoDialog = false
            // this.messageTip = ''
        },
        //获取笔试信息
        getInfo() {
            let id = this.$route.query.id
            if (id) {
                get('/exam-answer/play/' + id).then((res) => {
                    this.loadingInfo = false
                    //   console.log(this.loadingInfo);
                    if (res.code == 1) {
                        this.isInfo = false
                        this.infoData = res.data
                        this.fullscreenLoading = false;
                        console.log(this.infoData.style)
                        if (res.data.candidatePhonePrefix == '86') {
                            this.$i18n.locale = 'zh-Cn'
                        } else {
                            this.$i18n.locale = 'en'
                        }
                        this.$store.dispatch('app/setLanguage', this.$i18n.locale)
                    } else {
                        this.isInfo = true
                        // this.$message.error(res.data)
                    }
                })
            } else {
                this.isInfo = true
                this.$message.error(this.$t('test.invalidAndContect'))
            }
        },
        //提交验证码，通过跳转笔试页面
        submitCode() {
            let url = ''
            let obj = {
                smsCode: this.form.code,
            }
            if (this.infoData.notificationMode == 1) {
                url = '/exam-answer/check-mail-code'
                obj.mail = this.infoData.candidateEmail
            } else {
                url = '/exam-answer/check-sms-code'
                obj.phone = this.infoData.candidatePhone
            }
            this.$refs.infoForm.validate((valid) => {
                if (valid) {
                    if (this.infoData.candidatePhone) {
                        // 验证
                        if (
                            (this.isShare && this.infoData.screenRecording == 1) ||
                            (!this.isShare && this.infoData.screenRecording == 2)) {
                            if (this.infoData.isVerification) {
                                post(url, obj).then((res) => {
                                    if (res.code == 1) {
                                        post('/exam-examinee/getExamExamineeEntityDetails', {
                                            id: this.infoData.id,
                                        }).then((resq) => {
                                            if (resq.code == 1) {
                                                this.fillData = resq.data
                                                this.infoData.screenRecording = resq.data.screenRecording
                                                this.fillDialog = true
                                                localStorage.setItem(
                                                    'info',
                                                    JSON.stringify(this.infoData)
                                                )
                                            } else {
                                                this.$message.error(resq.data)
                                            }
                                        })
                                        //this.$message.success(this.$t('test.joinSuccess'))
                                    } else {
                                        if (res.data == '验证码过期') {
                                            this.$message.error(this.$t('test.vCodeInvalid'))
                                        } else {
                                            this.$message.error(res.data)
                                        }
                                    }
                                })
                                } else {
                                // 不验证
                                post('/exam-examinee/getExamExamineeEntityDetails', {
                                    id: this.infoData.id,
                                }).then((resq) => {
                                    if (resq.code == 1) {
                                        this.fillData = resq.data
                                        this.infoData.screenRecording = resq.data.screenRecording
                                        this.fillDialog = true
                                        localStorage.setItem(
                                            'info',
                                            JSON.stringify(this.infoData)
                                        )
                                    } else {
                                        this.$message.error(resq.data)
                                    }
                                })
                            }
                        } else {
                            this.$message({
                                message: this.$t('test.agreeToRecord'),
                                type: 'warning',
                            })
                        }
                    } else {
                        this.$message.error(
                            this.$t('common.pleaseEnter') + this.$t('test.phone')
                        )
                    }
                } else {
                    return false
                }
            })
        },
        //打开验证码信息
        openCode(type) {
            if (this.infoData.candidatePhone) {
                let url = ''
                // type == 1
                // ?url = '/exam-answer/send-mail-code'
                // :url = '/exam-answer/send-sms-code'

                let obj = {}
                if (type == 1) {
                    url = '/exam-answer/send-mail-code'
                    obj.mail = this.infoData.candidateEmail
                } else {
                    url = '/exam-answer/send-sms-code'
                    obj.phone = this.infoData.candidatePhone
                    obj.prefix = this.infoData.candidatePhonePrefix
                }
                post(url, obj).then((res) => {
                    if (res.code == 1) {
                        if (res.data.substr(0, 6) == '验证码已发送' && this.infoData.notificationMode == 2) {
                            this.messageTip = 'sms'
                        } else if (res.data.substr(0, 6) == '验证码已发送' && this.infoData.notificationMode == 1) {
                            this.messageTip = 'email'
                        } else {
                            this.$message.success(res.data)
                        }
                        this.codeMsg = 61
                        this.codeDisabled = true
                        this.countTime()
                    } else {
                        this.$message.error(res.data)
                    }
                })
            } else {
                this.$message.error(
                    this.$t('common.pleaseEnter') + this.$t('test.phone')
                )
            }
        },
        // 倒计时
        countTime() {
            this.codeMsg = Number(this.codeMsg) - 1
            // 等于0的时候不调用
            if (Number(this.codeMsg) === 0) {
                this.codeMsg = this.$t('test.getVerifyCode')
                this.codeDisabled = false
                return
            } else {
                // 递归每秒调用countTime方法，显示动态时间效果,
                setTimeout(this.countTime, 1000)
            }
        },
        //阿里云检测设备信息
        testing() {
            //阿里云检测设备信息
            this.aliWebrtc
                .getDevices()
                .then((re) => {
                    if (re.videoDevices.length) {
                        this.hasVideo = false
                    } else {
                        this.hasVideo = true
                    }
                    if (re.audioDevices.length) {
                        this.hasAudio = false
                    } else {
                        this.hasAudio = true
                    }
                    if (this.hasAudio || this.hasVideo) {
                        alert(this.$t('test.noCamaraOrMic'))
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                })
        },
        nameSet(name) {
            return name.split('_')[1]
        },

        //测试用户填写
        fillClear() {
            this.fillDialog = false
        },
        async skipClick() {
            // let obj = {
            //   phone: this.infoData.candidatePhone,
            //   smsCode: this.form.code,
            // }
            // post('/exam-answer/check-sms-code', obj).then((res) => {
            //   if (res.code == 1) {
            //     localStorage.setItem('info', JSON.stringify(this.infoData))

            //     // this.$router.push({name:'Home',params:{info:this.infoData}})
            //     this.$message.success(this.$t('test.joinSuccess'))
            //   } else {
            //     if (res.data == '验证码过期') {
            //       this.$message.error(this.$t('test.vCodeInvalid'))
            //     } else {
            //       this.$message.error(res.data)
            //     }
            //   }
            // })
            await this.updateTimeStamp()

            localStorage.setItem('info', JSON.stringify(this.infoData))
            this.$router.push({
                name: 'AnswerHome',
                params: { info: this.loginInfo },
                query: {
                    id: this.$route.query.id,
                },
            })
            console.log(this.$route.query.id)
            // this.$message.success(this.$t('test.joinSuccess'))
        },
        async submitForm(item) {
            //console.log(item)
            await this.updateTimeStamp()

            var objs = { id: this.infoData.id, ...item }
            postJSON('/exam-examinee/updateExamExamineeEntity', objs).then((res) => {
                if (res.code == 1) {
                    localStorage.setItem('info', JSON.stringify(this.infoData))
                    this.fillDialog = false
                    this.$router.push({
                        name: 'AnswerHome',
                        params: { info: this.loginInfo },
                        id: this.$route.query.id,
                    })
                    // this.$message.success(this.$t('test.joinSuccess'))
                } else {
                    this.$message.error(res.data)
                }
            })
        },
        // 记录考试开始时间戳
        updateTimeStamp(){
            return new Promise((resolve, reject)=>{
                post('/exam-examinee/updateExamineeTimeStartExam', {
                    id:this.infoData.id
                }).then((res) => {
                    resolve(res.code)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mounted() {
        this.openFullScreen()
        this.getInfo()
        // this.testing();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name == 'AnswerHome') {
                location.reload()
            }
        })
    },
}
</script>

<style scoped>
.navbar {
    width: 100%;
    height: 68px;
}

.nav-img {
    text-align: left;
    padding: 0 15px;
    display: flex;
    align-items: center;
    height: 68px;
}

.nav-menu {
    height: 68px;
}

.nav-my {
    height: 68px;
}

.content {
    width: 100%;
    height: calc(100vh - 68px);
    background-color: #ececf4;
    padding: 50px 0;
    box-sizing: border-box;
}

.type-box {
    width: 80%;
    height: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 50px;
    position: relative;
}

.type-box .type-title {
    text-align: center;
}

.type-box .type-title p {
    font-size: 14px;
    margin: 15px;
    color: #78788e;
}

.type {
    width: 350px;
    height: 250px;
    border: 1px solid #d5d5e6;
    position: absolute;
    padding: 20px;
}

.type-left {
    left: 15%;
    top: 140px;
}

.type-right {
    right: 15%;
    top: 140px;
}

.tt-content {
    text-align: center;
    margin-top: 80px;
}

.tt-content p {
    margin-bottom: 40px;
}

.login-box {
    width: 100%;
    height: 330px;
    position: relative;
}

.login-info {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -200px;
}

.el-input {
    width: 300px;
}

.input-with-select {
    width: 300px;
}

.remark {
    width: 800px;
    height: 450px;
    margin: 40px auto;
    overflow: scroll;
}

.get-into {
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 100px;
    width: 160px;
    margin-left: -80px;
}

/* checkbox换行 */
::v-deep .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    /* word-wrap: break-word; */
    overflow: hidden;
    line-height: 20px;
}

.tip span {
    background: #E1F3D8;
    padding: 0 15px;
    color: #67C23A;
    display: inline-block;
    margin-left: 15px;
    line-height: 32px;
}
</style>
