<template>
  <div class="add-temp">
    <el-form
      ref="elForm"
      :model="rowData"
      :rules="rules"
      size="medium"
      label-width="160px"
    >
      <el-form-item :label="$t('setup.paperName')" prop="title">
        <el-input
          v-model="rowData.title"
          :placeholder="$t('common.pleaseEnter') + $t('setup.paperName')"
          clearable
          :style="{ width: '70%' }"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('setup.paperExplain')" prop="remark">
        <el-input
          type="textarea"
          v-model="rowData.remark"
          :placeholder="$t('common.pleaseEnter') + $t('setup.paperExplain')"
          clearable
          :style="{ width: '70%' }"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="boontbtn">
      <el-button
        type="primary"
        size="small"
        style="margin: 0 30px"
        @click="submitForm()"
      >
        {{ $t('common.ok') }}
      </el-button>
      <el-button type="info" size="small" @click="clearAdd">{{
        $t('common.cancel')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'add',
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      },
    },
    dialogType: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      rules: {
        title: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('setup.paperName') + '!',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('common.pleaseComplete'))
          return false
        } else {
          let subObj = {
            ...this.rowData,
            // enterpriseId:1
          }
          this.$emit('add', subObj)
        }
      })
    },
    clearAdd() {
      this.formData = {}
      this.$emit('clear')
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
