<template>
  <div class="user">
    <div class="three">
      <el-button type="primary" @click="addForm()" size="mini">{{
      $t('question.addQuestion')
      }}</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" :header-cell-style="{ background: '#e9ecf7' }" style="width: 100%">
        <el-table-column prop="title" :label="$t('question.questionName')">
        </el-table-column>
        <el-table-column prop="title" :label="$t('question.category')" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.labelName.toString() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" :label="$t('question.qtype')" width="180">
          <template slot-scope="scope">
            <span>{{
            scope.row.type == '1'
            ? $t('question.choiceq')
            : scope.row.type == '2'
            ? $t('question.fillInq')
            : scope.row.type == '3'
            ? $t('question.QandA')
            : $t('question.programq')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="answerTime" :label="$t('question.time')" width="80">
        </el-table-column>
        <el-table-column prop="score" :label="$t('question.grade')" width="80">
        </el-table-column>
        <el-table-column fixed="right" width="100" :label="$t('question.operation')">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start">
              <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="operation('del', scope.row)">
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--新增-->
    <div v-if="addDialog" class="temp-box">
      <el-dialog append-to-body center :title="$t('question.addQuestion')" :visible.sync="addDialog"
        :before-close="clearAdd" width="90%" custom-class="addTemp">
        <AddTemp ref="addRef" :rowData="tableData" :dialogType="dialogType" :paperId="paperId" @getList="getList">
        </AddTemp>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import AddTemp from './add'
export default {
  name: 'position',
  components: { AddTemp },
  data() {
    return {
      tableData: [],
      addDialog: false,
      rowData: {},
      dialogType: 'add',
      paperId: '',
    }
  },
  methods: {
    getList() {
      post('/paper-question/list', {
        paperId: this.paperId,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    addForm() {
      this.rowData = {}
      this.dialogType = 'add'
      this.addDialog = true
    },
    clearAdd() {
      this.addDialog = false
    },
    saveAdd(data) {
      post('/paper/insert', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.clearAdd()
          this.getList()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    operation(type, row) {
      if (type == 'del') {
        this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning',
        })
          .then(() => {
            del('/paper-question/delete/' + row.paperQuestionId).then((res) => {
              if (res.code == 1) {
                this.$message.success(this.$t('common.success'))
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => { })
      }
    },
    setData(id) {
      this.paperId = id
      this.getList()
    },
  },
  mounted() {
    // this.setData('1467671103208640514')
  },
}
</script>

<style scoped>

</style>
<style>
.addTemp .el-dialog__body {
  padding: 0px;
}
</style>
