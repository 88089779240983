<template>
  <div class="interview" style="position: relative">
    <div class="header">
      <div class="navbar">
        <el-row>
          <el-col :span="4">
            <div class="nav-img">
              <img src="@/assets/u6376.png" alt="" style="height: 50px" />
            </div>
          </el-col>
          <el-col :span="16">
            <div
              style="
                text-align: center;
                line-height: 68px;
                color: #fff;
                font-size: 18px;
              "
            >
              {{ $t('interview.chooseInterviewTime') }}
            </div>
          </el-col>
          <el-col :span="4">
            <div style="float: right; margin: 10px 20px">
              <el-button @click="submit">{{ $t('common.submit') }}</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="interview-time">
      <div class="dis-flex justify-center">
        <div class="week-time justify-center dis-flex items-center">
          <i class="el-icon-arrow-left" @click="prevWeek"></i>
          {{ week }} {{ year }}
          <i class="el-icon-arrow-right" @click="nextWeek"></i>
        </div>
      </div>
      <el-table
        :data="newTimeLists"
        height="700"
        style="width: 100%"
        ref="multipleTable"
        class="table-box"
        border
      >
        <el-table-column width="80" align="center">
          <template slot="header">
            <i class="el-icon-caret-top fs-24 icon-caret" @click="scollTop"></i>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header" slot-scope="scope">
            <span
              >{{ timeArr[0].month + '.' + timeArr[0].day
              }}{{ $t('common.mon') }}</span
            >
          </template>
          <template slot-scope="scope">
            <!-- {{ scope.row.mon.day }} -->

            <div
              @click="weekClick(scope.row, scope.$index, 'mon')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.mon.flag == 2,
                'mon-ceil-bgSel': scope.row.mon.flag == 3,
              }"
            >
              <span v-if="scope.row.mon.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.mon.flag == 3">{{
                $t('common.selected')
              }}</span>

              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[1].month + '.' + timeArr[1].day
              }}{{ $t('common.tue') }}</span
            >
          </template>
          <template slot-scope="scope">
            <div
              @click="weekClick(scope.row, scope.$index, 'tue')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.tue.flag == 2,
                'mon-ceil-bgSel': scope.row.tue.flag == 3,
              }"
            >
              <span v-if="scope.row.tue.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.tue.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[2].month + '.' + timeArr[2].day
              }}{{ $t('common.wed') }}</span
            >
          </template>
          <template slot-scope="scope">
            <div
              @click="weekClick(scope.row, scope.$index, 'wed')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.wed.flag == 2,
                'mon-ceil-bgSel': scope.row.wed.flag == 3,
              }"
            >
              <span v-if="scope.row.wed.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.wed.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[3].month + '.' + timeArr[3].day
              }}{{ $t('common.thu') }}</span
            >
          </template>
          <template slot-scope="scope">
            <div
              @click="weekClick(scope.row, scope.$index, 'thur')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.thur.flag == 2,
                'mon-ceil-bgSel': scope.row.thur.flag == 3,
              }"
            >
              <span v-if="scope.row.thur.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.thur.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[4].month + '.' + timeArr[4].day
              }}{{ $t('common.fri') }}</span
            >
          </template>
          <template slot-scope="scope">
            <div
              @click="weekClick(scope.row, scope.$index, 'fri')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.fri.flag == 2,
                'mon-ceil-bgSel': scope.row.fri.flag == 3,
              }"
            >
              <span v-if="scope.row.fri.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.fri.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[5].month + '.' + timeArr[5].day
              }}{{ $t('common.sat') }}</span
            >
          </template>
          <template slot-scope="scope">
            <div
              @click="weekClick(scope.row, scope.$index, 'sat')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.sat.flag == 2,
                'mon-ceil-bgSel': scope.row.sat.flag == 3,
              }"
            >
              <span v-if="scope.row.sat.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.sat.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span
              >{{ timeArr[6].month + '.' + timeArr[6].day
              }}{{ $t('common.sun') }}</span
            >
          </template>
          <template slot-scope="scope">
            <!-- {{ scope.row.sun.day }} -->
            <div
              @click="weekClick(scope.row, scope.$index, 'sun')"
              class="mon-ceil"
              :class="{
                'mon-ceil-bg': scope.row.sun.flag == 2,
                'mon-ceil-bgSel': scope.row.sun.flag == 3,
              }"
            >
              <span v-if="scope.row.sun.flag == 2">{{
                $t('common.optional')
              }}</span>
              <span v-else-if="scope.row.sun.flag == 3">{{
                $t('common.selected')
              }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 提交成功 
      -->
    <div v-if="submitDialog">
      <el-dialog
        append-to-body
        :title="$t('common.tips')"
        :visible.sync="submitDialog"
        width="50%"
      >
        <el-row type="flex" justify="space-around">
          <el-col :span="1"></el-col>
          <el-col :span="3">
            <i class="el-icon-warning-outline share-tip-icon"></i>
          </el-col>
          <el-col :span="19">
            <div class="share-tip-word">
              <span> {{ selectedTimeTxt }} </span>
            </div>
          </el-col>
          <el-col :span="1"></el-col>
        </el-row>
        <div class="boontbtn">
          <el-button
            type="primary"
            size="small"
            style="margin: 0 30px"
            @click="submitOk"
          >
            {{ $t('common.ok') }}
          </el-button>
          <el-button size="small" @click="submitDialog = false">
            {{ $t('common.cancel') }}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import '../../assets/css/style.css'
import { post } from '@/api/http'
export default {
  name: 'InterviewTime',
  data() {
    return {
      currentFirstDate: '',
      year: '', //年
      week: '', //周 如03.14-03.20
      timeArr: [], // 一周的数据
      timeList: [
        {
          date: '00:00',
          dateEnd: '00:30',
        },
        {
          date: '00:30',
          dateEnd: '01:00',
        },
        {
          date: '01:00',
          dateEnd: '01:30',
        },
        {
          date: '01:30',
          dateEnd: '02:00',
        },
        {
          date: '02:00',
          dateEnd: '02:30',
        },
        {
          date: '02:30',
          dateEnd: '03:00',
        },
        {
          date: '03:00',
          dateEnd: '03:30',
        },
        {
          date: '03:30',
          dateEnd: '04:00',
        },
        {
          date: '04:00',
          dateEnd: '04:30',
        },
        {
          date: '04:30',
          dateEnd: '05:00',
        },
        {
          date: '05:00',
          dateEnd: '05:30',
        },
        {
          date: '05:30',
          dateEnd: '06:00',
        },
        {
          date: '06:00',
          dateEnd: '06:30',
        },
        {
          date: '06:30',
          dateEnd: '07:00',
        },
        {
          date: '07:00',
          dateEnd: '07:30',
        },
        {
          date: '07:30',
          dateEnd: '08:00',
        },
        {
          date: '08:00',
          dateEnd: '08:30',
        },
        {
          date: '08:30',
          dateEnd: '09:00',
        },
        {
          date: '09:00',
          dateEnd: '09:30',
        },
        {
          date: '09:30',
          dateEnd: '10:00',
        },
        {
          date: '10:00',
          dateEnd: '10:30',
        },
        {
          date: '10:30',
          dateEnd: '11:00',
        },
        {
          date: '11:00',
          dateEnd: '11:30',
        },
        {
          date: '11:30',
          dateEnd: '12:00',
        },
        {
          date: '12:00',
          dateEnd: '12:30',
        },
        {
          date: '12:30',
          dateEnd: '13:00',
        },
        {
          date: '13:00',
          dateEnd: '13:30',
        },
        {
          date: '13:30',
          dateEnd: '14:00',
        },
        {
          date: '14:00',
          dateEnd: '14:30',
        },
        {
          date: '14:30',
          dateEnd: '15:00',
        },
        {
          date: '15:00',
          dateEnd: '15:30',
        },
        {
          date: '15:30',
          dateEnd: '16:00',
        },
        {
          date: '16:00',
          dateEnd: '16:30',
        },
        {
          date: '16:30',
          dateEnd: '17:00',
        },
        {
          date: '17:00',
          dateEnd: '17:30',
        },
        {
          date: '17:30',
          dateEnd: '18:00',
        },
        {
          date: '18:00',
          dateEnd: '18:30',
        },
        {
          date: '18:30',
          dateEnd: '19:00',
        },
        {
          date: '19:00',
          dateEnd: '19:30',
        },
        {
          date: '19:30',
          dateEnd: '20:00',
        },
        {
          date: '20:00',
          dateEnd: '20:30',
        },
        {
          date: '20:30',
          dateEnd: '21:00',
        },
        {
          date: '21:00',
          dateEnd: '21:30',
        },
        {
          date: '21:30',
          dateEnd: '22:00',
        },
        {
          date: '22:00',
          dateEnd: '22:30',
        },
        {
          date: '22:30',
          dateEnd: '23:00',
        },
        {
          date: '23:00',
          dateEnd: '23:30',
        },
        {
          date: '23:30',
          dateEnd: '23:59',
        },
      ], //每半小时的时间段
      newTimeList: [], //判断timeList时间段与当前时间段是否<=,是则存储
      newTimeLists: [],
      selectedTimeDate: '', //提交 日期
      selectedTimeTxt: '',
      submitDialog: false,
      scheduleId: '',
      scheduleIdArr: [], //存储点击当前的scheduleId
    }
  },

  components: {},

  created() {
    this.setDate(new Date())
    this.timeCycle()
    //this.setTime()
    // setInterval(() => {
    //   this.setTime()
    // }, 60000)
  },

  methods: {
    scollTop() {
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    prevWeek() {
      this.timeArr = []
      this.setDate(this.addDate(this.currentFirstDate, -7))
      this.timeCycle()
    },
    nextWeek() {
      this.timeArr = []
      this.setDate(this.addDate(this.currentFirstDate, 7))
      this.timeCycle()
    },
    submit() {
      //console.log(this.selectedTimeDate)
      if (this.selectedTimeDate == '' || this.scheduleId == '') {
        return this.$message.error('请选择面试时间')
      }
      this.submitDialog = true
    },
    submitOk() {
      post('/inviteCandidates/selectedTime', {
        id: this.$route.query.id,
        selectedTime: this.selectedTimeDate,
        scheduleId: this.scheduleId,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.submitDialog = false
          this.$router.push(`/login`)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    weekClick(row, index, item) {
      let newTimeList = []
      newTimeList = JSON.parse(JSON.stringify(this.newTimeList))
      //console.log(row)
      row[item].timeFlage = !row[item].timeFlage //单选 true为3 false为2
      //console.log(row[item].timeFlage)
      if (row[item].isClick == 1) {
        if (row[item].timeFlage) {
          row[item].flag = 3
        } else {
          row[item].flag = 2
        }
        //this.newTimeLists = []
        this.$nextTick(() => {
          this.selectedTime(newTimeList, index, item, row)
        })
      }
    },
    selectedTime(list, index, item, row) {
      this.scheduleId = row[item].id
      //this.scheduleIdArr.push(row[item].id)
      let arr = []
      let weekArr = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun']
      for (let i = 0; i < list.length; i++) {
        for (let r = 0; r < weekArr.length; r++) {
          if (list[i][weekArr[r]].flag == 3) {
            list[i][weekArr[r]].flag = 2
          }
          list[i][weekArr[r]].timeFlage = false
        }
        arr.push(list[i])
      }

      this.newTimeLists = [...arr]

      if (row[item].timeFlage) {
        //console.log(this.newTimeLists)

        for (let i = 0; i < this.newTimeLists.length; i++) {
          for (let r = 0; r < weekArr.length; r++) {
            //row[item].flag = 3

            if (this.newTimeLists[i][weekArr[r]].flag == 3) {
              this.newTimeLists[i][weekArr[r]].flag = 2
              row[weekArr[r]].flag = 2
            }
            if (row[weekArr[r]].flag == 3) {
              row[weekArr[r]].flag = 2
            }
            //console.log(row[weekArr[r]])
            this.newTimeLists[i][weekArr[r]].timeFlage = false
            row[weekArr[r]].timeFlage = false
          }
        }
        row[item].flag = 3
        row[item].timeFlage = true

        this.selectedTimeDate =
          row[item].year +
          '-' +
          row[item].month +
          '-' +
          row[item].day +
          ' ' +
          row.date +
          '~' +
          row.dateEnd
        this.selectedTimeTxt =
          this.$t('interview.choose') +
          row[item].month +
          '.' +
          row[item].day +
          '(' +
          row[item].week +
          ')' +
          ' ' +
          row.date +
          '~' +
          row.dateEnd +
          ' ' +
          this.$t('interview.forInterview')
        this.$nextTick(() => {
          this.$set(this.newTimeLists, index, row)
        })
      } else {
        //row[item].flag = 2
        for (let i = 0; i < this.newTimeLists.length; i++) {
          for (let r = 0; r < weekArr.length; r++) {
            if (this.newTimeLists[i][weekArr[r]].flag == 3) {
              this.newTimeLists[i][weekArr[r]].flag = 2
              row[weekArr[r]].flag = 2
            }
            //row[item].timeFlage = false
            this.newTimeList[i][weekArr[r]].timeFlage = false
            this.newTimeLists[i][weekArr[r]].timeFlage = false
            //newTimeList[i][weekArr[r]].flag = 1
            row[weekArr[r]].timeFlage = false
          }
        }
        row[item].flag = 2
        row[item].timeFlage = false
        this.$nextTick(() => {
          //console.log(this.scheduleIdArr, 2)
          post('/schedule/candidateCancel', {
            scheduleId: this.scheduleId,
          }).then((res) => {
            if (res.code == 1) {
            } else {
              this.$message.error(res.data)
            }
          })
        })
        this.selectedTimeDate = ''
        this.scheduleId = ''
        this.$nextTick(() => {
          this.$set(this.newTimeLists, index, row)
        })
      }
      this.$nextTick(() => {
        this.$set(this.newTimeLists, index, row)
      })
    },
    setTime() {
      let date = new Date()
      let year = date.getFullYear()
      let month =
        date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let minute =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      //console.log(this.timeList)
      let timeList = this.timeList
      let getetTime = year + '-' + month + '-' + day + ' '
      let oldGetTime = '' //每半小时的日期
      let newGetTime = new Date(getetTime + hour + ':' + minute).getTime() //当前日期
      //console.log(newGetTime)
      let newTimeList = []
      for (let i = 0; i < timeList.length; i++) {
        //每半小时插入
        //oldGetTime = new Date(getetTime + timeList[i].date).getTime() //每半小时的时间戳
        // if (oldGetTime <= newGetTime) {
        //   //console.log(oldGetTime <= newGetTime, timeList[i])
        //   newTimeList.push(timeList[i])
        // }
        newTimeList.push(timeList[i])
      }
      this.newTimeList = newTimeList
      //console.log(this.newTimeList)
      //this.getList(newTimeList)
      this.getList(newTimeList).then((res) => {
        this.newTimeLists = [...res]
      })
    },

    getList(list) {
      //查询查询面试官日程安排
      return new Promise((resolve, reject) => {
        let weekArr = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun']
        post('/schedule/getListByEnterpriseId', {
          enterpriseId: this.$route.query.enterpriseId,
          id: this.$route.query.id,
        }).then((res) => {
          if (res.code == 1) {
            this.selectedArr = res.data
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < this.selectedArr.length; j++) {
                //list[i].mon
                //获取返回行 true
                for (let r = 0; r < weekArr.length; r++) {
                  if (
                    this.selectedArr[j].startTime ==
                      list[i][weekArr[r]].year +
                        '-' +
                        list[i][weekArr[r]].month +
                        '-' +
                        list[i][weekArr[r]].day +
                        ' ' +
                        list[i].date +
                        ':' +
                        '00' &&
                    this.selectedArr[j].isClick == 1
                  ) {
                    if (this.selectedArr[j].isSelect == 2) {
                      list[i][weekArr[r]].flag = 3
                      this.scheduleId = this.selectedArr[j].id
                      //console.log(list[i].date)
                      this.selectedTimeDate =
                        list[i][weekArr[r]].year +
                        '-' +
                        list[i][weekArr[r]].month +
                        '-' +
                        list[i][weekArr[r]].day +
                        ' ' +
                        list[i].date +
                        '~' +
                        list[i].dateEnd
                      //console.log(this.selectedTimeDate)
                      this.selectedTimeTxt =
                        this.$t('interview.choose') +
                        list[i][weekArr[r]].month +
                        '.' +
                        list[i][weekArr[r]].day +
                        '(' +
                        list[i][weekArr[r]].week +
                        ')' +
                        ' ' +
                        list[i].date +
                        '~' +
                        list[i].dateEnd +
                        ' ' +
                        this.$t('interview.forInterview')
                      //list[i][weekArr[r]].flag = 1
                      //this.scheduleIdArr.push(this.selectedArr[j].id)
                    } else {
                      list[i][weekArr[r]].flag = 2
                    }
                    list[i][weekArr[r]].id = this.selectedArr[j].id
                    list[i][weekArr[r]].isClick = this.selectedArr[j].isClick
                  } else {
                    //list[i][weekArr[r]].isClick = 2
                  }
                }
              }
              this.$set(this.newTimeList, i, list[i])
              resolve(this.newTimeList)
            }
          } else {
            this.$message.error(res.data)
          }
          //console.log(this.selectedArr)
        })
      })
    },
    timeCycle(date) {
      //循环添加相应数据
      let timeList = this.timeList
      let timeArr = this.timeArr
      //console.log(timeArr)

      this.monDay = this.timeArr[0].month + '.' + this.timeArr[0].day

      this.week =
        timeArr[0].month +
        '.' +
        timeArr[0].day +
        '-' +
        timeArr[6].month +
        '.' +
        timeArr[6].day

      //循环添加相应数据 数据跟后台协商
      for (let i = 0; i < timeList.length; i++) {
        timeList[i].mon = {
          ...timeArr[0],
        }
        timeList[i].tue = {
          ...timeArr[1],
        }
        timeList[i].wed = {
          ...timeArr[2],
        }
        timeList[i].thur = {
          ...timeArr[3],
        }
        timeList[i].fri = {
          ...timeArr[4],
        }
        timeList[i].sat = {
          ...timeArr[5],
        }
        timeList[i].sun = {
          ...timeArr[6],
        }
      }
      //console.log(timeList)
      this.setTime()
    },
    //
    addDate(date, n) {
      date.setDate(date.getDate() + n)
      // console.log(date.getDate(), n)
      return date
    },
    //获取年月日时分
    setCalendar(date) {
      let year = ''
      year = this.year = date.getFullYear()
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let minute =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      let week = date.getDay() - 1
      let str = ''
      if (week == 0) {
        str = '周一'
      } else if (week == 1) {
        str = '周二'
      } else if (week == 2) {
        str = '周三'
      } else if (week == 3) {
        str = '周四'
      } else if (week == 4) {
        str = '周五'
      } else if (week == 5) {
        str = '周六'
      } else {
        str = '周日'
      }
      //console.log(str)
      return this.timeArr.push({
        year: year,
        month: month,
        day: day,
        week: str,
        flag: 1,
        timeFlage: false,
      })
    },
    setDate(date) {
      //设置周
      var week = date.getDay() - 1
      date = this.addDate(date, week * -1)
      // console.log(date)
      this.currentFirstDate = new Date(date)

      for (let i = 0; i < 7; i++) {
        //从星期一开始
        if (i == 0) {
          //date
          this.setCalendar(date)
        } else {
          this.setCalendar(this.addDate(date, 1))
        }
        //从星期日开始
        // if (i == 0) {
        //   //date
        //   this.setCalendar(this.addDate(date, -1))
        // } else {
        //   this.setCalendar(this.addDate(date, 1))
        // }
      }
    },
  },
}
</script>
<style scoped>
.interview-time {
  width: 1200px;
  margin: 0 auto;
}
.week-time {
  width: 100%;
  height: 48px;
  font-size: 22px;
  color: #fff;
  background: rgb(144, 166, 253);
}
.week-time i {
  margin: 0 10px;
  cursor: pointer;
}
.time-left {
  width: 100px;
  height: 600px;
  overflow-y: scroll;
}
.time-left ul {
  width: 100%;
  overflow-y: hidden;
}

.time-left::-webkit-scrollbar {
  width: 0 !important;
}
.time-left li {
  width: 100%;
  height: 48px;
  line-height: 48px;
}
.caret-time {
  position: absolute;
  margin-top: 0px;
  width: 100px;
  height: 30px;
  text-align: center;
  background: #fff;
}
h2 {
  font-size: 26px;
  padding: 15px 0;
}
.fs-24 {
  font-size: 24px;
}
.icon-caret {
  cursor: pointer;
  color: rgb(144, 166, 253);
}
.relative {
  position: relative;
}
.share-tip-icon {
  font-size: 48px;
  color: #ff2121;
}
.share-tip-word span {
  font-size: 16px;
  line-height: 48px;
}
.el-table {
  margin-bottom: 5px;
}

/* .el-table .el-table__cell {
  padding: 0;
} */
.mon-ceil {
  height: 48px;
  line-height: 48px;
}
.el-table .cell {
  padding: 0;
}
.mon-ceil-bg {
  background: rgb(144, 173, 253);
  color: #fff;
}
.mon-ceil-bgSel {
  background: rgb(252, 147, 147);
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  height: 48px;
  line-height: 48px;
}
.el-table__row .is-center:first-child {
  border-bottom: 0;
}
.el-table__row .is-center:first-child .cell {
  margin-top: -30px !important;
}
</style>
<style>
.table-box .el-table__cell {
  padding: 0;
}
.table-box .cell {
  padding: 0;
}
</style>
