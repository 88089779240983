<template>
    <div class="setting">
        <div class="left-card">
            <h4>{{$t('menu.setup')}}</h4>
            <el-menu
                :default-active="navMenu"
                class="el-menu-vertical-demo"
                @select="handleSelect">
                <el-menu-item :index="item.index" v-for="(item,ind) in permitList" :key="ind">
                    <span slot="title">{{item.title}}</span>
                </el-menu-item>
                <!-- <el-menu-item index="info">
                    <span slot="title">{{$t('setup.enterInfo')}}</span>
                </el-menu-item>
                <el-menu-item index="invoice">
                    <span slot="title">{{$t('setup.invoiceTit')}}</span>
                </el-menu-item>
                <el-menu-item index="client">
                    <span slot="title">{{$t('setup.clientManage')}}</span>
                </el-menu-item>
                <el-menu-item index="user">
                    <span slot="title">{{$t('setup.userSet')}}</span>
                </el-menu-item>
                <el-menu-item index="role">
                    <span slot="title">{{$t('setup.roleSet')}}</span>
                </el-menu-item>
                <el-menu-item index="position">
                    <span slot="title">{{$t('setup.positionSet')}}</span>
                </el-menu-item>
                <el-menu-item index="source">
                    <span slot="title">{{$t('setup.paperSource')}}</span>
                </el-menu-item>
                <el-menu-item index="test">
                    <span slot="title">{{$t('setup.paperSet')}}</span>
                </el-menu-item>
                <el-menu-item index="log">
                    <span slot="title">{{$t('setup.oprationLog')}}</span>
                </el-menu-item> -->
            </el-menu>
        </div>
        <div class="right-card">
          <Invoice v-if="navMenu=='invoice'"></Invoice>
          <Client v-if="navMenu=='client'"></Client>
          <Role v-if="navMenu=='role'"></Role>
          <Info v-if="navMenu=='info'"></Info>
          <User v-if="navMenu=='user'"></User>
          <Position v-if="navMenu=='position'"></Position>
          <Source v-if="navMenu=='source'"></Source>
          <Test v-if="navMenu=='test'"></Test>
        </div>
        <div style="clear: both"></div>
    </div>
</template>

<script>
    import Info from './info/index'
    import Position from './Position/index'
    import User from './User/index'
    import Role from './Role/index'
    import Invoice from './Invoice/index'
    import Client from './Client/index'
    import Source from './Source/index'
    import Test from './Test/index'
    export default {
        name: "setting",
        components: {
            Info,Position,User,Role,Invoice,Test,Client,Source
        },
        data() {
            return {
                navMenu:'',
                searchlist:{},
                value: '',
                sidebarList:[
                    {index:'info',title:this.$t('setup.enterInfo')},
                    {index:'invoice',title:this.$t('setup.invoiceTit')},
                    {index:'client',title:this.$t('setup.clientManage')},
                    {index:'user',title:this.$t('setup.userSet')},
                    {index:'role',title:this.$t('setup.roleSet')},
                    {index:'position',title:this.$t('setup.positionSet')},
                    {index:'source',title:this.$t('setup.paperSource')},
                    {index:'test',title:this.$t('setup.paperSet')},
                    {index:'log',title:this.$t('setup.oprationLog')},
                ],
                navList:[],
                permitList:[],
                sideList:[],
            }
        },
        methods:{
            handleSelect(key, keyPath) {
                this.navMenu = key;
                localStorage.setItem('navMenu', key);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            // 设置列表项
            getSetupList(){
                this.navList = JSON.parse(window.localStorage.getItem('navList'))
                if(this.navList){
                    let setupNode = this.navList.find(x => x.resourceUrl == '/setting');
                    this.sideList = this.navList.filter(ele=>{
                        return ele.parentId == setupNode.id
                    })
                    this.filterMenu()
                }
            },
            // 筛选列表
            filterMenu(){
                this.sidebarList.filter(ele=>{
                    this.sideList.forEach(x=>{
                        if(x.resourceUrl){
                            if(ele.index == x.resourceUrl)this.permitList.push(ele)
                        }
                    })
                })
            }
        },
        mounted(){
            let navMenu=localStorage.getItem('navMenu');
            if (navMenu) {
                this.navMenu=navMenu
            }else {
                this.navMenu='info'
            }
            setTimeout(()=>{
                this.getSetupList()
            },150)
        }
    }
</script>

<style scoped>
    .setting{
        padding: 15px;
    }
    .left-card{
        width: 15.5%;
        float: left;
        height: 100vh;
        background-color: #fff;
    }
    .left-card h4{
        padding: 15px;
        border-bottom: 1px solid #8682b0;
    }
    .right-card{
        float: right;
        background-color: #fff;
        padding: 15px;
        width: calc(85% - 50px);
    }
    .table .table-title{
        background-color: #f3f5f7;
        padding: 10px 20px;
        font-size: 14px;
        color: #778ca2;
        font-weight: bold;
    }
    .table .table-content{

    }

    .table-content li{
        height: 120px;
        padding: 15px 20px;
        border-bottom: 1px solid #f3f5f7;
    }
    .table-content li:hover{
        background-color: #f8f8fd;
    }
    .table-content li div{
        line-height: 40px;
    }
    .table-content li .li-title{
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .table-content li .li-title img{
        margin-right: 10px;
    }
    .table-content li .li-title span{
        margin-left: 10px;
        font-size: 14px;
        color: #fff;
        padding: 0 15px;
        line-height: 22px;
    }
    .table-content li .li-info{
        padding-left: 30px;
    }
    .table-content li .li-info span{
        font-size: 14px;
        display: inline-block;
        width: 150px;
        color: #7b8b99;
    }
    .table-content li .li-time{
        padding-left: 30px;
    }
  .table-content li .li-time span{
      font-size: 14px;
      display: inline-block;
      color: #7b8b99;
    }

    .warning{
        background-color: #ffb61e;
    }
    .success{
        background-color: #00af9b;
    }
    .danger{
        background-color: #ff2121;
    }
</style>
