var option = [{
  "value": "美国",
  "label": "USA",
  "children": [{
    "value": "阿肯色",
    "label": "AR",
    "children": [{
      "value": "费耶特维尔",
      "label": "FYV"
    },
    {
      "value": "史密斯堡",
      "label": "FSM"
    },
    {
      "value": "小石城",
      "label": "LIT"
    }
    ]
  },
  {
    "value": "阿拉巴马",
    "label": "AL",
    "children": [{
      "value": "伯明罕",
      "label": "BHM"
    },
    {
      "value": "蒙哥马利",
      "label": "MGM"
    },
    {
      "value": "莫比尔",
      "label": "MOB"
    }
    ]
  },
  {
    "value": "阿拉斯加",
    "label": "AK",
    "children": [{
      "value": "安克雷奇",
      "label": "ANC"
    },
    {
      "value": "费尔班克斯",
      "label": "FAI"
    },
    {
      "value": "朱诺",
      "label": "JNU"
    }
    ]
  },
  {
    "value": "爱达荷",
    "label": "ID",
    "children": [{
      "value": "爱达荷福尔斯",
      "label": "IDA"
    },
    {
      "value": "波卡特洛",
      "label": "PIH"
    },
    {
      "value": "博伊西",
      "label": "BOI"
    },
    {
      "value": "布莱克富特",
      "label": "BLK"
    },
    {
      "value": "科达伦",
      "label": "COE"
    },
    {
      "value": "刘易斯顿",
      "label": "LWS"
    },
    {
      "value": "莫斯科",
      "label": "MJL"
    },
    {
      "value": "墨菲",
      "label": "ZMU"
    },
    {
      "value": "楠帕",
      "label": "NPA"
    },
    {
      "value": "岂彻姆",
      "label": "QKM"
    },
    {
      "value": "森瓦利",
      "label": "SVY"
    },
    {
      "value": "亚美利加瀑布城",
      "label": "YAF"
    }
    ]
  },
  {
    "value": "爱荷华",
    "label": "IA",
    "children": [{
      "value": "达文波特",
      "label": "DVN"
    },
    {
      "value": "得梅因",
      "label": "DSM"
    },
    {
      "value": "锡达拉皮兹",
      "label": "CID"
    }
    ]
  },
  {
    "value": "北达科他",
    "label": "ND",
    "children": [{
      "value": "俾斯麦",
      "label": "BIS"
    },
    {
      "value": "大福克斯",
      "label": "GFK"
    },
    {
      "value": "法戈",
      "label": "FAR"
    },
    {
      "value": "迈诺特",
      "label": "MOT"
    }
    ]
  },
  {
    "value": "北卡罗来纳",
    "label": "NC",
    "children": [{
      "value": "艾许维尔",
      "label": "AEV"
    },
    {
      "value": "杜罕",
      "label": "DHH"
    },
    {
      "value": "格林斯伯勒",
      "label": "GBO"
    },
    {
      "value": "教堂山",
      "label": "CHE"
    },
    {
      "value": "罗利",
      "label": "RAG"
    },
    {
      "value": "洛利杜罕都会区",
      "label": "RDU"
    },
    {
      "value": "夏洛特",
      "label": "CRQ"
    }
    ]
  },
  {
    "value": "宾夕法尼亚",
    "label": "PA",
    "children": [{
      "value": "阿伦敦",
      "label": "AEW"
    },
    {
      "value": "费城",
      "label": "PHL"
    },
    {
      "value": "匹兹堡",
      "label": "PIT"
    }
    ]
  },
  {
    "value": "德克萨斯",
    "label": "TX",
    "children": [{
      "value": "埃尔帕索",
      "label": "ELP"
    },
    {
      "value": "奥斯汀",
      "label": "AUS"
    },
    {
      "value": "达拉斯",
      "label": "DAL"
    },
    {
      "value": "哥帕斯基斯蒂",
      "label": "CRP"
    },
    {
      "value": "交维斯顿",
      "label": "GLS"
    },
    {
      "value": "拉雷多",
      "label": "LRD"
    },
    {
      "value": "麦亚伦",
      "label": "TXC"
    },
    {
      "value": "圣安东尼奥",
      "label": "SAT"
    },
    {
      "value": "休斯敦",
      "label": "HOU"
    }
    ]
  },
  {
    "value": "俄亥俄",
    "label": "OH",
    "children": [{
      "value": "代顿",
      "label": "DYT"
    },
    {
      "value": "哥伦布",
      "label": "CZX"
    },
    {
      "value": "克利夫兰",
      "label": "CLE"
    },
    {
      "value": "托莱多",
      "label": "TOL"
    },
    {
      "value": "辛辛那提",
      "label": "CVG"
    }
    ]
  },
  {
    "value": "俄克拉荷马",
    "label": "OK",
    "children": [{
      "value": "俄克拉荷马城",
      "label": "OKC"
    },
    {
      "value": "诺曼",
      "label": "OUN"
    },
    {
      "value": "塔尔萨",
      "label": "TUL"
    }
    ]
  },
  {
    "value": "俄勒冈",
    "label": "OR",
    "children": [{
      "value": "本德",
      "label": "BZO"
    },
    {
      "value": "波特兰",
      "label": "PDX"
    },
    {
      "value": "达尔斯",
      "label": "DLS"
    },
    {
      "value": "达拉斯",
      "label": "DAC"
    },
    {
      "value": "蒂拉穆克",
      "label": "TLM"
    },
    {
      "value": "格兰茨帕斯",
      "label": "XFX"
    },
    {
      "value": "胡德里弗",
      "label": "HDX"
    },
    {
      "value": "火山口湖",
      "label": "CTR"
    },
    {
      "value": "科瓦利斯",
      "label": "YCV"
    },
    {
      "value": "库斯贝",
      "label": "COB"
    },
    {
      "value": "梅德福",
      "label": "MFR"
    },
    {
      "value": "塞勒姆",
      "label": "SLE"
    },
    {
      "value": "圣海伦斯",
      "label": "STH"
    },
    {
      "value": "斯普林菲尔德",
      "label": "SPY"
    },
    {
      "value": "尤金",
      "label": "EUG"
    }
    ]
  },
  {
    "value": "佛罗里达",
    "label": "FL",
    "children": [{
      "value": "奥兰多",
      "label": "ORL"
    },
    {
      "value": "基韦斯特",
      "label": "EYW"
    },
    {
      "value": "杰克逊维尔",
      "label": "JAX"
    },
    {
      "value": "卡纳维尔角",
      "label": "CPV"
    },
    {
      "value": "罗德岱堡",
      "label": "FLL"
    },
    {
      "value": "迈阿密",
      "label": "MIA"
    },
    {
      "value": "圣彼德斯堡市",
      "label": "PIE"
    },
    {
      "value": "塔拉哈西",
      "label": "TLH"
    },
    {
      "value": "坦帕",
      "label": "TPA"
    }
    ]
  },
  {
    "value": "佛蒙特",
    "label": "VT",
    "children": [{
      "value": "伯灵顿",
      "label": "BTV"
    },
    {
      "value": "拉特兰",
      "label": "RUT"
    },
    {
      "value": "南伯灵顿",
      "label": "ZBR"
    }
    ]
  },
  {
    "value": "哥伦比亚特区",
    "label": "DC",
    "children": [{
      "value": "华盛顿哥伦比亚特区",
      "label": "WAS"
    }]
  },
  {
    "value": "华盛顿",
    "label": "WA",
    "children": [{
      "value": "斯波坎",
      "label": "GEG"
    },
    {
      "value": "塔科马",
      "label": "TTW"
    },
    {
      "value": "西雅图",
      "label": "SEA"
    }
    ]
  },
  {
    "value": "怀俄明",
    "label": "WY",
    "children": [{
      "value": "埃文斯顿",
      "label": "EVD"
    },
    {
      "value": "卡斯珀",
      "label": "CPR"
    },
    {
      "value": "拉勒米",
      "label": "LAR"
    },
    {
      "value": "罗克斯普林斯",
      "label": "RKS"
    },
    {
      "value": "夏延",
      "label": "CYS"
    },
    {
      "value": "谢里登",
      "label": "SHR"
    }
    ]
  },
  {
    "value": "加利福尼亚",
    "label": "CA",
    "children": [{
      "value": "旧金山",
      "label": "SFO"
    },
    {
      "value": "洛杉矶",
      "label": "LAX"
    },
    {
      "value": "圣迭戈",
      "label": "SAN"
    },
    {
      "value": "圣何塞",
      "label": "SJC"
    }
    ]
  },
  {
    "value": "堪萨斯",
    "label": "KS",
    "children": [{
      "value": "阿比林",
      "label": "ABZ"
    },
    {
      "value": "奥弗兰公园",
      "label": "OVL"
    },
    {
      "value": "哈钦森",
      "label": "HCH"
    },
    {
      "value": "堪萨斯城",
      "label": "KCK"
    },
    {
      "value": "莱文沃思",
      "label": "XIA"
    },
    {
      "value": "劳伦斯",
      "label": "LWC"
    },
    {
      "value": "曼哈顿",
      "label": "MHK"
    },
    {
      "value": "托皮卡",
      "label": "TOP"
    },
    {
      "value": "威奇托",
      "label": "ICT"
    }
    ]
  },
  {
    "value": "康涅狄格",
    "label": "CT",
    "children": [{
      "value": "布里奇波特",
      "label": "BDR"
    },
    {
      "value": "达里恩",
      "label": "DAQ"
    },
    {
      "value": "格林尼治",
      "label": "GRH"
    },
    {
      "value": "哈特福德",
      "label": "HFD"
    },
    {
      "value": "米德尔顿",
      "label": "XIN"
    },
    {
      "value": "纽黑文",
      "label": "HVN"
    },
    {
      "value": "韦斯特波特",
      "label": "WPT"
    },
    {
      "value": "沃特伯里",
      "label": "WAT"
    },
    {
      "value": "新不列颠",
      "label": "NWT"
    }
    ]
  },
  {
    "value": "科罗拉多",
    "label": "CO",
    "children": [{
      "value": "阿斯彭",
      "label": "ASE"
    },
    {
      "value": "奥罗拉",
      "label": "AUX"
    },
    {
      "value": "博尔德",
      "label": "WBU"
    },
    {
      "value": "大章克申",
      "label": "GJT"
    },
    {
      "value": "丹佛",
      "label": "DEN"
    },
    {
      "value": "柯林斯堡",
      "label": "FNL"
    },
    {
      "value": "科罗拉多斯普林斯",
      "label": "COS"
    },
    {
      "value": "韦尔",
      "label": "VAC"
    }
    ]
  },
  {
    "value": "肯塔基",
    "label": "KY",
    "children": [{
      "value": "列克星敦",
      "label": "LEX"
    },
    {
      "value": "路易斯维尔",
      "label": "LUI"
    },
    {
      "value": "欧文斯伯勒",
      "label": "OWB"
    }
    ]
  },
  {
    "value": "路易斯安那",
    "label": "LA",
    "children": [{
      "value": "巴吞鲁日",
      "label": "BTR"
    },
    {
      "value": "什里夫波特",
      "label": "SHV"
    },
    {
      "value": "新奥尔良",
      "label": "MSY"
    }
    ]
  },
  {
    "value": "罗德岛",
    "label": "RI",
    "children": [{
      "value": "波塔基特",
      "label": "PAW"
    },
    {
      "value": "克兰斯顿",
      "label": "CQH"
    },
    {
      "value": "纽波特",
      "label": "NPO"
    },
    {
      "value": "普罗维登斯",
      "label": "PVD"
    },
    {
      "value": "韦斯特利",
      "label": "WST"
    },
    {
      "value": "文索基特",
      "label": "SFN"
    },
    {
      "value": "沃威克",
      "label": "UZO"
    }
    ]
  },
  {
    "value": "马里兰",
    "label": "MD",
    "children": [{
      "value": "巴尔的摩",
      "label": "BAL"
    },
    {
      "value": "盖瑟斯堡",
      "label": "GAI"
    },
    {
      "value": "罗克维尔",
      "label": "RKV"
    }
    ]
  },
  {
    "value": "马萨诸塞",
    "label": "MA",
    "children": [{
      "value": "波士顿",
      "label": "BZD"
    },
    {
      "value": "斯普林菲尔德",
      "label": "SFY"
    },
    {
      "value": "伍斯特",
      "label": "ORH"
    }
    ]
  },
  {
    "value": "蒙大拿",
    "label": "MT",
    "children": [{
      "value": "比灵斯",
      "label": "BGS"
    },
    {
      "value": "大瀑布村",
      "label": "GTF"
    },
    {
      "value": "米苏拉",
      "label": "MSO"
    }
    ]
  },
  {
    "value": "密苏里",
    "label": "MO",
    "children": [{
      "value": "哥伦比亚",
      "label": "COV"
    },
    {
      "value": "杰佛逊市",
      "label": "JEF"
    },
    {
      "value": "堪萨斯城",
      "label": "MKC"
    },
    {
      "value": "圣路易斯",
      "label": "STL"
    },
    {
      "value": "斯普林菲尔德",
      "label": "SGF"
    }
    ]
  },
  {
    "value": "密西西比",
    "label": "MS",
    "children": [{
      "value": "比洛克西",
      "label": "BIX"
    },
    {
      "value": "格尔夫波特",
      "label": "GPT"
    },
    {
      "value": "格林维尔",
      "label": "GLH"
    },
    {
      "value": "哈蒂斯堡",
      "label": "HBG"
    },
    {
      "value": "杰克逊",
      "label": "JAN"
    },
    {
      "value": "默里迪恩",
      "label": "MEI"
    },
    {
      "value": "维克斯堡",
      "label": "VKS"
    }
    ]
  },
  {
    "value": "密歇根",
    "label": "MI",
    "children": [{
      "value": "安娜堡",
      "label": "ARB"
    },
    {
      "value": "巴特尔克里克",
      "label": "BTL"
    },
    {
      "value": "贝城",
      "label": "BCY"
    },
    {
      "value": "大急流城",
      "label": "GRR"
    },
    {
      "value": "迪尔伯恩",
      "label": "DEO"
    },
    {
      "value": "底特律",
      "label": "DET"
    },
    {
      "value": "弗林特",
      "label": "FNT"
    },
    {
      "value": "怀恩多特",
      "label": "WYD"
    },
    {
      "value": "卡拉马袓",
      "label": "AZO"
    },
    {
      "value": "兰辛",
      "label": "LAN"
    },
    {
      "value": "马斯基根",
      "label": "MKG"
    },
    {
      "value": "庞菷亚克",
      "label": "PTK"
    },
    {
      "value": "萨吉诺",
      "label": "SGM"
    },
    {
      "value": "苏圣玛丽",
      "label": "SSM"
    },
    {
      "value": "沃伦",
      "label": "WAM"
    },
    {
      "value": "休伦港",
      "label": "PHN"
    }
    ]
  },
  {
    "value": "缅因",
    "label": "ME",
    "children": [{
      "value": "班戈",
      "label": "BNQ"
    },
    {
      "value": "波特兰",
      "label": "POL"
    },
    {
      "value": "刘易斯顿",
      "label": "QLW"
    }
    ]
  },
  {
    "value": "明尼苏达",
    "label": "MN",
    "children": [{
      "value": "罗切斯特",
      "label": "RST"
    },
    {
      "value": "明尼阿波利斯",
      "label": "MES"
    },
    {
      "value": "圣保罗",
      "label": "STP"
    }
    ]
  },
  {
    "value": "南达科他",
    "label": "SD",
    "children": [{
      "value": "阿伯丁",
      "label": "ABK"
    },
    {
      "value": "拉皮德城",
      "label": "RAP"
    },
    {
      "value": "苏福尔斯",
      "label": "FSD"
    }
    ]
  },
  {
    "value": "南卡罗来纳",
    "label": "SC",
    "children": [{
      "value": "北查尔斯顿",
      "label": "NTS"
    },
    {
      "value": "查尔斯顿",
      "label": "CHS"
    },
    {
      "value": "哥伦比亚",
      "label": "COV"
    }
    ]
  },
  {
    "value": "内布拉斯加",
    "label": "NE",
    "children": [{
      "value": "奥马哈",
      "label": "OMA"
    },
    {
      "value": "贝尔维尤",
      "label": "XDE"
    },
    {
      "value": "林肯",
      "label": "LNK"
    }
    ]
  },
  {
    "value": "内华达",
    "label": "NV",
    "children": [{
      "value": "埃尔科",
      "label": "EKO"
    },
    {
      "value": "北拉斯维加斯",
      "label": "NVS"
    },
    {
      "value": "弗吉尼亚城",
      "label": "VGI"
    },
    {
      "value": "亨德森",
      "label": "HNZ"
    },
    {
      "value": "卡森城",
      "label": "CSN"
    },
    {
      "value": "拉斯维加斯",
      "label": "LAS"
    },
    {
      "value": "里诺",
      "label": "RNO"
    },
    {
      "value": "斯帕克斯",
      "label": "SPK"
    }
    ]
  },
  {
    "value": "纽约",
    "label": "NY",
    "children": [{
      "value": "布法罗",
      "label": "FFO"
    },
    {
      "value": "罗切斯特",
      "label": "ROC"
    },
    {
      "value": "纽约市",
      "label": "QEE"
    }
    ]
  },
  {
    "value": "特拉华",
    "label": "DE",
    "children": [{
      "value": "多佛",
      "label": "DOR"
    },
    {
      "value": "纽瓦克",
      "label": "NWK"
    },
    {
      "value": "威明顿",
      "label": "ILG"
    }
    ]
  },
  {
    "value": "田纳西",
    "label": "TN",
    "children": [{
      "value": "布利斯托",
      "label": "BSJ"
    },
    {
      "value": "查塔努加",
      "label": "CHA"
    },
    {
      "value": "金斯波特",
      "label": "TRI"
    },
    {
      "value": "孟菲斯",
      "label": "MEM"
    },
    {
      "value": "纳什维尔",
      "label": "BNA"
    },
    {
      "value": "诺克斯维尔",
      "label": "TYS"
    },
    {
      "value": "三城区",
      "label": "YTC"
    },
    {
      "value": "士麦那",
      "label": "MQY"
    },
    {
      "value": "斯普林希尔",
      "label": "RGI"
    },
    {
      "value": "约翰逊城",
      "label": "JCY"
    }
    ]
  },
  {
    "value": "威斯康星",
    "label": "WI",
    "children": [{
      "value": "阿普尓顿",
      "label": "ATW"
    },
    {
      "value": "奥什科什",
      "label": "OSH"
    },
    {
      "value": "格林贝",
      "label": "GBK"
    },
    {
      "value": "基诺沙",
      "label": "ENW"
    },
    {
      "value": "拉克罗斯",
      "label": "LSE"
    },
    {
      "value": "拉辛",
      "label": "RAC"
    },
    {
      "value": "马尼托沃克",
      "label": "MTW"
    },
    {
      "value": "迈迪逊",
      "label": "QMD"
    },
    {
      "value": "密尔沃基",
      "label": "MKE"
    },
    {
      "value": "欧克莱尓",
      "label": "EAU"
    },
    {
      "value": "沃索",
      "label": "AUW"
    },
    {
      "value": "希博伊根",
      "label": "SBM"
    }
    ]
  },
  {
    "value": "维吉尼亚",
    "label": "VA",
    "children": [{
      "value": "弗吉尼亚比奇",
      "label": "VAB"
    },
    {
      "value": "诺福克",
      "label": "ORF"
    },
    {
      "value": "切萨皮克",
      "label": "HTW"
    }
    ]
  },
  {
    "value": "西佛吉尼亚",
    "label": "WV",
    "children": [{
      "value": "查尔斯顿",
      "label": "CRW"
    },
    {
      "value": "亨廷顿",
      "label": "HNU"
    },
    {
      "value": "帕克斯堡",
      "label": "PKB"
    }
    ]
  },
  {
    "value": "夏威夷",
    "label": "HI",
    "children": [{
      "value": "凯卢阿",
      "label": "KHH"
    },
    {
      "value": "檀香山",
      "label": "HNL"
    },
    {
      "value": "希洛",
      "label": "ITO"
    }
    ]
  },
  {
    "value": "新罕布什尔",
    "label": "NH",
    "children": [{
      "value": "康科德",
      "label": "CON"
    },
    {
      "value": "曼彻斯特",
      "label": "MHT"
    },
    {
      "value": "纳舒厄",
      "label": "ASH"
    }
    ]
  },
  {
    "value": "新墨西哥",
    "label": "NM",
    "children": [{
      "value": "阿尔伯克基",
      "label": "ABQ"
    },
    {
      "value": "拉斯克鲁塞斯",
      "label": "LRU"
    },
    {
      "value": "罗斯韦尔",
      "label": "ROW"
    },
    {
      "value": "圣菲",
      "label": "SAF"
    }
    ]
  },
  {
    "value": "新泽西",
    "label": "NJ",
    "children": [{
      "value": "纽瓦克",
      "label": "NRK"
    },
    {
      "value": "帕特森",
      "label": "PAT"
    },
    {
      "value": "泽西城",
      "label": "JEC"
    }
    ]
  },
  {
    "value": "亚利桑那",
    "label": "AZ",
    "children": [{
      "value": "凤凰城",
      "label": "PHX"
    },
    {
      "value": "格兰代尔",
      "label": "GDA"
    },
    {
      "value": "梅萨",
      "label": "MQA"
    },
    {
      "value": "史卡兹代尔",
      "label": "STZ"
    },
    {
      "value": "坦普",
      "label": "TPE"
    },
    {
      "value": "图森",
      "label": "TUC"
    },
    {
      "value": "优玛",
      "label": "YUM"
    }
    ]
  },
  {
    "value": "伊利诺斯",
    "label": "IL",
    "children": [{
      "value": "奥尔顿",
      "label": "ALN"
    },
    {
      "value": "奥罗拉",
      "label": "AUZ"
    },
    {
      "value": "布卢明顿",
      "label": "BLO"
    },
    {
      "value": "丹维尓",
      "label": "DVI"
    },
    {
      "value": "迪卡尔布",
      "label": "DEK"
    },
    {
      "value": "迪凯持",
      "label": "DEC"
    },
    {
      "value": "东圣路易斯",
      "label": "ESL"
    },
    {
      "value": "厄巴纳-香槟",
      "label": "CMI"
    },
    {
      "value": "盖尔斯堡",
      "label": "GSU"
    },
    {
      "value": "卡本代尔",
      "label": "MDH"
    },
    {
      "value": "罗克艾兰",
      "label": "RKI"
    },
    {
      "value": "罗克福德",
      "label": "RFD"
    },
    {
      "value": "诺黙尔",
      "label": "NOM"
    },
    {
      "value": "皮奥里亚",
      "label": "PLA"
    },
    {
      "value": "森特勒利亚",
      "label": "CRA"
    },
    {
      "value": "斯普林菲尔德",
      "label": "SPI"
    },
    {
      "value": "沃其根",
      "label": "UGN"
    },
    {
      "value": "芝加哥",
      "label": "CHI"
    }
    ]
  },
  {
    "value": "印第安那",
    "label": "IN",
    "children": [{
      "value": "埃文斯维尔",
      "label": "EVV"
    },
    {
      "value": "韦恩堡",
      "label": "FWA"
    },
    {
      "value": "印第安纳波利斯",
      "label": "IND"
    }
    ]
  },
  {
    "value": "犹他",
    "label": "UT",
    "children": [{
      "value": "奥格登",
      "label": "OGD"
    },
    {
      "value": "雷登",
      "label": "LTJ"
    },
    {
      "value": "欧仁",
      "label": "OEU"
    },
    {
      "value": "帕克城",
      "label": "PAC"
    },
    {
      "value": "普罗沃",
      "label": "PVU"
    },
    {
      "value": "圣乔治",
      "label": "SGU"
    },
    {
      "value": "西瓦利城",
      "label": "WVC"
    },
    {
      "value": "盐湖城",
      "label": "SLC"
    }
    ]
  },
  {
    "value": "佐治亚",
    "label": "GA",
    "children": [{
      "value": "奥古斯塔",
      "label": "AUT"
    },
    {
      "value": "哥伦布",
      "label": "CZX"
    },
    {
      "value": "梅肯",
      "label": "MCN"
    },
    {
      "value": "沙瓦纳",
      "label": "SAV"
    },
    {
      "value": "亚特兰大",
      "label": "TAT"
    }
    ]
  },
  {
    "value": "美属萨摩亚",
    "label": "ASM",
    "children": [{
      "value": "阿纳",
      "label": "AAN"
    },
    {
      "value": "阿图阿",
      "label": "ATU"
    },
    {
      "value": "艾加伊勒泰",
      "label": "AIT"
    },
    {
      "value": "法塞莱莱阿加",
      "label": "FAA"
    },
    {
      "value": "加盖福毛加",
      "label": "GFG"
    },
    {
      "value": "加加埃毛加",
      "label": "GMG"
    },
    {
      "value": "帕劳利",
      "label": "PAL"
    },
    {
      "value": "萨图帕伊泰阿",
      "label": "SAT"
    },
    {
      "value": "萨瓦伊岛",
      "label": "SAV"
    },
    {
      "value": "图阿马萨加",
      "label": "TUA"
    },
    {
      "value": "瓦奥福诺蒂",
      "label": "VAF"
    },
    {
      "value": "韦西加诺",
      "label": "VAI"
    },
    {
      "value": "乌波卢岛",
      "label": "UPO"
    }
    ]

  }, {
    "value": "美属外岛",
    "label": "UMI"
  }
  ]
}]
export default option