<template>
  <div class="address">
    <div class="add-btn" @click="addForm()">
      <svg-icon icon-class="location" :size="40" :color="'#333399'" style="vertical-align: -11px;"/>{{$t('setup.addAddress')}}
    </div>
    <div class="table">
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="address"
        :label="$t('setup.region')"
        width="300">
        <template slot-scope="scope">
          <span>{{fomatValue(scope.row.address)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="fullAddress"
        :label="$t('setup.detailAddress')">
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('common.operation')"
        width="100">
        <template slot-scope="scope">
          <el-button @click="handleUpdate(scope.row)" type="text" size="small">{{$t('common.edit')}}</el-button>
          <el-button @click="handleDelete(scope.row)" type="text" size="small" >{{$t('common.delete')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <!--新增-->
    <div v-if="addDialog">
        <el-dialog center :title="dialogType=='add'?$t('common.add'):$t('common.edit')" :visible.sync="addDialog" :before-close="clearAdd" width="40%" >
            <AddTemp ref="addRef" :rowData="rowData"  @add="saveAdd" @clear="clearAdd"></AddTemp>
        </el-dialog>
    </div>
  </div>
  
</template>

<script>
import {post, del, get, putUrl} from "@/api/http";
import AddTemp from './add'
export default {
  name: "CoAddress",
  components: {AddTemp,},
  data() {
    return {
      tableData:[],
      addDialog:false,
      rowData:{},
      dialogType:'add',
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
    };
  },
  methods: {
    getList(){
        post("/enterpriseAddressController/getPage",{
          page: this.pageConfig.pageNo,
          limit: this.pageConfig.pageSize,
        }).then(res => {
            if (res.code == 1) {
                this.tableData=res.data.data
            } else {
                this.$message.error(res.data);
            }
        });
    },
    fomatValue(value){
      let reg = new RegExp(',','g')
      let str = value.replace(reg,'')
      return str
    },
    addForm(){
      this.rowData={}
      this.dialogType='add'
      this.addDialog=true
    },
    handleUpdate(row){
      post("/enterpriseAddressController/getDetails",{
          id: row.id,
        }).then(res => {
            if (res.code == 1) {
                this.rowData=res.data
                this.dialogType='edit'
                this.addDialog = true
            } else {
                this.$message.error(res.data);
            }
        });
    },
    handleDelete(row){
      this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
      }).then(() => {
          post("/enterpriseAddressController/delete" , {id:row.id}).then(res => {
              if (res.code == 1) {
                  this.$message.success(this.$t('common.success'));
                  this.getList();
              } else {
                  this.$message.error(res.msg);
              }
          });
      })
    },
    saveAdd(data){
        console.log(data);
        if(data.id){
          post("/enterpriseAddressController/update",{
            ...data
          }).then(res => {
              if (res.code == 1) {
                this.$message.success(this.$t('common.success'));
                  this.clearAdd();
                  this.getList();
              } else {
                  this.$message.error(res.data);
              }
          });
        }else{
          post("/enterpriseAddressController/add",{
              ...data
          }).then(res => {
              if (res.code == 1) {
                  this.$message.success(this.$t('common.success'));
                  this.clearAdd();
                  this.getList();
              } else {
                  this.$message.error(res.data);
              }
          });
        }
    },
    clearAdd(){
      this.addDialog=false
    },
  },
  mounted() {
    this.getList()
  },
};
</script>

<style scoped>
.address {
  width: 80%;
  padding: 20px;
}
.add-btn {
  color: #333399;
  text-align: center;
  background-color: #fbfbfb;
  border: 1px solid #f0f0f5;
  height: 70px;
  line-height: 70px;
  cursor: pointer;
}
.address-box{
  height: 60px;
  line-height: 60px;
}
</style>
