<template>
    <div class="create-temp">
        <el-form ref="elForm" :model="rowCreateData" :rules="rules" size="medium" label-width="170px" label-suffix="：">
            <el-form-item :label="$t('interview.position')" prop="positionId">
                <el-select v-model="rowCreateData.positionId"
                    :placeholder="$t('common.pleaseEnter') + $t('interview.position')">
                    <el-option v-for="(item, index) in positionList" :key="index" :label="item.positionName"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('interview.candidateArea')" prop="phoneNumberPrefix">
                <el-select v-model="rowCreateData.phoneNumberPrefix" :placeholder="$t('common.pleaseSelect')">
                    <el-option :label="$t('interview.china')" value="86"> </el-option>
                    <el-option label="U.S.A" value="1"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('interview.candidate')" prop="name">
                <el-input :placeholder="$t('interview.candidate')" v-model="rowCreateData.name" style="width: 20%">
                </el-input>
                <el-select v-model="rowCreateData.sex" :placeholder="$t('interview.gender')" style="width: 20%">
                    <el-option :label="$t('interview.male')" value="1"></el-option>
                    <el-option :label="$t('interview.female')" value="2"></el-option>
                </el-select>
                <el-input :placeholder="$t('interview.email')" v-model="rowCreateData.mailbox" style="width: 20%">
                </el-input>
                <el-input :placeholder="$t('interview.phoneNumber')" v-model="rowCreateData.phoneNumber"
                    style="width: 20%">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('interview.resume')">
                <el-upload class="upload-demo" ref="upload" :action="baseURL + 'file/upload'"
                    :on-success="uploadSuccess" :on-preview="fileView" :on-change="uploadChange" :headers="uploadHeader"
                    :file-list="fileList" accept=".pdf">
                    <el-button slot="trigger" size="small" type="primary">{{
                    $t('interview.selectFile')
                    }}</el-button>
                    <!--<el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传-->
                    <!--</el-button>-->
                    <p class="tips">{{ $t('interview.uploadPDF') }}</p>
                </el-upload>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewExplain')" prop="explain">
                <el-input type="textarea" rows="5" v-model="explain" readonly></el-input>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewer')" prop="user">
                <el-button type="primary" @click="userDialog = true">{{
                $t('interview.add')
                }}</el-button>
                <el-table :data="userData" style="width: 100%">
                    <el-table-column prop="username" :label="$t('interview.interviewer')" width="180">
                    </el-table-column>
                    <el-table-column prop="isPrimary" :label="$t('interview.isPrimary')">
                        <template slot-scope="scope">
                            <el-switch @change="primaryUser(scope.row)" :value="scope.row.isPrimary == '1'">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" prop="address" :label="$t('common.operation')">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="light" :content="$t('common.delete')"
                                placement="top-start">
                                <el-button type="danger" size="mini" icon="el-icon-delete" circle
                                    @click="deleteUser(scope.row)">
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewTime')" prop="interviewTime">
                <el-date-picker class="interview-time" v-model="rowCreateData.interviewTime" type="datetime"
                    :placeholder="$t('common.pleaseSelect')">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item :label="$t('interview.interviewLink')">
        <el-input v-model="rowCreateData.faceToFaceLink"></el-input>
      </el-form-item> -->
            <el-form-item :label="$t('interview.notifyMethod')">
                <el-checkbox v-model="sms">{{ $t('interview.message') }}</el-checkbox>
                <el-checkbox v-model="email" style="pointer-events:none">{{ $t('interview.email') }}</el-checkbox>
            </el-form-item>
            <el-form-item :label="$t('interview.remarks')">
                <el-input type="textarea" rows="5" v-model="rowCreateData.remark"></el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{ $t('interview.publishAndSend') }}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{
            $t('common.cancel')
            }}</el-button>
        </div>
        <!--添加用户-->
        <div v-if="userDialog">
            <el-dialog append-to-body :title="$t('interview.interviewer')" :visible.sync="userDialog"
                :before-close="clearUser" width="30%" top="100px">
                <UserSelete ref="addRef" :userData="userData" @add="saveUser" @clear="clearUser"></UserSelete>
            </el-dialog>
        </div>
        <!--查看简历-->
        <el-dialog append-to-body
            :title="$t('interview.viewResume')"
            :visible.sync="fileDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="70%" top="60px">
            <div class="endMask">
                <div class="iFrame" v-if="iframeUrl" >
                    <iframe style="width: 100%;height: calc( 100vh - 220px )" :src="iframeUrl"></iframe>
                </div>
                <div class="boontbtn">
                    <el-button type="info" size="small" @click="fileDialog=false">{{$t('common.cancel')}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import request from "@/utils/request.js";
import { post, del, get, putUrl } from '@/api/http'
import baseURL from '@/api/baseURL.js'
import { getToken } from '@/utils/auth'
import UserSelete from '@/views/publish/userSelete'
import 'mavon-editor/dist/css/index.css'

export default {
    name: 'add',
    components: { UserSelete },
    props: {
        rowCreateData: {
            type: Object,
            default() {
                return {}
            },
        },
        positionList: {
            type: Array,
            default() {
                return []
            },
        },
    },
    data() {
        return {
            value: '',
            rules: {
                positionId: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseSelect') +
                            this.$t('interview.position') +
                            '!',
                        trigger: 'blur',
                    },
                ],
                phoneNumberPrefix: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseSelect') +
                            this.$t('interview.candidateArea') +
                            '!',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseSelect') +
                            this.$t('interview.candidate') +
                            '!',
                        trigger: 'blur',
                    },
                ],
                interviewTime: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseSelect') +
                            this.$t('interview.interviewTime') +
                            '!',
                        trigger: 'blur',
                    },
                ],
            },
            explain: this.$t('interview.interviewTips'),
            userList: [],
            user: '',
            positionListId: '',
            fileList: [],
            uploadHeader: {},
            baseURL: baseURL,
            uploadIdList: '',
            iframeUrl: '',
            sms: true,
            email: true,
            userDialog: false,
            fileDialog: false,
            userData: [],
        }
    },
    watch: {},
    methods: {
        //提交
        submitForm() {
            this.$refs['elForm'].validate((valid) => {
                if (!valid) {
                    this.$message.error(this.$t('common.pleaseComplete'))
                    return false
                } else {
                    if (this.userData.length !== 0) {
                        this.userData.map(ele => {
                            ele.isNotificationSms = this.sms ? '1' : '0'
                            ele.isNotificationEmail = '1'
                        })
                    }
                    let subData = {
                        ...this.rowCreateData,
                        list: this.userData,
                        // enterpriseId:1
                    }
                    if (this.sms) {
                        subData.isNotificationSms = '2'
                    }
                    if (this.email) {
                        subData.isNotificationEmail = '1'
                    }
                    if (this.uploadIdList) {
                        subData.fileId = this.uploadIdList
                    }
                    this.$emit('createClick', subData)
                }
            })
        },
        //关闭
        clearAdd() {
            this.formData = {}
            this.$emit('clearCreate')
        },
        //获取面试官信息
        getUser() {
            post('/setting-user/list', {
                page: 1,
                limit: 1000,
            }).then((res) => {
                if (res.code == 1) {
                    this.userList = res.data.data
                } else {
                    this.$message.error(res.data)
                }
            })
        },
        userChange(data) { },
        // //上传文件,
        // submitUpload() {
        //     this.$refs.upload.submit();
        //     this.loading = true;
        // },
        //上传成功回调
        uploadSuccess(response, file, fileList) {
            if (response.code == 1) {
                this.$message.success(this.$t('common.uploadSuccess'))
                this.uploadIdList = response.data.id
                this.loading = false
            }
        },
        //超出处理
        uploadChange(file, fileList) {
            let fileData = []
            fileData.push(fileList[fileList.length - 1])
            this.fileList = fileData
        },
        setData(row) {
            this.userData = row.list
        },
        clearUser() {
            this.userDialog = false
        },
        //面试官新增保存
        saveUser(data) {
            //console.log(data)
            data.map((item, index) => {
                if (index == 0) {
                    item.isPrimary = 1
                } else {
                    item.isPrimary = 0
                }
            })
            this.userData = data
            this.clearUser()
        },
        //设置主面试官
        primaryUser(row) {
            this.userData.map((item) => {
                if (item.userId == row.userId) {
                    item.isPrimary = 1
                } else {
                    item.isPrimary = 0
                }
            })
        },
        //删除面试官
        deleteUser(row) {
            if (row.isPrimary == '0') {
                var r = this.userData.filter(function (item) {
                    return item.userId != row.userId
                })
                this.userData = r
            } else {
                this.$message.error(this.$t('interview.primaryCantDelete'))
            }
        },
        //简历初始化
        getFileInfo(id) {
            get('/file/info/' + id).then((res) => {
                if (res.code == 1) {
                    this.uploadIdList = res.data.id
                    this.fileList.push({
                        name: res.data.sourceName,
                        status: 'success',
                    })
                } else {
                    this.$message.error(res.data)
                }
            })
        },
        // 文件预览
        async fileView(file) {
            var res = await request({
                url: "/file/download",
                method: "POST",
                params: {
                    id: file.response.data.id
                },
                responseType: "blob"
            });
            const blob = new Blob([res], {
                type: "application/pdf"
            });
            this.iframeUrl = URL.createObjectURL(blob);
            this.fileDialog = true;
        }
    },
    mounted() {
        this.getUser()
        //console.log(this.rowCreateData)
        if (this.rowCreateData.fileId) {
            this.getFileInfo(this.rowCreateData.fileId)
        }
        this.userData = this.rowCreateData.list
        this.rowCreateData.interviewTime =
            this.rowCreateData.selectedTime.split('~')[0] + ':00'
        this.uploadHeader = {
            Authorization: getToken(),
        }
        // 只有一个时默认主面试官
        if (this.rowCreateData.list && this.rowCreateData.list.length == 1) {
            this.rowCreateData.list[0].isPrimary = '1'
        }
    },
}
</script>

<style>
.tips {
    color: red;
}

.tips:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}

.interview-time .el-time-spinner__item {
    display: none !important;
}
</style>
