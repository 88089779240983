<template>
  <div class="add-temp">
      <el-form ref="elForm" :model="rowData" size="medium" label-width="50px">
          <el-form-item label="">
            <el-checkbox-group 
            v-model="checkList"  
            @change="handleCheckedChange">
				<el-checkbox :label="item.id" v-for="item in roleList" :key="item.id">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
      </el-form>
      <div class="boontbtn">
          <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
              {{$t('common.ok')}}
          </el-button>
          <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
      </div>
  </div>
</template>

<script>
import {post, del, get, putUrl} from "@/api/http";

export default {
    name: "add",
    props:{
        rowData:{
            type: Object,
            default() {
                return {};
            }
        },
        dialogType:{
            type: String,
            default() {
                return '';
            }
        },
    },
    data() {
        const vm = window.vm;
        return {
            vm: vm,
            checkList:[],
            roleList:[],
        }
    },
    methods: {
        getList(){
            post("/enterpriseRoleController/setRole",{enterpriseUserId:this.rowData.id}).then(res => {
                if (res.code == 1) {
                    this.roleList=res.data
                    res.data.map(ele=>{
                        if(ele.choice)
                        this.checkList.push(ele.id) 
                    })
                } else {
                    this.$message.error(res.data);
                }
            });
        },
        handleCheckedChange(value){
            this.checkList = value
        },
        submitForm() {
            this.$emit("add",{
                enterpriseRoleId:this.checkList.toString(),
                enterpriseUserId:this.rowData.id
            })
        },
        clearAdd() {
            this.formData = {};
            this.$emit("clear");
        },
    },
    mounted() {
        this.getList()
    }
  }
</script>

<style scoped>

</style>
