import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import langZh from "./zh.js"
import langEN from "./en.js"

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale:getLanguage() ,
    messages: {
        'zh-Cn': {...langZh,...zhLocale},
        'en': {...langEN,...enLocale}
    }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))


function getTimeZone(){
    let time = new Date();
    let timeZone = time.toLocaleTimeString('en-us',{timeZoneName:'short'});  //'1:12:38 PM GMT+8'
    let timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone; //'Asia/Shanghai'
    return timeZoneId
}
export function getLanguage() {
    let time = getTimeZone()
    let lan = (time=='Asia/Shanghai'?'zh-Cn':'en')
    
    if(Cookies.get('language')){
        return Cookies.get('language')
    }else{
        Cookies.set('language',lan)
        return lan
    }
    // const chooseLanguage = Cookies.get('language')
    // if (chooseLanguage) return chooseLanguage
  
    // // if has not choose language
    // const language = (navigator.language || navigator.browserLanguage).toLowerCase()
    // const locales = Object.keys(messages)
    // for (const locale of locales) {
    //   if (language.indexOf(locale) > -1) {
    //     return locale
    //   }
    // }
    // return 'en'
  }

export default i18n
