<template>
  <div>
      <div class="pop-search" >
          <el-input placeholder="请输入内容" v-model="clientN" size="small">
          <el-button slot="append" @click="searchClient" icon="el-icon-search" size="mini"></el-button>
          </el-input>
      </div>
      <div class="checkList">
          <div class="check-box" >
          <el-checkbox-group v-model="checkList" @change="select" size="mini">
              <el-checkbox
              class="label-item"
              v-for="item in clientData"
              :key="item.id"
              :label="item"
              border
              >{{ item.name }}</el-checkbox>
          </el-checkbox-group>
          <el-pagination
              style="margin-left:10px"
              small
              layout="total,prev, pager, next"
              :page-size="pageSet.pageSize"
              @current-change="currentChange"
              :current-page="pageSet.pageNo"
              :total="pageSet.total">
          </el-pagination>
          </div>
          <!-- <div class="check-box" v-else>{{ $t("question.nonClient") }}</div> -->
      </div>
  </div>
</template>

<script>
    import {post, del, get, putUrl,uploadImg} from "@/api/http";

    export default {
        name: "add",
        props:{
          id: {
              type: Array,
              default() {
                  return [];
              }
          },
          value:{
              type: Array,
              default() {
                  return '';
              }
          },
          name:{
              type: String,
              default() {
                  return '';
              }
          },
          prop:{
              type: String,
              default() {
                  return '';
              }
          }
        },
        data() {
            return {
              clientN:'',
              pageSet:{
                  pageSize: 12,
                  total: 0,
                  pageNo: 1,
              },
              // clientData:[
              //   {
              //     id:'1',
              //     name:'111',
              //   }
              // ],
              clientData:[],
              checkList:[],
              checkOne:'',
              nameC:'',
              propsValue:this.value,
              // propsList:this.list
            }
        },
        watch:{
          // clientData: {
          //   handler (val, oldVal) {
          //     this.clientData = val
          //     // console.log(val);
          //     // this.$forceUpdate()
          //     // this.revert_data()
          //   },
          //   deep: true
          // },
        },
        methods: {
            // 搜索客户
            searchClient(item){
                this.pageSet.pageNo = 1;
                this.getClientData()
            },
            getClientData() {
                post("/enterpriseCustomer/getPage", {
                    page:this.pageSet.pageNo,
                    limit:this.pageSet.pageSize,
                    name:this.clientN,
                }).then((res) => {
                    if (res.code == 1) {
                    this.clientData = res.data.data;
                    this.pageSet.total = res.data.count
                    } else {
                    this.$message.error(res.data);
                    }
                });
            },
            // 客户翻页
            currentChange(val) {
                this.pageSet.pageNo = val;
                this.getClientData();
            },
            select(){
              // // 多选改单选
              // if(this.checkList.length>1){
              //   this.checkList.shift()
              // }
              // // console.log(this.checkList);
              // this.clientData.map(x=>{
              //   if(x.id == this.checkList[0])
              //   this.nameC = x.name
              // })

              this.revert_data()
            },
            revert_data(){
              this.$emit('input',this.checkList)
              // let obj={
              //   name : this.prop,
              //   value :this.nameC
              // }
              this.$emit('update')
              // console.log(obj);
            }
        },
        mounted() {
          this.getClientData()
          // console.log(this.clientData);
          // this.checkList.push(this.propsValue)
        }
    }
</script>

<style scoped>
    .search-select{
        width:600px;
        height: 300px;
    }
    .el-select-dropdown__item.hover{
        background-color: #fff;
    }
    .checkList .el-checkbox-group{
        padding-top: 10px;
        display: flex;
        flex-wrap:wrap;
    }
    .pop-search{
        margin: 0px auto;
        width: 50%;
        display: block;
    }
    .checkList .check-box .label-item{
    margin:5px 10px;
    }
    .el-checkbox{
        width:29%
    }
</style>
