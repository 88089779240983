<template>
    <div class="contacts-list">
        <el-card class="item-card" v-for="item in listData" :key="item.id">
            <div slot="header" class="clearfix">
                <span>{{item.name}}</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">更多<i class="el-icon-d-arrow-right"></i></el-button> -->
                <el-dropdown trigger="click" style="float: right;" @command="inputContact">
                    <span class="el-dropdown-link">
                        {{$t('setup.more')}}<i class="el-icon-d-arrow-right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="commandValue('view',item)">{{$t('common.detail')}}</el-dropdown-item>
                        <el-dropdown-item v-if="dialogType!=='view'" :command="commandValue('edit',item)">{{$t('common.edit')}}</el-dropdown-item>
                        <el-dropdown-item v-if="dialogType!=='view'" :command="commandValue('del',item)">{{$t('common.delete')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="info">
                <p><i class="el-icon-postcard"></i>{{$t('setup.position')}}：{{item.position}}</p>
                <!-- <p><i class="el-icon-phone-outline"></i>电话：0551-63562589</p> -->
                <p><i class="el-icon-mobile-phone"></i>{{$t('setup.mobilePhone')}}：{{item.mobilePhone}}</p>
                <p><i class="el-icon-message"></i>{{$t('setup.email')}}：{{item.mailbox}}</p>
            </div>
        </el-card>
        <el-card class="item-card plus" v-if="dialogType!=='view'">
            <div class="icon" @click="addItem()">
                <i class="el-icon-circle-plus-outline"></i>
                <span>{{$t('setup.addContacts')}}</span>
            </div>
        </el-card>
        <div v-if="addDialog">
            <el-dialog center :title="$t('setup.contacts')" :visible.sync="addDialog" append-to-body :before-close="clearInnerAdd" width="45%" >
                <Add ref="addRef" :rowData="rowData" @add="addSave(arguments)" @clear="clearInnerAdd"></Add>
            </el-dialog>
        </div>
        <div v-if="viewDialog">
            <el-dialog center :title="$t('setup.contacts')" :visible.sync="viewDialog" append-to-body :before-close="clearInnerAdd" width="45%" >
                <ViewContact ref="addRef" :rowData="rowData" @clear="clearInnerAdd"></ViewContact>
            </el-dialog>
        </div>
        
    </div>
</template>

<script>
import {post, del, get, putUrl} from "@/api/http";
import Add from './contacts-add'
import ViewContact from './contacts-view'

    export default {
        name: "add",
        props:{
            customerId:{
                type: String,
                default() {
                    return '';
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
            addCustomerId:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        components:{
            Add,ViewContact
        },
        data() {
            return {
                listData:[],
                addDialog:false,
                viewDialog:false,
                rowData:{},
                addId:''
                // dialogType:'',
            }
        },
        watch: {
            addCustomerId(newValue) {
                this.addId = newValue
                console.log('addId变化',this.addId);
                this.getList(this.addId)
            },
        },
        methods: {
            getList(id){
                post("/customerHuman/getPage",{
                    page:1,
                    limit:100,
                    customerId:id
                }).then(res => {
                    if (res.code == 1) {
                        this.listData=res.data.data
                    }
                });
            },
            // 保存
            addSave(data) {
                if(data[1] == 'add'){
                    post("/customerHuman/insert",data[0]).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.clearInnerAdd()
                            if(this.addId!==''){
                                this.getList(this.addId)
                            }else{
                                this.getList(this.customerId)
                            }
                        }else if(res.code==408){
                            this.$message.error(this.$t('setup.clientRepeat'));
                        } 
                        else {
                            this.$message.error(res.message);
                        }
                    });
                }else{
                    post("/customerHuman/update",data[0]).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.clearInnerAdd()
                            this.getList(this.customerId)
                        } else if(res.code==408){
                            this.$message.error(this.$t('setup.clientRepeat'));
                        } 
                        else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 新增
            addItem() {
                if(this.addId==''&&this.customerId==''){
                    this.$message.error(this.$t('setup.pleaseFoundClient'))
                }else{
                    let customerId = this.addId!==''?this.addId:this.customerId
                    this.rowData = {customerId:customerId}
                    this.addDialog= true
                }
            },
            // 预览修改
            inputContact(command) {
                console.log(command);
                if(command.type == 'del'){
                    post("/customerHuman/delete",{id:command.data.id}).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.delSuccess'));
                            this.getList(this.customerId)

                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }else{
                    post("/customerHuman/getDetails",{id:command.data.id}).then(res => {
                        if (res.code == 1) {
                            this.rowData = res.data
                            if(command.type == 'edit'){
                                this.addDialog= true
                            }else{
                                this.viewDialog= true
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 下拉框返回多个参数
            commandValue(type,data){
                return {
                    type:type,
                    data:data
                }
            },
            clearAdd() {
                this.$emit("clear");
            },
            clearInnerAdd() {
                this.addDialog = false
                this.viewDialog = false
            },
        },
        mounted() {
            // if(this.addId!==''){
            //     this.getList(this.addId)
            // }else{
            //     this.getList(this.customerId)
            // }
            this.getList(this.customerId)
        },
    }
</script>

<style scoped>
.contacts-list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -30px;
}
.item-card{
    width:290px;
    border-radius: 5px;
    cursor: pointer;
    min-height: 170px;
    margin-bottom: 20px;
    margin-right: 30px;
}
.plus{
    text-align: center;
    color:#8A8A8A;
}
.plus .icon{
    margin:34px 0
}
.plus .icon i{
    font-size: 58px;
    display: block;
}
::v-deep .el-card__header{
    padding:12px 20px
}
::v-deep .el-card__body{
    padding:12px 20px
}
.item-card .info p{
    line-height: 34px;
}
.item-card .info p i{
    /* font-size:14px */
    padding-right: 5px;
}
.el-dropdown-link {
    cursor: pointer;
    color: #333;
    font-size: 14px;
}
</style>
