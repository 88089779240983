<template>
  <div :class="classObj" class="app-wrapper">
    1231231
  </div>
</template>

<script>


export default {
  name: 'Layout',
  components: {

  },
    computed: {
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
