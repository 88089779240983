<template>
    <div class="user">
        <div class="three">
            <el-button type="primary" size="small" @click="addForm()" >{{$t('setup.addInvoiceTit')}}</el-button>
        </div>
        <div class="table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background: '#e9ecf7' }"
                    style="width: 100%">
                <el-table-column
                        prop="username"
                        :label="$t('setup.tittleType')">
                    <template slot-scope="scope">
                       <span v-if="scope.row.type=='1'">{{$t('setup.enterUnit')}}</span>
                       <span v-else>{{$t('setup.INDuint')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="title"
                        :label="$t('setup.invoiceName')">
                </el-table-column>
                <el-table-column
                        prop="enabled"
                        :label="$t('setup.defaultTit')">
                    <template slot-scope="scope">
                        <el-switch
                                @change="switchChange(scope.row)"
                                :value="scope.row.isDefault=='1'">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="360"
                        :label="$t('common.operation')">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start">
                            <el-button type="success" size="mini" @click="operation('edit',scope.row)">{{$t('common.edit')}}</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start">
                            <el-button type="danger" size="mini" @click="operation('del',scope.row)">{{$t('common.delete')}}</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--新增-->
        <div v-if="addDialog">
            <el-dialog center :title="$t('setup.invoiceTit')" :visible.sync="addDialog" :before-close="clearAdd" width="30%" >
                <AddTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></AddTemp>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {post, del, get, putUrl} from "@/api/http";
    import AddTemp from './add'
    export default {
        name: "position",
        components: {AddTemp},
        data() {
            return {
                tableData: [],
                addDialog:false,
                rowData:{ type:1},
                dialogType:'add',
            }
        },
        methods:{
            getList(){
                post("/setting-invoice/list",{
                    page:1,
                    limit:10
                }).then(res => {
                    if (res.code == 1) {
                        this.tableData=res.data.data
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            addForm(){
                this.rowData={
                    type:1
                };
                this.dialogType='add';
                this.addDialog=true;
            },
            clearAdd(){
                this.addDialog=false;
            },
            saveAdd(data){
                console.log(data);
                post("/setting-invoice/insert",{
                    // enterpriseId:1,
                    isDefault:0,
                    ...data
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(this.$t('common.success'));
                        this.clearAdd();
                        this.getList();
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            operation(type,row){
                if (type=='edit'){
                    get("/setting-invoice/"+row.id).then(res => {
                        if (res.code == 1) {
                            this.rowData=res.data;
                            this.dialogType='edit';
                            this.addDialog=true;
                        } else {
                            this.$message.error(res.data);
                        }
                    });

                }
                if (type=='del'){
                    this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
                        confirmButtonText: this.$t('common.ok'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning'
                    }).then(() => {
                        del("/setting-invoice/delete/" + row.id).then(res => {
                            if (res.code == 1) {
                                this.$message.success(this.$t('common.delSuccess'));
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }).catch(() => {

                    });
                }
            },
            switchChange(row){
                console.log(row);
                if (row.isDefault == 0) {
                    post("/setting-invoice/set-default",{
                        id:row.id,
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message.success(this.$t('common.success'));
                            this.getList();
                        } else {
                            this.$message.error(res.data);
                        }
                    });
                }

            },
        },
        mounted(){
            this.getList();
        }
    }
</script>

<style scoped>

</style>
