<template>
  <div class="personnel">
    <div class="personnel-title">
      {{ $t('test.personManage') }}
      <i class="el-icon-close back-icon" @click="backTo"></i>
    </div>
    <div class="statistics-box">
      <el-row type="flex" justify="space-between">
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.invitedNum') }}</p>
          <p>{{ statisticsData.headCount || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.participantNum') }}</p>
          <p>{{ statisticsData.joinCount || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.noParticipantNum') }}</p>
          <p>{{ statisticsData.notJoinCount || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.qualifiedNum') }}</p>
          <p>{{ statisticsData.passCount || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.nonQualifiedNum') }}</p>
          <p>{{ statisticsData.notPassCount || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.highestScore') }}</p>
          <p>{{ statisticsData.maxScore || 0 }}</p>
        </el-col>
        <el-col :span="3" class="box-item">
          <p>{{ $t('test.lowestScore') }}</p>
          <p>{{ statisticsData.minScore || 0 }}</p>
        </el-col>
      </el-row>
    </div>
    <div style="border-radius: 5px; background-color: #fff">
      <div class="sear">
        <el-form :inline="true" :model="searchlist" ref="filterForm" class="demo-form-inline" size="mini"
          label-suffix="：">
          <el-form-item :label="$t('test.candidateName')">
            <el-input :placeholder="$t('common.pleaseEnter') + $t('test.candidateName')"
              v-model="searchlist.candidateName">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('test.candidatePhone1')">
            <el-input :placeholder="
              $t('common.pleaseEnter') + $t('test.candidatePhone1')
            " v-model="searchlist.candidatePhone">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('test.testStatus')">
            <el-select v-model="searchlist.status" :placeholder="$t('common.pleaseSelect')">
              <el-option :label="$t('test.all')" value=""></el-option>
              <el-option :label="$t('test.waitStart')" :value="0"></el-option>
              <el-option :label="$t('test.inProgress')" :value="1"></el-option>
              <el-option :label="$t('test.testEnd')" :value="2"></el-option>
              <el-option :label="$t('test.scoringEnd')" :value="3"></el-option>
              <el-option :label="$t('test.reviewed')" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchList" size="mini">{{
            $t('test.queryPerson')
            }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="resetForm" size="mini">{{
            $t('common.reset')
            }}</el-button>
          </el-form-item>

          <el-form-item style="float: right">
            <el-button type="primary" @click="addForm()" size="medium">{{
            $t('test.addPerson')
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="candidateEmail" :label="$t('test.invitedEmail')" width="180">
          </el-table-column>
          <el-table-column prop="candidateName" :label="$t('test.name')" width="180">
          </el-table-column>
          <el-table-column prop="candidatePhonePrefix" :label="$t('test.candidateArea')" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.candidatePhonePrefix=='86'">中国</span>
              <span v-if="scope.row.candidatePhonePrefix=='1'">USA</span>
            </template>
          </el-table-column>
          <el-table-column prop="invitationTime" :label="$t('test.invitedTime')" width="160">
          </el-table-column>
          <el-table-column prop="examTime" :label="$t('test.examStartTime')" width="160">
          </el-table-column>
          <el-table-column prop="startTime" :label="$t('test.testStartTime')" width="160">
          </el-table-column>
          <el-table-column prop="endTime" :label="$t('test.testEndTime')" width="160">
          </el-table-column>
          <el-table-column prop="address" :label="$t('test.costTime')" width="100">
            <template slot-scope="scope">
              <span>{{ consumTime(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="examScore" :label="$t('test.score')" width="60">
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="address"-->
          <!--label="排名"-->
          <!--width="60"-->
          <!--&gt;-->
          <!--</el-table-column>-->
          <el-table-column prop="address" :label="$t('test.isqualified')">
            <template slot-scope="scope">
              <span>{{ isQualified(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('test.testStatus')">
            <template slot-scope="scope">
              <span v-if="scope.row.status == '0'">{{
              $t('test.waitStart')
              }}</span>
              <span v-if="scope.row.status == '1'">{{
              $t('test.inProgress')
              }}</span>
              <span v-if="scope.row.status == '2'">{{
              $t('test.testEnd')
              }}</span>
              <span v-if="scope.row.status == '3'">{{
              $t('test.scoringEnd')
              }}</span>
              <span v-if="scope.row.status == '5'">{{
              $t('test.reviewed')
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="$t('common.operation')" width="260">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start"
                v-if="scope.row.status == '0'">
                <el-button icon="el-icon-edit" circle @click="editClick(scope.row, 'edit')"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('test.seeScene')" placement="top-start" v-if="
                scope.row.status == '2' ||
                scope.row.status == '3' ||
                scope.row.status == '5'
              ">
                <el-button :disabled="scope.row.screenRecording == 2" icon="el-icon-video-play" circle
                  @click="seeVideo(scope.row, 'download')"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('test.manualMark')" placement="top-start"
                v-if="scope.row.status == '2'">
                <el-button icon="el-icon-document-checked" circle @click="toCorrect(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start"
                v-if="scope.row.status == '0'">
                <el-button icon="el-icon-delete" circle @click="deleteClick(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('test.haveRead')" placement="top-start"
                v-if="scope.row.status == '5'">
                <el-button icon="el-icon-view" circle @click="haveRead(scope.row)"></el-button>
              </el-tooltip>
              <!--<span v-if="scope.row.status=='1'">-->
              <!--考试正在进行中-->
              <!--</span>-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="background-color: #ffffff; padding: 10px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="pageConfig.currentPage"
          :page-sizes="[10, 100, 200, 300, 400]" :page-size="pageConfig.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pageConfig.total">
        </el-pagination>
      </div>
    </div>

    <!--新增-->
    <div v-if="addDialog">
      <el-dialog append-to-body :title="$t('test.personnelDetail')" :visible.sync="addDialog" :before-close="clearAdd"
        width="30%">
        <PersonnelAdd ref="addRef" :rowData="rowData" @add="saveAdd" @clear="clearAdd"></PersonnelAdd>
      </el-dialog>
    </div>
    <!--答题录屏播放-->
    <div v-if="videoDialog">
      <el-dialog append-to-body :title="$t('test.recordplay')" :visible.sync="videoDialog" :before-close="clearAdd"
        width="80%">
        <div class="video-box" v-loading="loading">
          <video :src="videoSrc" autoplay controls style="margin: 0 auto; width: 650px"></video>
        </div>
        <div class="boontbtn">
          <el-button type="info" size="small" @click="clearAdd">{{
          $t('common.close')
          }}</el-button>
        </div>
      </el-dialog>
    </div>
    <!--人工阅卷-->
    <div v-if="correctDialog">
      <el-dialog append-to-body :title="$t('test.manualMark')" :visible.sync="correctDialog" :before-close="clearAdd"
        width="80%" top="20px" custom-class="correctDia">
        <Correct ref="addRef" :userData="correctData" :title="correctTitle" :haveReadonly="haveReadonly"
          @clear="clearAdd"></Correct>
      </el-dialog>
    </div>
    <!-- 已阅 -->
    <div v-if="haveReadDialog">
      <el-dialog append-to-body :title="$t('test.haveRead')" :visible.sync="haveReadDialog" :before-close="clearAdd"
        width="90%" top="20px">
        <Correct :userData="haveReadData" :title="haveReadTitle" :haveReadonly="haveReadonly" @clear="clearAdd">
        </Correct>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PersonnelAdd from './personnel-add'
import Correct from '../test-correct/correct-index'
import { post, del, get, putUrl } from '@/api/http'
import request from '@/utils/request.js'
export default {
  name: 'personnel-index',
  components: { PersonnelAdd, Correct },
  data() {
    return {
      examItem: {},
      tableData: [],
      statisticsData: {},
      searchlist: {},
      addDialog: false,
      videoDialog: false,
      correctDialog: false,
      haveReadDialog: false,
      rowData: {},
      videoSrc: '',
      pageConfig: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        pageSizes: [10, 50, 100, 150, 200],
      },
      correctData: {},
      correctTitle: '',
      haveReadData: {},
      haveReadTitle: '',
      loading: true,
      haveReadonly: true,
    }
  },
  methods: {
    handleSizeChange() {
      console.log('handleClick!')
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val
      this.getList()
    },
    getList() {
      post('/exam-examinee/list', {
        page: this.pageConfig.currentPage,
        limit: this.pageConfig.pageSize,
        examId: this.examItem.id,
        candidateName: this.searchlist.candidateName,
        candidatePhone: this.searchlist.candidatePhone,
        status: this.searchlist.status,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
      post('/exam-examinee/top-statistics', {
        examId: this.examItem.id,
      }).then((res) => {
        if (res.code == 1) {
          this.statisticsData = res.data
          // console.log(res.data);
        } else {
          this.$message.error(res.data)
        }
      })
    },
    searchList() {
      this.pageConfig.currentPage = 1
      this.getList()
    },
    resetForm() {
      this.searchlist = {}
      this.pageConfig.currentPage = 1
      this.getList()
    },
    setDate(item) {
      this.examItem = item
      console.log(item);

      this.getList()
    },
    addForm() {
      this.rowData = {
        screenRecording: 2,
        notificationMode: 1,
        style: 1,
        isVerification:true
      }
      this.addDialog = true
    },
    clearAdd() {
      this.addDialog = false
      this.videoDialog = false
      this.correctDialog = false
      this.haveReadDialog = false
      this.getList()
    },
    saveAdd(data) {
      console.log(data)
      if (data.status) {
        data.status = data.status
      } else {
        data.status = '0'
      }
      post('/exam-examinee/insert', { ...data, examId: this.examItem.id }).then(
        (res) => {
          if (res.code == 1) {
            this.$message.success(this.$t('common.success'))
            this.clearAdd()
            this.getList()
          } else {
            this.$message.error(res.data)
          }
        }
      )
    },
    editClick(row, type) {
      get('/exam-examinee/' + row.id).then((res) => {
        if (res.code == 1) {
          this.rowData = res.data
          this.addDialog = true
        } else {
          this.$message.error(res.data)
        }
      })
    },
    deleteClick(row) {
      this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      })
        .then(() => {
          del('/exam-examinee/delete/' + row.id).then((res) => {
            if (res.code == 1) {
              this.$message.success(this.$t('common.success'))
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    seeVideo(row) {
      this.loading = true
      post('/exam/video-file-path', { id: row.id }).then((res) => {
        if (res.code == 1) {
          this.videoSrc = res.data
          this.loading = false
          this.videoDialog = true
        } else {
          this.$message.error(res.data)
        }
      })
    },
    haveRead(row) {
      console.log(row)
      this.haveReadDialog = true
      this.haveReadData = row

      this.haveReadTitle = this.examItem.title
    },
    backTo() {
      this.$router.go(-1)
    },
    toCorrect(row) {
      this.correctDialog = true
      this.correctData = row
      this.correctTitle = this.examItem.title
      // this.$router.push({name:'TestCorrect',query:{userData:row,title:this.examItem.title}})
    },
    consumTime(row) {
      let consumTime = 0
      if (row.startTime && row.endTime) {
        let startTime = new Date(row.startTime)
        let endTime = new Date(row.endTime)
        consumTime = ((endTime - startTime) / 1000 / 60).toFixed(1)
      } else {
        consumTime = 0
      }
      return consumTime + ' ' + this.$tc('test.min', consumTime)
    },
    isQualified(row) {
        let isQualify = ''
        if ((row.examScore||row.examScore==0) && this.examItem.passScore && row.status==5) {
            if (row.examScore >= this.examItem.passScore) {
              isQualify = this.$t('test.qualified')
            } else {
              isQualify = this.$t('test.unqualified')
            }
        } else if(row.examScore==null&&row.status==5){
            isQualify = this.$t('test.uncorrect')
        }else {
            isQualify = ''
        }
        return isQualify
    },
  },
  mounted() {
    this.setDate(JSON.parse(this.$route.query.testData))
  },
}
</script>

<style scoped>
.personnel {
  padding: 15px;
  min-height: calc(100vh - 98px);
}

.video-box {
  text-align: center;
}

.personnel-title {
  background-color: #fff;
  padding: 15px;
  margin: 10px 0;
  font-weight: bold;
  border-radius: 5px;
}

.sear {
  /*background-color: #fff;*/
  padding: 15px;
}

.back-icon {
  float: right;
  cursor: pointer;
}

.box-item {
  background-color: #fff;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.box-item p {
  margin: 10px;
}

.box-item p:last-child {
  font-weight: bold;
}
</style>
<style>
.correctDia .el-dialog__body {
  padding: 0px;
}
</style>
