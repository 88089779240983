<template>
  <div class="user">
    <div class="three">
      <el-button type="primary" size="small" @click="addForm()">{{
      $t('setup.addPaper')
      }}</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" :header-cell-style="{ background: '#e9ecf7' }" style="width: 100%">
        <el-table-column prop="title" :label="$t('setup.paperName')">
        </el-table-column>
        <el-table-column prop="questionCount" :label="$t('setup.questionNum')" width="80">
        </el-table-column>
        <el-table-column prop="scoreCount" :label="$t('setup.grade')" width="80">
        </el-table-column>
        <el-table-column prop="updateTime" :label="$t('setup.lastModifyTime')" width="200">
        </el-table-column>
        <el-table-column fixed="right" width="360" :label="$t('common.operation')">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start">
              <el-button type="success" size="mini" @click="operation('edit', scope.row)">{{ $t('common.edit') }}
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('setup.paperEdit')" placement="top-start">
              <el-button type="primary" size="mini" @click="operation('question', scope.row)">{{ $t('setup.paperEdit')
              }}</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="scope.row.isDelete>0?$t('setup.paperInuse'):$t('common.delete')" placement="top-start">
              <el-button type="info" size="mini" v-if="scope.row.isDelete>0">{{ $t('common.delete') }}
              </el-button>
              <el-button type="danger" size="mini" @click="operation('del', scope.row)" v-else>{{ $t('common.delete') }}
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('common.view')" placement="top-start">
              <el-button type="warning" size="mini" @click="operation('see', scope.row)">{{ $t('common.view') }}
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageConfig.pageSize"
          layout="total, prev, pager, next"
          :total="pageConfig.total">
      </el-pagination>
    </div>

    <!--新增-->
    <div v-if="addDialog">
      <el-dialog center :title="$t('setup.paperSet')" :visible.sync="addDialog" :before-close="clearAdd" width="30%">
        <AddTemp ref="addRef" :rowData="rowData" :dialogType="dialogType" @add="saveAdd" @clear="clearAdd"></AddTemp>
      </el-dialog>
    </div>
    <!--试题编辑-->
    <div v-if="questionDialog">
      <el-dialog center :title="$t('setup.paperEdit')" :visible.sync="questionDialog" :before-close="clearAdd"
        width="80%">
        <QuestionList ref="question" @add="saveAdd" @clear="clearAdd"></QuestionList>
      </el-dialog>
    </div>
    <!--试卷预览-->
    <div v-if="seeDialog">
      <el-dialog center :title="$t('setup.paperView')" :visible.sync="seeDialog" :before-close="clearAdd" width="80%">
        <QuestionSee ref="questionSee" :seeData="seeData" @clear="clearAdd"></QuestionSee>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import AddTemp from './add'
import QuestionSee from './see'
import QuestionList from './question/index'
export default {
  name: 'position',
  components: { AddTemp, QuestionList, QuestionSee },
  data() {
    return {
      pageConfig: {
          pageSize: 10,
          total: 0,
          pageNo: 1,
          pageSizes: this.$pageSizes,
      },
      tableData: [],
      addDialog: false,
      questionDialog: false,
      seeDialog: false,
      rowData: {},
      dialogType: 'add',
      seeData: {},
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    getList() {
      post('/paper/list', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    addForm() {
      this.rowData = {}
      this.dialogType = 'add'
      this.addDialog = true
    },
    clearAdd() {
      this.addDialog = false
      this.questionDialog = false
      this.seeDialog = false
      this.getList()
    },
    saveAdd(data) {
      post('/paper/insert', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.clearAdd()
          this.getList()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    operation(type, row) {
      if (type == 'edit') {
        get('/paper/' + row.id).then((res) => {
          if (res.code == 1) {
            this.rowData = res.data
            this.dialogType = 'edit'
            this.addDialog = true
          } else {
            this.$message.error(res.data)
          }
        })
      }
      if (type == 'del') {
        this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning',
        })
          .then(() => {
            del('/paper/delete/' + row.id).then((res) => {
              if (res.code == 1) {
                this.$message.success(this.$t('common.success'))
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => { })
      }
      if (type == 'question') {
        this.questionDialog = true
        this.$nextTick(() => {
          this.$refs['question'].setData(row.id)
        })
      }
      if (type == 'see') {
        get('/paper/show/' + row.id).then((res) => {
          if (res.code == 1) {
            res.data.questionList.sort(this.sortByKey('questionNumber'))
            this.seeData = res.data
            // this.dialogType='edit';
            this.seeDialog = true
          } else {
            this.$message.error(res.data)
          }
        })
      }
    },
    sortByKey(p) {
      return function (m, n) {
        var a = m[p]
        var b = n[p]
        return a - b //升序
      }
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style scoped>

</style>
