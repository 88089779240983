<template>
    <div>
       <el-form ref="elForm"  :model="rowData" :rules="rules" size="medium" label-width="120px" label-suffix="：">
           <el-row style="width:90%">
               <el-col :span="12">
                    <el-form-item :label="$t('setup.name')" prop="name">
                        <span>{{rowData.name}}</span>
                    </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item :label="$t('setup.position')" prop="position">
                       <span>{{rowData.position}}</span>
                    </el-form-item>
               </el-col>
           </el-row>
           <el-row style="width:90%">
               <el-col :span="12">
                    <el-form-item :label="$t('setup.mobilePhone')" prop="mobilePhone">
                        <span>{{rowData.mobilePhone}}</span>
                    </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item :label="$t('setup.email')" prop="mailbox">
                        <span>{{rowData.mailbox}}</span>
                    </el-form-item>
               </el-col>
            </el-row>
            <el-form-item :label="$t('setup.gender')" prop="sex">
                <span v-if="rowData.sex==1">{{$t('setup.male')}}</span>
                <span v-if="rowData.sex==2">{{$t('setup.female')}}</span>
                <span v-if="rowData.sex==3">{{$t('setup.unknown')}}</span>
            </el-form-item>
            <el-form-item :label="$t('setup.address')" prop="bank">
                <span>{{rowData.address}}</span>
            </el-form-item>
            <el-form-item :label="$t('setup.remarks')" prop="bankAccount">
                <span>{{rowData.remarks}}</span>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <!-- <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.save')}}
            </el-button> -->
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
        },
        data() {
            return {
                rules: {
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.$emit("add",this.rowData)
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
