<template>
    <div class="test">
        <!--<div class="left-card">-->
            <!--<el-menu-->
                    <!--default-active="1"-->
                    <!--class="el-menu-vertical-demo"-->
                    <!--@open="handleOpen"-->
                    <!--@close="handleClose">-->
                <!--<el-menu-item index="1">-->
                    <!--<i class="el-icon-menu"></i>-->
                    <!--<span slot="title">全部面试</span>-->
                <!--</el-menu-item>-->
                <!--&lt;!&ndash;<el-menu-item index="2">&ndash;&gt;-->
                    <!--&lt;!&ndash;<i class="el-icon-star-on"></i>&ndash;&gt;-->
                    <!--&lt;!&ndash;<span slot="title">星标面试</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
                <!--<el-divider></el-divider>-->
                <!--<el-menu-item index="3">-->
                    <!--<i class="el-icon-document-add"></i>-->
                    <!--<span slot="title">未开始</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="4">-->
                    <!--<i class="el-icon-time"></i>-->
                    <!--<span slot="title">进行中</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="5">-->
                    <!--<i class="el-icon-document-checked"></i>-->
                    <!--<span slot="title">已完成</span>-->
                <!--</el-menu-item>-->
                <!--&lt;!&ndash;<el-divider></el-divider>&ndash;&gt;-->
                <!--&lt;!&ndash;<el-menu-item index="6">&ndash;&gt;-->
                    <!--&lt;!&ndash;<i class="el-icon-folder-checked"></i>&ndash;&gt;-->
                    <!--&lt;!&ndash;<span slot="title">已归档</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
            <!--</el-menu>-->
        <!--</div>-->
        <div class="right-card">
           <AllTemp></AllTemp>
        </div>
        <div style="clear: both"></div>
    </div>
</template>

<script>
    import AllTemp from './interview-all/index'
    export default {
        name: "interview",
        components: {AllTemp},
        data() {
            return {



            }
        },
        methods:{
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped>
    .test{
        padding: 15px;
    }
    .left-card{
        width: 15%;
        float: left;
        height: 100vh;
        background-color: #fff;
    }
    .right-card{
        background-color: #fff;
        padding: 15px;
        min-height: calc(100vh - 128px);
        /* width: 100%; */
    }
    .table .table-title{
        background-color: #f3f5f7;
        padding: 10px 20px;
        font-size: 14px;
        color: #778ca2;
        font-weight: bold;
    }
    .table .table-content{

    }

    .table-content li{
        height: 120px;
        padding: 15px 20px;
        border-bottom: 1px solid #f3f5f7;
    }
    .table-content li:hover{
        background-color: #f8f8fd;
    }
    .table-content li div{
        line-height: 40px;
    }
    .table-content li .li-title{
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .table-content li .li-title img{
        margin-right: 10px;
    }
    .table-content li .li-title span{
        margin-left: 10px;
        font-size: 14px;
        color: #fff;
        padding: 0 15px;
        line-height: 22px;
    }
    .table-content li .li-info{
        padding-left: 30px;
    }
    .table-content li .li-info span{
        font-size: 14px;
        display: inline-block;
        width: 150px;
        color: #7b8b99;
    }
    .table-content li .li-time{
        padding-left: 30px;
    }
  .table-content li .li-time span{
      font-size: 14px;
      display: inline-block;
      color: #7b8b99;
    }

    .warning{
        background-color: #ffb61e;
    }
    .success{
        background-color: #00af9b;
    }
    .danger{
        background-color: #ff2121;
    }
</style>
