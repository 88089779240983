<template>
  <div class="correct">
    <!--<div class="personnel-title">-->
    <!--人工阅卷-->
    <!--<i class="el-icon-close back-icon" @click="backTo"></i>-->
    <!--</div>-->
    <div class="content">
      <div class="title">
        <h3>{{ title }}</h3>
        <div class="sub">
          <el-button type="primary" size="mini" v-show="userData.status != 5" @click="submitClick">{{
          $t('test.submitMark') }}</el-button>
        </div>
        <div style="clear: both"></div>
      </div>
      <el-row :gutter="22" type="flex" justify="space-between">
        <el-col :span="5">
          <!-- 左侧列表 -->
          <div class="side-card">
            <div class="side-top">
              <h4>{{ $t('test.qustionList') }}</h4>
            </div>
            <el-scrollbar style="height: 100%">
              <ul class="side-list">
                <li class="list-item" v-for="(item, index) in tableData" :key="item.id"
                  @click="questionClick(item, index)" :class="selectLi == index ? 'select-item' : ''">
                  <el-row>
                    <el-col :span="24">
                      <div class="item-title">
                        <i class="el-icon-success is-answers" style="color: #005eff" v-show="item.answers"></i>
                        {{ index + 1 }}.{{ item.title }}
                      </div>
                    </el-col>
                  </el-row>
                </li>
              </ul>
            </el-scrollbar>
          </div>
        </el-col>
        <el-col :span="19" style="padding: 0 15px 0 0">
          <!-- 试题详情 -->
          <div class="detail-card">
            <el-scrollbar style="height: 100%; width: 100%" v-loading="loading">
              <QuestionContent ref="qusetionRef" :clickItem="clickItem" :answerData="answerData"
                :haveReadonly="haveReadonly" @last="lastQuestion" @next="nextQuestion" @correctOper="correctOper"
                @correctAuto="correctAuto" />
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import QuestionContent from './correct-question'
import { post, del, get, putUrl } from '@/api/http'
export default {
  name: 'personnel-index',
  components: { QuestionContent },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    title: {
      type: String,
      default() {
        return ''
      },
    },
    haveReadonly: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      tableData: [],
      selectLi: 0,
      clickItem: {},
      clickData: {},
      answerData: {},
      loading: false,
      userID: '1473107998591844354',
    }
  },
  methods: {
    // 获取题目列表
    getList() {
      get('/exam-correct/' + this.userData.id).then((res) => {
        if (res.code == 1) {
          res.data.map(ele=>{
            if(ele)
            this.tableData.push(ele)
          })
          this.selectQuestion(this.tableData[0], 0)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    backTo() {
      this.$router.go(-1)
    },
    questionClick(item, index) {
      this.selectQuestion(item, index)
    },
    // 上一题
    lastQuestion() {
      if (this.selectLi > 0) {
        let question = this.tableData[this.selectLi - 1]
        this.selectQuestion(question, this.selectLi - 1)
      }
    },
    // 下一题
    nextQuestion() {
      if (this.selectLi < this.tableData.length - 1) {
        let question = this.tableData[this.selectLi + 1]
        this.selectQuestion(question, this.selectLi + 1)
      }else{
        this.$message.warning(this.$t('test.lastOne'))
      }
    },
    // 选中题目
    async selectQuestion(item, index) {
      this.selectLi = index
      let res = {}
      // 查询列表里题目详情
      if (item.type == '1') {
        res = await get('/question-store/find-id-choice/' + item.id)
      } else {
        res= await get('/question-store/find-id-essay/' + item.id)
      }
      if (res.code == 1) {
        if (res.data.labelId) {
          res.data.labelId = res.data.labelId.split(',')
        }
        this.clickItem = res.data
      } else {
        this.$message.error(res.data)
      }

      // 查询答题情况
      post('/exam-correct/info', {
        examineeId: this.userData.id,
        questionId: item.id,
      }).then((res) => {
        if (res.code == 1) {
          this.answerData = res.data
          // 题目没有成绩才会自动批改
          if(res.data.answerScore==null){
            // console.log(index+1,'未批改');
            this.correctAuto(this.clickItem)
          }else{
            // console.log(index+1,'已批改');
          }
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 手动批改
    correctOper() {
      let msg = ''
      // 判断赋值
      let autoScore = ''
      this.clickItem.answers == this.answerData.answer?
      autoScore = this.clickItem.score:
      autoScore = ''
      this.$prompt(this.$t('test.score'), this.$t('test.manualMark'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        inputValue:autoScore,
        inputValidator:(value)=>{
          if(value>this.clickItem.score){
            msg = this.$message.warning(this.$t('test.exceedScore'))
            return !(value>this.clickItem.score)
          }
        },
        inputErrorMessage:msg
      }).then(({ value }) => {
          post('/exam-correct/input', {
            answerScore: value,
            sheetId: this.answerData.id,
          }).then((res) => {
            if (res.code == 1) {
              this.scoreValue = value
              this.$message({
                type: 'success',
                message: this.$t('common.success'),
              })
              this.answerData.answerScore = value
              // this.$refs.qusetionRef.getScore(this.answerData)
            } else {
              this.$message.error(res.data)
            }
          })
        })
        .catch(() => { })
    },
    // 自动批改
    correctAuto(data) {
      let useranswer = this.anagrams(this.answerData.answer)
      let answer = this.anagrams(data.answers)
      // console.log(useranswer,'useranswer');
      // console.log(answer,'answer');
      let submitScore = useranswer == answer? data.score:0
      post('/exam-correct/input', {
        answerScore: submitScore,
        sheetId: this.answerData.id,
      }).then((res) => {
        if (res.code == 1) {
          this.answerData.answerScore = submitScore
          this.$refs.qusetionRef.getScore(this.answerData)
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 答案排序
    anagrams (str) {
        var arr = str.split(',')
        arr.sort(function (s1, s2) {
            let x1 = s1.toUpperCase();
            let x2 = s2.toUpperCase();
            if (x1 < x2) {
                return -1;
            }
            if (x1 > x2) {
                return 1;
            }
            return 0;
        }); 
        return arr.toString()
    },
    // 试卷提交
    submitClick() {
      post('/exam-correct/over', {
        examineeId: this.userData.id,
        status: 5,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: 'success',
            message: this.$t('common.success'),
          })
          this.$emit('clear')
        } else {
          this.$message.error(res.data)
        }
      })
    },
  },
  mounted() {
    // this.userData=this.$route.query.userData;
    // this.title=this.$route.query.title;
    this.getList()
    //console.log(this.$route.query.title)
  },
}
</script>

<style scoped>
.correct {}

.video-box {
  text-align: center;
}

.personnel-title {
  background-color: #fff;
  padding: 15px;
  margin: 10px 0;
  font-weight: bold;
}

.sear {
  background-color: #fff;
  padding: 15px;
}

.back-icon {
  float: right;
  cursor: pointer;
}

.content {
  padding: 15px;
  background-color: #ececf4;
}

.content .title {
  background-color: #fff;
  padding: 20px 15px;
  margin-bottom: 15px;
  position: relative;
}

.content .title h3 {
  float: left;
}

.content .title .time {
  float: right;
  vertical-align: middle;
  margin-right: 20px;
}

.content .title .time p {
  font-size: 18px;
  color: #ff0000;
  font-weight: bold;
}

.content .title .time img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 15px;
}

.content .title .sub {
  float: right;
}

.form-header {
  padding: 12px 20px;
  height: 35px;
  background-color: #fff;
  margin-bottom: 10px;
}

.el-dropdown button {
  background-color: #efefff;
  border-color: #a9a9e3;
  color: #333399;
}

.form-header .el-col {
  line-height: 35px;
}

.side-card {
  background-color: #fff;
  height: calc(100vh - 260px);
  overflow: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.side-card .side-top {
  padding: 10px 20px;
  border-bottom: 1px solid #ebebf5;
}

.side-card .side-top h4 {
  font-size: 14px;
}

.side-card .side-list {
  height: calc(100vh - 207px);
}

.list-item {
  padding: 15px;
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

.list-checkbox {
  position: absolute;
  right: 15px;
  top: 15px;
}

.list-item p {
  line-height: 35px;
}

.item-title {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  padding: 0 30px;
  position: relative;
}

.is-answers {
  position: absolute;
  left: 12px;
  top: 3px;
}

.item-tag {
  margin-top: 15px;
}

.operate {
  line-height: 100px;
}

.el-tag {
  margin-left: 20px;
}

.list-item:hover {
  background-color: #f5f5fa;
}

/* center */
.detail-card {
  background-color: #fff;
  border-radius: 2px;
  min-width: 100%;
}

/* right */
.right-card {
  background-color: #fff;
  border-radius: 2px;
  height: calc(100vh - 167px);
}

.right-header {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.right-card .info {
  padding: 10px 20px;
}

.right-header .info .item {
  color: #515151;
}

.right-card .info p {
  line-height: 40px;
  font-size: 14px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}

.select-item {
  background-color: #f7f8f9;
  border-right: 1px solid #005eff;
}

.select-item .item-title {
  color: #005eff;
}
</style>
