function setTime(data) {
    let time = new Date(data)
    let Year = time.getFullYear()
    let Month = time.getMonth() + 1
    let Day = time.getDate()
    let Hours = time.getHours()
    let Minutes = time.getMinutes()
    let Seconds = time.getSeconds()
    return (
        Year +
        '-' +
        add0(Month) +
        '-' +
        add0(Day) +
        ' ' +
        add0(Hours) +
        ':' +
        add0(Minutes) +
        ':' +
        add0(Seconds)
    )
}
//补0
function add0(time) {
    if (time < 10) {
        return '0' + time
    } else {
        return time
    }
}
export default setTime
