<template>
    <div class="add-temp">
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="120px">
            <el-form-item :label="$t('setup.region')" required :error="errorMessage">
            <!-- <el-form-item :label="$t('setup.region')" prop="citys" required :error="errorMessage"> -->
                <el-cascader
                    v-model="citys"
                    :options="cityList"
                    @change="handleCityChange"
                    class="input-width"
                    :style="{width: '80%'}"
                ></el-cascader>
            </el-form-item>
            <el-form-item :label="$t('setup.detailAddress')" prop="fullAddress">
                <el-input v-model="rowData.fullAddress" clearable :style="{width: '80%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    import CN from '@/assets/js/cn.js'
    import US from '@/assets/js/us.js'
    import Cookies from 'js-cookie'
    export default {
        name: "add",
        components: {
        },
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        computed:{
            ifAddress: function () {
                return this.citys.length!==0 || (this.rowData.address&&this.rowData.address!=='')
            },
        },
        data() {
            const vm = window.vm;
            return {
                vm: vm,
                citys:[],
                cityList:[],
                errorMessage:'',
                rules: {
                    // citys: [{
                    //     required: true,
                    //     message: this.$t('common.pleaseEnter')+this.$t('setup.region')+'!',
                    //     trigger: 'blur'
                    // }],
                    fullAddress: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.detailAddress')+'!',
                        trigger: 'blur'
                    }],
                },
                language:Cookies.get('language'),
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    this.changeRequire()
                    if (!valid || !this.ifAddress) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.$emit("add",this.rowData)
                    }
                })
            },
            handleCityChange(value) {
                // console.log(value);
                // if(value==''){
                //     this.errorMessage = this.$t('common.pleaseEnter')+this.$t('setup.region')+'!'
                // }else{
                    this.rowData.address = value.toString()
                    this.changeRequire()
                    // this.rowData.address = value.join('')
                    // console.log(this.citys);
                // }
            },
            changeRequire(){
                if(this.ifAddress){
                    this.errorMessage = ''
                }else{
                    this.errorMessage = this.$t('common.pleaseEnter')+this.$t("setup.region") + "!"
                }
            },
            clearAdd() {
                this.$emit("clear");
            },
        },
        mounted() {
            if(this.rowData.address){
                this.citys=this.rowData.address.split(',')
            }
        },
        created() {
            if (this.language == 'zh-Cn') {
            this.cityList = CN[0].children
            } else {
            this.cityList = US[0].children
            }
        },
    }
</script>

<style scoped>

</style>
