<template>
  <div class="select-time">
    <el-form
      ref="selectForm"
      :model="searchlist"
      :rules="rules"
      label-suffix="："
      size="mini"
      :inline="true"
    >
      <el-row type="flex" class="row-bg">
        <el-col :span="21">
          <el-form-item>
            <el-select
              v-model="searchlist.status"
              :placeholder="$t('common.pleaseSelect') + $t('common.status')"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getList" size="mini">{{
              $t('common.query')
            }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="resetForm" size="mini">{{
              $t('common.reset')
            }}</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="text-align: right">
          <el-button type="primary" @click="addClick" size="mini">{{
            $t('common.add')
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column :label="$t('interview.candidate')">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('interview.interviewer')">
        <template slot-scope="scope">
          {{ scope.row.enterpriseUserName }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('interview.selectedDate')">
        <template slot-scope="scope">{{ scope.row.selectedTime }}</template>
      </el-table-column>
      <el-table-column :label="$t('common.status')">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">{{
            $t('interview.candidateChoice')
          }}</span>
          <span v-else-if="scope.row.status == 2">{{
            $t('interview.candidateReview')
          }}</span>
          <span v-else-if="scope.row.status == 3">{{
            $t('interview.candidateAgree')
          }}</span>
          <span v-else>{{ $t('interview.candidateSuccess') }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operation')">
        <template slot-scope="scope">
          <template v-if="scope.row.status == 1 || scope.row.status == 3">
            <el-button type="text" @click="interviewYes(scope.row)" disabled
              >{{ $t('interview.interviewerConfirm') }}
            </el-button>
          </template>
          <template v-else>
            <el-button type="text" @click="interviewYes(scope.row)" :disabled="scope.row.status == 4">{{
              $t('interview.interviewerConfirm')
            }}</el-button>
          </template>
          <template v-if="scope.row.status == 1 || scope.row.status == 2">
            <el-button type="text" @click="createInterview(scope.row)" disabled
              >{{ $t('interview.generateInterview') }}
            </el-button>
          </template>
          <template v-else>
            <el-button type="text" @click="createInterview(scope.row)" :disabled="scope.row.status == 4">
              {{ $t('interview.generateInterview') }}
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-sizes="pageConfig.pageSizes"
      :total="pageConfig.total"
    ></el-pagination>
    <!-- <div class="select-timeBtn">
      <el-button type="primary" size="small" @click="selTimeDialog = true">
        候选人选择时间界面点此查看
      </el-button>
    </div> -->
    <div class="boontbtn">
      <el-button size="small" @click="$emit('clear')">{{
        $t('common.close')
      }}</el-button>
    </div>
    <!-- 新增 -->
    <div v-if="addDialog">
      <el-dialog
        append-to-body
        :title="$t('common.add')"
        :visible.sync="addDialog"
        width="30%"
      >
        <InterviewAdd
          @clearAdd="addDialog = false"
          @sendAddClick="sendAddClick"
        ></InterviewAdd>
      </el-dialog>
    </div>
    <!-- 面试确认 -->
    <div v-if="interviewDialog">
      <el-dialog
        append-to-body
        :title="$t('interview.interviewerConfirm')"
        :visible.sync="interviewDialog"
        width="900px"
      >
        <InterviewYes
          :yesRowDate="yesRowDate"
          @yesClick="interviewClick"
          @sendClick="interviewSendClick"
          @clear="interviewDialog = false"
        ></InterviewYes>
      </el-dialog>
    </div>
    <!-- 生成面试 -->
    <div v-if="createInterviewDialog">
      <el-dialog
        append-to-body
        :title="$t('interview.generateInterview')"
        :visible.sync="createInterviewDialog"
        width="50%"
      >
        <createInterview
          ref="rowCreateRef"
          :rowCreateData="rowCreateData"
          :positionList="positionList"
          @createClick="createInterviewClick"
          @clearCreate="createInterviewDialog = false"
        ></createInterview>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/api/http'
import InterviewAdd from './add'
import InterviewYes from './interview-yes'
import createInterview from './create'

export default {
  data() {
    return {
      searchlist: {},
      statusList: [
        {
          value: '1',
          label: this.$t('interview.candidateChoice'),
        },
        {
          value: '2',
          label: this.$t('interview.candidateReview'),
        },
        {
          value: '3',
          label: this.$t('interview.candidateAgree'),
        },
        {
          value: '4',
          label: this.$t('interview.candidateSuccess'),
        },
      ],
      tableData: [],
      rules: {},
      addDialog: false,
      interviewDialog: false,
      createInterviewDialog: false,
      rowCreateData: {},
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
    }
  },

  components: {
    InterviewYes,
    InterviewAdd,
    createInterview,
  },
  created() {
    this.getPosition()
    this.getList()
  },

  methods: {
    addClick() {
      this.addDialog = true
    },
    sendAddClick(data) {
      //console.log(data, 2)
      post('/inviteCandidates/insert', {
        ...data,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.getList()
          this.addDialog = false
        } else {
          this.$message.error(res.data)
        }
      })
    },
    interviewYes(row) {
      this.interviewDialog = true

      this.yesRowDate = row
      //console.log(row)
    },
    interviewClick(data) {
      post('/inviteCandidates/update', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.interviewDialog = false
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    interviewSendClick(data) {
      post('/inviteCandidates/update', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.interviewDialog = false
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    createInterview(row) {
      let time = row.selectedTime.split(' ')
      let timeF = time[1].split('~')
      let radioValue = time[0]

      let startTime = timeF[0]
      let endTime = timeF[1]
      // get('/video-interview/' + row.id).then((res) => {
      //   if (res.code == 1) {
      //     // this.rowCreateData = res.data
      //     // this.createInterviewDialog = true
      //   } else {
      //     this.$message.error(res.data)
      //   }
      // })

      this.rowCreateData = row
      this.createInterviewDialog = true
    },
    createInterviewClick(data) {
      //console.log(data)
      data.inviteCandidatesId = data.id
      delete data.id
      post('/video-interview/insert', {
        candidatePhonePrefix: data.phoneNumberPrefix,
        candidateName: data.name,
        candidateSex: data.sex,
        candidateEmail: data.mailbox,
        candidatePhone: data.phoneNumber,
        //interviewTime: data.selectedTime,
        ...data,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.getList()
          this.createInterviewDialog = false
          this.$emit('update')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取职位列表
    getPosition() {
      post(
        '/dict-position/list',
        {
          page: 1,
          limit: 1000,
        },
        true
      ).then((res) => {
        if (res.code == 1) {
          this.positionList = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    getList() {
      post('/inviteCandidates/getPage', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
        ...this.searchlist,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    resetForm() {
      this.searchlist = {}
      this.getList()
    },

    selTimeDialogClick(data) {
      this.selTimeDialog = false
    },
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    //时间段选择赋值参数
    timgChange() {
      if (this.timeValue) {
        this.searchlist.beginTime = this.timeValue[0]
        this.searchlist.overTime = this.timeValue[1]
      } else {
        this.searchlist.beginTime = ''
        this.searchlist.overTime = ''
      }
    },
  },
}
</script>
<style scoped>
.select-time {
  padding: 15px;
}
.select-timeBtn {
  margin: 15px 0;
}
</style>
