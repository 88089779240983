<template>
  <div class="share-tip">
    <el-row type="flex" justify="space-around" v-show="flag">
      <el-col :span="1"></el-col>
      <el-col :span="3">
        <i class="el-icon-warning-outline share-tip-icon"></i>
      </el-col>
      <el-col :span="19">
        <div class="share-tip-word">
          {{ $t('question.shareTip') }}<br />
          <span> ({{ $t('question.shareTip1') }}) </span>
        </div>
      </el-col>
      <el-col :span="1"></el-col>
    </el-row>
    <el-row type="flex" justify="center" v-show="!flag">
      <el-col :span="3"></el-col>
      <el-col :span="18">
        {{ $t('question.submitShare') }}
        <span class="share-tip-record" @click="$emit('shareRecord')">
          {{ $t('question.viewShare') }}
        </span>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
    <div class="boontbtn" v-show="flag">
      <el-button
        type="primary"
        size="small"
        style="margin: 0 30px"
        @click="submit"
      >
        {{ $t('common.ok') }}
      </el-button>
      <el-button size="small" @click="$emit('clear')">
        {{ $t('common.cancel') }}</el-button
      >
    </div>
    <div class="boontbtn" v-show="!flag">
      <el-button size="small" @click="$emit('clear')">{{
        $t('common.close')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { post } from '@/api/http'
export default {
  props: {
    shareTipData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      flag: true,
    }
  },

  components: {},

  computed: {},

  created() {},

  methods: {
    submit() {
      post('/questionSharedRecord/insert', {
        questionId: this.shareTipData.id,
        status: 1,
      }).then((res) => {
        if (res.code == 1) {
          this.flag = false
          this.$emit('getList')
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
.share-tip-icon {
  font-size: 48px;
  color: #ff2121;
}
.share-tip-word span {
  font-size: 12px;
  color: #999;
}
.share-tip-record {
  cursor: pointer;
  color: #5c5cad;
}
</style>
