<template>
    <div class="add-temp">
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="180px">
            <el-form-item :label="$t('setup.invoiceType')" prop="type">
                <el-radio-group v-model="rowData.type">
                    <el-radio :label="1">{{$t('setup.enterUnit')}}</el-radio>
                    <el-radio :label="0">{{$t('setup.INDuint')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('setup.invoicetit')" prop="title">
                <el-input v-model="rowData.title" :placeholder="$t('common.pleaseEnter')+$t('setup.invoicetit')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.taxNum')" prop="dutyParagraph">
                <el-input v-model="rowData.dutyParagraph" :placeholder="$t('common.pleaseEnter')+$t('setup.taxNum')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.registAddr')" prop="address">
                <el-input v-model="rowData.address" :placeholder="$t('common.pleaseEnter')+$t('setup.registAddr')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.registtele')" prop="tel">
                <el-input v-model="rowData.tel" :placeholder="$t('common.pleaseEnter')+$t('setup.registtele')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.depositBank')" prop="bank">
                <el-input v-model="rowData.bank" :placeholder="$t('common.pleaseEnter')+$t('setup.depositBank')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('setup.bankAccount')" prop="bankAccount">
                <el-input v-model="rowData.bankAccount" :placeholder="$t('common.pleaseEnter')+$t('setup.bankAccount')" clearable :style="{width: '70%'}">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
                {{$t('common.ok')}}
            </el-button>
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "add",
        props:{
            rowData:{
                type: Object,
                default() {
                    return {};
                }
            },
            dialogType:{
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            return {
                rules: {
                    title: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.invoicetit')+'!',
                        trigger: 'blur'
                    }], dutyParagraph: [{
                        required: true,
                        message: this.$t('common.pleaseEnter')+this.$t('setup.taxNum')+'!',
                        trigger: 'blur'
                    }],
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    }else {
                        this.$emit("add",this.rowData)
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
