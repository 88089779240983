<template>
  <div>
    <el-form ref="elForm" :model="fillData" :rules="rules" size="medium" label-width="270px" label-suffix="：">
      <el-row>
        <el-col :span="24" class="basic-info">
          <div class="fs16 bold">{{ $t('candidate.basicInfo') }}</div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.candidateName')" prop="candidateName">
            <el-input v-model="fillData.candidateName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.phone')" prop="candidatePhone">
            <el-input v-model="fillData.candidatePhone" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.email')">
            <el-input v-model="fillData.candidateEmail" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.workLocation')">
            <el-select v-model="fillData.workLocation" :placeholder="
              $t('common.pleaseSelect') + $t('candidate.workLocation')
            ">
              <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
            <el-input v-if="
              fillData.workLocation == $t('candidate.specificPlace') ||
              fillData.workLocation == $t('candidate.goSomewhere')
            " v-model="fillData.workLocationAddress"
              :placeholder="$t('common.pleaseEnter') + $t('candidate.cityName')" class="fill-input"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.workStatus')">
            <el-select v-model="fillData.workIdentity" :placeholder="
              $t('common.pleaseSelect') + $t('candidate.workStatus')
            ">
              <el-option v-for="item in workOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.jobHuntingStatus')">
            <el-select v-model="fillData.jobHuntingStatus" :placeholder="
              $t('common.pleaseSelect') + $t('candidate.jobHuntingStatus')
            ">
              <el-option v-for="item in jobOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.whatsAccount')">
            <el-input v-model="fillData.whatsAccount" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.whatsAccount')
            "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.linkedinAccount')">
            <el-input v-model="fillData.linkedinAccount" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.linkedinAccount')
            "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.facebookAccount')">
            <el-input v-model="fillData.facebookAccount" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.facebookAccount')
            "></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.weChatAccount')">
            <el-input v-model="fillData.weChatAccount" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.weChatAccount')
            "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.cptSponsorship')">
            <el-select v-model="fillData.cptSponsorship" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.marketingServices')">
            <el-select v-model="fillData.marketingServices" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.hOneBSponsorship')">
            <el-select v-model="fillData.honeBSponsorship" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.gcSponsorship')">
            <el-select v-model="fillData.gcSponsorship" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.optExtensionSponsorship')">
            <el-select v-model="fillData.optExtensionSponsorship" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="9">
          <el-form-item :label="$t('candidate.technicalTraining')">
            <el-select v-model="fillData.technicalTraining" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in sponsorshipOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('candidate.degree')">
            <el-select v-model="fillData.degree" :placeholder="$t('common.pleaseSelect')">
              <el-option v-for="item in schoolOptions" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9"> </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="24">
          <el-form-item :label="$t('candidate.currentPlaceWork')">
            <!-- <el-select
              v-model="fillData.currentPlaceWork"
              placeholder="城市"
              style="width: 23%; margin-right: 10px"
            >
              <el-option
                v-for="item in sponsorshipOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select> -->
            <el-cascader size="large" :options="options" v-model="selectedWorkOptions" @change="currentPlaceWork"
              :placeholder="$t('common.pleaseSelect') + $t('candidate.city')" style="width: 23%; margin-right: 10px">
            </el-cascader>
            <el-input style="width: 30%" v-model="fillData.currentPlaceWorkDetails" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.detailedAddress')
            "></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="basic-box">
        <el-col :span="24">
          <el-form-item :label="$t('candidate.currentPlaceResidence')">
            <!-- <el-select
              v-model="fillData.currentPlaceResidence"
              placeholder="城市"
              style="width: 23%; margin-right: 10px"
            >
              <el-option
                v-for="item in sponsorshipOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select> -->
            <el-cascader size="large" :options="options" v-model="selectedResidenceOptions"
              @change="currentPlaceResidence" :placeholder="$t('common.pleaseSelect') + $t('candidate.city')"
              style="width: 23%; margin-right: 10px">
            </el-cascader>
            <el-input style="width: 30%" v-model="fillData.currentPlaceResidenceDetails" :placeholder="
              $t('common.pleaseEnter') + $t('candidate.detailedAddress')
            "></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="basic-info">
          <div>
            <span class="fs16">{{ $t('candidate.educationIfo') }}</span>
            <el-button type="primary" size="medium" class="select-btn" @click="addEducation">{{ $t('common.add') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="basic-info">
          <el-table :data="fillData.examEducationList" style="width: 100%">
            <el-table-column :label="$t('candidate.schoolName')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" :placeholder="$t('common.pleaseEnter')"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('candidate.studyTime')" width="400">
              <template slot-scope="scope">
                <el-date-picker v-model="scope.row.time" type="daterange" :start-placeholder="$t('candidate.startDate')"
                  :end-placeholder="$t('candidate.endDate')" format="yyyy - MM - dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss" @change="educationChange(scope.row.time, scope.$index)">
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column :label="$t('candidate.profession')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.profession" :placeholder="$t('common.pleaseEnter')"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('candidate.degree')">
              <template slot-scope="scope">
                <el-select v-model="scope.row.degree" :placeholder="$t('common.pleaseSelect')">
                  <el-option v-for="item in schoolOptions" :key="item.value" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column :label="$t('candidate.achievement')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.achievement" :placeholder="$t('common.pleaseSelect')"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')">
              <template slot-scope="scope">
                <!-- <span
                  class="remove"
                  @click="removeEducation(scope.row, scope.$index)"
                  >删除</span
                > -->
                <el-popover placement="top" width="160" v-model="scope.row.educationVisible">
                  <p>{{ $t('common.sureToDel') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.educationVisible = false">{{
                    $t('common.cancel') }}</el-button>
                    <el-button type="primary" size="mini" @click="removeEducation(scope.row, scope.$index)">{{
                    $t('common.ok') }}</el-button>
                  </div>
                  <!-- <el-button slot="reference">删除</el-button> -->
                  <span class="remove" slot="reference">{{
                  $t('common.delete')
                  }}</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="basic-info">
          <div>
            <span class="fs16">{{ $t('candidate.workExperience') }}</span>
            <el-button type="primary" size="medium" class="select-btn" @click="addWork">{{ $t('common.add') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-table :data="fillData.examWorkExperienceList" style="width: 100%">
          <el-table-column :label="$t('candidate.companyName')" width="300">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" :placeholder="$t('common.pleaseEnter')"></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('candidate.workingTime')" width="400">
            <template slot-scope="scope">
              <el-date-picker v-model="scope.row.workTime" type="daterange"
                :start-placeholder="$t('candidate.startDate')" :end-placeholder="$t('candidate.endDate')"
                format="yyyy - MM - dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                @change="workChange(scope.row.workTime, scope.$index)">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column :label="$t('candidate.position')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.position" :placeholder="$t('common.pleaseEnter')"></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')">
            <template slot-scope="scope">
              <!-- <span class="remove" @click="removeWork(scope.row, scope.$index)"
                >删除</span
              > -->
              <el-popover placement="top" width="160" v-model="scope.row.experienceVisible">
                <p>{{ $t('common.sureToDel') }}</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="scope.row.experienceVisible = false">{{ $t('common.cancel')
                  }}</el-button>
                  <el-button type="primary" size="mini" @click="removeWork(scope.row, scope.$index)">{{ $t('common.ok')
                  }}</el-button>
                </div>

                <span class="remove" slot="reference">{{
                $t('common.delete')
                }}</span>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-form>
    <div class="boontbtn">
      <el-button type="primary" size="small" style="margin: 0 30px" @click="submitForm()">
        {{ $t('candidate.saveTest') }}
      </el-button>
      <el-button size="small" @click="skipClick()">
        {{ $t('candidate.skipTest') }}</el-button>
    </div>
  </div>
</template>

<script>
import cnCity from '../../../api/cn'
import usCity from '../../../api/us'
import { post } from '../../../api/http'
import Cookies from 'js-cookie'
export default {
  props: {
    fillData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      areaOptions: [
        { label: this.$t('candidate.request'), value: '1' },
        { label: this.$t('candidate.specificPlace'), value: '2' },
        { label: this.$t('candidate.goSomewhere'), value: '3' },
        { label: this.$t('candidate.other'), value: '4' },
      ],
      workOptions: [
        { label: 'OPT (year one)', value: '1' },
        { label: 'CPT', value: '2' },
        { label: 'H1B', value: '3' },
        { label: 'GC', value: '4' },
        { label: 'Citzen', value: '5' },
        { label: 'OPT extension', value: '6' },
        { label: 'other', value: '7' },
      ],
      jobOptions: [
        { label: this.$t('candidate.Leaveavailabl'), value: '1' },
        { label: this.$t('candidate.jobWithin'), value: '2' },
        { label: this.$t('candidate.jobConsider'), value: '3' },
        { label: this.$t('candidate.jobYet'), value: '4' },
      ],
      sponsorshipOptions: [
        { label: this.$t('candidate.accepted'), value: '1' },
        { label: this.$t('candidate.paid'), value: '2' },
        { label: this.$t('candidate.freeCharge'), value: '3' },
        { label: this.$t('candidate.depends'), value: '4' },
        { label: this.$t('candidate.na'), value: '5' },
      ],
      schoolOptions: [
        { label: this.$t('candidate.postgraduate'), value: '1' },
        { label: this.$t('candidate.undergraduate'), value: '2' },
        { label: this.$t('candidate.aLevel'), value: '3' },
        { label: this.$t('candidate.juniorMiddleSchool'), value: '4' },
        { label: this.$t('candidate.primarySchool'), value: '5' },
      ],
      options: [],
      selectedWorkOptions: [],
      selectedResidenceOptions: [],
      language: Cookies.get('language'),
      rules: {},
    }
  },

  components: {},

  created() {
    //console.log(this.language)
    if (this.language == 'zh-Cn') {
      this.options = cnCity[0].children
    } else {
      this.options = usCity[0].children
    }
  },
  mounted() {
    //console.log(this.sponsorshipOptions)
    if (this.fillData.examEducationList.length > 0) {
      this.fillData.examEducationList.forEach((item, index) => {
        item.time = [item.studyTimeStart, item.studyTimeEnd]
        item.educationVisible = false
      })
    }
    if (this.fillData.examWorkExperienceList.length > 0) {
      this.fillData.examWorkExperienceList.forEach((item, index) => {
        item.workTime = [item.workingTimeStart, item.workingTimeEnd]
        item.experienceVisible = false
      })
    }
    if (this.fillData.currentPlaceWorkCode) {
      //console.log(this.fillData.currentPlaceWorkCode.split(','))
      this.selectedWorkOptions = this.fillData.currentPlaceWorkCode.split(',')
    }
    if (this.fillData.currentPlaceResidenceCode) {
      //console.log(this.fillData.currentPlaceResidenceCode.split(','))
      this.selectedResidenceOptions =
        this.fillData.currentPlaceResidenceCode.split(',')
    }
    //console.log(this.fillData)
  },

  methods: {
    citySelect() { },
    currentPlaceWork(value) {
      // if (value[1] != null && value[2] != null) {
      //   var dz =
      //     CodeToText[value[0]] +
      //     '/' +
      //     CodeToText[value[1]] +
      //     '/' +
      //     CodeToText[value[2]]
      // } else {
      //   if (value[1] != null) {
      //     dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
      //   } else {
      //     dz = CodeToText[value[0]]
      //   }
      // }
      //console.log(value)
      this.fillData.currentPlaceWorkCode = value.join(',')
      //this.fillData.currentPlaceWork = dz
    },
    currentPlaceResidence(value) {
      // if (value[1] != null && value[2] != null) {
      //   var dz =
      //     CodeToText[value[0]] +
      //     '/' +
      //     CodeToText[value[1]] +
      //     '/' +
      //     CodeToText[value[2]]
      // } else {
      //   if (value[1] != null) {
      //     dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
      //   } else {
      //     dz = CodeToText[value[0]]
      //   }
      // }
      this.fillData.currentPlaceResidenceCode = value.join(',')
      //this.fillData.currentPlaceResidence = dz
    },
    addEducation() {
      this.fillData.examEducationList.push({ educationVisible: false })
      //console.log(this.educationData)
    },
    educationChange(value, index) {
      //console.log(value)

      if (value) {
        this.fillData.examEducationList[index].studyTimeStart = value[0]
        this.fillData.examEducationList[index].studyTimeEnd = value[1]
      } else {
        this.fillData.examEducationList[index].studyTimeStart = ''
        this.fillData.examEducationList[index].studyTimeEnd = ''
      }
      //delete this.fillData.examEducationList[index].workTime
    },
    removeEducation(row, index) {
      if (row.id) {
        post('/examEducation/delete', {
          id: row.id,
        }).then((res) => {
          if (res.code == 1) {
            row.educationVisible = false
            this.fillData.examEducationList.splice(index, 1)
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        this.fillData.examEducationList.splice(index, 1)
      }
    },

    addWork() {
      this.fillData.examWorkExperienceList.push({ experienceVisible: false })
      //console.log(this.workData)
    },
    workChange(value, index) {
      if (value) {
        this.fillData.examWorkExperienceList[index].workingTimeStart = value[0]
        this.fillData.examWorkExperienceList[index].workingTimeEnd = value[1]
      } else {
        this.fillData.examWorkExperienceList[index].workingTimeStart = ''
        this.fillData.examWorkExperienceList[index].workingTimeEnd = ''
      }

      //delete this.fillData.examWorkExperienceList[index].time
    },
    removeWork(row, index) {
      if (row.id) {
        post('/examWorkExperience/delete', {
          id: row.id,
        }).then((res) => {
          if (res.code == 1) {
            row.experienceVisible = false
            this.fillData.examWorkExperienceList.splice(index, 1)
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        this.fillData.examWorkExperienceList.splice(index, 1)
      }
    },

    submitForm() {
      this.$emit('submitForm', this.fillData)
    },
    skipClick() {
      this.$emit('skipClick')
    },
  },
}
</script>
<style scoped>
.basic-info {
  margin-bottom: 15px;
}

.basic-box {
  margin-bottom: 15px;
}

.fs16 {
  font-size: 16px;
}

.fill-input {
  margin-top: 15px;
}

.select-btn {
  margin-left: 10px;
  padding: 5px 15px;
}

.boontbtn {
  margin-bottom: 20px;
}
</style>
<style>
.el-select {
  width: 100%;
}

.remove {
  color: rgb(253, 120, 120);
}
</style>
