<template>
    <div class="info">
        <el-tabs v-model="infoType" type="card">
            <el-tab-pane :label="$t('setup.baseInfo')">
                <Essential></Essential>
            </el-tab-pane>
            <el-tab-pane :label="$t('setup.businessInfo')">
                <!--工商信息可以-->
                <Business></Business>
            </el-tab-pane>
            <el-tab-pane :label="$t('setup.CoAdress')">
                <CoAddress></CoAddress>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Business from './Business'
    import Essential from './Essential'
    import CoAddress from './Address'
    export default {
        name: "index",
        components: {Business,Essential,CoAddress},
        data() {
            return {
                infoType: '0',
            }
        },
        methods:{

        },
        mounted(){

        }
    }
</script>

<style scoped>


</style>
