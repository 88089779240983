<template>
    <div class="add-temp">
        <el-form ref="elForm" :model="rowData" :rules="rules" size="medium" label-width="160px" label-suffix="：">
            <el-form-item :label="$t('interview.position')" prop="positionId">
                {{rowData.positionName}}
            </el-form-item>
            <el-form-item :label="$t('interview.candidate')" prop="candidateName">
               {{rowData.candidateName}}-{{rowData.candidateSex=='1'?$t('interview.male'):$t('interview.female')}}-{{rowData.candidateEmail}}-{{rowData.candidatePhone}}
            </el-form-item>
            <el-form-item :label="$t('interview.resume')">
                 <span @click="showFile" class="file-tip">{{rowData.sourceName}}</span>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewExplain')" prop="explain">
                <el-input type="textarea" rows="5" v-model="explain" readonly></el-input>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewer')" prop="user">
                <span v-for="item in rowData.list" :key="item.id">{{item.username}},</span>
            </el-form-item>
            <el-form-item :label="$t('interview.interviewTime')" prop="interviewTime">
               {{rowData.interviewTime}}
            </el-form-item>
            <el-form-item :label="$t('interview.interviewLink')" prop="interviewTime">
               {{rowData.faceToFaceLink}}
            </el-form-item>
            <el-form-item :label="$t('interview.remarks')" prop="interviewTime">
                {{rowData.remark}}
            </el-form-item>
        </el-form>
        <div class="boontbtn">
            <el-button type="info" size="small" @click="clearAdd">{{$t('common.cancel')}}</el-button>
        </div>
        <!--添加用户-->
        <div v-if="userDialog">
            <el-dialog append-to-body :title="$t('interview.addInterviewer')" :visible.sync="userDialog" :before-close="clearUser" width="30%"
                       top="100px">
                <UserSelete ref="addRef" @add="saveUser" @clear="clearUser"></UserSelete>
            </el-dialog>
        </div>
        <!--查看简历-->
        <el-dialog append-to-body
                    :title="$t('interview.viewResume')"
                    :visible.sync="fileDialog"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    width="70%" top="60px">
            <div class="endMask">
                <div class="iFrame" v-if="iframeUrl" >
                    <iframe style="width: 100%;height: calc( 100vh - 220px )" :src="iframeUrl"></iframe>
                </div>
                <div class="boontbtn">
                    <el-button type="info" size="small" @click="fileDialog=false">{{$t('common.cancel')}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import request from "@/utils/request.js";
    import {post, del, get, putUrl} from "@/api/http";
    import baseURL from '@/api/baseURL.js'
    import {getToken} from '@/utils/auth'
    import UserSelete from '@/views/publish/userSelete'

    export default {
        name: "add",
        components: {UserSelete},
        props: {
            rowData: {
                type: Object,
                default() {
                    return {};
                }
            }, positionList: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                value: '',
                rules: {
                    positionId: [{
                        required: true,
                        message: this.$t('common.pleaseSelect')+this.$t('interview.position')+'!',
                        trigger: 'blur'
                    }], candidateName: [{
                        required: true,
                        message: this.$t('common.pleaseSelect')+this.$t('interview.candidate')+'!',
                        trigger: 'blur'
                    }], interviewTime: [{
                        required: true,
                        message: this.$t('common.pleaseSelect')+this.$t('interview.interviewTime')+'!',
                        trigger: 'blur'
                    }],
                },
                explain: this.$t('interview.interviewTips'),
                userList: [],
                user: '',
                positionListId: '',
                fileList: [],
                uploadHeader: {},
                baseURL: baseURL,
                uploadIdList: '',
                sms: false,
                email: false,
                userDialog: false,
                fileDialog: false,
                iframeUrl:'',
                userData: []
            }
        },
        watch: {},
        methods: {
            submitForm() {
                this.$refs['elForm'].validate(valid => {
                    if (!valid) {
                        this.$message.error(this.$t('common.pleaseComplete'));
                        return false
                    } else {
                        let subData = {
                            ...this.rowData,
                            list: this.userData,
                        }
                        if (this.sms) {
                            subData.isNotificationSms = '1';
                        }
                        if (this.email) {
                            subData.isNotificationEmail = '1';
                        }
                        if (this.uploadIdList) {
                            subData.fileId = this.uploadIdList;
                        }
                        this.$emit("add", subData)
                    }
                })
            },
            clearAdd() {
                this.formData = {};
                this.$emit("clear");
            },
            getUser() {
                post("/setting-user/list", {
                    page: 1,
                    limit: 1000
                }).then(res => {
                    if (res.code == 1) {
                        this.userList = res.data.data
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            userChange(data) {

            },
            //上传文件,
            submitUpload() {
                this.$refs.upload.submit();
                this.loading = true;
            },
            //上传成功回调
            uploadSuccess(response, file, fileList) {
                if (response.code == 1) {
                    this.$message.success(this.$t('common.uploadSuccess'));
                    this.uploadIdList = response.data.id;
                    this.loading = false
                }
            },
            //超出处理
            uploadChange(file, fileList) {
                let fileData = [];
                fileData.push(fileList[fileList.length - 1]);
                this.fileList = fileData;
            },
            setData(row) {
                console.log(row);
                this.userData = row.list
                // row.list.forEach(item=>{
                //     console.log(item);
                //     this.user.push(item.userId)
                // })
            },
            clearUser() {
                this.userDialog = false
            },
            saveUser(data) {
                console.log(data);
                data.map((item, index) => {
                    if (index == 0) {
                        item.isPrimary = 1
                    } else {
                        item.isPrimary = 0
                    }
                })
                this.userData = data;
                this.clearUser();
            },
            primaryUser(row) {
                this.userData.map(item => {
                    if (item.userId == row.userId) {
                        item.isPrimary = 1
                    } else {
                        item.isPrimary = 0
                    }
                })
            },
            deleteUser(row) {
                var r = this.userData.filter(function (item) {
                    return item.userId != row.userId;
                });
                this.userData = r;
            },
            getFileInfo(id) {
                get("/file/info/" + id).then(res => {
                    if (res.code == 1) {
                        this.uploadIdList = res.data.id;
                        this.fileList.push({
                            name:res.data.sourceName,
                            status:'success'
                        })
                        // 预览文件名称,添加响应式 prop
                        this.$set(this.rowData,'sourceName',res.data.sourceName)
                    } else {
                        this.$message.error(res.data);
                    }
                });
            },
            setTime(data){
                let time = new Date(data);
                let Year = time.getFullYear();
                let Month = time.getMonth() + 1;
                let Day = time.getDate();
                let Hours = time.getHours();
                let Minutes = time.getMinutes();
                let Seconds = time.getSeconds();
                return Year+'-'+ this.add0(Month)+'-'+this.add0(Day)+' '+this.add0(Hours)+':'+this.add0(Minutes)+':'+this.add0(Seconds)
            },
            //补0
            add0(time){
                if (time<10){
                    return '0'+time
                } else {
                    return time
                }
            },
            //查看简历
            async showFile(){
                var res = await request({
                    url: "/file/download",
                    method: "POST",
                    params: {
                        id:this.rowData.fileId
                    },
                    responseType: "blob"
                });
                const blob = new Blob([res], {
                    type:"application/pdf"
                });
                this.iframeUrl=URL.createObjectURL(blob);

                this.fileDialog=true;
                console.log(this.iframeUrl);
            }

        },
        mounted() {
            this.rowData.interviewTime = this.setTime(this.rowData.interviewTime)
            this.getUser();
            if (this.rowData.fileId) {
                this.getFileInfo(this.rowData.fileId)
            }
            this.uploadHeader = {
                Authorization: getToken()
            };
        }
    }
</script>

<style scoped>
    .tips{
        color: red;
    }
    .tips:before{
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }
    .file-tip{
        cursor: pointer;
        color:rgb(43, 112, 241)
    }
</style>
