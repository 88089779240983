<template>
  <div class="add-temp">
    <el-form
      ref="addForm"
      :model="searchlist"
      :rules="rules"
      label-suffix="："
      label-width="160px"
    >
      <el-form-item :label="$t('interview.candidate')" prop="name">
        <el-input
          v-model="searchlist.name"
          :placeholder="$t('common.pleaseEnter') + $t('interview.candidate')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('test.candidateArea')" prop="phoneNumberPrefix">
        <el-select
          v-model="searchlist.phoneNumberPrefix"
          :placeholder="$t('common.pleaseEnter') + $t('test.candidateArea')"
          style="width: 100%"
        >
          <el-option :label="$t('test.china')" value="86"></el-option>
          <el-option label="U.S.A" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('interview.gender')" prop="sex">
        <el-select
          v-model="searchlist.sex"
          :placeholder="$t('common.pleaseSelect') + $t('interview.gender')"
          style="width: 100%"
        >
          <el-option
            v-for="item in genderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Email" prop="mailbox">
        <el-input
          v-model="searchlist.mailbox"
          :placeholder="$t('common.pleaseEnter') + $t('interview.email')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('interview.phoneNumber')" prop="phoneNumber">
        <el-input
          v-model="searchlist.phoneNumber"
          :placeholder="$t('common.pleaseEnter') + $t('interview.phoneNumber')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('interview.interviewer')" prop="interviewerId">
        <el-select
          v-model="searchlist.interviewerId"
          :placeholder="$t('common.pleaseSelect') + $t('interview.interviewer')"
          v-loadMore="loadMore"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in interviewerOptions"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="通知方式">
        <el-radio v-model="searchlist.note" label="2">短信</el-radio>
        <el-radio v-model="searchlist.email" label="1">邮件</el-radio>
      </el-form-item> -->
      <el-form-item :label="$t('interview.notifyMethod')">
        <el-checkbox v-model="sms">{{ $t('interview.message') }}</el-checkbox>
        <el-checkbox v-model="email" @click.native.prevent>{{
          $t('interview.email')
        }}</el-checkbox>
      </el-form-item>
    </el-form>
    <div class="boontbtn">
      <el-button type="primary" size="small" @click="sendAddClick">
        {{ $t('interview.invitation') }}
      </el-button>
      <el-button size="small" @click="$emit('clearAdd')">{{
        $t('common.cancel')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { post } from '@/api/http'
import '../../../utils/loadMore'
export default {
  data() {
    return {
      searchlist: {
        sex: '1',
      },
      genderOptions: [
        {
          label: this.$t('interview.male'),
          value: '1',
        },
        {
          label: this.$t('interview.female'),
          value: '2',
        },
      ],
      sms: false,
      email: true,
      interviewerOptions: [],
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 0,
        pageSizes: this.$pageSizes,
      },
      rules: {
        name: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('interview.candidate'),
            trigger: 'blur',
          },
        ],
        mailbox: [
          {
            required: true,
            message: this.$t('common.pleaseEnter') + this.$t('interview.email'),
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('interview.phoneNumber'),
            trigger: 'blur',
          },
        ],
        interviewerId: [
          {
            required: true,
            message:
              this.$t('common.pleaseSelect') + this.$t('interview.interviewer'),
            trigger: 'change',
          },
        ],
        phoneNumberPrefix: [
          {
            required: true,
            message:
              this.$t('common.pleaseSelect') + this.$t('test.candidateArea'),
            trigger: 'change',
          },
        ],
      },
    }
  },

  components: {},

  computed: {},

  created() {
    this.loadMore()
  },

  methods: {
    sendAddClick() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          //alert('submit!');

          if (this.email) {
            this.searchlist.notificationMode = 1
          }
          if (this.sms) {
            this.searchlist.notificationMode = 2
          }
          this.$emit('sendAddClick', this.searchlist)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    loadMore() {
      this.pageConfig.pageNo++
      this.getInterviewerList(this.pageConfig.pageNo)
    },
    getInterviewerList(page) {
      post('/setting-user/list', {
        page: page,
        limit: this.pageConfig.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.interviewerOptions = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
  },
}
</script>
<style scoped>
.add-temp {
  padding: 15px;
}
.wd-15 {
  width: 15%;
}
.wd-20 {
  width: 20%;
}
.pl-15 {
  padding-left: 15px;
}
</style>
