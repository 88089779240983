<template>
  <div>
    <el-row>
      <el-col :span="24"
        ><div class="fs16 bold mt-20">
          {{ $t('candidate.basicInfo') }}
        </div></el-col
      >
    </el-row>
    <div class="basic-box">
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.candidateName') }}：</span>
            <span>{{ rowDetailsData.candidateName }}</span>
          </div>
        </el-col>
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.phone') }}：</span>
            <span>{{ rowDetailsData.candidatePhone }}</span>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.email') }}：</span>
            <span>{{ rowDetailsData.candidateEmail }}</span>
          </div></el-col
        >
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.workStatus') }}：</span>
            <span v-if="rowDetailsData.workIdentity == '1'">OPT (year one)</span>
            <span v-if="rowDetailsData.workIdentity == '2'">CPT</span>
            <span v-if="rowDetailsData.workIdentity == '3'">H1B</span>
            <span v-if="rowDetailsData.workIdentity == '4'">GC</span>
            <span v-if="rowDetailsData.workIdentity == '5'">Citzen</span>
            <span v-if="rowDetailsData.workIdentity == '6'">OPT</span>
            <span v-if="rowDetailsData.workIdentity == '7'">other</span>
          </div></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.jobHuntingStatus') }}：</span>
            <span v-if="rowDetailsData.jobHuntingStatus == '1'">{{ $t('candidate.Leaveavailabl') }}</span>
            <span v-if="rowDetailsData.jobHuntingStatus == '2'">{{ $t('candidate.jobWithin') }}</span>
            <span v-if="rowDetailsData.jobHuntingStatus == '3'">{{ $t('candidate.jobConsider') }}</span>
            <span v-if="rowDetailsData.jobHuntingStatus == '4'">{{ $t('candidate.jobYet') }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.workLocation') }}：</span>
            <span>{{ rowDetailsData.workLocation }}</span>
          </div>
        </el-col>
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.whatsAccount') }}：</span>
            <span>{{ rowDetailsData.whatsAccount }}</span>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.linkedinAccount') }}：</span>
            <span>{{ rowDetailsData.linkedinAccount }}</span>
          </div></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.facebookAccount') }}：</span>
            <span>{{ rowDetailsData.facebookAccount }}</span>
          </div></el-col
        >
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.weChatAccount') }}：</span>
            <span>{{ rowDetailsData.weChatAccount }}</span>
          </div></el-col
          >
          <el-col :span="6">
            <div class="basic-box-info">
              <span>{{ $t('candidate.cptSponsorship') }}：</span>
              <span>{{ rowDetailsData.cptSponsorship }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="basic-box-info">
              <span>{{ $t('candidate.marketingServices') }}：</span>
              <span>{{ rowDetailsData.marketingServices }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="6">
            <div class="basic-box-info">
              <span>{{ $t('candidate.hOneBSponsorship') }}：</span>
              <span>{{ rowDetailsData.honeBSponsorship }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="basic-box-info">
            <span> {{ $t('candidate.gcSponsorship') }}：</span>
            <span>{{ rowDetailsData.gcSponsorship }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="basic-box-info">
            <span>{{ $t('candidate.optExtensionSponsorship') }}：</span>
            <span v-if="rowDetailsData.optExtensionSponsorship == '1'">{{ $t('candidate.accepted') }}</span>
            <span v-if="rowDetailsData.optExtensionSponsorship == '2'">{{ $t('candidate.paid') }}</span>
            <span v-if="rowDetailsData.optExtensionSponsorship == '3'">{{ $t('candidate.freeCharge') }}</span>
            <span v-if="rowDetailsData.optExtensionSponsorship == '4'">{{ $t('candidate.depends') }}</span>
            <span v-if="rowDetailsData.optExtensionSponsorship == '5'">{{ $t('candidate.na') }}</span>
          </div>
        </el-col>
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.technicalTraining') }}：</span>
            <span v-if="rowDetailsData.technicalTraining == '1'">{{ $t('candidate.accepted') }}</span>
            <span v-if="rowDetailsData.technicalTraining == '2'">{{ $t('candidate.paid') }}</span>
            <span v-if="rowDetailsData.technicalTraining == '3'">{{ $t('candidate.freeCharge') }}</span>
            <span v-if="rowDetailsData.technicalTraining == '4'">{{ $t('candidate.depends') }}</span>
            <span v-if="rowDetailsData.technicalTraining == '5'">{{ $t('candidate.na') }}</span>
          </div></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="6"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.degree') }}：</span>
            <span>{{ rowDetailsData.degree }}</span>
          </div></el-col
        >
        <el-col :span="18"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.currentPlaceWork') }}：</span>
            <span
              >{{ rowDetailsData.currentPlaceWork || rowDetailsData.currentPlaceWorkCode
              }}{{ rowDetailsData.currentPlaceWorkDetails }}
            </span>
          </div></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="24"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.currentPlaceResidence') }}：</span>
            <span
              >{{ rowDetailsData.currentPlaceResidence || rowDetailsData.currentPlaceResidenceCode
              }}{{ rowDetailsData.currentPlaceResidenceDetails }}</span
            >
          </div></el-col
        >
      </el-row>
    </div>
    <el-row v-if="rowDetailsData.examEducationList.length > 0">
      <el-col :span="24"
        ><div class="fs16 bold mt-20">
          {{ $t('candidate.educationIfo') }}
        </div></el-col
      >
    </el-row>
    <div class="basic-box">
      <!-- <el-table
        :data="rowDetailsData.examEducationList"
        style="width: 100%"
        label-suffix="："
      >
        <el-table-column label="学校名称">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="就读时间" width="320">
          <template slot-scope="scope">
            {{ scope.row.studyTimeStart }}-{{ scope.row.studyTimeEnd }}
          </template>
        </el-table-column>
        <el-table-column label="所学专业">
          <template slot-scope="scope">
            {{ scope.row.profession }}
          </template>
        </el-table-column>
        <el-table-column label="学历学位">
          <template slot-scope="scope">
            {{ scope.row.degree }}
          </template>
        </el-table-column>
      </el-table> -->
      <el-row
        type="flex"
        justify="space-between"
        v-for="(item, index) in rowDetailsData.examEducationList"
        :key="'examEducation' + index"
      >
        <el-col :span="5">
          <div class="basic-box-info">
            <span>{{ $t('candidate.schoolName') }}：</span>
            {{ item.name }}
          </div>
        </el-col>
        <el-col :span="9"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.studyTime') }}：</span>
            {{ item.studyTimeStart }}-{{ item.studyTimeEnd }}
          </div></el-col
        >
        <el-col :span="4"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.profession') }}：</span>
            {{ item.profession }}
          </div></el-col
        >
        <el-col :span="3">
          <div class="basic-box-info">
            <span>{{ $t('candidate.academicDegree') }}：</span>
            {{ item.degree }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="basic-box-info">
            <span>{{ $t('candidate.achievement') }}：</span>
            {{ item.achievement }}
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="rowDetailsData.examWorkExperienceList.length > 0">
      <el-col :span="24"
        ><div class="fs16 bold mt-20">
          {{ $t('candidate.workExperience') }}
        </div></el-col
      >
    </el-row>
    <div class="basic-box">
      <el-row
        type="flex"
        justify="space-between"
        v-for="(item, index) in rowDetailsData.examWorkExperienceList"
        :key="'examWorkExperience' + index"
      >
        <el-col :span="5">
          <div class="basic-box-info">
            <span>{{ $t('candidate.companyName') }}：</span>
            {{ item.name }}
          </div>
        </el-col>
        <el-col :span="9"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.workingTime') }}：</span>
            {{ item.workingTimeStart }}-{{ item.workingTimeEnd }}
          </div></el-col
        >
        <el-col :span="4"
          ><div class="basic-box-info">
            <span>{{ $t('candidate.position') }}：</span>
            {{ item.position }}
          </div></el-col
        >
        <el-col :span="6"></el-col>
      </el-row>
      <!-- <el-table
        :data="rowDetailsData.examWorkExperienceList"
        style="width: 100%"
        label-suffix="："
      >
        <el-table-column label="公司">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="工作时间">
          <template slot-scope="scope">
            {{ scope.row.workingTimeStart }}-{{ scope.row.workingTimeEnd }}
          </template>
        </el-table-column>
        <el-table-column label="职位">
          <template slot-scope="scope">
            {{ scope.row.position }}
          </template>
        </el-table-column>
      </el-table> -->
    </div>
    <div class="boontbtn">
      <el-button type="primary" size="small" @click="clearDetails"
        >{{$t('common.cancel')}}</el-button
      >
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl, uploadImg } from '@/api/http'
import '../../assets/css/style.css'
export default {
  props: {
    rowDetailsData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },

  components: {},

  computed: {},


  methods: {
    clearDetails() {
      this.$emit('clearDetails')
    },
  },
  mounted(){
  },
  created() {},

}
</script>
<style scoped>
.basic-box {
  padding: 15px;
}
.basic-box .el-row {
  padding-bottom: 20px;
}
.basic-box-info {
  margin-bottom: 20px;
}
.fs16 {
  font-size: 16px;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.bold {
  font-weight: bold;
}
.mt-20 {
  margin-bottom: 20px;
}
</style>
